/**
 * ----------------------------------
 * Breadcrumb
 * ----------------------------------
 */
.breadcrumb {
  margin: $gutter 0;
  display: none;

  @include media-breakpoint-up(md) {
    display: block;
  }

  ol {
    display: flex;
    align-items: center;
    list-style: none;
  }

  li {
    &:not(:last-child) {
      a {
        color: col(blue);
        padding-right: $gutter;

        > span {
          &:after {
            @include background(true, 'chevron.svg', blue, contain);

            content: "";
            position: absolute;
            top: 4px;
            right: 4px;
            width: 8px;
            height: 8px;
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  a {
    @include montserrat;

    position: relative;
    font-size: $font-size-small;
    text-decoration: none;
    text-transform: uppercase;
    color: col(gold);
  }
}
