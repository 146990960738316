/**
 * ----------------------------------
 * Spinner loader
 * ----------------------------------
 */
.is--loading {
  .spin-loader {
    opacity: 1;
    visibility: visible;
  }
}

.spin-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 3;
  visibility: hidden;
  background-color: rgba(col(white), .85);
  transition: opacity .2s linear;
}
