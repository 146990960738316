/**
 * ----------------------------------
 * Mixins
 * ----------------------------------
 */

// Roboto
@mixin roboto {
  font-family: $font-roboto;
  font-weight: 400;
}

@mixin roboto-light {
  font-family: $font-roboto;
  font-weight: 300;
}

@mixin roboto-medium {
  font-family: $font-roboto;
  font-weight: 500;
}

@mixin roboto-semibold {
  font-family: $font-roboto;
  font-weight: 600;
}

@mixin roboto-bold {
  font-family: $font-roboto;
  font-weight: 700;
}

// Montserrat
@mixin montserrat {
  font-family: $font-montserrat;
  font-weight: 400;
}

@mixin montserrat-light {
  font-family: $font-montserrat;
  font-weight: 300;
}

@mixin montserrat-medium {
  font-family: $font-montserrat;
  font-weight: 500;
}

@mixin montserrat-semibold {
  font-family: $font-montserrat;
  font-weight: 600;
}

@mixin montserrat-bold {
  font-family: $font-montserrat;
  font-weight: 700;
}

// Heading title
@mixin typo-h1 {
  @include montserrat-bold;
  font-size: 24px;

  @include media-breakpoint-up(lg) {
    font-size: 28px;
  }
}

@mixin typo-h2 {
  @include montserrat-bold;
  font-size: 18px;

  @include media-breakpoint-up(lg) {
    font-size: 24px;
  }
}

@mixin typo-h3 {
  @include montserrat-bold;
  font-size: 16px;

  @include media-breakpoint-up(lg) {
    font-size: 18px;
  }
}

// CTA
%button {
  @include montserrat-semibold;

  display: inline-block;
  transition: all .3s ease;
  text-decoration: none;
  outline: none;
  border: none;
  cursor: pointer;
  text-align: center;
}

@mixin default-button {
  @extend %button;

  font-size: $font-size-medium;
  color: col(blue);
  background-color: col(white);
  padding: calc(#{$gutter} - 5px) calc(#{$gutter} * 2);

  &:hover {
    color: col(white);
    background-color: col(gold);
    border-color: col(gold);
  }
}

@mixin button-color($color, $bg, $hover) {
  @extend %button;

  color: col($color);
  background-color: col($bg);
  padding: $gutter;
  border: 2px solid col($bg);
  font-size: $font-size-small;
  line-height: normal;

  &:disabled {
    background-color: col(grey);
    border-color: col(grey);
    cursor: not-allowed;
  }

  &:hover {
    color: col($color);
    background-color: col($hover);
    border-color: col($hover);
  }
}

@mixin a-button-color($color, $bg, $hover) {
  @extend %button;

  color: col($color);
  background-color: col($bg);
  padding: $gutter;

  &:disabled {
    background-color: col(grey);
    border-color: col(grey);
    cursor: not-allowed;
  }

  &:hover {
    color: col($color);
    background-color: col($hover);
    border-color: col($hover);
  }
}

@mixin button-border($color, $hover) {
  @extend %button;

  color: col($color);
  background-color: transparent;
  padding: $gutter;
  border: 2px solid col($color);
  font-size: $font-size-small;
  line-height: normal;

  &:hover {
    color: col($hover);
    background-color: transparent;
    border-color: col($hover);
  }
}

@mixin add-to-cart-button {
  @include montserrat-semibold;

  display: inline-block;
  font-size: $font-size-medium;
  color: col(white);
  background-color: col(blue);
  text-transform: uppercase;
  padding: calc(#{$gutter} + 5px);
  transition: all .3s ease;
  text-decoration: none;
  outline: none;
  border: none;
  cursor: pointer;

  &:hover {
    color: col(white);
    background-color: col(gold);
  }

  &:disabled {
    background-color: col(grey);
    cursor: not-allowed;
  }
}

// Figure shape
@mixin figure-square() {
  position: relative;

  &:before {
    content: "";
    display: block;
    padding-top: 100%;
  }

  .image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;

    img {
      width: 100%;
      min-height: auto;
    }
  }
}

// Link
@mixin link($color: white, $border-color: white, $font-size: 14px, $y: 0, $inverse: false) {
  color: col($color);
  text-decoration: none;
  position: relative;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: $font-size;
  line-height: 21px;

  &:after {
    content: "";
    position: absolute;
    bottom: $y;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: col($border-color);
    transform-origin: center;
    transition: transform .3s $ease-default;
  }

  @if $inverse {
    &:after {
      transform: scaleX(0);
    }
    &:hover {
      &:after {
        transform: scaleX(1);
      }
    }
  }
  @else {
    &:after {
      transform: scaleX(1);
    }
    &:hover {
      &:after {
        transform: scaleX(0);
      }
    }
  }
}

// accordion header
@mixin accordion-header($color, $chevron, $color-chevron, $size-chevron, $x: 0, $is_aria: true) {
  position: relative;
  text-align: left;
  padding: 5px 14px 5px 0;
  text-transform: uppercase;
  color: col($color);
  display: block;
  background: transparent;
  width: 100%;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid col(gold);
  cursor: pointer;
  @content;

  &:focus,
  &:hover {
    outline: none;
  }

  &:after {
    @include background(true, $chevron, $color-chevron, $size-chevron, right, center);

    content: '';
    position: absolute;
    right: $x;
    top: 0;
    bottom: 0;
    width: $size-chevron;
    transform: rotate(-90deg);
    transition: transform .55s ease;
  }

  @if $is_aria {
    &[aria-expanded="true"],
    &.active-header {
      &:after {
        transform: rotate(90deg);
      }
    }
  }
}

// background image
@mixin background($svg: false, $image, $color: white, $size: cover, $x: 50%, $y: 50%) {

  @if $svg {
    @include svg($image, $color);
  }
  @else {
    background-image: $image;
  }

  background: {
    color: transparent;
    repeat: no-repeat;
    position: $x $y;
    size: $size;
  }
}

// svg
@mixin svg($svg, $fill: false) {
  background: {
    image: svg-load($svg, fill=col($fill));
  }
}

// text ellipsis
@mixin text-ellipsis() {
  width: calc(100% - 30px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin breakpointMixin() {
  @each $breakpoint, $container-max-width in $container-max-widths {
    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
      @content($breakpoint, $container-max-width);
    }
  }
}
