/**
 * ----------------------------------
 * Manufacturers stylesheet
 * ----------------------------------
 */
.manufacturer-list-header {
  padding: calc(#{$gutter} * 2) 0;

  @include media-breakpoint-up(md) {
    padding: calc(#{$gutter} * 4) 0;
  }

  .title {
    @include typo-h1;

    color: col(blue);
    text-transform: uppercase;
    margin-bottom: $gutter;
  }

  /* .subtitle {
    @include montserrat-light;

    font-size: $font-size-subtitle;
    color: col(gold);
    line-height: 1.4;
    margin-bottom: $gutter;

    @include media-breakpoint-up(md) {
      font-size: $font-size-title;
    }
  } */

  .map {
    position: relative;
    height: 100%;
    width: 100%;
    padding-top: 75%;

    .image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      img {
        width: 90%;
        min-width: auto;
        min-height: auto;
      }
    }
  }
}

.manufacturer-list {
  div[class*="col"] {
    margin-bottom: calc(#{$gutter} * 2);

    @media (min-width: 480px) and (max-width: 640px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  .brand {
    padding: 10px;
    height: 100%;
    max-width: 320px;
    min-height: 450px;
    border: 1px solid col(gold);
    margin: auto;

    &-img {
      position: relative;
      min-height: 200px;
      margin-bottom: 10px;

      @include media-breakpoint-up(md) {
        min-height: 160px;
      }

      .image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        img {
          width: 100%;
          min-width: auto;
          min-height: auto;
        }
      }
    }

    &-infos {
      .thumbnail {
        position: relative;
        min-height: 200px;
        margin-bottom: $gutter;

        @include media-breakpoint-up(md) {
          min-height: 150px;
        }

        .image {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }

      .link {
        @include link(blue, gold);
        @include montserrat-bold;

        font-size: $font-size-medium;
        padding-top: 10px;
      }
    }
  }
}

.henaff-manufacturer {
  .manufacturer-logo {
    position: relative;
    width: calc(59.87% + calc(#{$gutter} * 2));
    height: 150px;
    //padding-top: 5%;
    margin: {
      top: -80px;
      left: auto;
      right: auto;
    };

    @include media-breakpoint-up(sm) {
      margin-top: -145px;
    }

    @include media-breakpoint-up(lg) {
      height: 200px;
      width: calc(42.77% + calc(#{$gutter} * 2));
    }

    &:before {
      content: "";
      position: absolute;
      background-color: col(white);
      left: 0;
      right: 0;
      top: 0;
      padding-top: 100%;
      border-radius: 50%;
    }

    .image {
      width: 45%;
      max-width: 160px;
      min-height: 160px;
      margin: auto;

      img {
        width: 100%;
        min-height: auto;
      }
    }
  }

  .manufacturer-about {
    padding: calc(#{$gutter} * 3) 0;

    @include media-breakpoint-up(md) {
      padding: calc(#{$gutter} * 4) 0;
    }

    .visual {
      @include figure-square();

      max-width: 360px;
      margin: auto;
    }

    .text-content {
      text-align: center;
      padding: 0 8.54%;
      margin-bottom: calc(#{$gutter} * 2);

      .title {
        @include typo-h1;

        color: col(blue);
        margin-bottom: $gutter;
      }

      .subtitle {
        @include montserrat-light;

        font-size: $font-size-subtitle;
        color: col(gold);
        line-height: 1.4;
        margin-bottom: $gutter;

        @include media-breakpoint-up(md) {
          font-size: $font-size-title;
        }
      }
    }
  }

  .manufacturer-sections {
    padding: 0 0 100px;
    background-color: col(light-gold);

    @include media-breakpoint-up(md) {
      padding: calc(#{$gutter} * 2) 0 176px;
    }

    .section {
      padding: $gutter 0;
      text-align: center;

      @include media-breakpoint-up(md) {
        padding: calc(#{$gutter} * 2) 0;
      }

      &-column {
        .text-content {
          padding: 0;

          @include media-breakpoint-up(md) {
            padding: 0 8.55%;
          }
        }
      }
    }

    .image-section,
    .video {
      margin-bottom: $gutter;
    }

    .image-section {
      position: relative;
      padding-top: 70%;

      .image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }

    .title {
      @include typo-h1;

      color: col(blue);
      margin-bottom: $gutter;
    }
  }
}

.manufacturer-adress {
  margin-top: -85px;

  @include media-breakpoint-up(md) {
    margin-top: -146px;
  }

  &.no-marge {
    margin-top: 0;
  }

  .adress {
    border: 1px solid col(gold);
    background-color: col(white);
    padding: $gutter;
    width: 100%;
    margin: auto;

    @include media-breakpoint-up(md) {
      width: 75%;
    }

    @include media-breakpoint-up(lg) {
      width: 62%;
    }

    .map {
      position: relative;
      
      &:before {
        content: "";
        display: block;
        padding-top: 85%;
      }

      .image {
        position: absolute;
        top: 0;
        left: 0;

        img {
          top: 0;
          width: 100%;
          min-height: auto;
          transform: rotate(.001deg) translate3d(-50%, 0, 0);
        }
      }
    }

    .logo {
      position: relative;
      padding-top: 160px;
      margin-bottom: calc(#{$gutter} * 2);

      .image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        img {
          width: 160px;
          min-width: auto;
          min-height: auto;
          left: 0;
          top: 0;
          transform: rotate(.001deg) translate3d(0, 0, 0);
        }
      }
    }

    .editor-content {
      color: col(blue);
      margin-bottom: $gutter;
      font-size: $font-size-medium;

      p {
        margin: 0;
      }
    }
  }
}

.manufacturer-products {
  padding: calc(#{$gutter} * 2) 0;

  @include media-breakpoint-up(md) {
    padding: calc(#{$gutter} * 3) 0;
  }

  .title {
    @include typo-h2;

    color: col(blue);
    text-transform: uppercase;
    margin-bottom: $gutter;
    text-align: center;
  }
  .link-more {
    @include montserrat-semibold;
    @include button-color(white, blue, gold);

    font-size: $font-size-medium;
    text-transform: uppercase;
  }
  .link-more-wrapper {
    text-align: center;
  }
}
