/**
 * ----------------------------------
 * Product card stylesheet
 * ----------------------------------
 */
.product-card {
  height: 100%;
  min-height: 340px;
  box-shadow: $shadow-card;

  @include media-breakpoint-up(sm) {
    min-height: 439px;
  }

  .card-image {
    position: relative;
    background-color: col(white);

    &:hover,
    &.hover {
      .product-quickview {
        opacity: 1;
        visibility: visible;

        .picto,
        .product-description {
          opacity: 1;
          visibility: visible;
          transform: translate3d(0, 0, 0);
        }

        .picto {
          transition-delay: .3s;
        }

        .product-description {
          transition-delay: .5s;
        }
      }
    }

    .thumbnail {
      display: block;

      .image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        img {
          width: 100%;
          min-width: auto;
          min-height: auto;

          @include media-breakpoint-up(sm) {
            width: auto;
          }
        }
      }
    }

    .discount-product {
      @include montserrat-semibold;

      position: absolute;
      bottom: 0;
      right: 0;
      font-size: $font-size-medium;
      padding: 10px 5px;
      color: col(white);
      background-color: col(promo);
    }

    .manufacturer {
      position: absolute;
      top: 10px;
      right: $gutter;
      width: 70px;

      a {
        color: col(white);
        text-decoration: none;
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    .flags {
      li {
        @include montserrat-semibold;

        position: absolute;
        z-index: 2;
        color: col(white);
      }

      .on-sale {
        top: 0;
        left: 0;

        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
          border-top: 90px solid col(promo);
          border-right: 95px solid transparent;
        }

        span {
          display: inline-block;
          text-transform: uppercase;
          transform: translate3d(10%, 130%, 0) rotate(-45deg);
        }
      }

      .new {
        display: none;
        left: 0;
        bottom: 0;
        padding: 12px 5px;
        background-color: col(green);
      }
    }
  }

  .card-body {
    .product-manufacturer {
      @include montserrat-semibold;

      background-color: col(gold);
      text-transform: uppercase;
      padding: 8px $gutter;
      margin-bottom: 0;

      a {
        color: col(white);
        text-decoration: none;
      }
    }

    .product-title {
      font-size: $font-size-small;
      margin-bottom: $gutter;

      @include media-breakpoint-up(md) {
        font-size: $font-size-default;
      }

      & > a {
        @include montserrat-light;

        //text-transform: uppercase;
        color: col(white);
        text-decoration: none;
      }
    }
  }

  .card-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: $gutter;
    display: flex;
    justify-content: space-between;

    .product-price-and-shipping {
      @include montserrat-semibold;

      a {
        color: col(white);
        text-decoration: none;
      }

      .regular-price {
        position: relative;
        font-size: $font-size-medium;
        color: col(gold);
        margin-left: 5px;

        &:after {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          width: 100%;
          height: 2px;
          background: col(gold);
          transform: rotate(-12deg);
        }
      }

      .price {
        font-size: $font-size-sectiontitle;
      }
    }

    .preview-add-to-cart {
      .add-to-cart {
        background-color: transparent;
        border: none;
        transition: all .3s $ease-default;

        &:disabled {
          cursor: not-allowed;
          opacity: .5;

          .icon {
            @include svg('panier.svg', grey);

            &:hover {
              @include svg('panier.svg', grey);
            }
          }
        }

        .icon {
          width: 27px;
          height: 33px;
          display: block;
          margin: 0;

          &:hover {
            @include svg('panier.svg', white);
          }
        }
      }
    }
  }
}
/**
 * Product simple
 */
.product-simple-card {
  position: relative;
  padding-bottom: 70px;
  background-color: col(blue);

  .card-image {
    .thumbnail {
      position: relative;

      &:before {
        content: "";
        display: block;
        padding-top: 92%;
      }
    }

    .product-quickview {
      @include montserrat;

      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      font-size: 12px;
      padding: $gutter;
      background-color: $modal-backdrop-bg;
      opacity: 0;
      z-index: 3;
      visibility: hidden;
      transition: opacity .5s linear, visibility .5s linear;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: col(white);
      text-decoration: none;

      .picto {
        width: 36px;
        margin-bottom: $gutter;
      }

      .picto,
      .product-description {
        opacity: 0;
        visibility: hidden;
        transform: translate3d(0, 10px, 0);
        transition: opacity .5s $ease-default, visibility .5s $ease-default, transform .5s $ease-default;
      }
    }
  }

  .card-body {
    .product-title {
      padding: 0 $gutter;
      margin-top: 20px;
    }
  }
}
/**
 * Product highlight
 */
.product-highlight-card {
  display: flex;
  height: 100%;

  .card-image {
    flex-grow: 0;
    flex-shrink: 0;
    width: 50%;
  }

  .card-body {
    display: flex;
    align-items: center;
    position: relative;
    padding-top: 52px;
    padding-bottom: 70px;
    background-color: col(blue);
    flex-grow: 0;
    flex-shrink: 0;
    width: 50%;

    .product-manufacturer {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }

    .center-block {
      padding: 0 $gutter;
    }

    .flags {
      margin-bottom: $gutter;

      > li {
        @include montserrat-bold;

        display: inline-block;
        font-size: $font-size-small;
        padding: $gutter;
        margin-right: calc(#{$gutter} - 5px);
        background-color: col(gold);
        color: col(white);
        text-transform: uppercase;
        border-top-left-radius: 23px;
        border-bottom-right-radius: 23px;
      }
    }

    .product-description {
      @include roboto;

      font-size: $font-size-small;

      @include media-breakpoint-up(md) {
        font-size: $font-size-default;
      }

      a {
        color: col(white);
        text-decoration: none;
      }
    }
  }
}
