/**
 * ----------------------------------
 * Editor
 * ----------------------------------
 */
.editor-content,
.cms-content {
  p {
    @include roboto;

    font-size: $font-size-default;
    margin-bottom: $gutter;

    &:only-child {
      margin-bottom: 0;
    }
  }

  a {
    @include link();

    &.link-blue {
      @include link(blue, gold);
    }
  }

  #{$header-selector} {
    text-transform: uppercase;
    color: col(blue);
    margin-bottom: $gutter;
  }

  h1 {
    @include typo-h1;
  }

  h2 {
    @include typo-h2;
  }

  h3 {
    @include typo-h3;
  }

  ul {
    @include roboto;

    list-style: none;
    margin-bottom: $gutter;

    li {
      position: relative;
      padding-left: 10px;
      line-height: 1.5;
      margin-bottom: 5px;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 10.5px;
        height: 4px;
        width: 4px;
        transform: translateY(-2px);
        border-radius: 50%;
        background-color: col(gold);
      }
    }
  }

  ol {
    @include roboto;

    list-style: none;
    counter-reset: number;
    margin-bottom: $gutter;

    li {
      position: relative;
      padding-left: 20px;
      line-height: 1.5;
      margin-bottom: 5px;
      counter-increment: number;

      &:before {
        @include roboto-bold;

        content: counter(number) ".";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        color: col(gold);
      }
    }
  }

  .text-wrapper {
    margin-bottom: $gutter;
  }

  &.content-blue {
    a {
      @include link(blue, blue);
    }
  }
}
