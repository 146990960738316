/**
 * ----------------------------------
 * product comment stylesheet
 * ----------------------------------
 */
#product-comments-list-header {
  padding: $gutter;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

#post-product-comment-modal {
  .star-content div.star a {
    display: block;
    position: absolute;
    text-indent: -5000px;
  }

  input,
  textarea {
    &.error {
      border-color: col(red);
    }
  }
}

#product-comments-list-pagination {
  text-align: right;
  margin-top: $gutter;

  ul {
    > li {
      line-height: 0;
      display: inline-block;
      vertical-align: middle;

      &.disabled {
        opacity: .3;
        cursor: not-allowed;
      }

      &.active {
        span {
          font-weight: bold;
          color: col(blue);
          cursor: not-allowed;
        }
      }
    }
  }

  span {
    @include roboto-medium;

    color: col(grey);
    font-size: $font-size-medium;
    margin: 0 5px;
    display: block;
  }
}

#empty-product-comment {
  span {
    @include roboto-medium;

    color: col(blue);
  }
}

.product-comment {
  margin-bottom: calc(#{$gutter} * 2);

  &-header {
    padding: $gutter 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid col(gold);

    button {
      @include roboto-bold;
      @include link(blue, gold, $font-size-default);
    }

    .title {
      @include typo-h2;

      text-transform: uppercase;
      text-decoration: none;
      color: col(blue);
      margin-bottom: 0;
    }
  }

  &-body {
    @include roboto;

    .comments-nb,
    .comments-note {
      @include roboto-medium;

      span {
        vertical-align: top;
      }
    }
  }

  &-list-item {
    padding: $gutter 0;
    border-bottom: 1px solid rgba(col(gold), .2);

    .comment-infos {
      font-size: $font-size-medium;
      margin-bottom: $gutter;

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
    }

    .comment-author {
      color: col(blue);
    }

    .comment-date {
      color: col(gold);
    }

    .comment-content {
      h4 {
        margin-bottom: 5px;
      }

      p {
        margin-bottom: 5px;
      }
    }

    .comment-buttons {
      a {
        display: inline-block;
        margin-right: 5px;
      }

      .useful-review-value,
      .not-useful-review-value {
        vertical-align: bottom;
        color: col(gold);
      }
    }
  }

  &-modal {
    margin-top: $gutter;

    .btn-comment {
      @include button-color(white, blue, gold);

      padding: $gutter calc(#{$gutter} * 2);
      text-transform: uppercase;

      &-big {
        width: 100%;
        text-transform: none;
      }
    }

    .btn-comment-inverse {
      @include button-color(white, blue, gold);

      padding: $gutter calc(#{$gutter} * 2);
      text-transform: uppercase;
      background-color: col(grey);
    }

    .card {
      margin: auto;
      max-width: 500px;

      &-header {
        h3 {
          @include typo-h3;

          color: col(blue);
          text-transform: uppercase;
        }
      }

      &-body {
        @include roboto;

        padding: $gutter 0;

        .form-label {
          .required {
            font-weight: 700;
            color: col(gold);
            vertical-align: baseline;
          }
        }

        .form-group {
          margin-bottom: $gutter;
        }

        input[type="text"],
        input[type="email"],
        textarea {
          width: 100%;
        }
      }
    }
  }
}

// star
.star-content {
  position: absolute;
  top: 3px;
  left: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  .star,
  .star-on,
  .star-hover {
    display: block;
    width: 20px;
    height: 20px;
    background: url("images/stars.png") no-repeat 0 0 transparent;
    flex: auto;
    margin-left: 3px;
  }

  .star-on,
  .star-hover {
    background-position: -24px 0;
  }

  .star-hover {
    cursor: pointer;
  }
}

.small-stars {
  .star-content {
    .star,
    .star-on,
    .star-hover {
      background: url("images/small_stars.png") no-repeat 0 0 transparent;
      width: 16px;
      height: 16px;
      margin-left: 2px;
    }

    .star-on,
    .star-hover {
      background-position: -19px 0;
    }
  }
}

.grade-stars {
  position: relative;
  height: 20px;
  min-width: 120px;
  display: inline-block;

  .small-stars {
    min-width: 100px;
  }
}

.criterion-rating .grade-stars .star-content {
  top: 0;
}
