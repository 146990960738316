/**
 * ----------------------------------
 * Product pack stylesheet
 * ----------------------------------
 */
.product-pack {
  padding: calc(#{$gutter} * 2) 0;

  @include media-breakpoint-up(md) {
    padding: calc(#{$gutter} * 3) 0;
  }

  .title {
    @include typo-h1;

    color: col(blue);
    text-transform: uppercase;
  }

  .ribbon-wrapper {
    @include montserrat-semibold;

    position: absolute;
    bottom: 0;
    right: 0;
    font-size: $font-size-medium;
    padding: 10px 5px;
    color: col(white);
    background-color: col(promo);

    .ribbon {
      background: none;
    }
  }

  &-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: $gutter 0;

    .product-name {
      flex-grow: 0;
      flex-shrink: 1;
      display: flex;
      flex-wrap: wrap;

      a {
        @include typo-h3;

        text-transform: uppercase;
        text-decoration: none;
        color: col(blue);
        margin-bottom: $gutter;

        @include media-breakpoint-up(lg) {
          margin-right: 10px;
          margin-bottom: 0;
        }
      }

      .see-pack {
        @include montserrat-semibold;
        @include button-color(white, blue, gold);

        font-size: $font-size-default;
      }
    }

    .add-to-cart {
      @include button-border(blue, gold);

      text-transform: uppercase;
      font-size: $font-size-default;
      height: 55px;
    }

    .content_prices {
      flex-grow: 0;
      flex-shrink: 1;

      .price {
        @include montserrat-bold;
        @include typo-h1;

        color: col(blue);
      }

      .regular-price {
        @include typo-h3;

        position: relative;
        color: col(gold);

        &:after {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          width: 100%;
          height: 2px;
          background: col(gold);
          transform: rotate(-12deg);
        }
      }

      .discount-amount {
        @include montserrat-bold;

        color: col(gold);
        font-size: $font-size-subtitle;
      }

      .product-without-taxes {
        @include typo-h3;

        position: relative;
        color: col(gold);
        margin-bottom: 0;
      }
    }
  }
}
