/**
 * ----------------------------------
 * Product list
 * ----------------------------------
 */
.product-list-header {
  margin-bottom: $gutter;

  .title {
    @include typo-h1;

    color: col(blue);
    margin-bottom: $gutter;
  }

  .current-page {
    @include typo-h3;
  }

  .description {
    @include montserrat-medium;

    color: col(blue);
  }
}

// Search filter mobile
#search_filters_wrapper {
  #search_filter_controls {
    margin-bottom: $gutter;
    margin-right: -$gutter;
    margin-left: -$gutter;
    border-bottom: 1px solid col(gold);
  }

  .mobile-facet-toggler {
    @include montserrat-semibold;

    font-size: $font-size-medium;
    color: col(blue);
    text-align: right;

    span {
      vertical-align: sub;
    }
  }

  .mobile-facet-toggler .btn {
    height: 70px;
    border: none;
    color: col(blue);
    font-size: $font-size-medium;
    padding: $gutter 25px;
    margin-right: $gutter;
    transition: all .3s $ease-default;
    @include montserrat-semibold;
    @include background(true, 'chevron-right.svg', blue, 18px, right);

    &.show-facet {
      @include background(true, 'close.svg', blue, 18px, right);
    }
  }
}

// Product List
#js-product-list {
  .products {
    display: flex;
    flex-wrap: wrap;
    margin-right: -7px;
    margin-left: -7px;

    @include media-breakpoint-up(lg) {
      margin-right: calc(#{$gutter} * -2);
      margin-left: calc(#{$gutter} * -2);
    }

    > [class*="col-"] {
      padding-right: 7px;
      padding-left: 7px;
      margin-bottom: $gutter;

      @include media-breakpoint-up(sm) {
        padding-right: calc(#{$gutter} * 2);
        padding-left: calc(#{$gutter} * 2);
        margin-bottom: calc(#{$gutter} * 4);
      }
    }
  }
}

// FAcets filter
#search_filters {
  .facet {
    margin-bottom: $gutter;

    .title {
      @include text-ellipsis();
      @include accordion-header(blue, "chevron.svg", gold, 18px);

      margin-bottom: $gutter;
    }

    &-title {
      @include montserrat-bold;

      margin: 0;
    }

    &-label {
      display: flex;
      padding: 5px 0;

      .custom-checkbox {
        top: 0;

        span {
          border-color: col(gold);
        }
      }

      .search-link {
        @include montserrat;
        @include text-ellipsis();

        color: col(blue);
        text-decoration: none;
        font-size: $font-size-medium;
      }

      .magnitude {
        display: none;
      }
    }
  }
}

// Reset Facet
#_desktop_search_filters_clear_all {
  padding: calc(#{$gutter} * 2) 0;

  .btn {
    @include button-border(gold, blue);

    display: flex;
    align-items: center;
    text-transform: uppercase;

    &:hover {
      .close {
        @include svg('close.svg', blue);
      }
    }
  }

  .close {
    width: 12px;
    height: 12px;
    margin-right: 10px;
    transition: all .3s $ease-default;
  }
}

// Active facet
#js-active-search-filters {
  padding: $gutter calc(#{$gutter} * 2);
  margin: calc(#{$gutter} * 2) 0;
  background-color: col(blue);

  .active-filter-title {
    @include montserrat-semibold;

    margin: 0 0 5px;
    color: col(white);
    text-transform: uppercase;
    padding-top: 5px;
  }

  .filter-block {
    @include montserrat;

    position: relative;
    display: inline-block;
    padding: 10px 25px 10px 10px;
    margin-right: 5px;
    margin-bottom: 10px;
    color: col(blue);
    background-color: col(white);
    font-size: $font-size-small;
  }

  .js-search-link {
    position: absolute;
    top: 50%;
    right: 10px;
    z-index: 2;
    transform: translate3d(0, -50%, 0);

    .close {
      width: 10px;
      height: 10px;
      margin-right: 0;
    }
  }
}

// Select Filter
#js-product-list-top {
  .form-inline {
    .form-group {
      text-align: right;
    }

    .custom-select {
      .holder {
        width: 240px;
        text-align: left;
      }
    }
  }
}
