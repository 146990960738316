/**
 * ----------------------------------
 * Authentication stylesheet
 * ----------------------------------
 */
// return page account link
.account-link {
  @include montserrat-bold;
  @include link(blue, gold, $font-size-medium);

  margin-top: calc(#{$gutter} * 2);
  display: inline-block;
}

.have-account {
  @include montserrat-medium;

  font-size: $font-size-medium;
  margin-top: calc(#{$gutter} * 2);
  color: col(blue);

  a {
    @include link(gold, gold, $font-size-medium);

    margin-left: 10px;
  }
}

// Mention RGPS
.mention-rgpd {
  @include montserrat-medium;

  color: col(blue);
  font-size: $font-size-small;

  a {
    @include link(blue, blue);

    font-size: 12px;
  }
}

// Form section container
.login-form,
.register-form,
.renew-password {
  padding: calc(#{$gutter} * 2) $gutter;
  margin-bottom: calc(#{$gutter} * 2);
  border: 2px solid col(gold);

  @include media-breakpoint-up(md) {
    padding: calc(#{$gutter} * 2);
    margin-bottom: $gutter;
  }
}
//login form
.login-form {
  #login-form {
    @include media-breakpoint-up(md) {
      width: 100%;
    }

    @include media-breakpoint-up(lg) {
      width: 100%;
    }
  }
}

// Forgotten password
.forgotten-password {
  margin-bottom: calc(#{$gutter} * 2);

  .send-renew-password-link {
    @include montserrat-medium;

    padding: calc(#{$gutter} * 2) $gutter;
    background-color: col(light-gold);
    color: col(blue);

    @include media-breakpoint-up(md) {
      padding: calc(#{$gutter} * 2);
    }
  }

  .form-group {
    label {
      @include montserrat-medium;

      color: col(blue);
      font-size: $font-size-default;
      line-height: 1;
      margin-bottom: $gutter;

      @include media-breakpoint-up(md) {
        font-size: $font-size-medium;
      }
    }

    .email {
      margin-bottom: $gutter;

      input[type="email"] {
        width: 100%;
      }
    }

    .button-wrapper {
      text-align: right;

      button {
        @include button-color(white, blue, gold);

        &.d-sm-inline-block {
          @include media-breakpoint-up(sm) {
            display: inline-block !important;
          }
        }
      }

      a {
        @include button-border(blue, gold);
      }
    }
  }
}

// Registration form
.register-form {
  .registration-form-checkout {
    @include media-breakpoint-up(md) {
      width: 100%;
    }
  }

  .loyalty-registration-form {
    label {
      @include montserrat-medium;

      color: col(blue);
      font-size: $font-size-default;
      line-height: 1;
      margin-bottom: $gutter;

      @include media-breakpoint-up(md) {
        font-size: $font-size-medium;
      }
    }

    .form-group {
      margin-bottom: $gutter;
    }

    .title {
      @include montserrat-medium;

      font-size: $font-size-medium;
      color: col(blue);
    }
  }

  .have-loyalty-card {
    .label {
      @include montserrat-medium;

      color: col(blue);
      font-size: $font-size-default;
      line-height: 1;
      margin-bottom: $gutter;

      @include media-breakpoint-up(md) {
        font-size: $font-size-medium;
      }
    }

    .code,
    .description {
      @include montserrat-medium;

      color: col(blue);
      display: block;
    }

    .code {
      font-size: $font-size-default;
      padding-bottom: 5px;
      background-color: transparent;
      border: none;
      width: 100%;

      @include media-breakpoint-up(md) {
        font-size: $font-size-medium;
      }
    }

    .description {
      padding-top: 5px;
      font-size: $font-size-small;
      border-top: 1px solid col(gold);
    }
  }
}

// Renew password
.renew-password {
  .email {
    @include montserrat-medium;

    font-size: $font-size-medium;
    color: col(blue);
    margin-bottom: calc(#{$gutter} * 2);
  }
}
