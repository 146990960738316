.block-reassurance {
  margin-top: calc(#{$gutter} * 3);

  .title {
    @include montserrat-medium;

    color: col(blue);
    display: none;
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
      padding: 0 $gutter;
    }
  }

  .assurance-picto {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;

    .assurance-item {
      @include montserrat-semibold;

      flex-grow: 1;
      flex-shrink: 0;
      width: 50%;
      font-size: 11px;
      text-transform: uppercase;
      text-align: center;
      padding: $gutter;

      @include media-breakpoint-up(md) {
        width: 25%;
        font-size: $font-size-default;
        padding: 0 $gutter;
      }

      a {
        text-decoration: none;
        color: col(blue);
      }
    }

    .image {
      height: 40px;
      width: auto;
      margin: 0 auto $gutter;

      @include media-breakpoint-up(sm) {
        height: 50px;
      }

      img {
        display: inline-block;
        width: auto;
        height: 100%;
        min-width: auto;
      }
    }
  }
}

.checkout-right-column {
  .block-reassurance {
    margin-top: 0;
    margin-bottom: $gutter;

    .container {
      padding: 0;
    }

    .title {
      display: block;
    }

    .assurance-picto {
      .assurance-item {
        font-size: 8px;
        width: 25%;

        @include media-breakpoint-up(md) {
          padding: 0 10px;
        }

        .image {
          height: 30px;
        }
      }
    }
  }
}

.henaff-common {
  .checkout-right-column {
    .block-reassurance {
      display: none;

      @include media-breakpoint-up(lg) {
        display: block;
      }
    }
  }
}
