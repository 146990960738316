/**
 * ----------------------------------
 * Form
 * ----------------------------------
 */
// placeholder
input,
textarea {
  &::placeholder {
    @include roboto;

    color: col(grey);
    font-size: $font-size-default;
  }
}

// Input
input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"],
input[type="date"],
.select2-search__field {
  @include montserrat-medium;

  padding: 5px $gutter;
  height: 40px;
  color: col(blue);
  border: 1px solid col(grey);
  font-size: $font-size-default;

  &.border-bottom {
    border: none;
    padding: 10px $gutter;
    border-bottom: 1px solid col(grey);
    background: transparent;
  }

  &:disabled,
  &:read-only,
  &[readonly] {
    background-color: col(mid-grey);
    cursor: not-allowed;
  }
}

// Textarea
textarea {
  @include montserrat-medium;

  padding: 10px $gutter;
  height: 150px;
  resize: none;
  border: 1px solid col(grey);
  color: col(blue);
  font-size: $font-size-medium;

  &.border-bottom {
    border: none;
    border-bottom: 1px solid col(grey);
    background: transparent;
  }
}

// Button
button,
input[type="submit"] {
  cursor: pointer;

  &:focus,
  &:active,
  &:hover {
    outline: none;
  }
}

// select
.custom-select, {
  position: relative;
  display: inline-block;

  &.border-bottom {
    .holder {
      border: none;
      padding: 10px calc(#{$gutter} * 2) 10px $gutter;
      border-bottom: 1px solid col(grey);
      background: transparent;
    }
  }

  &.select-faq {
    display: block;

    .holder {
      border-color: col(gold);
    }
  }

  select {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 42px;
    z-index: 2;
    cursor: pointer;
    outline: none;
    opacity: 0;

    option {
      @include montserrat-medium;

      padding: 10px;
      font-size: $font-size-default;
    }

    &:focus,
    &:active {
      & + .holder {
        &:after {
          transform: rotate(90deg);
        }
      }
    }
  }

  .holder {
    @include montserrat-medium;

    position: relative;
    padding: 5px calc(#{$gutter} * 2) 5px $gutter;
    background: transparent;
    min-width: 90px;
    min-height: 40px;
    display: block;
    border: 1px solid col(grey);
    color: col(blue);
    font-size: $font-size-default;
    line-height: 30px;

    &:after {
      @include background(true, 'chevron.svg', blue, 12px, right);

      content: "";
      position: absolute;
      right: 10px;
      top: 0;
      bottom: 0;
      width: 12px;
      transform: rotate(-90deg);
      transition: transform .55s ease;
    }
  }
}

// Select2
.custom-select2 {
  display: block;

  &.border-bottom {
    .select2-container--default {
      .select2-selection--single {
        border: none;
        border-bottom: 1px solid col(grey);
      }
    }
  }

  .select2-container--default {
    .select2-selection--single {
      border-color: col(grey);
      border-radius: 0;
      padding: 10px calc(#{$gutter} * 2) 10px $gutter;
      height: 40px;

      .select2-selection__rendered {
        @include montserrat-medium;

        color: col(blue);
        line-height: 1.5;
        font-size: $font-size-default;
        padding: 0;
      }

      .select2-selection__arrow {
        transform: rotate(-90deg);
        top: 8px;
        right: 8px;

        b {
          @include background(true, 'chevron.svg', blue, 12px, right);

          border: none;
          width: 12px;
          height: 12px;
          margin: 0;
          transform: translate3d(-50%, -50%, 0);
        }
      }
    }
  }
}

.select2-container {
  &--default {
    .select2-search--dropdown {
      .select2-search__field {
        border: none;
        border-bottom: 1px solid col(grey);
        padding: $gutter;
      }
    }

    .select2-results {
      li {
        @include montserrat-medium;

        color: col(blue);
        padding: 10px;
        border-bottom: 1px solid col(grey);
      }

      .select2-results__option--highlighted[aria-selected] {
        background-color: col(blue);
      }
    }
  }

  &--open {
    .select2-dropdown {
      border: none;

      &--below {
        top: -40px;

        .select2-results {
          box-shadow: 0 4px 14px rgba(col(grey), .4);
          border-bottom: 1px solid col(grey);
        }
      }

      &--above {
        top: calc(100% + 40px);
        display: flex;
        flex-direction: column-reverse;

        .select2-results {
          box-shadow: 0 -4px 14px rgba(col(grey), .4);
          border-top: 1px solid col(grey);
        }
      }
    }

    .select2-search {
      &--dropdown {
        padding: 0;
      }
    }

    .select2-results {
      border: {
        left: 1px solid col(grey);
        right: 1px solid col(grey);
      }
    }
  }
}

// Bootstrap touchspin
.bootstrap-touchspin {
  button {
    @include montserrat-light;

    background: transparent;
    border: none;
    padding: 5px;
    width: 55px;
    height: 55px;
    vertical-align: bottom;
    line-height: 0;
    color: col(blue);

    @include media-breakpoint-only(lg) {
      width: 45px;
      height: 45px;
    }

    &.bootstrap-touchspin-down {
      font-size: 40px;
    }

    &.bootstrap-touchspin-up {
      font-size: 35px;
    }
  }

  input[type="text"],
  input[type="number"] {
    @include montserrat-bold;

    font-size: calc(#{$font-size-medium} + 2px);
    padding: 5px;
    width: 55px;
    height: 55px;
    text-align: center;

    @include media-breakpoint-only(lg) {
      width: 45px;
      height: 45px;
    }

    &:disabled,
    &:read-only,
    &[readonly] {
      background-color: col(white);
      color: col(black);
      cursor: not-allowed;
    }
  }
}

// Radio
.radio-inline {
  @include montserrat-medium;

  font-size: $font-size-medium;
  color: col(blue);
  vertical-align: middle;
  margin-right: 10px;
}

.custom-radio {
  position: relative;
  cursor: pointer;
  padding-left: 25px;

  input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 2;
    width: 25px;
    height: 25px;

    &:checked {
      + span {
        &:before {
          opacity: 1;
        }
      }
    }
  }

  span {
    position: absolute;
    top: 50%;
    left: 0;
    width: 20px;
    height: 20px;
    display: inline-block;
    transform: translate3d(0, -50%, 0);
    border: 1px solid col(blue);
    border-radius: 50%;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 10px;
      height: 10px;
      transform: translate3d(-50%, -50%, 0);
      background-color: col(gold);
      border-radius: 50%;
      opacity: 0;
      transition: opacity .2s linear;
    }

    &.color {
      &:before {
        display: none;
      }
    }
  }
}

// Checkbox
.custom-checkbox {
  position: relative;
  cursor: pointer;
  padding-left: 25px;
  display: block;

  input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 2;
    width: 25px;
    height: 25px;

    &:checked {
      + span {
        border-color: col(gold);

        &:before {
          opacity: 1;
        }
      }
    }
  }

  a {
    color: col(blue);
    transition: .3s all $ease-default;

    &:hover {
      text-decoration: none;
    }
  }

  span {
    position: absolute;
    top: 5px;
    left: 0;
    width: 20px;
    height: 20px;
    display: inline-block;
    transform: translate3d(0, 0, 0);
    border: 1px solid col(blue);
    cursor: pointer;

    @media (min-width: 480px) {
      top: 50%;
      transform: translate3d(0, -11px, 0);
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: opacity .2s linear;
      background: {
        color: col(gold);
        size: 16px;
        position: center;
        repeat: no-repeat;
      };

      @include svg('check.svg', white);
    }

    &.color {
      &:before {
        display: none;
      }
    }
  }
}

// Custom file input
.custom-file {
  position: relative;

  &.border-bottom {
    label {
      border: none;
      padding: 10px calc(#{$gutter} * 2) 10px $gutter;
      border-bottom: 1px solid col(grey);
      background: transparent;
    }
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    cursor: pointer;
    outline: none;
    opacity: 0;
  }

  label {
    @include montserrat-medium;

    position: relative;
    padding: 5px calc(#{$gutter} * 2) 5px $gutter;
    background: transparent;
    min-width: 90px;
    min-height: 40px;
    display: block;
    border: 1px solid col(grey);
    color: col(blue);
    font-size: $font-size-default;
    line-height: 25px;
    cursor: pointer;
  }

  .btn {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    z-index: 3;
    display: block;
    height: 40px;
    padding: 5px $gutter;
    line-height: 30px;
    color: col(white);
    font-size: $font-size-small;
    background-color: col(gold);
    border: 1px solid col(gold);
  }
}

// custom autocomplete
.henaff-checkout,
.henaff-address {
  .ui-autocomplete {
    max-width: 100%;

    li {
      padding: 10px;

      &:not(:last-child) {
        border-bottom: 1px solid rgba(col(grey), .7);
      }

      &:hover {
        background-color: col(blue);

        a {
          color: col(white);
        }
      }
    }

    a,
    .ui-state-active {
      @include montserrat-medium;

      display: block;
      text-decoration: none;
      color: col(blue);
      cursor: pointer;
    }

    .ui-state-hover,
    .ui-widget-content .ui-state-hover,
    .ui-widget-header .ui-state-hover,
    .ui-state-focus,
    .ui-widget-content .ui-state-focus,
    .ui-widget-header .ui-state-focus {
      cursor: pointer;
      background: col(blue);
      color: col(white);
    }
  }
}
