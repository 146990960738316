/**
 * ----------------------------------
 * Product page stylesheet
 * ----------------------------------
 */
.product-detail {
  padding: calc(#{$gutter} * 3) 0;

  // product image
  .product-image {
    position: relative;
    margin-bottom: calc(#{$gutter} * 3);

    .flags {
      li {
        @include montserrat-semibold;

        position: absolute;
        top: 0;
        z-index: 2;
        color: col(white);
      }

      .on-sale {
        left: 0;

        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
          border-top: 100px solid col(promo);
          border-right: 105px solid transparent;
        }

        span {
          display: inline-block;
          text-transform: uppercase;
          transform: translate3d(20%, 150%, 0) rotate(-45deg);
        }
      }

      .discount {
        font-size: $font-size-medium;
        right: 0;
        padding: 12px 5px;
        background-color: col(promo);
      }
    }

    .product-cover {
      @include figure-square;

      .toggle-layer {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 40px;
        height: 40px;
        background: none;
        border: none;
        outline: none;
        z-index: 2;
        cursor: pointer;
      }

      .icon {
        width: 36px;
        height: 33px;
      }
    }

    .product-thumbnails {
      position: relative;
      margin-top: $gutter;

      ul {
        position: relative;
        padding: 0 calc(#{$gutter} + 5px);

        @include media-breakpoint-up(sm) {
          padding: 0 calc(#{$gutter} * 4);
        }
      }

      .thumb-item {
        .image {
          width: 70px;
          height: 70px;
          margin: auto;

          @include media-breakpoint-up(sm) {
            width: 100px;
            height: 100px;
          }
        }

        .thumb {
          width: 100%;
        }
      }

      .slick-arrow {
        @include svg('chevron.svg', blue);

        position: absolute;
        top: 50%;
        width: 20px;
        height: 20px;
        border: none;
        font-size: 0;
        outline: none;
        background-color: transparent;

        &.slick-disabled {
          opacity: .3;
        }

        &.slick-prev {
          left: 0;
          transform: translateY(-15px);

          @include media-breakpoint-up(sm) {
            left: calc(#{$gutter} * 3);
          }
        }

        &.slick-next {
          right: 0;
          transform: translateY(-15px) rotate(180deg);

          @include media-breakpoint-up(sm) {
            right: calc(#{$gutter} * 3);
          }
        }
      }
    }
  }

  // product info
  .product-info {
    margin-bottom: calc(#{$gutter} * 3);

    h1 {
      @include typo-h1;

      color: col(blue);
    }

    .product-flags {
      display: flex;
      align-items: center;
      margin-bottom: calc(#{$gutter} * 2);

      > li {
        @include montserrat-bold;

        font-size: $font-size-small;
        padding: $gutter;
        margin-right: calc(#{$gutter} - 5px);
        margin-bottom: $gutter;
        background-color: col(gold);
        color: col(white);
        text-transform: uppercase;
        border-top-left-radius: 23px;
        border-bottom-right-radius: 23px;

        &.product-type {
          background-color: col(green);
          border-radius: 0;
        }
      }
    }

    .product-label {
      margin-bottom: $gutter;

      .label-list {
        display: flex;
        align-items: center;

        .label-item {
          height: 50px;
          width: auto;
          padding-right: 10px;

          img {
            width: auto;
            height: 100%;
            display: block;
          }

          span {
            @include montserrat;
            display: block;
            font-size: 10px;
          }
        }
      }
    }

    .product-short-description {
      @include roboto;

      font-size: $font-size-medium;
      margin-bottom: $gutter;

      a {
        color: col(black);
        font-size: $font-size-medium;
        text-decoration: none;
        border-bottom: 1px solid col(blue);

        &:hover {
          color: col(gold);
          border: none;
          transition: .3s all $ease-default;
        }
      }

      &[data-readmore] {
        margin-bottom: 0;
      }
    }

    .product-variants-info {
      .composition,
      .variants {
        margin-bottom: $gutter;
      }

      .composition {
        display: flex;
        align-items: center;

        span {
          @include roboto-bold;

          font-size: $font-size-medium;
          color: col(blue);
          padding: 0 8px;
          border-right: 1px solid col(gold);

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            padding-right: 0;
            border: none;
          }
        }
      }

      .variants {
        @include roboto-bold;

        font-size: $font-size-medium;
        color: col(blue);

        p {
          display: inline;
        }

        a {
          color: col(blue);
          margin: 0 5px;
        }
      }
    }

    .product-prices {
      margin-bottom: $gutter;

      @include media-breakpoint-up(md) {
        margin-bottom: calc(#{$gutter} * 2);
      }

      .product-discount {
        .regular-price {
          @include montserrat-bold;

          position: relative;
          font-size: $font-size-subtitle;
          color: col(blue);

          &:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            height: 2px;
            background: col(blue);
            transform: rotate(-12deg);
          }
        }
      }

      .product-pack-price {
        display: none;
      }

      .price-ecotax,
      .product-without-taxes {
        @include montserrat-bold;

        position: relative;
        font-size: $font-size-subtitle;
        color: col(grey);
      }

      .product-price {
        &.has-discount {
          .discount-amount {
            @include montserrat-bold;

            font-size: $font-size-default;
            color: col(gold);

            @include media-breakpoint-up(sm) {
              font-size: $font-size-medium;
            }
          }
        }

        .current-price {
          @include montserrat-bold;

          color: col(blue);
          font-size: 36px;
          display: flex;
          align-items: baseline;
          flex-wrap: wrap;

          span:not(:last-child) {
            margin-right: 10px;
          }
        }
      }

      .tax-shipping-delivery-label {
        @include montserrat;

        font-size: $font-size-medium;
        margin-bottom: $gutter;
      }

      .discount,
      .price_unity {
        @include montserrat-bold;

        position: relative;
        font-size: 18px;
        color: col(gold);
        margin-left: calc(#{$gutter} * 2);
      }

      .product-availability {
        @include montserrat-medium;

        font-size: $font-size-medium;
        margin-bottom: $gutter;

        .available {
          color: col(green);
        }

        .unavailable {
          color: col(red);
        }

        .last-remaining {
          color: col(orange);
        }
      }
    }

    .product-discounts {
      &-title {
        @include typo-h3;

        text-transform: uppercase;
        color: col(blue);
        margin-bottom: 5px;
      }

      .table-product-discounts {
        background-color: col(light-gold);
        margin-bottom: calc(#{$gutter} * 2);

        th,
        td {
          padding: 8px;
        }

        tr {
          border-bottom: col(white);
        }

        th {
          @include montserrat-semibold;

          color: col(gold);
        }

        td {
          @include montserrat;

          text-align: center;
        }
      }
    }

    .product-actions {
      .product-quantity {
        display: inline-block;
      }

      .label {
        @include montserrat;

        font-size: $font-size-medium;
        display: inline-block;
        margin-right: 10px;
      }
    }

    .product-variants {
      padding-top: 10px;

      &-item {
        display: flex;
        align-items: center;
        margin-bottom: $gutter;
      }

      .color-list {
        display: flex;
        align-items: center;

        label {
          position: relative;
        }

        input {
          position: absolute;
          top: 15px;
          left: 15px;
          width: 15px;
          height: 15px;
          opacity: 0;
          transform: translate3d(-50%, -50%, 0);
        }

        .color {
          display: block;
          width: 30px;
          height: 30px;
          margin-right: 10px;
          border-radius: 15px;
          border: 1px solid rgba(col(gold), .3);
          cursor: pointer;
          transition: all .3s;

          &:hover {
            box-shadow: 2px 2px 4px 0 rgba(col(black), .2);
          }
        }
      }

      .product-property {
        li {
          display: block;
          padding: 7px 0 12px;
        }

        span:not(.label) {
          @include roboto-bold;

          font-size: $font-size-medium;
          color: col(blue);
        }
      }
    }

    .product-add-to-cart {
      margin-top: calc(#{$gutter} * 2);
      margin-bottom: $gutter;

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }

      .add {
        margin-bottom: $gutter;

        @include media-breakpoint-up(md) {
          flex-grow: 1;
          flex-shrink: 0;
          width: 50%;
          max-width: 350px;
        }

        .add-to-cart {
          @include add-to-cart-button;

          width: 100%;
        }
      }

      .assurance-picto {
        .assurance-item {
          @include montserrat-semibold;

          font-size: 9px;
          text-transform: uppercase;
          text-align: center;
          padding: 0;
          display: inline-block;
          max-width: 70px;

          @include media-breakpoint-up(xl) {
            padding: 0 $gutter;
            max-width: 100px;
          }

          a {
            text-decoration: none;
            color: col(blue);
          }
        }

        .image {
          height: 30px;
          width: auto;
          margin: 0 auto 5px;

          img {
            display: inline-block;
            width: auto;
            height: 100%;
            min-width: auto;
          }
        }
      }

      .product-features {
        position: relative;

        .social-sharing {
          position: absolute;
          bottom: 10px;
          right: 0;

          @include media-breakpoint-up(md) {
            position: relative;
            bottom: 0;
            flex-grow: 1;
            flex-shrink: 0;
            width: 100%;
            margin-top: $gutter;
          }
        }
      }

      .block-reassurance {
        display: none;
      }
    }
  }

  .product-additional-info {
    .js-mailalert {
      padding: $gutter 0;

      input[type="email"] {
        margin-bottom: $gutter;
        width: 100%;

        @include media-breakpoint-up(sm) {
          width: 320px;
        }
      }

      .btn {
        @include button-border(blue, gold);
      }
    }
  }

  .product-more-info {
    padding-top: $gutter;
    margin-top: $gutter;
    border-top: 1px solid col(gold);

    @include media-breakpoint-up(sm) {
      padding-top: calc(#{$gutter} * 3);
      margin-top: calc(#{$gutter} * 3);
    }

    .product-manufacturer {
      .manufacturer {
        width: 160px;
        margin-bottom: $gutter;

        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }

      .title {
        @include typo-h2;

        margin-bottom: $gutter;

        a {
          text-transform: uppercase;
          text-decoration: none;
          color: col(blue);
        }
      }
    }

    .detail {
      margin-bottom: calc(#{$gutter} * 2);
      margin-top: $gutter;

      .nav-link {
        @include roboto-bold;
        @include link(blue, gold, $font-size-default);

        display: inline-block;
      }

      .product-manufacturer,
      .product-reference,
      .product-quantities,
      .product-availability-date,
      .product-features {
        @include roboto;

        margin-bottom: $gutter;

        label {
          font-weight: bold;
        }

        a,
        span {
          text-decoration: none;
          color: col(gold);
        }

        .title {
          font-weight: bold;
        }
      }

      .product-features {
        .data-sheet {
          display: flex;
          align-items: center;

          dt,
          dd {
            flex-grow: 0;
            flex-shrink: 0;
            width: 50%;
            padding: 0 $gutter;
          }
        }
      }
    }

    .why {
      margin-bottom: calc(#{$gutter} * 2);

      @include media-breakpoint-up(md) {
        margin-bottom: calc(#{$gutter} * 4);
      }

      .title-info {
        @include typo-h3;

        text-transform: uppercase;
        color: col(blue);
        margin-bottom: $gutter;
      }
    }

    .product-info-accordion {
      .collapse:not(.show) {
        display: none;
      }

      .accordion-toggler {
        @include typo-h3;
        @include accordion-header(blue, "chevron.svg", gold, 14px);

        h2 {
          @include typo-h3;
        }
      }

      .accordion-content {
        padding: $gutter 0;
        min-height: 1px;
      }
    }

    .tables-information-nutritionnelles {
      td {
        @include roboto;

        padding: 5px 0;
        border-bottom: 1px solid col(grey);

        &:first-child {
          @include media-breakpoint-up(sm) {
            width: 65%;
          }
        }
      }

      .taille_portion {
        td {
          font-weight: 700;
          color: col(gold);
          border: none;
        }
      }

      .energie {
        td {
          font-weight: 700;
        }
      }
    }

    .product-pack-list {
      li {
        margin-bottom: 5px;
      }
      a {
        @include roboto-bold;
        @include link(black, gold, $font-size-default);
      }
    }
  }

  .product-recette {
    &-wrapper {
      background-color: col(light-gold);
      margin-bottom: calc(#{$gutter} * 2);
    }

    .title {
      @include montserrat;

      text-transform: uppercase;
      color: col(blue);
      font-size: $font-size-default;

      @include media-breakpoint-up(md) {
        font-size: $font-size-subtitle;
      }
    }

    .recette-toggler {
      @include typo-h2;

      position: relative;
      line-height: 1.5;
      text-align: left;
      padding: $gutter 40px $gutter 0;
      color: col(blue);
      display: block;
      background: transparent;
      width: 100%;
      border: none;

      @include media-breakpoint-up(md) {
        padding: $gutter 40px;
      }

      &:after {
        @include background(true, "chevron.svg", blue, 20px, right, center);

        content: "";
        position: absolute;
        right: 10px;
        top: 50%;
        width: 20px;
        height: 20px;
        transform: translate3d(0, -10px, 0) rotate(-90deg);
        transition: transform .55s ease;
      }

      &[aria-expanded="true"] {
        &:after {
          transform: translate3d(0, -10px, 0) rotate(90deg);
        }
      }

      span {
        @include montserrat-light;

        color: col(gold);
        text-transform: uppercase;
      }
    }

    .recette-accordion {
      padding: $gutter 0 calc(#{$gutter} * 2);
    }

    .how-to-cook {
      margin-bottom: $gutter;

      @include media-breakpoint-up(sm) {
        margin-bottom: calc(#{$gutter} * 2);
      }

      ul {
        display: flex;
        align-items: center;

        li {
          flex-grow: 0;
          flex-shrink: 0;
          padding: 10px;
          border-right: 1px solid col(gold);

          @include media-breakpoint-up(sm) {
            padding: $gutter;
            min-width: 100px;
          }

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            padding-right: 0;
            border-right: none;
          }
        }
      }

      span {
        font-weight: 700;
        display: block;
        padding-top: 10px;
        text-align: center;
      }

      .picto {
        width: auto;
        height: 30px;

        @include media-breakpoint-up(sm) {
          height: 36px;
        }

        img {
          display: block;
          width: auto;
          height: 100%;
          margin: auto;
        }
      }
    }

    .recette-image {
      position: relative;
      height: 400px;

      .image {
        position: absolute;
        top: -1px;
        left: -1px;
        bottom: -1px;
        right: -1px;
      }
    }

    .recette-ingredient,
    .recette-prepration {
      margin-top: $gutter;
    }
  }

  .product-tabs {
    margin-bottom: $gutter;

    &-mobile {
      .collapse:not(.show) {
        display: none;
      }

      .accordion-toggler {
        @include typo-h2;
        @include accordion-header(gold, "chevron.svg", blue, 20px);
      }

      .accordion-pane {
        padding: calc(#{$gutter} * 2) 0;
        min-height: 1px;
      }
    }

    &-desktop {
      .tab-list {
        ul {
          display: flex;
          align-items: center;
          border-bottom: 1px solid col(gold);

          > li {
            flex-grow: 1;
            flex-shrink: 0;
            max-width: 33.3333333333%;
          }
        }
      }

      .tab-content {
        position: relative;

        &.tab-wrapper {
          min-height: 510px;
        }
      }

      .tab-toggler {
        @include typo-h3;

        width: 100%;
        height: 74px;
        color: col(blue);
        text-transform: uppercase;
        background: transparent;
        border-color: col(gold);
        border-style: solid;
        border-width: 0;
        padding: $gutter;

        &.active {
          border-width: 1px 1px 0;
          position: relative;

          &:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: -1px;
            width: 100%;
            height: 1px;
            background: col(white);
          }
        }
      }

      .tab-pane {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
      }

    }
  }

  .product-notice {
    margin-top: calc(#{$gutter} * 3);

    @include media-breakpoint-up(md) {
      margin-top: $gutter;
    }

    .alcohol-notice {
      @include montserrat-semibold;

      text-align: center;
      margin-bottom: 10px;
      font-size: $font-size-small;
    }

    .random-notice {
      @include montserrat-semibold;

      text-align: center;
      text-transform: uppercase;
      color: col(blue);
      padding-top: 10px;
      border-top: 1px solid col(gold);
    }
  }
}
