/**
 * ----------------------------------
 * Modal stylesheet
 * ----------------------------------
 */
.modal {
  @include media-breakpoint-up(sm) {
    padding: 0 17px !important;
  }

  &-header {
    .title {
      @include roboto-bold;

      font-size: $font-size-medium;
      color: col(blue);
      margin-bottom: 0;
      display: flex;
      align-items: center;
    }

    .icon {
      margin-right: 10px;
    }
  }

  &-body {
    @include roboto;
  }

  .close {
    @include montserrat-semibold;

    font-size: 20px;
    color: col(blue);
    background: transparent;
    border: 0;
    padding: 5px;
    width: 62px;
    height: 62px;
    cursor: pointer;
  }
}

#product-modal {
  .modal-dialog {
    max-width: 700px;
  }

  .modal-body {
    .product-cover {
      @include figure-square;

      figcaption {
        @include montserrat;

        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding: $gutter;
        background-color: $modal-backdrop-bg;
        color: col(white);
      }

      p {
        margin: 0;
      }
    }

    .product-thumbnails {
      position: relative;
      margin-top: $gutter;

      ul {
        padding: 0 calc(#{$gutter} * 4);
        position: relative;
      }

      .thumb-item {
        padding: 0 $gutter;

        .image {
          width: 100px;
          height: 100px;
          margin: auto;
        }

        .thumb {
          width: 100%;
        }
      }

      .slick-arrow {
        @include svg('chevron.svg', blue);

        position: absolute;
        top: 50%;
        width: 20px;
        height: 20px;
        border: none;
        font-size: 0;
        outline: none;

        &.slick-prev {
          left: calc(#{$gutter} * 3);
          transform: translateY(-15px);
        }

        &.slick-next {
          right: calc(#{$gutter} * 3);
          transform: translateY(-15px) rotate(180deg);
        }
      }
    }
  }
}

#blockcart-modal {
  .modal-content {
    position: relative;

    .close {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
    }
  }

  .modal-dialog {
    @include media-breakpoint-up(sm) {
      max-width: 1242px;
    }
  }

  .modal-body {
    padding: 0;

    .header {
      border-bottom: 1px solid col(gold);
      background-color: col(light-grey);

      .title {
        @include montserrat-bold;

        color: col(blue);
        margin: 0;
      }

      &.product-cart-header {
        padding: 20px;

        @include media-breakpoint-up(md) {
          padding: 20px 62px 20px 30px;
        }
      }

      &.product-info-header {
        padding: 20px 62px 20px 20px;

        @include media-breakpoint-up(md) {
          padding: 20px 0 20px 85px;
        }

        .title {
          @include background(true, 'check-mark.svg', blue, 20px, left);

          padding-left: 25px;
        }
      }
    }

    .content {
      padding: calc(#{$gutter} + 5px);

      @include media-breakpoint-up(md) {
        padding: calc(#{$gutter} * 4) calc(#{$gutter} * 2);
      }

      .image {
        width: 300px;
        height: 300px;
        margin: auto;

        img {
          width: 100%;
          max-height: auto;
        }
      }

      .manufacturer-name {
        @include montserrat-semibold;

        color: col(gold);
        text-transform: uppercase;
      }

      .product-name {
        @include montserrat-light;

        color: col(blue);
        text-transform: uppercase;
        font-size: $font-size-medium;
      }

      .variants {
        @include montserrat;

        margin-bottom: 5px;
        font-size: $font-size-medium;

        strong {
          color: col(blue);
        }
      }

      .product-availability {
        @include montserrat-medium;

        margin-bottom: $gutter;

        .available {
          color: col(green);
        }

        .unavailable {
          color: col(red);
        }

        .last-remaining {
          color: col(orange);
        }
      }

      .product-actions {
        margin-bottom: $gutter;

        .form-product-quantity {
          padding: $gutter 0;

          .label {
            @include montserrat-light;

            font-size: 18px;
            display: inline-block;
            margin-right: $gutter;
            color: col(blue);
          }

          .product-quantity {
            display: inline-block;
          }
        }
      }

      .product-price {
        @include montserrat-bold;

        font-size: $font-size-sectiontitle;
        color: col(blue);
      }
    }

    .cart-table {
      .d-flex {
        padding: 8px 0;
        align-items: center;
        justify-content: space-between;
      }

      .label {
        @include montserrat-bold;

        color: col(blue);
        font-size: $font-size-medium;
      }

      .value {
        @include montserrat;

        text-align: right;
        font-size: $font-size-medium;
      }

      .detail {
        display: block;
        font-size: $font-size-small;
      }

      .cart-products-count {
        @include montserrat-bold;

        color: col(blue);
        border-bottom: 1px solid col(gold);

        .value {
          @include montserrat-bold;
        }
      }

      .cart-product-total {
        .value {
          @include montserrat-bold;

          color: col(blue);
        }

        .detail {
          @include montserrat-light;
        }
      }
    }

    .cart-btn {
      padding-top: $gutter;
      border-top: 1px solid col(gold);

      .btn {
        font-size: 14px;
        line-height: 21px;
        width: 100%;
        margin-bottom: $gutter;

        &-primary {
          @include button-border(blue, gold);
        }

        &-secondary {
          @include button-color(white, blue, gold);
        }
      }
    }
  }
}

#alcohol-modal {
  .modal-body {
    @include montserrat-medium;

    color: col(blue);
    text-align: center;
    padding-bottom: 0;
  }

  .modal-footer {
    border: none;
    justify-content: center;

    .btn {
      @include button-color(white, blue, gold);

      padding: $gutter calc(#{$gutter} * 2);
      text-transform: uppercase;
    }
  }

  .notice {
    color: col(gold);
  }

  .form {
    display: inline-block;
    padding-top: $gutter;
    margin-bottom: 0;

    .custom-checkbox {
      text-align: left;
    }
  }
}
