/**
 * ----------------------------------
 * Cart stylesheet
 * ----------------------------------
 */
.cart-slide-product {
  margin-top: calc(#{$gutter} * 2);

  @include media-breakpoint-up(md) {
    margin-top: calc(#{$gutter} * 4);
  }

  .title {
    @include typo-h2;

    color: col(blue);
    text-align: center;
    text-transform: uppercase;
    margin-bottom: $gutter;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }
}

.fix-cart-detailed-actions {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 5px 10px;
  background-color: col(white);
  display: block;
  z-index: 100;
  box-shadow: 0 -2px 16px 0 rgba(col(black), .2);

  @include media-breakpoint-up(md) {
    display: none;
  }

  .btn {
    font-size: 14px;
    line-height: 21px;
    width: 100%;

    &-primary {
      @include button-color(white, blue, gold);
      padding: 10px 15px;
    }
  }
}

.subscribe-fid {
  @include button-border(blue, gold);

  font-size: 14px;
  line-height: 21px;
  padding: 8px $gutter;
  width: 150px;
}

.cart-content {
  .title-section {
    margin-bottom: calc(#{$gutter} * 2);

    .label {
      text-transform: none;
      font-size: 18px;
    }
  }

  .cart-grid {
    &-body {
      margin-bottom: $gutter;

      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
      }
    }
  }

  .cart-body {
    position: relative;
    margin-bottom: calc(#{$gutter} * 2);
  }

  .cart-detailed-totals--wrapper {
    padding: $gutter;
    border: 2px solid col(gold);
    margin-bottom: $gutter;

    .cart-detailed-totals {
      padding: 0;
      border: none;
      margin-bottom: 0;
    }
  }

  .cart-message {
    button {
      background-color: transparent;
      border: none;
      padding: 8px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate3d(0, -47%, 0);
      z-index: 1;

      @include media-breakpoint-up(md) {
        left: $gutter;
      }
    }

    .close {
      @include svg('close.svg', white);

      width: 12px;
      height: 12px;
      margin-right: 0;

      @include media-breakpoint-up(md) {
        width: 14px;
        height: 14px;
      }
    }

    .message {
      @include montserrat-light;

      position: relative;
      padding: 8px 8px 8px 25px;
      color: col(white);
      background-color: col(blue);
      font-size: 11px;

      @include media-breakpoint-up(md) {
        font-size: $font-size-default;
        padding: 10px $gutter;
        text-align: center;
      }

      p {
        margin-bottom: 0;
      }

      strong {
        font-size: $font-size-small;

        @include media-breakpoint-up(md) {
          font-size: $font-size-medium;
        }
      }

      &:before {
        content: "";
        width: 70%;
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 50%;
        background-color: rgba(col(white), .3);
        transform: translate3d(-50%, 0, 0);
      }
    }
  }

  // cart overview
  .cart-overview {
    .no-items {
      @include montserrat-medium;

      padding: $gutter;
      background-color: col(light-gold);
      color: col(blue);
      display: block;
      margin-bottom: calc(#{$gutter} * 2);
    }

    .cart-items {
      .cart-item {
        padding: $gutter 0;
        border-top: 1px solid col(light-gold);

        &:first-child {
          border: none;
        }
      }
    }

    .product-line-grid {
      .product-image {
        @include figure-square;

        width: 100%;

        @include media-breakpoint-up(md) {
          width: 125px;
        }
      }

      .product-line-info {
        @include montserrat;

        margin-bottom: 5px;

        .value {
          @include montserrat-semibold;

          color: col(blue);
        }

        &.product-name {
          margin-top: $gutter;
          font-size: $font-size-medium;

          a {
            @include montserrat-medium;

            color: col(blue);
            text-decoration: none;
          }

          .separator {
            display: inline;

            @include media-breakpoint-up(sm) {
              display: none;
            }
          }

          .price {
            font-weight: bold;
            display: inline;

            @include media-breakpoint-up(sm) {
              display: none;
            }
          }
        }

        &.product-price {
          .product-discount {
            .regular-price {
              @include montserrat-bold;

              position: relative;
              color: col(blue);

              &:after {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                width: 100%;
                height: 2px;
                background: col(blue);
                transform: rotate(-12deg);
              }
            }

            .discount {
              @include montserrat-bold;

              color: col(gold);
            }
          }

          .current-price {
            @include montserrat-bold;

            font-size: $font-size-title;
            color: col(blue);
            display: none;

            @include media-breakpoint-up(sm) {
              display: block;
            }
          }
        }
      }

      .product-line-actions {
        padding-top: $gutter;

        .price {
          @include montserrat-bold;

          font-size: $font-size-title;
          color: col(blue);
        }

        .cart-line-product-actions {
          a {
            vertical-align: middle;
            line-height: 1;
          }

          .icon {
            width: 24px;
            height: 24px;

            @include media-breakpoint-up(sm) {
              width: 18px;
              height: 18px;
            }
          }
        }
      }
    }
  }

  // cart summary
  .cart-summary {
    &-subtotal-discount {
      .value {
        color: col(gold);
      }
    }
  }

  .cart-empty-actions {
    .btn {
      font-size: 14px;
      line-height: 21px;
      width: 100%;
      margin-top: $gutter;

      &-primary {
        @include button-color(white, light-grey, gold);
      }
    }
  }

  .cart-detailed-actions {
    .btn {
      font-size: 14px;
      line-height: 21px;
      width: 100%;
      margin-top: $gutter;

      &-primary {
        @include button-color(white, blue, gold);
      }
    }
  }

  // voucher block
  .block-promo {
    .cart-voucher {
      &-list {
        margin-bottom: $gutter;
        padding: $gutter calc(#{$gutter} * 2);
        background-color: col(blue);

        .title {
          @include montserrat;

          text-transform: uppercase;
          color: col(gold);
          font-size: $font-size-subtitle;
        }

        .card-block {
          li {
            .label {
              @include montserrat-semibold;

              text-transform: uppercase;
              color: col(gold);
              font-size: $font-size-default;
              padding-bottom: 5px;
              margin-bottom: 10px;
              border-bottom: 1px solid col(gold);
            }

            .value {
              @include montserrat-semibold;

              text-align: right;
              color: col(white);
              font-size: $font-size-medium;
            }
          }
        }
      }

      .promo-highlighted {
        margin-bottom: calc(#{$gutter} * 2);

        .libelle {
          @include montserrat-semibold;

          font-size: $font-size-medium;
          text-transform: uppercase;
          color: col(gold);
        }

        .discount-list-item {
          @include montserrat-semibold;

          padding: 5px 0;
          text-transform: uppercase;
          font-size: $font-size-default;
          border-bottom: 1px solid rgba(col(gold), .3);
          color: col(blue);
        }
      }
    }
  }

  .continue_shopping-link {
    @include montserrat-medium;
    @include link(blue, blue);
    @include background(true, 'slide-prev.svg', blue, 12px, 0);

    padding-left: 14px;
    margin-bottom: $gutter;
    display: inline-block;
  }

  .refund-policy-link {
    @include montserrat-medium;
    @include link(blue, blue);

    margin-bottom: $gutter;
    display: inline-block;
  }

  .block-promo--wrapper {
    padding: $gutter calc(#{$gutter} - 5px);
    border: 2px solid col(gold);
    margin-bottom: calc(#{$gutter} * 2);

    @include media-breakpoint-up(md) {
      padding: $gutter calc(#{$gutter} + 5px);
    }

    .block-cart-loyalty {
      margin-top: $gutter;
    }

    .block-promo,
    .block-cart-loyalty {
      .alert {
        margin-top: $gutter;
      }
    }
  }

  .form-voucher,
  .form-loyalty {
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: calc(#{$gutter} * 2);

    @include media-breakpoint-up(lg) {
      flex-wrap: nowrap;
    }

    label {
      @include montserrat-bold;

      position: relative;
      color: col(blue);
      margin-bottom: $gutter;
      padding-right: 40px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
      }
    }

    .btn {
      @include button-border(blue, gold);

      font-size: 14px;
      line-height: 21px;
      padding: 8px $gutter;
      width: 150px;
    }

    .tip {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translate3d(0, -9px, 0);

      span {
        @include montserrat-semibold;

        width: 20px;
        height: 20px;
        color: col(white);
        background-color: col(blue);
        border-radius: 10px;
        padding: 3px;
        display: block;
        font-size: $font-size-small;
      }
    }

    .promo-input,
    .loyalty-input {
      @include montserrat-bold;

      color: col(blue);
      border: 1px solid col(blue);
      margin-right: $gutter;
      width: calc(100% - 165px);
    }

    .voucher-input--wrapper {
      align-items: center;
      flex-grow: 1;
      flex-shrink: 0;
      width: 100%;

      @include media-breakpoint-up(md) {
        width: auto;
      }
    }
  }
}
