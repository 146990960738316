#main-footer {
  background: col('blue');
  color: col('white');
  margin: 30px 0 0;
  p {
    font-family: $font-roboto;
    line-height: 1.8;
    font-size: 14px;
  }

  hr {
    width: 100%;
  }
  a {
    color: col(white);
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  @include media-breakpoint-up(lg) {
    .footer__inner {
      display: flex;

    }

    .footer__right {
      flex: 1;
      padding: 73px 0 0 30px;
    }

    .footer__bottom {
      display: flex;
      flex-wrap: wrap;
      .footer__stores {
        flex: 1;
        padding-left: 28px;
      }
    }
  }

  .footer__left {
    padding: 45px 0;
    @include media-breakpoint-up(lg) {
      width: 262px;
    }

    .footer__logo {
      text-align: center;

      img {
        max-width: 100%;
        padding: 0 0 25px;
      }
    }

    .footer__text {
      padding-bottom: 12px;
    }

    .footer__social {
      ul {
        display: flex;
        margin: calc(#{$gutter} + 5px) 0;
        justify-content: left;

        li {
          margin-right: $gutter;
        }
      }

      .social-icon {
        display: block;
        width: 60px;
        height: 60px;
        transition: all .3s;
        border-radius: 30px;
        border: 1px solid col(white);
        background-color: col(white);
        background-size: 30px;
        background-position: center;
        background-repeat: no-repeat;
      }

      .facebook {
        @include svg('facebook.svg', blue);
      }

      .instagram {
        @include svg('instagram.svg', blue);
      }
    }

    .footer__brand {
      width: 100%;
      text-align: left;

      p {
        margin-bottom: 9px;
      }

      img {
        max-width: 100%;
      }
    }

    .footer__brands {
      display: flex;
      flex-wrap: wrap;
      width: 264px;
      height: 89px;
      align-items: center;
      justify-content: space-between;
      margin: calc(#{$gutter} + 5px) 0;

      .brand {
        flex-shrink: 0;
        flex-grow: 0;
        width: 50%;

        &-1 {
          img {
            width: 100px;
          }
        }

        &-2 {
          img {
            width: 152px;

            @include media-breakpoint-up(sm) {
              display: none;
            }
          }
        }

        &-3 {
          display: none;
        }
      }

      img {
        min-height: auto;
        min-width: auto;
        left: 0;
        transform: rotate(.001deg) translate3d(0, -50%, 0);
      }
    }
  }

  .footer__bottom p, .footer__copyright p {
    margin: 0;
  }

  .footer__bottom {
    padding: calc(#{$gutter} * 2) 0;
    margin-top: calc(#{$gutter} * 2);
    border-top: 1px solid col(gold);

    .stores__title {
      font-family: $font-montserrat;
      text-align: left;
      font-size: 14px;
      font-weight: 500;
      color: col(gold);
      text-transform: uppercase;
      margin-bottom: $gutter;
    }

    .stores {
      text-align: left;
      display: flex;
      flex-wrap: wrap;

      .store {
        width: 100%;
        padding: 0 12px 12px 0;

        @include media-breakpoint-up(lg) {
          width: 25%;
        }
      }

      address.store__city {
        text-transform: uppercase;
      }

      p, address {
        margin-bottom: 5px;
        font-style: normal;
      }
    }
  }

  .footer__copyright {
    text-align: center;
    background: col(blue-dark);
    padding: 20px 15px;
    flex-wrap: wrap;
    font-size: $font-size-small;

    @include media-breakpoint-up(sm) {
      font-size: $font-size-default;
    }

    ul {
      display: inline;
      li {
        display: inline;
        padding: 0 2px;
        &:before {
          content: "|";
          display: inline-block;
        }
      }
    }

    .spacer {
      opacity: 0;
      visibility: hidden;
      display: block;
      line-height: 0;

      @include media-breakpoint-up(sm) {
        opacity: 1;
        visibility: visible;
        display: inline;
        line-height: 1.5;
      }
    }
  }

  .text-footer {
    width: 100%;
    flex-grow: 1;
    flex-shrink: 1;

    p {
      padding-top: $gutter;
      font-size: $font-size-small;
    }
  }

  .footer-links {
    display: flex;
    flex-direction: column;
    text-align: left;
    flex-grow: 0;
    flex-shrink: 0;
    width: 100%;

    @include media-breakpoint-up(sm) {
      max-height: 350px;
      flex-wrap: wrap;
    }

    @include media-breakpoint-up(lg) {
      width: 50%;
    }

    ul {
      width: 100%;
      padding: 0 15px;
      font-family: $font-montserrat;
      @media (min-width: 330px) {
        width: 50%;
      }
    }

    .h3, h3 {
      text-transform: uppercase;
      margin-bottom: 10px;
      font-size: 14px;
    }

    a.upcase,
    li.upcase {
      padding: 0;
      text-transform: uppercase;
      letter-spacing: 0;

      &:before {
        display: none;
      }
    }

    li {
      margin-bottom: 11px;

      a {
        position: relative;
        display: block;
        padding-left: 12px;

        &:before {
          content: '';
          display: block;
          position: absolute;
          width: 6px;
          height: 6px;
          left: 0;
          top: 10.5px;
          transform: translateY(-50%);
          background-image: url('/themes/henaff-vad/assets/svg/next.svg');
          background-size: cover;
        }
      }
    }
  }

  .footer-newsletter {
    flex-grow: 0;
    flex-shrink: 0;
    width: 100%;

    @include media-breakpoint-up(lg) {
      width: 50%;
    }

    .email_subscription {
      label {
        @include montserrat-medium;

        font-size: $font-size-medium;
        display: block;
        color: col(gold);
        margin-bottom: 10px;
      }

      .form-group {
        position: relative;
      }

      .email {
        width: 100%;
        margin-bottom: 10px;
        height: 50px;

        @include media-breakpoint-up(md) {
          margin-bottom: calc(#{$gutter} + 5px);
          padding-right: 150px;
        }
      }

      .btn {
        @include montserrat-medium;
        @include button-color(white, gold, gold);

        width: 100%;
        text-transform: uppercase;
        margin-bottom: 10px;

        @include media-breakpoint-up(lg) {
          position: absolute;
          top: 0;
          right: 0;
          width: 150px;
        }
      }

      .text {
        @include roboto;

        font-size: $font-size-small;
      }
    }
  }

  .footer-product-alcohol {
    padding: calc(#{$gutter} * 2) 0;
    background-color: col(blue-dark);

    .d-flex {
      flex-wrap: wrap;
      border: 1px solid col(gold);
      padding: 0;

      @include media-breakpoint-up(md) {
        padding: $gutter;
        width: 85%;
        margin: auto;
      }
    }

    .logo {
      flex-grow: 0;
      flex-shrink: 1;
      width: 100%;
      margin-bottom: $gutter;
      padding: 0 calc(#{$gutter} + 5px);

      @include media-breakpoint-up(md) {
        width: auto;
        margin-bottom: 0;
      }

      .picto {
        width: 200px;
        padding: $gutter;
        margin: auto;
        background-color: col(white);

        @include media-breakpoint-up(md) {
          width: 130px;
        }
      }
    }

    .text {
      @include montserrat-medium;

      flex-grow: 0;
      flex-shrink: 1;
      width: 100%;
      padding: 0 calc(#{$gutter} + 5px);
      text-align: center;

      @include media-breakpoint-up(md) {
        width: auto;
        text-align: left;
      }

      &-uppercase {
        font-size: $font-size-default;
        margin-bottom: 5px;
      }

      &-small {
        font-size: $font-size-small;
        margin-bottom: 0;
      }
    }
  }

  // footer nav checkout page
  .footer-nav-logo {
    display: flex;
    justify-content: center;
    padding: 40px 0;

    .brand {
      width: 165px;
      height: auto;

      @include media-breakpoint-up(md) {
        width: 200px;
      }

      @include media-breakpoint-up(lg) {
        width: 220px;
      }

      .logo {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }
}
