/**
 * ----------------------------------
 * Sitemap stylesheet
 * ----------------------------------
 */
.sitemap {
  .title {
    @include montserrat-bold;

    color: col(blue);
    font-size: $font-size-small;
    padding: 5px 0;
    border-bottom: 1px solid col(gold);
    text-transform: uppercase;
    margin-bottom: $gutter;
  }

  a {
    @include montserrat;
    @include background(true, 'turn-right.svg', gold, 12px, 0);

    color: col(black);
    text-decoration: none;
    transition: .3s all $ease-default;
    font-size: $font-size-small;
    padding: {
      top: 8px;
      bottom: 8px;
      left: 18px;
    };

    &:hover {
      color: col(gold);
    }
  }

  ul {
    padding-left: $gutter;
  }
}
