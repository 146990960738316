/**
 * ----------------------------------
 * Home slider
 * ----------------------------------
 */
.home-slider {
  overflow: hidden;
  position: relative;
  display: block;
  height: 330px;

  @include media-breakpoint-up(lg) {
    height: 600px;
  }

  .slider-wrapper {
    display: none;

    &.slick-initialized {
      display: block;
    }
  }

  .slider-item {
    position: relative;
    height: 330px;

    @include media-breakpoint-up(lg) {
      height: 600px;
    }
  }

  .image {
    height: 100%;
    width: 100%;
  }

  .caption {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translate3d(0, -50%, 0);

    @include breakpointMixin() using ($breakpoint, $container-max-width) {
      $margin: calc((#{$width-no-scroll} - #{$container-max-width}) / 2);

      margin-left: $margin;
    }

    @media (min-width: 1400px) {
      max-width: 475px;
    }

    a {
      @include default-button;
    }

    &-wrapper {
      padding: $gutter;
      background-color: col(blue);

      @include media-breakpoint-up(md) {
        padding: calc(#{$gutter} * 2);
      }
    }

    .title {
      @include montserrat-semibold;

      font-size: $font-size-medium;
      color: col(white);
      margin-bottom: $gutter;

      @include media-breakpoint-up(lg) {
        font-size: 32px;
        margin-bottom: calc(#{$gutter} + 5px);
      }
    }
  }

  .pagination {
    position: absolute;
    left: 50%;
    bottom: calc(#{$gutter} * 2);
    transform: translate3d(-50%, 0, 0);

    li {
      vertical-align: middle;
      display: inline-block;

      &.slick-active {
        button {
          &:before {
            background-color: col(blue);
          }
        }
      }
    }

    button {
      font-size: 0;
      line-height: 0;
      display: block;
      cursor: pointer;
      color: transparent;
      border: 0;
      outline: none;
      padding: 8px 5px;
      background-color: transparent;

      &:before {
        content: "";
        display: block;
        width: 50px;
        height: 8px;
        background-color: col(white);
        transition: background-color .3s;
      }
    }
  }
}
