/**
 * ----------------------------------
 * Checkout stylessheet
 * ----------------------------------
 */

.checkout-content {
  .checkout-right-column {
    display: none;
  }

  .return_cart-link {
    @include montserrat-medium;
    @include link(blue, blue);
    @include background(true, 'slide-prev.svg', blue, 12px, 0);

    padding-left: 14px;
  }

  // Checkout cart summary
  .cart-summary {
    &-line {
      &.cart-summary-article {
        margin-bottom: $gutter;

        a {
          @include montserrat-medium;
          @include link(gold, gold, $font-size-default);
        }
      }
    }
  }

  .cart-summary-product-list {
    .media-list {
      .media {
        display: flex;

        &-left {
          flex-grow: 0;
          flex-shrink: 0;
          width: 80px;

          img {
            width: 100%;
            height: auto;
          }
        }

        &-body {
          flex-grow: 1;
          flex-shrink: 0;
          width: 70%;
          padding-left: $gutter;
        }

        .product-name {
          @include montserrat-bold;

          color: col(blue);
        }

        .product-quantity {
          @include montserrat-semibold;

          float: right;
          color: col(gold);
        }

        .product-price {
          @include montserrat-bold;

          display: block;
          color: col(gold);
          margin-top: 5px;
        }

        .product-line-info {
          @include montserrat;

          font-size: $font-size-small;
        }
      }
    }
  }

  .checkout-voucher {
    .promo-list {
      padding: 0 $gutter;
      background-color: col(light-gold);
    }

    .d-flex {
      padding-top: 5px;
      border-top: 1px solid col(gold);
    }

    .label {
      @include montserrat-bold;

      font-size: $font-size-default;
      padding: 5px 0;
    }

    .value {
      @include montserrat-medium;

      font-size: $font-size-default;
    }

    .delete-action {
      line-height: 18px;

      a {
        color: col(blue);
        font-size: 11px;
      }

      .icon {
        top: 2px;
      }
    }
  }

  .checkout-voucher-list {
    .voucher-text {
      @include montserrat;

      color: col(blue);
      padding: $gutter 0;
      border-top: 1px solid col(gold);
    }

    .voucher-list {
      padding: $gutter calc(#{$gutter} * 2);
      background-color: col(blue);

      li:not(:last-child) {
        margin-bottom: 10px;
      }

      .label {
        @include montserrat-semibold;

        color: col(gold);
        border-bottom: 1px solid col(gold);
        text-transform: uppercase;
      }

      .value {
        @include montserrat-semibold;

        color: col(white);
        text-transform: uppercase;
        padding: 5px 0;
      }
    }

    .return-shipment {
      @include montserrat;

      color: col(blue);
      margin: $gutter 0;
      padding: $gutter 0;
      border-top: 1px solid col(gold);

      .title {
        text-transform: uppercase;
      }
    }
  }

  // Checkout step
  .checkout-step {
    margin-bottom: $gutter;

    @include media-breakpoint-up(md) {
      margin-bottom: calc(#{$gutter} * 2);
    }

    &.-current {
      border: 2px solid col(gold);

      .step-title {
        border-color: rgba(col(gold), .3);

        .step-edit {
          display: none;
        }
      }
    }

    &.-unreachable {
      opacity: .5;
    }

    .loyalty-registration-form {
      background-color: col(light-gold);
      padding: $gutter;
      margin-bottom: calc(#{$gutter} * 2);

      label {
        @include montserrat-medium;

        color: col(blue);
        font-size: $font-size-default;
        line-height: 1;
        margin-bottom: $gutter;

        @include media-breakpoint-up(md) {
          font-size: $font-size-medium;
        }
      }

      .form-group {
        margin-bottom: $gutter;
      }

      .title {
        @include montserrat-medium;

        font-size: $font-size-medium;
        color: col(blue);
      }
    }

    .have-loyalty-card {
      .label {
        @include montserrat-medium;

        color: col(blue);
        font-size: $font-size-default;
        line-height: 1;
        margin-bottom: $gutter;

        @include media-breakpoint-up(md) {
          font-size: $font-size-medium;
        }
      }

      .code,
      .description {
        @include montserrat-medium;

        color: col(blue);
        display: block;
      }

      .code {
        font-size: $font-size-default;
        padding-bottom: 5px;
        background-color: transparent;
        border: none;
        width: 100%;

        @include media-breakpoint-up(md) {
          font-size: $font-size-medium;
        }
      }

      .description {
        padding-top: 5px;
        font-size: $font-size-small;
        border-top: 1px solid col(gold);
      }

      .have-account {
        margin-top: $gutter;
        display: block;

        a {
          @include montserrat-medium;
          @include link(blue, blue);
        }
      }
    }

    .step-content {
      @include montserrat;

      padding-top: $gutter;
      padding-bottom: $gutter;

      @include media-breakpoint-up(md) {
        padding-top: calc(#{$gutter} * 2);
        padding-bottom: calc(#{$gutter} * 2);
      }

      .identity,
      .logout {
        a {
          @include montserrat-bold;
          @include link(gold, gold, $font-size-medium);
        }
      }
    }

    .step-title {
      @include typo-h2;

      position: relative;
      color: col(blue);
      text-transform: uppercase;
      padding: $gutter calc(#{$gutter} + 5px);
      border-bottom: 1px solid col(gold);
    }

    .step-subtitle {
      @include typo-h3;

      text-transform: uppercase;
      color: col(gold);
      margin-bottom: calc(#{$gutter} * 2);
      margin-top: $gutter;
      text-align: center;
    }

    .step-number {
      @include montserrat-medium;

      color: col(gold);
    }

    .step-edit {
      @include montserrat-semibold;

      color: col(grey-contact);
      font-size: $font-size-default;
      text-transform: none;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate3d(0, -50%, 0);
      cursor: pointer;

      span {
        display: none;

        @include media-breakpoint-up(md) {
          display: inline;
        }
      }
    }
  }
}

// authentication step
#checkout-personal-information-step {
  .step-content {
    padding: $gutter 10px;

    @include media-breakpoint-up(md) {
      padding: calc(#{$gutter} * 2);
      margin: auto;
    }

    @include media-breakpoint-up(lg) {
      width: 70%;
    }
  }

  .nav {
    &-tabs {
      display: flex;
      flex-wrap: nowrap;
    }

    &-item {
      flex-grow: 0;
      flex-shrink: 0;
      width: 50%;
    }

    &-link {
      @include montserrat-bold;

      font-size: $font-size-default;
      text-decoration: none;
      padding: $gutter;
      color: col(blue);
      display: block;
      text-align: center;
      border-bottom: 1px solid col(grey);
      height: 100%;

      &.active {
        border: {
          color: col(grey);
          width: 1px;
          style: solid;
          bottom: none;
        };
      }
    }
  }

  .tab-content {
    border: {
      color: col(grey);
      width: 1px;
      style: solid;
      top: none;
    };

    .tab-pane {
      display: none;
      padding: calc(#{$gutter} * 2) 10px;

      @include media-breakpoint-up(md) {
        padding: calc(#{$gutter} * 2) $gutter;
      }

      &.active {
        display: block;
      }
    }
  }

  .btn {
    @include button-color(white, blue, gold);

    padding: $gutter calc(#{$gutter} * 2);
  }

  .help-block .error {
    margin-bottom: $gutter;
  }
}

#checkout-addresses-step,
#checkout-delivery-step {
  .d-flex {
    flex-wrap: wrap;
  }

  .customer-address--wrapper,
  .invoice-address--wrapper,
  .relay-delivery-option--wrapper,
  .home-delivery-option--wrapper {
    @include media-breakpoint-up(md) {
      padding: 0 calc(#{$gutter} * 2);
    }
  }

  .customer-address--wrapper,
  .relay-delivery-option--wrapper {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      width: 1px;
      height: 90%;
      background-color: col(light-gold);
      visibility: hidden;

      @include media-breakpoint-up(md) {
        visibility: visible;
      }
    }

    &:before {
      content: "";
      position: absolute;
      left: 50%;
      bottom: 0;
      width: calc(100% - 20px);
      height: 1px;
      transform: translate3d(-50%, 0, 0);
      background-color: col(light-gold);
      visibility: visible;

      @include media-breakpoint-up(md) {
        visibility: hidden;
      }
    }
  }
}

// Step Adresses
#checkout-addresses-step {
  .content {
    padding: $gutter 0;

    @include media-breakpoint-up(md) {
      padding: calc(#{$gutter} * 2) 0;
    }
  }

  .customer-address--wrapper,
  .invoice-address--wrapper {
    padding-left: 10px;
    padding-right: 10px;

    @include media-breakpoint-up(md) {
      padding-left: $gutter;
      padding-right: $gutter;
    }
  }

  .customer-address--wrapper {
    .add-customer-address {
      text-align: center;
      margin-bottom: calc(#{$gutter} * 2);

      a {
        @include button-border(blue, gold);

        padding: 10px $gutter;
        font-size: $font-size-small;
        width: 100%;

        @include media-breakpoint-up(sm) {
          font-size: $font-size-default;
          width: auto;
        }

        @include media-breakpoint-up(md) {
          padding: 10px calc(#{$gutter} * 2);
        }
      }

      .add {
        @include montserrat-medium;

        font-style: normal;
        color: col(blue);
        padding-right: 10px;
      }
    }
  }

  .invoice-address--wrapper {
    margin-top: calc(#{$gutter} * 2);

    @include media-breakpoint-up(md) {
      margin-top: 0;
    }

    .add-invoice-address,
    .different-invoice-address {
      margin-top: calc(#{$gutter} * 2);
      text-align: center;

      @include media-breakpoint-up(md) {
        text-align: left;
      }

      a {
        @include montserrat-semibold;
        color: col(blue);
        text-decoration: underline;
        font-size: $font-size-small;

        &:hover {
          text-decoration: none;
        }

        @include media-breakpoint-up(md) {
          @include link(blue, blue);
          font-size: $font-size-default;
        }
      }
    }

    .invoice-address-text {
      @include montserrat-semibold;

      color: col(blue);
    }
  }

  .form-footer {
    .btn {
      min-width: 250px;
      margin-bottom: $gutter;
    }

    &.customer-address-footer {
      padding: $gutter 0;
    }

    &.checkout-address-footer {
      padding: 0 10px;
    }
  }

  .address-selector {
    margin-bottom: calc(#{$gutter} * 2);

    .address-item {
      &.selected {
        label {
          border-color: col(gold);
          border-width: 2px;
        }
      }

      &.disable-interaction {
        opacity: .3;

        label {
          cursor: not-allowed;

          &:hover {
            box-shadow: none;
          }
        }

        input[type="radio"],
        .custom-radio {
          cursor: not-allowed;
        }
      }

      label {
        cursor: pointer;
        padding: calc(#{$gutter} * 2) $gutter;
        border: 1px solid col(grey);
        margin-bottom: $gutter;
        transition: all .3s $ease-default;
        display: block;

        @include media-breakpoint-up(md) {
          padding: calc(#{$gutter} * 2);
        }

        &:hover {
          box-shadow: $box-shadow;
        }
      }

      address {
        @include montserrat-medium;

        margin: $gutter 0;
        padding-left: 25px;
        color: col(blue);
        font-style: normal;
        font-size: $font-size-medium;
      }

      .address-alias {
        @include montserrat-bold;

        font-size: $font-size-subtitle;
        color: col(blue);
      }

      .address-footer {
        padding-top: 10px;
        margin-left: 25px;
        border-top: 1px solid col(gold);

        a {
          @include montserrat-semibold;

          color: col(grey-contact);
          text-decoration: none;
          font-size: $font-size-small;
          margin-right: 10px;

          &:after {
            display: none;
          }
        }
      }
    }
  }
}

// Step Delivery
#checkout-delivery-step {
  .delivery-option {
    &.active {
      label {
        border-color: col(gold);
      }
    }

    label {
      @include montserrat-medium;

      color: col(blue);
      padding: $gutter 10px;
      border: 1px solid col(grey);
      margin-bottom: $gutter;
      display: block;
      cursor: pointer;

      @include media-breakpoint-up(sm) {
        padding: calc(#{$gutter} * 2) $gutter;
      }
    }

    .custom-radio {
      width: 10%;
      height: 40px;
      flex-grow: 0;
      flex-shrink: 0;
      padding-left: 30px;

      @include media-breakpoint-up(md) {
        width: 40px;
      }

      span {
        @include media-breakpoint-up(md) {
          width: 28px;
          height: 28px;
        }

        &:before {
          @include media-breakpoint-up(md) {
            width: 15px;
            height: 15px;
          }
        }
      }
    }

    .icon {
      width: 10%;
      height: 40px;
      flex-grow: 0;
      flex-shrink: 0;
      margin-right: 10px;
      background-position: center;
      display: none;

      @include media-breakpoint-up(md) {
        margin-right: $gutter;
        display: block;
      }
    }

    .carrier-delay {
      @include montserrat-medium;

      color: col(blue);
      flex-grow: 0;
      flex-shrink: 0;
      padding-right: 10px;
      font-size: $font-size-default;
      cursor: pointer;
      width: 64%;

      @include media-breakpoint-up(md) {
        width: 53%;
        padding-right: $gutter;
      }
    }

    .carrier-price {
      @include montserrat-bold;

      color: col(blue);
      font-size: $font-size-default;
      flex-grow: 0;
      flex-shrink: 0;
      width: 22%;
      text-align: right;

      @include media-breakpoint-up(sm) {
        font-size: $font-size-medium;
      }
    }
  }

  .order-options {
    label {
      display: inline-block;
      margin-bottom: $gutter;
    }

    textarea {
      width: 100%;
    }
  }

  .relay-delivery-option--wrapper {
    padding-bottom: 15px;
    padding-left: 10px;
    padding-right: 10px;

    @include media-breakpoint-up(md) {
      padding-left: $gutter;
      padding-right: $gutter;
    }

    @include media-breakpoint-up(lg) {
      padding-bottom: 0;
    }
  }

  .home-delivery-option--wrapper {
    padding-top: 15px;
    padding-left: 10px;
    padding-right: 10px;

    @include media-breakpoint-up(md) {
      padding-left: $gutter;
      padding-right: $gutter;
    }

    @include media-breakpoint-up(lg) {
      padding-top: 0;
    }
  }

  .delivery-option-setting {
    padding-left: 10px;
    padding-right: 10px;

    @include media-breakpoint-up(md) {
      padding-left: $gutter;
      padding-right: $gutter;
    }
  }

  .delivery-options-list {
    .form-footer {
      padding: 0 10px;

      @include media-breakpoint-up(md) {
        padding: 0 $gutter;
      }
    }
  }
}

#checkout-payment-step {
  .step-content {
    padding-left: 10px;
    padding-right: 10px;

    @include media-breakpoint-up(md) {
      padding-left: calc(#{$gutter} * 2);
      padding-right: calc(#{$gutter} * 2);
    }
  }

  .cart-payment-intro {
    @include montserrat-medium;

    color: col(blue);
    margin-bottom: calc(#{$gutter} * 2);
  }

  .payment-options {
    padding-bottom: $gutter;

    .payment-option {
      label {
        @include montserrat-bold;

        display: flex;
        align-items: center;
        color: col(blue);
        font-size: $font-size-default;
        padding: $gutter 10px;
        border: 1px solid col(grey);
        margin-bottom: $gutter;
        cursor: pointer;
        transition: all .3s;

        @include media-breakpoint-up(sm) {
          padding: $gutter;
        }

        &.selected {
          border-color: col(gold);
        }
      }
    }

    .custom-radio,
    img {
      margin-right: 10px;

      @include media-breakpoint-up(md) {
        margin-right: 20px;
      }
    }

    .definition-list {
      padding: $gutter;
      background-color: col(light-gold);

      section {
        color: col(blue);
      }

      dl {
        dt {
          @include montserrat-bold;

          margin: 8px 0;
        }
      }
    }

    .tab-content {
      margin-top: 5px;

      @include media-breakpoint-up(md) {
        margin: 0;
      }
    }
  }

  .condition-label {
    padding: $gutter 10px;
    background-color: col(mid-grey);

    @include media-breakpoint-up(sm) {
      padding: $gutter;
    }

    label {
      @include montserrat-semibold;

      color: col(blue);
      font-size: $font-size-small;

      @include media-breakpoint-up(sm) {
        font-size: $font-size-default;
      }
    }

    a {
      color: col(gold);
      text-decoration: underline;
      font-size: $font-size-small;
      transition: all .3s;

      @include media-breakpoint-up(sm) {
        font-size: $font-size-default;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }

  #modal-terms {
    .page-heading {
      @include typo-h3();

      color: col(gold);
    }

    .page-subheading {
      @include montserrat-bold;

      font-size: $font-size-default;
    }
  }

  #payment-confirmation {
    .js-alert-payment-conditions {
      margin-top: calc(#{$gutter} * 2);
      display: block;
      text-align: left;
    }

    .accordion-toggler {
      @include montserrat-semibold;
      @include accordion-header(blue, "chevron.svg", gold, 14px) {
        padding: 5px 25px 5px 0;
        text-decoration: none;
        text-transform: none;
        border: none;
        font-size: $font-size-small;

        @media (min-width: 400px) {
          display: inline;
          font-size: $font-size-default;
        }
      }
    }

  }

  #order-summary-content {
    padding-top: $gutter;

    @include media-breakpoint-up(md) {
      padding-top: calc(#{$gutter} * 2);
    }

    .title {
      @include typo-h3;

      color: col(blue);
      text-transform: uppercase;
      margin-bottom: $gutter;
    }

    .subtitle {
      @include montserrat-bold;

      color: col(gold);
      font-size: $font-size-medium;
      margin-bottom: $gutter;
      position: relative;
    }

    .address {
      cursor: pointer;
      padding: calc(#{$gutter} * 2);
      border: 1px solid col(grey);
      margin-bottom: $gutter;
      transition: all .3s $ease-default;
      display: block;

      &:hover {
        box-shadow: $box-shadow;
      }

      address {
        @include montserrat-medium;

        margin: $gutter 0;
        color: col(blue);
      }

      &-alias {
        @include montserrat-bold;

        font-size: $font-size-medium;
        color: col(blue);
      }
    }

    .summary-selected-carrier {
      border: 1px solid col(grey);
      padding: $gutter;

      .carrier-delay {
        font-size: $font-size-small;
      }

      .carrier-logo {
        width: 40px;
        height: 40px;
        margin-right: $gutter;
        flex-grow: 1;
        flex-shrink: 0;

        img {
          width: 100%;
          min-height: auto;
        }
      }

      .carrier-name {
        flex-grow: 1;
        flex-wrap: 0;
      }

      .carrier-price {
        @include montserrat-bold;

        color: col(blue);
        font-size: $font-size-medium;
      }
    }
  }
}

.delivery-option-setting {
  @include media-breakpoint-up(md) {
    padding: 0 calc(#{$gutter} * 2);
  }

  .note {
    @include montserrat;
  }

  .btn-link {
    @include link(blue, blue);

    margin-top: $gutter;
  }

  .delivery-adress-text {
    h4 {
      @include montserrat-bold;

      font-size: $font-size-medium;
      color: col(blue);
      margin-bottom: $gutter;
    }

    p {
      @include montserrat-semibold;

      font-size: $font-size-default;
      color: col(blue);
      margin-bottom: 0;
    }
  }

  .mondialrelay-summary--wrapper,
  .collisimo-summary--wrapper {
    padding: $gutter $gutter 0;
    border: 1px solid col(grey);
    border-bottom: none;

    @include media-breakpoint-up(md) {
      padding: calc(#{$gutter} * 2) calc(#{$gutter} * 2) 0;
    }
  }

  .order-information-delivery {
    padding: calc(#{$gutter} * 2) $gutter $gutter;
    border: 1px solid col(grey);
    border-top: none;

    @include media-breakpoint-up(md) {
      padding: calc(#{$gutter} * 2);
    }

    .form-group {
      margin-bottom: calc(#{$gutter} * 2);

      @include media-breakpoint-up(md) {
        margin-bottom: $gutter;
      }

      .form-control-label {
        @include media-breakpoint-up(md) {
          width: auto;
          margin-right: $gutter;
        }
      }

      .form-control-input {
        @include media-breakpoint-up(md) {
          flex-grow: 1;
        }
      }
    }
  }

  #mondialrelay_summary {
    margin-top: calc(#{$gutter} * 2);
  }

  #delivery-information-delivery {
    textarea {
      height: 40px;
      padding-right: 30px;

      @include media-breakpoint-up(md) {
        padding-right: 140px;
      }
    }

    .form-control-input {
      position: relative;
    }

    .form-control-comment {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }
}

#mondialrelay_content {
  .leaflet-popup-content-wrapper {
    border-radius: 0;
  }

  #mondialrelay_save-container {
    padding: $gutter 0;
    border-bottom-color: col(light-gold);

    button {
      @include button-color(white, blue, gold);

      width: 100%;

      @include media-breakpoint-up(md) {
        width: auto;
      }
    }
  }

  .MR-Widget {
    border: none !important;
    width: 100%;
    padding: 0;
    margin: 0;

    .MRW-Title {
      @include montserrat-bold;

      font-size: $font-size-medium;
      color: col(blue);
      background: none;
      border: none;
      text-align: left;
      padding: $gutter 0;
    }

    .MRW-RList {
      @include media-breakpoint-down(sm) {
        display: none !important;
      }
    }

    .MRW-ShowList {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    .leaflet-zoom-animated {
      @include media-breakpoint-down(sm) {
        width: 240px;
      }
    }

    .leaflet-popup-content-wrapper {
      @include media-breakpoint-down(sm) {
        overflow: scroll;
      }
    }
  }
}
