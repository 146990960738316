/**
 * ----------------------------------
 * Order confirmation stylesheet
 * ----------------------------------
 */
#order-confirmation,
#order-confirmation-resume {
  .order-payement,
  .order-resume,
  .order-details {
    padding: $gutter;
    border: 2px solid col(gold);
    margin-bottom: $gutter;
  }

  .confirmation-message {
    padding: $gutter;
    color: col(green);
    border: 2px solid rgba(col(green), .3);
    background-color: transparent;
    border-radius: 0;
    margin-bottom: calc(#{$gutter} * 2);

    p {
      @include montserrat;

      margin-bottom: 0;
    }

    a {
      @include link(green, green);
    }
  }

  .page-order-confirmation,
  .resume-order--wrapper {
    .order-resume {
      h3 {
        @include montserrat-bold;

        color: col(blue);
        font-size: $font-size-default;
      }

      .order-confirmation-table {
        margin-top: $gutter;
        padding: 0 $gutter;

        .order-line {
          padding: $gutter 0;
          border-top: 1px solid col(light-gold);
        }

        .product-image {
          @include figure-square;

        }

        .details {
          @include montserrat-bold;

          color: col(gold);
          font-size: $font-size-small;

          @include media-breakpoint-up(md) {
            font-size: $font-size-default;
          }
        }

        .qty {
          @include montserrat-medium;

          font-size: $font-size-medium;
          color: col(blue);
          padding-top: 8px;

          @include media-breakpoint-up(md) {
            padding-top: 0;
          }

          .bold {
            @include montserrat-bold;
          }
        }

        .table-prices {
          border-top: 1px solid col(light-gold);

          td {
            @include montserrat-medium;

            padding: 5px 0;
            font-size: $font-size-default;
            color: col(blue);

            @include media-breakpoint-up(md) {
              font-size: $font-size-medium;
            }

            &:first-child {
              @include montserrat-bold;
            }

            &:last-child {
              text-align: right;
            }

            &.has-discount {
              color: col(gold);
            }
          }

          .total-value {
            td {
              &:last-child {
                @include montserrat-bold;

                font-size: $font-size-subtitle;
              }
            }
          }
        }
      }
    }

    .order-details {
      .title {
        @include typo-h3;

        text-transform: uppercase;
        margin-bottom: $gutter;
        color: col(blue);
      }

      em {
        display: block;
        color: col(grey);
        padding: 5px 0;
      }

      ul {
        li {
          @include montserrat-medium;

          padding: 5px 0;
        }
      }
    }
  }

  .definition-list {
    @include montserrat;

    strong {
      color: col(blue);
    }

    a {
      @include montserrat-bold;
      @include link(gold, gold);
    }

    .price {
      @include montserrat-bold;

      color: col(blue);
      font-size: $font-size-subtitle;
    }
  }
}

#order-confirmation-resume {
  .order-resume {
    margin-top: $gutter;

    a {
      @include montserrat-bold;
      color: col(gold);
      transition: all .3s;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
