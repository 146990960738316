/**
 * ----------------------------------
 * Video stylesheet
 * ----------------------------------
 */
.video {
  position: relative;
  overflow: hidden;
  transform: translateZ(0);
  cursor: pointer;

  &:before {
    content: "";
    display: block;
    padding-top: 70%;
  }

  iframe {
    display: block;
    max-width: inherit;
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: rotate(.001deg) translate3d(-50%, -50%, 0);
    z-index: 0;
    perspective: 1000px;
  }

  &-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .thumbnail {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      position: relative;
      z-index: 2;
      width: 60px;
      height: 60px;
      margin: 0;

      @include media-breakpoint-up(md) {
        width: 80px;
        height: 80px;
      }
    }

    .image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;

      img {
        width: 100%;

        @include media-breakpoint-up(md) {
          width: auto;
        }
      }
    }

    &.video-loading {
      position: relative;

      &:before {
        content: "";
        background-color: rgba(col(white), .85);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
      }
    }
  }
}
