/**
 * ----------------------------------
 * Variables
 * ----------------------------------
 */
// bootstrap breakpoint
$grid-breakpoints: (
  xxs: 0,
  xs: 480px,
  sm: 640px,
  md: 768px,
  lg: 1024px,
  xl: 1200px
);

// bootstrap container
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1170px
);

// bootstrap display
$displays: none, block, flex, inline !default;

// header
$header-selector: h1, h2, h3, h4, h5, h6;

// layout
$width-no-scroll: calc(100vw - var(--scrollbar-width));

// Box model
$gutter: 15px;

// easing
$ease-default: cubic-bezier(.4, .5, .15, 1);

// box shadow
$shadow-card: 0 4px 14px rgba(0, 0, 0, .25);
$shadow-menu: 0 20px 30px rgba(0, 0, 0, .25);

// font size
$font-size-small: 12px;
$font-size-default: 14px;
$font-size-medium: 16px;
$font-size-subtitle: 22px;
$font-size-title: 24px;
$font-size-sectiontitle: 26px;
$font-size-sectionvideo: 32px;

// modal
$modal-backdrop-bg: rgba(#00244c, .7);
$modal-header-border-color: rgba(#b88210, .3);
$modal-content-border-color: transparent;
$modal-content-border-radius: 0;
$modal-inner-padding: 1.875rem;

// tooltip
$tooltip-bg: #ffffff;
$tooltip-arrow-color: #ffffff;
$tooltip-color: #00244c;
$tooltip-max-width: 300px;
$tooltip-padding-y: 5px;
$tooltip-padding-x: 10px;
