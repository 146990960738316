/**
 * ----------------------------------
 * Gdpr stylesheet
 * ----------------------------------
 */
.gdpr-account-content {
  .gdpr-section {
    @include montserrat-medium;

    padding: calc(#{$gutter} * 2);
    background-color: col(light-gold);
    margin-bottom: $gutter;
    font-size: $font-size-default;
    color: col(blue);

    .title {
      @include typo-h2;

      margin-bottom: $gutter;
    }

    .text {
      a {
        @include link(blue, gold);
      }
    }

    .btn {
      @include button-color(white, blue, gold);

      display: block;
      margin-bottom: 10px;

      &:first-child {
        margin-right: 0;

        @include media-breakpoint-up(md) {
          margin-right: $gutter;
        }
      }

      @include media-breakpoint-up(md) {
        display: inline-block;
        margin-bottom: 0;
      }
    }

    .btn--wrapper {
      text-align: right;
    }
  }
}

.henaff-module-psgdpr-gdpr {
  .my-account-links--wrapper {
    margin-top: calc(#{$gutter} * 2);

    a {
      margin-top: 0;
    }
  }
}
