/**
 * ----------------------------------
 * Bootstrap
 * ----------------------------------
 */
// require
@import "bootstrap/scss/_functions";
@import "bootstrap/scss/_variables";
@import "bootstrap/scss/_mixins";

// component
@import "bootstrap/scss/_transitions";
@import "bootstrap/scss/_alert";
@import "bootstrap/scss/_modal";
@import "bootstrap/scss/_tooltip";
