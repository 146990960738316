/**
 * ----------------------------------
 * Application stylesheet
 * ----------------------------------
 */

// Fonts
@import 'fonts/google';

// Config
@import 'config/color';
@import 'config/fonts';
@import 'config/variables';

// Autoload
@import 'autoload/bootstrap';
@import 'autoload/slick';
@import 'autoload/select2';

// Helpers
@import 'helpers/animations';
@import 'helpers/functions';
@import 'helpers/mixins';
@import 'helpers/svg';

// Layout
@import 'layout/global';
@import 'layout/editor';

// Components
@import 'components/image';
@import 'components/video';
@import 'components/form';
@import 'components/spinner';
@import 'components/homeslider';
@import 'components/social-share';
@import 'components/product-card';
@import 'components/modal';
@import 'components/breadcrumb';
@import 'components/block-image-text';
@import 'components/product-comment';
@import 'components/product-pack';
@import 'components/blockreassurance';
@import 'components/loyalty';
@import 'components/footer';

// Page
@import 'components/page-home';
@import 'components/page-product';
@import 'components/page-boutiques';
@import 'components/page-contact';
@import 'components/page-cms';
@import 'components/page-not-found';
@import 'components/page-sitemap';
@import 'components/cart';
@import 'components/checkout';
@import 'components/authentication';
@import 'components/account';
@import 'components/order-confirmation';
@import 'components/product-list';
@import 'components/manufacturers';
@import 'components/faq';
@import 'components/gdpr';
