/**
 * ----------------------------------
 * Animations
 * ----------------------------------
 */

// Stagger animation
@mixin staggerAnimation ($delay, $lengh) {
  @for $i from 1 through $lengh {
    &:nth-child(#{$i}) {
      transition-delay: $delay * $i;
    }
  }
}

// Animation
@mixin inline-animation ($duration: .3s, $ease: linear, $count: 1) {
  $name: inline-#{unique-id()};

  @keyframes #{$name} {
    @content;
  }

  animation: {
    name: $name;
    duration: $duration;
    timing-function: $ease;
    iteration-count: $count;
  }
}
