/**
 * ----------------------------------
 * Application stylesheet
 * ----------------------------------
 */
/**
 * -------------------------------------------------------------
 * Google fonts
 * -------------------------------------------------------------
 */
@import url("https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,,700i&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,500,500i,600,600i,700,700i&display=swap");
/**
 * ----------------------------------
 * Color
 * ----------------------------------
 */
/**
 * ----------------------------------
 * Fonts
 * ----------------------------------
 */
/* stylelint-disable */
/* stylelint-enable */
/**
 * ----------------------------------
 * Variables
 * ----------------------------------
 */
/**
 * ----------------------------------
 * Bootstrap
 * ----------------------------------
 */
.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}
.alert-primary hr {
  border-top-color: #9fcdff;
}
.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

.alert-white {
  color: #858585;
  background-color: white;
  border-color: white;
}
.alert-white hr {
  border-top-color: #f2f2f2;
}
.alert-white .alert-link {
  color: #6c6c6c;
}

.alert-grey {
  color: #5d6269;
  background-color: #f0f2f4;
  border-color: #e9edf0;
}
.alert-grey hr {
  border-top-color: #dae1e6;
}
.alert-grey .alert-link {
  color: #45494e;
}

.alert-light-grey {
  color: #818385;
  background-color: #fefeff;
  border-color: #fdfeff;
}
.alert-light-grey hr {
  border-top-color: #e4f1ff;
}
.alert-light-grey .alert-link {
  color: #686a6b;
}

.alert-mid-grey {
  color: #7c7c7c;
  background-color: #fcfcfc;
  border-color: #fafafb;
}
.alert-mid-grey hr {
  border-top-color: #ececf0;
}
.alert-mid-grey .alert-link {
  color: #636363;
}

.alert-dark-grey {
  color: #242e39;
  background-color: #dadee2;
  border-color: #cbd0d6;
}
.alert-dark-grey hr {
  border-top-color: #bdc3cb;
}
.alert-dark-grey .alert-link {
  color: #10151a;
}

.alert-grey-contact {
  color: #434343;
  background-color: #e6e6e6;
  border-color: #dbdbdb;
}
.alert-grey-contact hr {
  border-top-color: #cecece;
}
.alert-grey-contact .alert-link {
  color: #2a2a2a;
}

.alert-black {
  color: black;
  background-color: #cccccc;
  border-color: #b8b8b8;
}
.alert-black hr {
  border-top-color: #ababab;
}
.alert-black .alert-link {
  color: black;
}

.alert-blue {
  color: #001328;
  background-color: #ccd3db;
  border-color: #b8c2cd;
}
.alert-blue hr {
  border-top-color: #a9b5c2;
}
.alert-blue .alert-link {
  color: black;
}

.alert-blue-dark {
  color: #000b18;
  background-color: #ccd0d5;
  border-color: #b8bec4;
}
.alert-blue-dark hr {
  border-top-color: #aab1b8;
}
.alert-blue-dark .alert-link {
  color: black;
}

.alert-gold {
  color: #604408;
  background-color: #f1e6cf;
  border-color: #ebdcbc;
}
.alert-gold hr {
  border-top-color: #e5d2a8;
}
.alert-gold .alert-link {
  color: #312304;
}

.alert-light-gold {
  color: #817e79;
  background-color: #fefcfa;
  border-color: #fdfbf9;
}
.alert-light-gold hr {
  border-top-color: #f7eee6;
}
.alert-light-gold .alert-link {
  color: #676460;
}

.alert-green {
  color: #1e5e26;
  background-color: #d7f0db;
  border-color: #c8eacc;
}
.alert-green hr {
  border-top-color: #b6e3bb;
}
.alert-green .alert-link {
  color: #123716;
}

.alert-red {
  color: #850900;
  background-color: #ffcfcc;
  border-color: #ffbcb8;
}
.alert-red hr {
  border-top-color: #ffa49f;
}
.alert-red .alert-link {
  color: #520600;
}

.alert-orange {
  color: #854f00;
  background-color: #ffeacc;
  border-color: #ffe2b8;
}
.alert-orange hr {
  border-top-color: #ffd89f;
}
.alert-orange .alert-link {
  color: #523100;
}

.alert-promo {
  color: #015678;
  background-color: #ccedfa;
  border-color: #b8e6f8;
}
.alert-promo hr {
  border-top-color: #a1def6;
}
.alert-promo .alert-link {
  color: #013245;
}

.alert-facebook {
  color: #1d304a;
  background-color: #d7dee8;
  border-color: #c7d1df;
}
.alert-facebook hr {
  border-top-color: #b7c4d6;
}
.alert-facebook .alert-link {
  color: #0f1825;
}

.alert-twitter {
  color: #3d587a;
  background-color: #e4eefb;
  border-color: #d9e7f9;
}
.alert-twitter hr {
  border-top-color: #c3d9f6;
}
.alert-twitter .alert-link {
  color: #2c3f58;
}

.alert-google-plus {
  color: #7e261d;
  background-color: #fddbd7;
  border-color: #fcccc7;
}
.alert-google-plus hr {
  border-top-color: #fbb6af;
}
.alert-google-plus .alert-link {
  color: #551913;
}

.alert-pinterest {
  color: #700a1e;
  background-color: #f7d0d8;
  border-color: #f4bdc8;
}
.alert-pinterest hr {
  border-top-color: #f0a7b6;
}
.alert-pinterest .alert-link {
  color: #410611;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid transparent;
  border-radius: 0;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 36, 76, 0.7);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid rgba(184, 130, 16, 0.3);
  border-top-left-radius: -1px;
  border-top-right-radius: -1px;
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1.875rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 1.625rem;
  border-top: 1px solid rgba(184, 130, 16, 0.3);
  border-bottom-right-radius: -1px;
  border-bottom-left-radius: -1px;
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 640px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 1024px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #ffffff;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #ffffff;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #ffffff;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #ffffff;
}

.tooltip-inner {
  max-width: 300px;
  padding: 5px 10px;
  color: #00244c;
  text-align: center;
  background-color: #ffffff;
  border-radius: 0.25rem;
}

/**
 * ----------------------------------
 * Swiper
 * ----------------------------------
 */
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/**
 * ----------------------------------
 * Select2
 * ----------------------------------
 */
.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
}

.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container .select2-selection--single .select2-selection__clear {
  position: relative;
}

.select2-container[dir=rtl] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}

.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-block;
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container .select2-search--inline {
  float: left;
}

.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  padding: 0;
}

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
}

.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-results__option[aria-selected] {
  cursor: pointer;
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  display: block;
  padding: 4px;
}

.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
  list-style: none;
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px;
  padding: 1px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #333;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice, .select2-container--default[dir=rtl] .select2-selection--multiple .select2-search--inline {
  float: right;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
}

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--default .select2-results__option[role=group] {
  padding: 0;
}

.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #ddd;
}

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #5897fb;
  color: white;
}

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: -o-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFFFF", endColorstr="#FFEEEEEE", GradientType=0);
}

.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-right: 10px;
}

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--classic .select2-selection--single .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEEEEEE", endColorstr="#FFCCCCCC", GradientType=0);
}

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: transparent;
  border: none;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: -o-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFFFF", endColorstr="#FFEEEEEE", GradientType=0);
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEEEEEE", endColorstr="#FFFFFFFF", GradientType=0);
}

.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0;
}

.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--multiple .select2-selection__rendered {
  list-style: none;
  margin: 0;
  padding: 0 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  color: #888;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555;
}

.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  float: right;
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0;
}

.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none;
}

.select2-container--classic .select2-dropdown {
  background-color: white;
  border: 1px solid transparent;
}

.select2-container--classic .select2-dropdown--above {
  border-bottom: none;
}

.select2-container--classic .select2-dropdown--below {
  border-top: none;
}

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--classic .select2-results__option[role=group] {
  padding: 0;
}

.select2-container--classic .select2-results__option[aria-disabled=true] {
  color: grey;
}

.select2-container--classic .select2-results__option--highlighted[aria-selected] {
  background-color: #3875d7;
  color: white;
}

.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb;
}

/**
 * ----------------------------------
 * Animations
 * ----------------------------------
 */
/**
 * ----------------------------------
 * Functions
 * ----------------------------------
 */
/**
 * ----------------------------------
 * Mixins
 * ----------------------------------
 */
.gdpr-account-content .gdpr-section .btn, .manufacturer-products .link-more, #_desktop_search_filters_clear_all .btn, .henaff-order-detail .box .invoice-url a, .henaff-order-detail .box .button, .henaff-loyalty .loyalty-card .btn, .henaff-history .orders .order-actions a,
.henaff-history .orders .slip-actions a,
.henaff-history .credit-slips .order-actions a,
.henaff-history .credit-slips .slip-actions a,
.henaff-order-slip .orders .order-actions a,
.henaff-order-slip .orders .slip-actions a,
.henaff-order-slip .credit-slips .order-actions a,
.henaff-order-slip .credit-slips .slip-actions a, .henaff-addresses .addresses-footer a, .henaff-my-account .card-account .btn, .henaff-my-account .account-sidemenu .log-out a,
.henaff-identity .account-sidemenu .log-out a,
.henaff-address .account-sidemenu .log-out a,
.henaff-addresses .account-sidemenu .log-out a,
.henaff-history .account-sidemenu .log-out a,
.henaff-order-slip .account-sidemenu .log-out a,
.henaff-loyalty .account-sidemenu .log-out a, .forgotten-password .form-group .button-wrapper a, .forgotten-password .form-group .button-wrapper button, #mondialrelay_content #mondialrelay_save-container button, #checkout-addresses-step .customer-address--wrapper .add-customer-address a, #checkout-personal-information-step .btn, .cart-content .form-voucher .btn,
.cart-content .form-loyalty .btn, .cart-content .cart-detailed-actions .btn-primary, .cart-content .cart-empty-actions .btn-primary, .subscribe-fid, .fix-cart-detailed-actions .btn-primary, .page-not-found .btn, .product-detail .product-additional-info .js-mailalert .btn, .home-boutique .content .cta, .home-focus .cta, .home-about-us .text-wrapper .cta, .home-slide-product .link-more-wrapper .link-more, #main-footer .footer-newsletter .email_subscription .btn, .product-pack-info .add-to-cart, .product-pack-info .product-name .see-pack, .product-comment-modal .btn-comment-inverse, .product-comment-modal .btn-comment, .block-image-text .cta, #alcohol-modal .modal-footer .btn, #blockcart-modal .modal-body .cart-btn .btn-secondary, #blockcart-modal .modal-body .cart-btn .btn-primary, .home-slider .caption a {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 600;
  display: inline-block;
  transition: all 0.3s ease;
  text-decoration: none;
  outline: none;
  border: none;
  cursor: pointer;
  text-align: center;
}

/**
 * ----------------------------------
 * SVG
 * ----------------------------------
 */
.danger {
  background-image: svg-load("warning.svg", fill=#ff1100);
}

.feedback {
  background-image: svg-load("comment.svg", fill=#ff9800);
}

.play {
  background-image: svg-load("play.svg", fill=#ffffff);
}

.edit {
  background-image: svg-load("edit.svg", fill=#808080);
}

.close {
  background-image: svg-load("close.svg", fill=#b88210);
}
.close-white {
  background-image: svg-load("close.svg", fill=#ffffff);
}
.close-blue {
  background-image: svg-load("close.svg", fill=#00244c);
}

.panier {
  background-image: svg-load("panier.svg", fill=#b88210);
}

.compte {
  background-image: svg-load("compte.svg", fill=#b88210);
}

.envelop {
  background-image: svg-load("envelop.svg", fill=#b88210);
}

.location {
  background-image: svg-load("pin.svg", fill=#b88210);
}

.gift {
  background-image: svg-load("gift.svg", fill=#b88210);
}

.return-merchandise {
  background-image: svg-load("return.svg", fill=#b88210);
}

.voucher {
  background-image: svg-load("voucher.svg", fill=#b88210);
}

.euro {
  background-image: svg-load("euro.svg", fill=#b88210);
}

.search {
  background-image: svg-load("search.svg", fill=#b88210);
}

.delete {
  background-image: svg-load("delete.svg", fill=#00244c);
}
.delete-white {
  background-image: svg-load("delete.svg", fill=#ffffff);
}
.delete-grey {
  background-image: svg-load("delete.svg", fill=#808080);
}

.help {
  background-image: svg-load("help.svg", fill=#b2bdc9);
}
.help:hover {
  background-image: svg-load("help.svg", fill=#00244c);
}

.slide-next {
  background-image: svg-load("slide-next.svg", fill=#00244c);
}

.slide-prev {
  background-image: svg-load("slide-prev.svg", fill=#00244c);
}

.turn {
  background-image: svg-load("turn-right.svg", fill=#b88210);
}

.eye {
  background-image: svg-load("eye.svg", fill=#00244c);
}

.livraison {
  background-image: svg-load("livraison.svg", fill=);
}

.pin-blue {
  background-image: svg-load("pin-blue.svg", fill=);
}

.instagram {
  background-image: svg-load("instagram.svg", fill=#00244c);
}

.facebook {
  background-image: svg-load("facebook.svg", fill=#00244c);
}
.facebook-white {
  background-image: svg-load("facebook.svg", fill=#ffffff);
}
.facebook-outline {
  background-image: svg-load("facebook-outline.svg", fill=#00244c);
}

/**
 * ----------------------------------
 * Global layout
 * ----------------------------------
 */
#captcha-box {
  margin-bottom: 15px;
}

.henaff-index .breadcrumb,
.henaff-authentication .breadcrumb,
.henaff-password .breadcrumb,
.henaff-cart .breadcrumb,
.henaff-order-confirmation .breadcrumb,
.henaff-my-account .breadcrumb,
.henaff-identity .breadcrumb,
.henaff-address .breadcrumb,
.henaff-addresses .breadcrumb,
.henaff-history .breadcrumb,
.henaff-order-slip .breadcrumb,
.henaff-loyalty .breadcrumb,
.henaff-order-detail .breadcrumb,
.henaff-module-henaff_asten-recovery_carte .breadcrumb {
  display: none;
}

.henaff-authentication #main-wrapper,
.henaff-password #main-wrapper,
.henaff-cart #main-wrapper,
.henaff-checkout #main-wrapper,
.henaff-order-confirmation #main-wrapper,
.henaff-my-account #main-wrapper,
.henaff-identity #main-wrapper,
.henaff-address #main-wrapper,
.henaff-addresses #main-wrapper,
.henaff-history #main-wrapper,
.henaff-order-slip #main-wrapper,
.henaff-loyalty #main-wrapper,
.henaff-order-detail #main-wrapper,
.henaff-module-henaff_asten-recovery_carte #main-wrapper {
  margin-top: calc(15px * 4);
}

.henaff-module-ps_emailsubscription-verification .message-txt {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  color: #00244c;
  font-size: 18px;
  margin-bottom: 0;
  padding: calc(15px * 3) 0;
}

.page-header {
  margin-bottom: calc(15px * 2);
}
.page-header .title {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 24px;
  color: #00244c;
  text-transform: uppercase;
}
@media (min-width: 1024px) {
  .page-header .title {
    font-size: 28px;
  }
}

.hidden-max-sm.ready {
  display: none;
}
@media (max-width: 767.98px) {
  .hidden-max-sm.ready {
    display: block;
  }
}
.hidden-sm.ready {
  display: none;
}
@media (min-width: 768px) {
  .hidden-sm.ready {
    display: block;
  }
}

/**
 * ----------------------------------
 * Editor
 * ----------------------------------
 */
.editor-content p,
.cms-content p {
  font-family: roboto, arial, helvetica, sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 15px;
}
.editor-content p:only-child,
.cms-content p:only-child {
  margin-bottom: 0;
}
.editor-content a,
.cms-content a {
  color: #ffffff;
  text-decoration: none;
  position: relative;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
}
.editor-content a:after,
.cms-content a:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #ffffff;
  transform-origin: center;
  transition: transform 0.3s cubic-bezier(0.4, 0.5, 0.15, 1);
}
.editor-content a:after,
.cms-content a:after {
  transform: scaleX(1);
}
.editor-content a:hover:after,
.cms-content a:hover:after {
  transform: scaleX(0);
}
.editor-content a.link-blue,
.cms-content a.link-blue {
  color: #00244c;
  text-decoration: none;
  position: relative;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
}
.editor-content a.link-blue:after,
.cms-content a.link-blue:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #b88210;
  transform-origin: center;
  transition: transform 0.3s cubic-bezier(0.4, 0.5, 0.15, 1);
}
.editor-content a.link-blue:after,
.cms-content a.link-blue:after {
  transform: scaleX(1);
}
.editor-content a.link-blue:hover:after,
.cms-content a.link-blue:hover:after {
  transform: scaleX(0);
}
.editor-content h1, .editor-content h2, .editor-content h3, .editor-content h4, .editor-content h5, .editor-content h6,
.cms-content h1,
.cms-content h2,
.cms-content h3,
.cms-content h4,
.cms-content h5,
.cms-content h6 {
  text-transform: uppercase;
  color: #00244c;
  margin-bottom: 15px;
}
.editor-content h1,
.cms-content h1 {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 24px;
}
@media (min-width: 1024px) {
  .editor-content h1,
.cms-content h1 {
    font-size: 28px;
  }
}
.editor-content h2,
.cms-content h2 {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 18px;
}
@media (min-width: 1024px) {
  .editor-content h2,
.cms-content h2 {
    font-size: 24px;
  }
}
.editor-content h3,
.cms-content h3 {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 16px;
}
@media (min-width: 1024px) {
  .editor-content h3,
.cms-content h3 {
    font-size: 18px;
  }
}
.editor-content ul,
.cms-content ul {
  font-family: roboto, arial, helvetica, sans-serif;
  font-weight: 400;
  list-style: none;
  margin-bottom: 15px;
}
.editor-content ul li,
.cms-content ul li {
  position: relative;
  padding-left: 10px;
  line-height: 1.5;
  margin-bottom: 5px;
}
.editor-content ul li:before,
.cms-content ul li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 10.5px;
  height: 4px;
  width: 4px;
  transform: translateY(-2px);
  border-radius: 50%;
  background-color: #b88210;
}
.editor-content ol,
.cms-content ol {
  font-family: roboto, arial, helvetica, sans-serif;
  font-weight: 400;
  list-style: none;
  counter-reset: number;
  margin-bottom: 15px;
}
.editor-content ol li,
.cms-content ol li {
  position: relative;
  padding-left: 20px;
  line-height: 1.5;
  margin-bottom: 5px;
  counter-increment: number;
}
.editor-content ol li:before,
.cms-content ol li:before {
  font-family: roboto, arial, helvetica, sans-serif;
  font-weight: 700;
  content: counter(number) ".";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  color: #b88210;
}
.editor-content .text-wrapper,
.cms-content .text-wrapper {
  margin-bottom: 15px;
}
.editor-content.content-blue a,
.cms-content.content-blue a {
  color: #00244c;
  text-decoration: none;
  position: relative;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
}
.editor-content.content-blue a:after,
.cms-content.content-blue a:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #00244c;
  transform-origin: center;
  transition: transform 0.3s cubic-bezier(0.4, 0.5, 0.15, 1);
}
.editor-content.content-blue a:after,
.cms-content.content-blue a:after {
  transform: scaleX(1);
}
.editor-content.content-blue a:hover:after,
.cms-content.content-blue a:hover:after {
  transform: scaleX(0);
}

/**
 * ----------------------------------
 * Image stylesheet
 * ----------------------------------
 */
.image {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  transform: translateZ(0);
}
.image.ready img {
  opacity: 1;
}
.image .media {
  width: 100%;
  height: 100%;
}
.image img {
  display: block;
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: rotate(0.001deg) translate3d(-50%, -50%, 0);
  z-index: 0;
  opacity: 0;
  transition: opacity 0.5s cubic-bezier(0.4, 0.5, 0.15, 1);
  perspective: 1000px;
}

.picto img {
  display: block;
  width: 100%;
  height: auto;
}

/**
 * ----------------------------------
 * Video stylesheet
 * ----------------------------------
 */
.video {
  position: relative;
  overflow: hidden;
  transform: translateZ(0);
  cursor: pointer;
}
.video:before {
  content: "";
  display: block;
  padding-top: 70%;
}
.video iframe {
  display: block;
  max-width: inherit;
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: rotate(0.001deg) translate3d(-50%, -50%, 0);
  z-index: 0;
  perspective: 1000px;
}
.video-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.video .thumbnail {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.video .thumbnail .icon {
  position: relative;
  z-index: 2;
  width: 60px;
  height: 60px;
  margin: 0;
}
@media (min-width: 768px) {
  .video .thumbnail .icon {
    width: 80px;
    height: 80px;
  }
}
.video .thumbnail .image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.video .thumbnail .image img {
  width: 100%;
}
@media (min-width: 768px) {
  .video .thumbnail .image img {
    width: auto;
  }
}
.video .thumbnail.video-loading {
  position: relative;
}
.video .thumbnail.video-loading:before {
  content: "";
  background-color: rgba(255, 255, 255, 0.85);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

/**
 * ----------------------------------
 * Form
 * ----------------------------------
 */
input::placeholder,
textarea::placeholder {
  font-family: roboto, arial, helvetica, sans-serif;
  font-weight: 400;
  color: #b2bdc9;
  font-size: 14px;
}

input[type=text],
input[type=email],
input[type=password],
input[type=tel],
input[type=date],
.select2-search__field {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  padding: 5px 15px;
  height: 40px;
  color: #00244c;
  border: 1px solid #b2bdc9;
  font-size: 14px;
}
input[type=text].border-bottom,
input[type=email].border-bottom,
input[type=password].border-bottom,
input[type=tel].border-bottom,
input[type=date].border-bottom,
.select2-search__field.border-bottom {
  border: none;
  padding: 10px 15px;
  border-bottom: 1px solid #b2bdc9;
  background: transparent;
}
input[type=text]:disabled, input[type=text]:read-only, input[type=text][readonly],
input[type=email]:disabled,
input[type=email]:read-only,
input[type=email][readonly],
input[type=password]:disabled,
input[type=password]:read-only,
input[type=password][readonly],
input[type=tel]:disabled,
input[type=tel]:read-only,
input[type=tel][readonly],
input[type=date]:disabled,
input[type=date]:read-only,
input[type=date][readonly],
.select2-search__field:disabled,
.select2-search__field:read-only,
.select2-search__field[readonly] {
  background-color: #eeeeef;
  cursor: not-allowed;
}

textarea {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  padding: 10px 15px;
  height: 150px;
  resize: none;
  border: 1px solid #b2bdc9;
  color: #00244c;
  font-size: 16px;
}
textarea.border-bottom {
  border: none;
  border-bottom: 1px solid #b2bdc9;
  background: transparent;
}

button,
input[type=submit] {
  cursor: pointer;
}
button:focus, button:active, button:hover,
input[type=submit]:focus,
input[type=submit]:active,
input[type=submit]:hover {
  outline: none;
}

.custom-select {
  position: relative;
  display: inline-block;
}
.custom-select.border-bottom .holder {
  border: none;
  padding: 10px calc(15px * 2) 10px 15px;
  border-bottom: 1px solid #b2bdc9;
  background: transparent;
}
.custom-select.select-faq {
  display: block;
}
.custom-select.select-faq .holder {
  border-color: #b88210;
}
.custom-select select {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 42px;
  z-index: 2;
  cursor: pointer;
  outline: none;
  opacity: 0;
}
.custom-select select option {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  padding: 10px;
  font-size: 14px;
}
.custom-select select:focus + .holder:after, .custom-select select:active + .holder:after {
  transform: rotate(90deg);
}
.custom-select .holder {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  position: relative;
  padding: 5px calc(15px * 2) 5px 15px;
  background: transparent;
  min-width: 90px;
  min-height: 40px;
  display: block;
  border: 1px solid #b2bdc9;
  color: #00244c;
  font-size: 14px;
  line-height: 30px;
}
.custom-select .holder:after {
  background-image: svg-load("chevron.svg", fill=#00244c);
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: right 50%;
  background-size: 12px;
  content: "";
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  width: 12px;
  transform: rotate(-90deg);
  transition: transform 0.55s ease;
}

.custom-select2 {
  display: block;
}
.custom-select2.border-bottom .select2-container--default .select2-selection--single {
  border: none;
  border-bottom: 1px solid #b2bdc9;
}
.custom-select2 .select2-container--default .select2-selection--single {
  border-color: #b2bdc9;
  border-radius: 0;
  padding: 10px calc(15px * 2) 10px 15px;
  height: 40px;
}
.custom-select2 .select2-container--default .select2-selection--single .select2-selection__rendered {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  color: #00244c;
  line-height: 1.5;
  font-size: 14px;
  padding: 0;
}
.custom-select2 .select2-container--default .select2-selection--single .select2-selection__arrow {
  transform: rotate(-90deg);
  top: 8px;
  right: 8px;
}
.custom-select2 .select2-container--default .select2-selection--single .select2-selection__arrow b {
  background-image: svg-load("chevron.svg", fill=#00244c);
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: right 50%;
  background-size: 12px;
  border: none;
  width: 12px;
  height: 12px;
  margin: 0;
  transform: translate3d(-50%, -50%, 0);
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: none;
  border-bottom: 1px solid #b2bdc9;
  padding: 15px;
}
.select2-container--default .select2-results li {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  color: #00244c;
  padding: 10px;
  border-bottom: 1px solid #b2bdc9;
}
.select2-container--default .select2-results .select2-results__option--highlighted[aria-selected] {
  background-color: #00244c;
}
.select2-container--open .select2-dropdown {
  border: none;
}
.select2-container--open .select2-dropdown--below {
  top: -40px;
}
.select2-container--open .select2-dropdown--below .select2-results {
  box-shadow: 0 4px 14px rgba(178, 189, 201, 0.4);
  border-bottom: 1px solid #b2bdc9;
}
.select2-container--open .select2-dropdown--above {
  top: calc(100% + 40px);
  display: flex;
  flex-direction: column-reverse;
}
.select2-container--open .select2-dropdown--above .select2-results {
  box-shadow: 0 -4px 14px rgba(178, 189, 201, 0.4);
  border-top: 1px solid #b2bdc9;
}
.select2-container--open .select2-search--dropdown {
  padding: 0;
}
.select2-container--open .select2-results {
  border-left: 1px solid #b2bdc9;
  border-right: 1px solid #b2bdc9;
}

.bootstrap-touchspin button {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 300;
  background: transparent;
  border: none;
  padding: 5px;
  width: 55px;
  height: 55px;
  vertical-align: bottom;
  line-height: 0;
  color: #00244c;
}
@media (min-width: 1024px) and (max-width: 1199.98px) {
  .bootstrap-touchspin button {
    width: 45px;
    height: 45px;
  }
}
.bootstrap-touchspin button.bootstrap-touchspin-down {
  font-size: 40px;
}
.bootstrap-touchspin button.bootstrap-touchspin-up {
  font-size: 35px;
}
.bootstrap-touchspin input[type=text],
.bootstrap-touchspin input[type=number] {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: calc(16px + 2px);
  padding: 5px;
  width: 55px;
  height: 55px;
  text-align: center;
}
@media (min-width: 1024px) and (max-width: 1199.98px) {
  .bootstrap-touchspin input[type=text],
.bootstrap-touchspin input[type=number] {
    width: 45px;
    height: 45px;
  }
}
.bootstrap-touchspin input[type=text]:disabled, .bootstrap-touchspin input[type=text]:read-only, .bootstrap-touchspin input[type=text][readonly],
.bootstrap-touchspin input[type=number]:disabled,
.bootstrap-touchspin input[type=number]:read-only,
.bootstrap-touchspin input[type=number][readonly] {
  background-color: #ffffff;
  color: #000000;
  cursor: not-allowed;
}

.radio-inline {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #00244c;
  vertical-align: middle;
  margin-right: 10px;
}

.custom-radio {
  position: relative;
  cursor: pointer;
  padding-left: 25px;
}
.custom-radio input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 2;
  width: 25px;
  height: 25px;
}
.custom-radio input:checked + span:before {
  opacity: 1;
}
.custom-radio span {
  position: absolute;
  top: 50%;
  left: 0;
  width: 20px;
  height: 20px;
  display: inline-block;
  transform: translate3d(0, -50%, 0);
  border: 1px solid #00244c;
  border-radius: 50%;
}
.custom-radio span:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  transform: translate3d(-50%, -50%, 0);
  background-color: #b88210;
  border-radius: 50%;
  opacity: 0;
  transition: opacity 0.2s linear;
}
.custom-radio span.color:before {
  display: none;
}

.custom-checkbox {
  position: relative;
  cursor: pointer;
  padding-left: 25px;
  display: block;
}
.custom-checkbox input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 2;
  width: 25px;
  height: 25px;
}
.custom-checkbox input:checked + span {
  border-color: #b88210;
}
.custom-checkbox input:checked + span:before {
  opacity: 1;
}
.custom-checkbox a {
  color: #00244c;
  transition: 0.3s all cubic-bezier(0.4, 0.5, 0.15, 1);
}
.custom-checkbox a:hover {
  text-decoration: none;
}
.custom-checkbox span {
  position: absolute;
  top: 5px;
  left: 0;
  width: 20px;
  height: 20px;
  display: inline-block;
  transform: translate3d(0, 0, 0);
  border: 1px solid #00244c;
  cursor: pointer;
}
@media (min-width: 480px) {
  .custom-checkbox span {
    top: 50%;
    transform: translate3d(0, -11px, 0);
  }
}
.custom-checkbox span:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.2s linear;
  background-color: #b88210;
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: svg-load("check.svg", fill=#ffffff);
}
.custom-checkbox span.color:before {
  display: none;
}

.custom-file {
  position: relative;
}
.custom-file.border-bottom label {
  border: none;
  padding: 10px calc(15px * 2) 10px 15px;
  border-bottom: 1px solid #b2bdc9;
  background: transparent;
}
.custom-file input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  cursor: pointer;
  outline: none;
  opacity: 0;
}
.custom-file label {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  position: relative;
  padding: 5px calc(15px * 2) 5px 15px;
  background: transparent;
  min-width: 90px;
  min-height: 40px;
  display: block;
  border: 1px solid #b2bdc9;
  color: #00244c;
  font-size: 14px;
  line-height: 25px;
  cursor: pointer;
}
.custom-file .btn {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  z-index: 3;
  display: block;
  height: 40px;
  padding: 5px 15px;
  line-height: 30px;
  color: #ffffff;
  font-size: 12px;
  background-color: #b88210;
  border: 1px solid #b88210;
}

.henaff-checkout .ui-autocomplete,
.henaff-address .ui-autocomplete {
  max-width: 100%;
}
.henaff-checkout .ui-autocomplete li,
.henaff-address .ui-autocomplete li {
  padding: 10px;
}
.henaff-checkout .ui-autocomplete li:not(:last-child),
.henaff-address .ui-autocomplete li:not(:last-child) {
  border-bottom: 1px solid rgba(178, 189, 201, 0.7);
}
.henaff-checkout .ui-autocomplete li:hover,
.henaff-address .ui-autocomplete li:hover {
  background-color: #00244c;
}
.henaff-checkout .ui-autocomplete li:hover a,
.henaff-address .ui-autocomplete li:hover a {
  color: #ffffff;
}
.henaff-checkout .ui-autocomplete a,
.henaff-checkout .ui-autocomplete .ui-state-active,
.henaff-address .ui-autocomplete a,
.henaff-address .ui-autocomplete .ui-state-active {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  display: block;
  text-decoration: none;
  color: #00244c;
  cursor: pointer;
}
.henaff-checkout .ui-autocomplete .ui-state-hover,
.henaff-checkout .ui-autocomplete .ui-widget-content .ui-state-hover,
.henaff-checkout .ui-autocomplete .ui-widget-header .ui-state-hover,
.henaff-checkout .ui-autocomplete .ui-state-focus,
.henaff-checkout .ui-autocomplete .ui-widget-content .ui-state-focus,
.henaff-checkout .ui-autocomplete .ui-widget-header .ui-state-focus,
.henaff-address .ui-autocomplete .ui-state-hover,
.henaff-address .ui-autocomplete .ui-widget-content .ui-state-hover,
.henaff-address .ui-autocomplete .ui-widget-header .ui-state-hover,
.henaff-address .ui-autocomplete .ui-state-focus,
.henaff-address .ui-autocomplete .ui-widget-content .ui-state-focus,
.henaff-address .ui-autocomplete .ui-widget-header .ui-state-focus {
  cursor: pointer;
  background: #00244c;
  color: #ffffff;
}

/**
 * ----------------------------------
 * Spinner loader
 * ----------------------------------
 */
.is--loading .spin-loader {
  opacity: 1;
  visibility: visible;
}

.spin-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 3;
  visibility: hidden;
  background-color: rgba(255, 255, 255, 0.85);
  transition: opacity 0.2s linear;
}

/**
 * ----------------------------------
 * Home slider
 * ----------------------------------
 */
.home-slider {
  overflow: hidden;
  position: relative;
  display: block;
  height: 330px;
}
@media (min-width: 1024px) {
  .home-slider {
    height: 600px;
  }
}
.home-slider .slider-wrapper {
  display: none;
}
.home-slider .slider-wrapper.slick-initialized {
  display: block;
}
.home-slider .slider-item {
  position: relative;
  height: 330px;
}
@media (min-width: 1024px) {
  .home-slider .slider-item {
    height: 600px;
  }
}
.home-slider .image {
  height: 100%;
  width: 100%;
}
.home-slider .caption {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translate3d(0, -50%, 0);
}
@media (min-width: 640px) {
  .home-slider .caption {
    margin-left: calc((calc(100vw - var(--scrollbar-width)) - 540px) / 2);
  }
}
@media (min-width: 768px) {
  .home-slider .caption {
    margin-left: calc((calc(100vw - var(--scrollbar-width)) - 720px) / 2);
  }
}
@media (min-width: 1024px) {
  .home-slider .caption {
    margin-left: calc((calc(100vw - var(--scrollbar-width)) - 960px) / 2);
  }
}
@media (min-width: 1200px) {
  .home-slider .caption {
    margin-left: calc((calc(100vw - var(--scrollbar-width)) - 1170px) / 2);
  }
}
@media (min-width: 1400px) {
  .home-slider .caption {
    max-width: 475px;
  }
}
.home-slider .caption a {
  font-size: 16px;
  color: #00244c;
  background-color: #ffffff;
  padding: calc(15px - 5px) calc(15px * 2);
}
.home-slider .caption a:hover {
  color: #ffffff;
  background-color: #b88210;
  border-color: #b88210;
}
.home-slider .caption-wrapper {
  padding: 15px;
  background-color: #00244c;
}
@media (min-width: 768px) {
  .home-slider .caption-wrapper {
    padding: calc(15px * 2);
  }
}
.home-slider .caption .title {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 15px;
}
@media (min-width: 1024px) {
  .home-slider .caption .title {
    font-size: 32px;
    margin-bottom: calc(15px + 5px);
  }
}
.home-slider .pagination {
  position: absolute;
  left: 50%;
  bottom: calc(15px * 2);
  transform: translate3d(-50%, 0, 0);
}
.home-slider .pagination li {
  vertical-align: middle;
  display: inline-block;
}
.home-slider .pagination li.slick-active button:before {
  background-color: #00244c;
}
.home-slider .pagination button {
  font-size: 0;
  line-height: 0;
  display: block;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  padding: 8px 5px;
  background-color: transparent;
}
.home-slider .pagination button:before {
  content: "";
  display: block;
  width: 50px;
  height: 8px;
  background-color: #ffffff;
  transition: background-color 0.3s;
}

/**
 * ----------------------------------
 * Social share stylesheet
 * ----------------------------------
 */
.social-sharing {
  text-align: right;
  position: relative;
}
.social-sharing .share-toggler {
  border: none;
  background: transparent;
  width: 40px;
  height: 40px;
  padding: 5px;
}
.social-sharing .share-toggler img {
  display: block;
  width: auto;
  height: 100%;
}
.social-sharing .share-icons {
  display: flex;
  align-items: center;
  position: absolute;
  top: 40px;
  right: 0;
  flex-direction: column;
  z-index: 1;
}
@media (min-width: 640px) {
  .social-sharing .share-icons {
    top: 0;
    right: 40px;
    flex-direction: row-reverse;
  }
}
.social-sharing .share-icons.toggle li {
  opacity: 1;
  visibility: visible;
  transform: translate3d(0, 0, 0);
}
.social-sharing .share-icons li {
  opacity: 0;
  visibility: hidden;
  margin: 10px 0 0;
  transform: translate3d(0, -10px, 0);
  transition: opacity 100ms ease-in-out, visibility 100ms ease-in-out, transform 100ms ease-in-out;
}
.social-sharing .share-icons li:nth-child(1) {
  transition-delay: 100ms;
}
.social-sharing .share-icons li:nth-child(2) {
  transition-delay: 200ms;
}
.social-sharing .share-icons li:nth-child(3) {
  transition-delay: 300ms;
}
.social-sharing .share-icons li:nth-child(4) {
  transition-delay: 400ms;
}
.social-sharing .share-icons li:nth-child(5) {
  transition-delay: 500ms;
}
@media (min-width: 640px) {
  .social-sharing .share-icons li {
    margin: 0 10px 0 0;
    transform: translate3d(10px, 0, 0);
  }
}
.social-sharing .share-icons .social-icon {
  display: block;
  width: 40px;
  height: 40px;
  transition: all 0.3s;
  border-radius: 20px;
  border: 1px solid #b2bdc9;
  background-color: #b2bdc9;
  background-size: 20px;
  background-position: center;
  background-repeat: no-repeat;
}
.social-sharing .share-icons .icon-facebook .social-icon {
  background-image: svg-load("facebook.svg", fill=#ffffff);
}
.social-sharing .share-icons .icon-facebook .social-icon:hover {
  border-color: #385c8e;
  background-color: #385c8e;
}
.social-sharing .share-icons .icon-twitter .social-icon {
  background-image: svg-load("twitter.svg", fill=#ffffff);
}
.social-sharing .share-icons .icon-twitter .social-icon:hover {
  border-color: #76a9ea;
  background-color: #76a9ea;
}
.social-sharing .share-icons .icon-googleplus .social-icon {
  background-image: svg-load("google-plus.svg", fill=#ffffff);
  background-size: 26px;
}
.social-sharing .share-icons .icon-googleplus .social-icon:hover {
  border-color: #f34a38;
  background-color: #f34a38;
}
.social-sharing .share-icons .icon-pinterest .social-icon {
  background-image: svg-load("pinterest.svg", fill=#ffffff);
}
.social-sharing .share-icons .icon-pinterest .social-icon:hover {
  border-color: #d7143a;
  background-color: #d7143a;
}
.social-sharing .share-icons .icon-mail .social-icon {
  background-image: svg-load("mail.svg", fill=#ffffff);
}
.social-sharing .share-icons .icon-mail .social-icon:hover {
  border-color: #b88210;
  background-color: #b88210;
}

/**
 * ----------------------------------
 * Product card stylesheet
 * ----------------------------------
 */
.product-card {
  height: 100%;
  min-height: 340px;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.25);
}
@media (min-width: 640px) {
  .product-card {
    min-height: 439px;
  }
}
.product-card .card-image {
  position: relative;
  background-color: #ffffff;
}
.product-card .card-image:hover .product-quickview, .product-card .card-image.hover .product-quickview {
  opacity: 1;
  visibility: visible;
}
.product-card .card-image:hover .product-quickview .picto,
.product-card .card-image:hover .product-quickview .product-description, .product-card .card-image.hover .product-quickview .picto,
.product-card .card-image.hover .product-quickview .product-description {
  opacity: 1;
  visibility: visible;
  transform: translate3d(0, 0, 0);
}
.product-card .card-image:hover .product-quickview .picto, .product-card .card-image.hover .product-quickview .picto {
  transition-delay: 0.3s;
}
.product-card .card-image:hover .product-quickview .product-description, .product-card .card-image.hover .product-quickview .product-description {
  transition-delay: 0.5s;
}
.product-card .card-image .thumbnail {
  display: block;
}
.product-card .card-image .thumbnail .image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.product-card .card-image .thumbnail .image img {
  width: 100%;
  min-width: auto;
  min-height: auto;
}
@media (min-width: 640px) {
  .product-card .card-image .thumbnail .image img {
    width: auto;
  }
}
.product-card .card-image .discount-product {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 600;
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 16px;
  padding: 10px 5px;
  color: #ffffff;
  background-color: #02a5e6;
}
.product-card .card-image .manufacturer {
  position: absolute;
  top: 10px;
  right: 15px;
  width: 70px;
}
.product-card .card-image .manufacturer a {
  color: #ffffff;
  text-decoration: none;
}
.product-card .card-image .manufacturer img {
  width: 100%;
  height: auto;
}
.product-card .card-image .flags li {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 600;
  position: absolute;
  z-index: 2;
  color: #ffffff;
}
.product-card .card-image .flags .on-sale {
  top: 0;
  left: 0;
}
.product-card .card-image .flags .on-sale:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  border-top: 90px solid #02a5e6;
  border-right: 95px solid transparent;
}
.product-card .card-image .flags .on-sale span {
  display: inline-block;
  text-transform: uppercase;
  transform: translate3d(10%, 130%, 0) rotate(-45deg);
}
.product-card .card-image .flags .new {
  display: none;
  left: 0;
  bottom: 0;
  padding: 12px 5px;
  background-color: #39b54a;
}
.product-card .card-body .product-manufacturer {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 600;
  background-color: #b88210;
  text-transform: uppercase;
  padding: 8px 15px;
  margin-bottom: 0;
}
.product-card .card-body .product-manufacturer a {
  color: #ffffff;
  text-decoration: none;
}
.product-card .card-body .product-title {
  font-size: 12px;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .product-card .card-body .product-title {
    font-size: 14px;
  }
}
.product-card .card-body .product-title > a {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 300;
  color: #ffffff;
  text-decoration: none;
}
.product-card .card-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 15px;
  display: flex;
  justify-content: space-between;
}
.product-card .card-footer .product-price-and-shipping {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 600;
}
.product-card .card-footer .product-price-and-shipping a {
  color: #ffffff;
  text-decoration: none;
}
.product-card .card-footer .product-price-and-shipping .regular-price {
  position: relative;
  font-size: 16px;
  color: #b88210;
  margin-left: 5px;
}
.product-card .card-footer .product-price-and-shipping .regular-price:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  background: #b88210;
  transform: rotate(-12deg);
}
.product-card .card-footer .product-price-and-shipping .price {
  font-size: 26px;
}
.product-card .card-footer .preview-add-to-cart .add-to-cart {
  background-color: transparent;
  border: none;
  transition: all 0.3s cubic-bezier(0.4, 0.5, 0.15, 1);
}
.product-card .card-footer .preview-add-to-cart .add-to-cart:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.product-card .card-footer .preview-add-to-cart .add-to-cart:disabled .icon {
  background-image: svg-load("panier.svg", fill=#b2bdc9);
}
.product-card .card-footer .preview-add-to-cart .add-to-cart:disabled .icon:hover {
  background-image: svg-load("panier.svg", fill=#b2bdc9);
}
.product-card .card-footer .preview-add-to-cart .add-to-cart .icon {
  width: 27px;
  height: 33px;
  display: block;
  margin: 0;
}
.product-card .card-footer .preview-add-to-cart .add-to-cart .icon:hover {
  background-image: svg-load("panier.svg", fill=#ffffff);
}

/**
 * Product simple
 */
.product-simple-card {
  position: relative;
  padding-bottom: 70px;
  background-color: #00244c;
}
.product-simple-card .card-image .thumbnail {
  position: relative;
}
.product-simple-card .card-image .thumbnail:before {
  content: "";
  display: block;
  padding-top: 92%;
}
.product-simple-card .card-image .product-quickview {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 400;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 12px;
  padding: 15px;
  background-color: rgba(0, 36, 76, 0.7);
  opacity: 0;
  z-index: 3;
  visibility: hidden;
  transition: opacity 0.5s linear, visibility 0.5s linear;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  text-decoration: none;
}
.product-simple-card .card-image .product-quickview .picto {
  width: 36px;
  margin-bottom: 15px;
}
.product-simple-card .card-image .product-quickview .picto,
.product-simple-card .card-image .product-quickview .product-description {
  opacity: 0;
  visibility: hidden;
  transform: translate3d(0, 10px, 0);
  transition: opacity 0.5s cubic-bezier(0.4, 0.5, 0.15, 1), visibility 0.5s cubic-bezier(0.4, 0.5, 0.15, 1), transform 0.5s cubic-bezier(0.4, 0.5, 0.15, 1);
}
.product-simple-card .card-body .product-title {
  padding: 0 15px;
  margin-top: 20px;
}

/**
 * Product highlight
 */
.product-highlight-card {
  display: flex;
  height: 100%;
}
.product-highlight-card .card-image {
  flex-grow: 0;
  flex-shrink: 0;
  width: 50%;
}
.product-highlight-card .card-body {
  display: flex;
  align-items: center;
  position: relative;
  padding-top: 52px;
  padding-bottom: 70px;
  background-color: #00244c;
  flex-grow: 0;
  flex-shrink: 0;
  width: 50%;
}
.product-highlight-card .card-body .product-manufacturer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.product-highlight-card .card-body .center-block {
  padding: 0 15px;
}
.product-highlight-card .card-body .flags {
  margin-bottom: 15px;
}
.product-highlight-card .card-body .flags > li {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  display: inline-block;
  font-size: 12px;
  padding: 15px;
  margin-right: calc(15px - 5px);
  background-color: #b88210;
  color: #ffffff;
  text-transform: uppercase;
  border-top-left-radius: 23px;
  border-bottom-right-radius: 23px;
}
.product-highlight-card .card-body .product-description {
  font-family: roboto, arial, helvetica, sans-serif;
  font-weight: 400;
  font-size: 12px;
}
@media (min-width: 768px) {
  .product-highlight-card .card-body .product-description {
    font-size: 14px;
  }
}
.product-highlight-card .card-body .product-description a {
  color: #ffffff;
  text-decoration: none;
}

/**
 * ----------------------------------
 * Modal stylesheet
 * ----------------------------------
 */
@media (min-width: 640px) {
  .modal {
    padding: 0 17px !important;
  }
}
.modal-header .title {
  font-family: roboto, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #00244c;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}
.modal-header .icon {
  margin-right: 10px;
}
.modal-body {
  font-family: roboto, arial, helvetica, sans-serif;
  font-weight: 400;
}
.modal .close {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 600;
  font-size: 20px;
  color: #00244c;
  background: transparent;
  border: 0;
  padding: 5px;
  width: 62px;
  height: 62px;
  cursor: pointer;
}

#product-modal .modal-dialog {
  max-width: 700px;
}
#product-modal .modal-body .product-cover {
  position: relative;
}
#product-modal .modal-body .product-cover:before {
  content: "";
  display: block;
  padding-top: 100%;
}
#product-modal .modal-body .product-cover .image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
#product-modal .modal-body .product-cover .image img {
  width: 100%;
  min-height: auto;
}
#product-modal .modal-body .product-cover figcaption {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 400;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 15px;
  background-color: rgba(0, 36, 76, 0.7);
  color: #ffffff;
}
#product-modal .modal-body .product-cover p {
  margin: 0;
}
#product-modal .modal-body .product-thumbnails {
  position: relative;
  margin-top: 15px;
}
#product-modal .modal-body .product-thumbnails ul {
  padding: 0 calc(15px * 4);
  position: relative;
}
#product-modal .modal-body .product-thumbnails .thumb-item {
  padding: 0 15px;
}
#product-modal .modal-body .product-thumbnails .thumb-item .image {
  width: 100px;
  height: 100px;
  margin: auto;
}
#product-modal .modal-body .product-thumbnails .thumb-item .thumb {
  width: 100%;
}
#product-modal .modal-body .product-thumbnails .slick-arrow {
  background-image: svg-load("chevron.svg", fill=#00244c);
  position: absolute;
  top: 50%;
  width: 20px;
  height: 20px;
  border: none;
  font-size: 0;
  outline: none;
}
#product-modal .modal-body .product-thumbnails .slick-arrow.slick-prev {
  left: calc(15px * 3);
  transform: translateY(-15px);
}
#product-modal .modal-body .product-thumbnails .slick-arrow.slick-next {
  right: calc(15px * 3);
  transform: translateY(-15px) rotate(180deg);
}

#blockcart-modal .modal-content {
  position: relative;
}
#blockcart-modal .modal-content .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}
@media (min-width: 640px) {
  #blockcart-modal .modal-dialog {
    max-width: 1242px;
  }
}
#blockcart-modal .modal-body {
  padding: 0;
}
#blockcart-modal .modal-body .header {
  border-bottom: 1px solid #b88210;
  background-color: #f8fbff;
}
#blockcart-modal .modal-body .header .title {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  color: #00244c;
  margin: 0;
}
#blockcart-modal .modal-body .header.product-cart-header {
  padding: 20px;
}
@media (min-width: 768px) {
  #blockcart-modal .modal-body .header.product-cart-header {
    padding: 20px 62px 20px 30px;
  }
}
#blockcart-modal .modal-body .header.product-info-header {
  padding: 20px 62px 20px 20px;
}
@media (min-width: 768px) {
  #blockcart-modal .modal-body .header.product-info-header {
    padding: 20px 0 20px 85px;
  }
}
#blockcart-modal .modal-body .header.product-info-header .title {
  background-image: svg-load("check-mark.svg", fill=#00244c);
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: left 50%;
  background-size: 20px;
  padding-left: 25px;
}
#blockcart-modal .modal-body .content {
  padding: calc(15px + 5px);
}
@media (min-width: 768px) {
  #blockcart-modal .modal-body .content {
    padding: calc(15px * 4) calc(15px * 2);
  }
}
#blockcart-modal .modal-body .content .image {
  width: 300px;
  height: 300px;
  margin: auto;
}
#blockcart-modal .modal-body .content .image img {
  width: 100%;
  max-height: auto;
}
#blockcart-modal .modal-body .content .manufacturer-name {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 600;
  color: #b88210;
  text-transform: uppercase;
}
#blockcart-modal .modal-body .content .product-name {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 300;
  color: #00244c;
  text-transform: uppercase;
  font-size: 16px;
}
#blockcart-modal .modal-body .content .variants {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 400;
  margin-bottom: 5px;
  font-size: 16px;
}
#blockcart-modal .modal-body .content .variants strong {
  color: #00244c;
}
#blockcart-modal .modal-body .content .product-availability {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  margin-bottom: 15px;
}
#blockcart-modal .modal-body .content .product-availability .available {
  color: #39b54a;
}
#blockcart-modal .modal-body .content .product-availability .unavailable {
  color: #ff1100;
}
#blockcart-modal .modal-body .content .product-availability .last-remaining {
  color: #ff9800;
}
#blockcart-modal .modal-body .content .product-actions {
  margin-bottom: 15px;
}
#blockcart-modal .modal-body .content .product-actions .form-product-quantity {
  padding: 15px 0;
}
#blockcart-modal .modal-body .content .product-actions .form-product-quantity .label {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 300;
  font-size: 18px;
  display: inline-block;
  margin-right: 15px;
  color: #00244c;
}
#blockcart-modal .modal-body .content .product-actions .form-product-quantity .product-quantity {
  display: inline-block;
}
#blockcart-modal .modal-body .content .product-price {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 26px;
  color: #00244c;
}
#blockcart-modal .modal-body .cart-table .d-flex {
  padding: 8px 0;
  align-items: center;
  justify-content: space-between;
}
#blockcart-modal .modal-body .cart-table .label {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  color: #00244c;
  font-size: 16px;
}
#blockcart-modal .modal-body .cart-table .value {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 400;
  text-align: right;
  font-size: 16px;
}
#blockcart-modal .modal-body .cart-table .detail {
  display: block;
  font-size: 12px;
}
#blockcart-modal .modal-body .cart-table .cart-products-count {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  color: #00244c;
  border-bottom: 1px solid #b88210;
}
#blockcart-modal .modal-body .cart-table .cart-products-count .value {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
}
#blockcart-modal .modal-body .cart-table .cart-product-total .value {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  color: #00244c;
}
#blockcart-modal .modal-body .cart-table .cart-product-total .detail {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 300;
}
#blockcart-modal .modal-body .cart-btn {
  padding-top: 15px;
  border-top: 1px solid #b88210;
}
#blockcart-modal .modal-body .cart-btn .btn {
  font-size: 14px;
  line-height: 21px;
  width: 100%;
  margin-bottom: 15px;
}
#blockcart-modal .modal-body .cart-btn .btn-primary {
  color: #00244c;
  background-color: transparent;
  padding: 15px;
  border: 2px solid #00244c;
  font-size: 12px;
  line-height: normal;
}
#blockcart-modal .modal-body .cart-btn .btn-primary:hover {
  color: #b88210;
  background-color: transparent;
  border-color: #b88210;
}
#blockcart-modal .modal-body .cart-btn .btn-secondary {
  color: #ffffff;
  background-color: #00244c;
  padding: 15px;
  border: 2px solid #00244c;
  font-size: 12px;
  line-height: normal;
}
#blockcart-modal .modal-body .cart-btn .btn-secondary:disabled {
  background-color: #b2bdc9;
  border-color: #b2bdc9;
  cursor: not-allowed;
}
#blockcart-modal .modal-body .cart-btn .btn-secondary:hover {
  color: #ffffff;
  background-color: #b88210;
  border-color: #b88210;
}

#alcohol-modal .modal-body {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  color: #00244c;
  text-align: center;
  padding-bottom: 0;
}
#alcohol-modal .modal-footer {
  border: none;
  justify-content: center;
}
#alcohol-modal .modal-footer .btn {
  color: #ffffff;
  background-color: #00244c;
  padding: 15px;
  border: 2px solid #00244c;
  font-size: 12px;
  line-height: normal;
  padding: 15px calc(15px * 2);
  text-transform: uppercase;
}
#alcohol-modal .modal-footer .btn:disabled {
  background-color: #b2bdc9;
  border-color: #b2bdc9;
  cursor: not-allowed;
}
#alcohol-modal .modal-footer .btn:hover {
  color: #ffffff;
  background-color: #b88210;
  border-color: #b88210;
}
#alcohol-modal .notice {
  color: #b88210;
}
#alcohol-modal .form {
  display: inline-block;
  padding-top: 15px;
  margin-bottom: 0;
}
#alcohol-modal .form .custom-checkbox {
  text-align: left;
}

/**
 * ----------------------------------
 * Breadcrumb
 * ----------------------------------
 */
.breadcrumb {
  margin: 15px 0;
  display: none;
}
@media (min-width: 768px) {
  .breadcrumb {
    display: block;
  }
}
.breadcrumb ol {
  display: flex;
  align-items: center;
  list-style: none;
}
.breadcrumb li:not(:last-child) a {
  color: #00244c;
  padding-right: 15px;
}
.breadcrumb li:not(:last-child) a > span:after {
  background-image: svg-load("chevron.svg", fill=#00244c);
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  content: "";
  position: absolute;
  top: 4px;
  right: 4px;
  width: 8px;
  height: 8px;
  transform: rotate(180deg);
}
.breadcrumb a {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 400;
  position: relative;
  font-size: 12px;
  text-decoration: none;
  text-transform: uppercase;
  color: #b88210;
}

/**
 * ----------------------------------
 * Block image text
 * ----------------------------------
 */
.block-image-text {
  padding: calc(15px * 3) 0;
}
.block-image-text .text-content {
  padding: calc(15px * 3);
  background-color: #00244c;
}
.block-image-text .subtitle {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  font-size: 22px;
  color: #b88210;
  text-transform: uppercase;
}
.block-image-text .title {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  font-size: 24px;
  color: #ffffff;
}
.block-image-text .description {
  color: #ffffff;
  font-size: 14px;
}
.block-image-text .cta {
  font-size: 16px;
  color: #00244c;
  background-color: #ffffff;
  padding: calc(15px - 5px) calc(15px * 2);
}
.block-image-text .cta:hover {
  color: #ffffff;
  background-color: #b88210;
  border-color: #b88210;
}
.block-image-text .media--wrapper:after {
  content: "";
  display: block;
  padding-bottom: 70%;
}
@media (min-width: 768px) {
  .block-image-text .media--wrapper:after {
    padding-bottom: 40%;
  }
}
.block-image-text .media--wrapper .image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/**
 * ----------------------------------
 * product comment stylesheet
 * ----------------------------------
 */
#product-comments-list-header {
  padding: 15px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

#post-product-comment-modal .star-content div.star a {
  display: block;
  position: absolute;
  text-indent: -5000px;
}
#post-product-comment-modal input.error,
#post-product-comment-modal textarea.error {
  border-color: #ff1100;
}

#product-comments-list-pagination {
  text-align: right;
  margin-top: 15px;
}
#product-comments-list-pagination ul > li {
  line-height: 0;
  display: inline-block;
  vertical-align: middle;
}
#product-comments-list-pagination ul > li.disabled {
  opacity: 0.3;
  cursor: not-allowed;
}
#product-comments-list-pagination ul > li.active span {
  font-weight: bold;
  color: #00244c;
  cursor: not-allowed;
}
#product-comments-list-pagination span {
  font-family: roboto, arial, helvetica, sans-serif;
  font-weight: 500;
  color: #b2bdc9;
  font-size: 16px;
  margin: 0 5px;
  display: block;
}

#empty-product-comment span {
  font-family: roboto, arial, helvetica, sans-serif;
  font-weight: 500;
  color: #00244c;
}

.product-comment {
  margin-bottom: calc(15px * 2);
}
.product-comment-header {
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #b88210;
}
.product-comment-header button {
  font-family: roboto, arial, helvetica, sans-serif;
  font-weight: 700;
  color: #00244c;
  text-decoration: none;
  position: relative;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
}
.product-comment-header button:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #b88210;
  transform-origin: center;
  transition: transform 0.3s cubic-bezier(0.4, 0.5, 0.15, 1);
}
.product-comment-header button:after {
  transform: scaleX(1);
}
.product-comment-header button:hover:after {
  transform: scaleX(0);
}
.product-comment-header .title {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 18px;
  text-transform: uppercase;
  text-decoration: none;
  color: #00244c;
  margin-bottom: 0;
}
@media (min-width: 1024px) {
  .product-comment-header .title {
    font-size: 24px;
  }
}
.product-comment-body {
  font-family: roboto, arial, helvetica, sans-serif;
  font-weight: 400;
}
.product-comment-body .comments-nb,
.product-comment-body .comments-note {
  font-family: roboto, arial, helvetica, sans-serif;
  font-weight: 500;
}
.product-comment-body .comments-nb span,
.product-comment-body .comments-note span {
  vertical-align: top;
}
.product-comment-list-item {
  padding: 15px 0;
  border-bottom: 1px solid rgba(184, 130, 16, 0.2);
}
.product-comment-list-item .comment-infos {
  font-size: 16px;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .product-comment-list-item .comment-infos {
    margin-bottom: 0;
  }
}
.product-comment-list-item .comment-author {
  color: #00244c;
}
.product-comment-list-item .comment-date {
  color: #b88210;
}
.product-comment-list-item .comment-content h4 {
  margin-bottom: 5px;
}
.product-comment-list-item .comment-content p {
  margin-bottom: 5px;
}
.product-comment-list-item .comment-buttons a {
  display: inline-block;
  margin-right: 5px;
}
.product-comment-list-item .comment-buttons .useful-review-value,
.product-comment-list-item .comment-buttons .not-useful-review-value {
  vertical-align: bottom;
  color: #b88210;
}
.product-comment-modal {
  margin-top: 15px;
}
.product-comment-modal .btn-comment {
  color: #ffffff;
  background-color: #00244c;
  padding: 15px;
  border: 2px solid #00244c;
  font-size: 12px;
  line-height: normal;
  padding: 15px calc(15px * 2);
  text-transform: uppercase;
}
.product-comment-modal .btn-comment:disabled {
  background-color: #b2bdc9;
  border-color: #b2bdc9;
  cursor: not-allowed;
}
.product-comment-modal .btn-comment:hover {
  color: #ffffff;
  background-color: #b88210;
  border-color: #b88210;
}
.product-comment-modal .btn-comment-big {
  width: 100%;
  text-transform: none;
}
.product-comment-modal .btn-comment-inverse {
  color: #ffffff;
  background-color: #00244c;
  padding: 15px;
  border: 2px solid #00244c;
  font-size: 12px;
  line-height: normal;
  padding: 15px calc(15px * 2);
  text-transform: uppercase;
  background-color: #b2bdc9;
}
.product-comment-modal .btn-comment-inverse:disabled {
  background-color: #b2bdc9;
  border-color: #b2bdc9;
  cursor: not-allowed;
}
.product-comment-modal .btn-comment-inverse:hover {
  color: #ffffff;
  background-color: #b88210;
  border-color: #b88210;
}
.product-comment-modal .card {
  margin: auto;
  max-width: 500px;
}
.product-comment-modal .card-header h3 {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #00244c;
  text-transform: uppercase;
}
@media (min-width: 1024px) {
  .product-comment-modal .card-header h3 {
    font-size: 18px;
  }
}
.product-comment-modal .card-body {
  font-family: roboto, arial, helvetica, sans-serif;
  font-weight: 400;
  padding: 15px 0;
}
.product-comment-modal .card-body .form-label .required {
  font-weight: 700;
  color: #b88210;
  vertical-align: baseline;
}
.product-comment-modal .card-body .form-group {
  margin-bottom: 15px;
}
.product-comment-modal .card-body input[type=text],
.product-comment-modal .card-body input[type=email],
.product-comment-modal .card-body textarea {
  width: 100%;
}

.star-content {
  position: absolute;
  top: 3px;
  left: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.star-content .star,
.star-content .star-on,
.star-content .star-hover {
  display: block;
  width: 20px;
  height: 20px;
  background: url("images/stars.png") no-repeat 0 0 transparent;
  flex: auto;
  margin-left: 3px;
}
.star-content .star-on,
.star-content .star-hover {
  background-position: -24px 0;
}
.star-content .star-hover {
  cursor: pointer;
}

.small-stars .star-content .star,
.small-stars .star-content .star-on,
.small-stars .star-content .star-hover {
  background: url("images/small_stars.png") no-repeat 0 0 transparent;
  width: 16px;
  height: 16px;
  margin-left: 2px;
}
.small-stars .star-content .star-on,
.small-stars .star-content .star-hover {
  background-position: -19px 0;
}

.grade-stars {
  position: relative;
  height: 20px;
  min-width: 120px;
  display: inline-block;
}
.grade-stars .small-stars {
  min-width: 100px;
}

.criterion-rating .grade-stars .star-content {
  top: 0;
}

/**
 * ----------------------------------
 * Product pack stylesheet
 * ----------------------------------
 */
.product-pack {
  padding: calc(15px * 2) 0;
}
@media (min-width: 768px) {
  .product-pack {
    padding: calc(15px * 3) 0;
  }
}
.product-pack .title {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 24px;
  color: #00244c;
  text-transform: uppercase;
}
@media (min-width: 1024px) {
  .product-pack .title {
    font-size: 28px;
  }
}
.product-pack .ribbon-wrapper {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 600;
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 16px;
  padding: 10px 5px;
  color: #ffffff;
  background-color: #02a5e6;
}
.product-pack .ribbon-wrapper .ribbon {
  background: none;
}
.product-pack-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 15px 0;
}
.product-pack-info .product-name {
  flex-grow: 0;
  flex-shrink: 1;
  display: flex;
  flex-wrap: wrap;
}
.product-pack-info .product-name a {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  text-decoration: none;
  color: #00244c;
  margin-bottom: 15px;
}
@media (min-width: 1024px) {
  .product-pack-info .product-name a {
    font-size: 18px;
  }
}
@media (min-width: 1024px) {
  .product-pack-info .product-name a {
    margin-right: 10px;
    margin-bottom: 0;
  }
}
.product-pack-info .product-name .see-pack {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 600;
  color: #ffffff;
  background-color: #00244c;
  padding: 15px;
  border: 2px solid #00244c;
  font-size: 12px;
  line-height: normal;
  font-size: 14px;
}
.product-pack-info .product-name .see-pack:disabled {
  background-color: #b2bdc9;
  border-color: #b2bdc9;
  cursor: not-allowed;
}
.product-pack-info .product-name .see-pack:hover {
  color: #ffffff;
  background-color: #b88210;
  border-color: #b88210;
}
.product-pack-info .add-to-cart {
  color: #00244c;
  background-color: transparent;
  padding: 15px;
  border: 2px solid #00244c;
  font-size: 12px;
  line-height: normal;
  text-transform: uppercase;
  font-size: 14px;
  height: 55px;
}
.product-pack-info .add-to-cart:hover {
  color: #b88210;
  background-color: transparent;
  border-color: #b88210;
}
.product-pack-info .content_prices {
  flex-grow: 0;
  flex-shrink: 1;
}
.product-pack-info .content_prices .price {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 24px;
  color: #00244c;
}
@media (min-width: 1024px) {
  .product-pack-info .content_prices .price {
    font-size: 28px;
  }
}
.product-pack-info .content_prices .regular-price {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 16px;
  position: relative;
  color: #b88210;
}
@media (min-width: 1024px) {
  .product-pack-info .content_prices .regular-price {
    font-size: 18px;
  }
}
.product-pack-info .content_prices .regular-price:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  background: #b88210;
  transform: rotate(-12deg);
}
.product-pack-info .content_prices .discount-amount {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  color: #b88210;
  font-size: 22px;
}
.product-pack-info .content_prices .product-without-taxes {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 16px;
  position: relative;
  color: #b88210;
  margin-bottom: 0;
}
@media (min-width: 1024px) {
  .product-pack-info .content_prices .product-without-taxes {
    font-size: 18px;
  }
}

.block-reassurance {
  margin-top: calc(15px * 3);
}
.block-reassurance .title {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  color: #00244c;
  display: none;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .block-reassurance .title {
    padding: 0 15px;
  }
}
.block-reassurance .assurance-picto {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}
.block-reassurance .assurance-picto .assurance-item {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 600;
  flex-grow: 1;
  flex-shrink: 0;
  width: 50%;
  font-size: 11px;
  text-transform: uppercase;
  text-align: center;
  padding: 15px;
}
@media (min-width: 768px) {
  .block-reassurance .assurance-picto .assurance-item {
    width: 25%;
    font-size: 14px;
    padding: 0 15px;
  }
}
.block-reassurance .assurance-picto .assurance-item a {
  text-decoration: none;
  color: #00244c;
}
.block-reassurance .assurance-picto .image {
  height: 40px;
  width: auto;
  margin: 0 auto 15px;
}
@media (min-width: 640px) {
  .block-reassurance .assurance-picto .image {
    height: 50px;
  }
}
.block-reassurance .assurance-picto .image img {
  display: inline-block;
  width: auto;
  height: 100%;
  min-width: auto;
}

.checkout-right-column .block-reassurance {
  margin-top: 0;
  margin-bottom: 15px;
}
.checkout-right-column .block-reassurance .container {
  padding: 0;
}
.checkout-right-column .block-reassurance .title {
  display: block;
}
.checkout-right-column .block-reassurance .assurance-picto .assurance-item {
  font-size: 8px;
  width: 25%;
}
@media (min-width: 768px) {
  .checkout-right-column .block-reassurance .assurance-picto .assurance-item {
    padding: 0 10px;
  }
}
.checkout-right-column .block-reassurance .assurance-picto .assurance-item .image {
  height: 30px;
}

.henaff-common .checkout-right-column .block-reassurance {
  display: none;
}
@media (min-width: 1024px) {
  .henaff-common .checkout-right-column .block-reassurance {
    display: block;
  }
}

/**
 * ----------------------------------
 * Loyaty stylesheet
 * ----------------------------------
 */
.loyalty-detail {
  padding: 15px;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.25);
}
.loyalty-detail .title {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #00244c;
}
@media (min-width: 1024px) {
  .loyalty-detail .title {
    font-size: 18px;
  }
}
.loyalty-detail .logo {
  position: relative;
  width: 100%;
  max-width: 280px;
  margin: auto;
}
.loyalty-detail .logo:before {
  content: "";
  display: block;
  padding-top: 100%;
}
.loyalty-detail .logo .image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.loyalty-detail .logo .image img {
  width: 100%;
  min-height: auto;
}
@media (min-width: 768px) {
  .loyalty-detail .logo {
    width: 25%;
  }
}
.loyalty-detail .text {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 400;
  padding-left: 15px;
  width: 100%;
}
@media (min-width: 768px) {
  .loyalty-detail .text {
    width: 75%;
  }
}
.loyalty-detail .text strong {
  color: #b88210;
}
.loyalty-detail .d-flex {
  flex-wrap: wrap;
}

#main-footer {
  background: #00244c;
  color: #ffffff;
  margin: 30px 0 0;
}
#main-footer p {
  font-family: roboto, arial, helvetica, sans-serif;
  line-height: 1.8;
  font-size: 14px;
}
#main-footer hr {
  width: 100%;
}
#main-footer a {
  color: #ffffff;
  text-decoration: none;
}
#main-footer a:hover {
  text-decoration: underline;
}
@media (min-width: 1024px) {
  #main-footer .footer__inner {
    display: flex;
  }
  #main-footer .footer__right {
    flex: 1;
    padding: 73px 0 0 30px;
  }
  #main-footer .footer__bottom {
    display: flex;
    flex-wrap: wrap;
  }
  #main-footer .footer__bottom .footer__stores {
    flex: 1;
    padding-left: 28px;
  }
}
#main-footer .footer__left {
  padding: 45px 0;
}
@media (min-width: 1024px) {
  #main-footer .footer__left {
    width: 262px;
  }
}
#main-footer .footer__left .footer__logo {
  text-align: center;
}
#main-footer .footer__left .footer__logo img {
  max-width: 100%;
  padding: 0 0 25px;
}
#main-footer .footer__left .footer__text {
  padding-bottom: 12px;
}
#main-footer .footer__left .footer__social ul {
  display: flex;
  margin: calc(15px + 5px) 0;
  justify-content: left;
}
#main-footer .footer__left .footer__social ul li {
  margin-right: 15px;
}
#main-footer .footer__left .footer__social .social-icon {
  display: block;
  width: 60px;
  height: 60px;
  transition: all 0.3s;
  border-radius: 30px;
  border: 1px solid #ffffff;
  background-color: #ffffff;
  background-size: 30px;
  background-position: center;
  background-repeat: no-repeat;
}
#main-footer .footer__left .footer__social .facebook {
  background-image: svg-load("facebook.svg", fill=#00244c);
}
#main-footer .footer__left .footer__social .instagram {
  background-image: svg-load("instagram.svg", fill=#00244c);
}
#main-footer .footer__left .footer__brand {
  width: 100%;
  text-align: left;
}
#main-footer .footer__left .footer__brand p {
  margin-bottom: 9px;
}
#main-footer .footer__left .footer__brand img {
  max-width: 100%;
}
#main-footer .footer__left .footer__brands {
  display: flex;
  flex-wrap: wrap;
  width: 264px;
  height: 89px;
  align-items: center;
  justify-content: space-between;
  margin: calc(15px + 5px) 0;
}
#main-footer .footer__left .footer__brands .brand {
  flex-shrink: 0;
  flex-grow: 0;
  width: 50%;
}
#main-footer .footer__left .footer__brands .brand-1 img {
  width: 100px;
}
#main-footer .footer__left .footer__brands .brand-2 img {
  width: 152px;
}
@media (min-width: 640px) {
  #main-footer .footer__left .footer__brands .brand-2 img {
    display: none;
  }
}
#main-footer .footer__left .footer__brands .brand-3 {
  display: none;
}
#main-footer .footer__left .footer__brands img {
  min-height: auto;
  min-width: auto;
  left: 0;
  transform: rotate(0.001deg) translate3d(0, -50%, 0);
}
#main-footer .footer__bottom p, #main-footer .footer__copyright p {
  margin: 0;
}
#main-footer .footer__bottom {
  padding: calc(15px * 2) 0;
  margin-top: calc(15px * 2);
  border-top: 1px solid #b88210;
}
#main-footer .footer__bottom .stores__title {
  font-family: montserrat, arial, helvetica, sans-serif;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  color: #b88210;
  text-transform: uppercase;
  margin-bottom: 15px;
}
#main-footer .footer__bottom .stores {
  text-align: left;
  display: flex;
  flex-wrap: wrap;
}
#main-footer .footer__bottom .stores .store {
  width: 100%;
  padding: 0 12px 12px 0;
}
@media (min-width: 1024px) {
  #main-footer .footer__bottom .stores .store {
    width: 25%;
  }
}
#main-footer .footer__bottom .stores address.store__city {
  text-transform: uppercase;
}
#main-footer .footer__bottom .stores p, #main-footer .footer__bottom .stores address {
  margin-bottom: 5px;
  font-style: normal;
}
#main-footer .footer__copyright {
  text-align: center;
  background: #00162e;
  padding: 20px 15px;
  flex-wrap: wrap;
  font-size: 12px;
}
@media (min-width: 640px) {
  #main-footer .footer__copyright {
    font-size: 14px;
  }
}
#main-footer .footer__copyright ul {
  display: inline;
}
#main-footer .footer__copyright ul li {
  display: inline;
  padding: 0 2px;
}
#main-footer .footer__copyright ul li:before {
  content: "|";
  display: inline-block;
}
#main-footer .footer__copyright .spacer {
  opacity: 0;
  visibility: hidden;
  display: block;
  line-height: 0;
}
@media (min-width: 640px) {
  #main-footer .footer__copyright .spacer {
    opacity: 1;
    visibility: visible;
    display: inline;
    line-height: 1.5;
  }
}
#main-footer .text-footer {
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;
}
#main-footer .text-footer p {
  padding-top: 15px;
  font-size: 12px;
}
#main-footer .footer-links {
  display: flex;
  flex-direction: column;
  text-align: left;
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
}
@media (min-width: 640px) {
  #main-footer .footer-links {
    max-height: 350px;
    flex-wrap: wrap;
  }
}
@media (min-width: 1024px) {
  #main-footer .footer-links {
    width: 50%;
  }
}
#main-footer .footer-links ul {
  width: 100%;
  padding: 0 15px;
  font-family: montserrat, arial, helvetica, sans-serif;
}
@media (min-width: 330px) {
  #main-footer .footer-links ul {
    width: 50%;
  }
}
#main-footer .footer-links .h3, #main-footer .footer-links h3 {
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 14px;
}
#main-footer .footer-links a.upcase,
#main-footer .footer-links li.upcase {
  padding: 0;
  text-transform: uppercase;
  letter-spacing: 0;
}
#main-footer .footer-links a.upcase:before,
#main-footer .footer-links li.upcase:before {
  display: none;
}
#main-footer .footer-links li {
  margin-bottom: 11px;
}
#main-footer .footer-links li a {
  position: relative;
  display: block;
  padding-left: 12px;
}
#main-footer .footer-links li a:before {
  content: "";
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  left: 0;
  top: 10.5px;
  transform: translateY(-50%);
  background-image: url("/themes/henaff-vad/assets/svg/next.svg");
  background-size: cover;
}
#main-footer .footer-newsletter {
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
}
@media (min-width: 1024px) {
  #main-footer .footer-newsletter {
    width: 50%;
  }
}
#main-footer .footer-newsletter .email_subscription label {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  font-size: 16px;
  display: block;
  color: #b88210;
  margin-bottom: 10px;
}
#main-footer .footer-newsletter .email_subscription .form-group {
  position: relative;
}
#main-footer .footer-newsletter .email_subscription .email {
  width: 100%;
  margin-bottom: 10px;
  height: 50px;
}
@media (min-width: 768px) {
  #main-footer .footer-newsletter .email_subscription .email {
    margin-bottom: calc(15px + 5px);
    padding-right: 150px;
  }
}
#main-footer .footer-newsletter .email_subscription .btn {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  color: #ffffff;
  background-color: #b88210;
  padding: 15px;
  border: 2px solid #b88210;
  font-size: 12px;
  line-height: normal;
  width: 100%;
  text-transform: uppercase;
  margin-bottom: 10px;
}
#main-footer .footer-newsletter .email_subscription .btn:disabled {
  background-color: #b2bdc9;
  border-color: #b2bdc9;
  cursor: not-allowed;
}
#main-footer .footer-newsletter .email_subscription .btn:hover {
  color: #ffffff;
  background-color: #b88210;
  border-color: #b88210;
}
@media (min-width: 1024px) {
  #main-footer .footer-newsletter .email_subscription .btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 150px;
  }
}
#main-footer .footer-newsletter .email_subscription .text {
  font-family: roboto, arial, helvetica, sans-serif;
  font-weight: 400;
  font-size: 12px;
}
#main-footer .footer-product-alcohol {
  padding: calc(15px * 2) 0;
  background-color: #00162e;
}
#main-footer .footer-product-alcohol .d-flex {
  flex-wrap: wrap;
  border: 1px solid #b88210;
  padding: 0;
}
@media (min-width: 768px) {
  #main-footer .footer-product-alcohol .d-flex {
    padding: 15px;
    width: 85%;
    margin: auto;
  }
}
#main-footer .footer-product-alcohol .logo {
  flex-grow: 0;
  flex-shrink: 1;
  width: 100%;
  margin-bottom: 15px;
  padding: 0 calc(15px + 5px);
}
@media (min-width: 768px) {
  #main-footer .footer-product-alcohol .logo {
    width: auto;
    margin-bottom: 0;
  }
}
#main-footer .footer-product-alcohol .logo .picto {
  width: 200px;
  padding: 15px;
  margin: auto;
  background-color: #ffffff;
}
@media (min-width: 768px) {
  #main-footer .footer-product-alcohol .logo .picto {
    width: 130px;
  }
}
#main-footer .footer-product-alcohol .text {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  flex-grow: 0;
  flex-shrink: 1;
  width: 100%;
  padding: 0 calc(15px + 5px);
  text-align: center;
}
@media (min-width: 768px) {
  #main-footer .footer-product-alcohol .text {
    width: auto;
    text-align: left;
  }
}
#main-footer .footer-product-alcohol .text-uppercase {
  font-size: 14px;
  margin-bottom: 5px;
}
#main-footer .footer-product-alcohol .text-small {
  font-size: 12px;
  margin-bottom: 0;
}
#main-footer .footer-nav-logo {
  display: flex;
  justify-content: center;
  padding: 40px 0;
}
#main-footer .footer-nav-logo .brand {
  width: 165px;
  height: auto;
}
@media (min-width: 768px) {
  #main-footer .footer-nav-logo .brand {
    width: 200px;
  }
}
@media (min-width: 1024px) {
  #main-footer .footer-nav-logo .brand {
    width: 220px;
  }
}
#main-footer .footer-nav-logo .brand .logo {
  display: block;
  width: 100%;
  height: auto;
}

/**
 * ----------------------------------
 * Block image text
 * ----------------------------------
 */
.home-about-us,
.home-focus,
.home-categories,
.home-boutique,
.home-social-wall {
  padding: 15px 0;
}
@media (min-width: 768px) {
  .home-about-us,
.home-focus,
.home-categories,
.home-boutique,
.home-social-wall {
    padding: calc(15px * 3) 0;
  }
}
.home-about-us .description,
.home-focus .description,
.home-categories .description,
.home-boutique .description,
.home-social-wall .description {
  margin-bottom: 15px;
}
.home-about-us .description.editor-content h1,
.home-about-us .description.editor-content h2,
.home-about-us .description.editor-content h3,
.home-about-us .description.editor-content h4,
.home-about-us .description.editor-content h5,
.home-about-us .description.editor-content h6,
.home-about-us .description.editor-content p,
.home-about-us .description.editor-content li,
.home-about-us .description.editor-content span,
.home-about-us .description.editor-content a,
.home-focus .description.editor-content h1,
.home-focus .description.editor-content h2,
.home-focus .description.editor-content h3,
.home-focus .description.editor-content h4,
.home-focus .description.editor-content h5,
.home-focus .description.editor-content h6,
.home-focus .description.editor-content p,
.home-focus .description.editor-content li,
.home-focus .description.editor-content span,
.home-focus .description.editor-content a,
.home-categories .description.editor-content h1,
.home-categories .description.editor-content h2,
.home-categories .description.editor-content h3,
.home-categories .description.editor-content h4,
.home-categories .description.editor-content h5,
.home-categories .description.editor-content h6,
.home-categories .description.editor-content p,
.home-categories .description.editor-content li,
.home-categories .description.editor-content span,
.home-categories .description.editor-content a,
.home-boutique .description.editor-content h1,
.home-boutique .description.editor-content h2,
.home-boutique .description.editor-content h3,
.home-boutique .description.editor-content h4,
.home-boutique .description.editor-content h5,
.home-boutique .description.editor-content h6,
.home-boutique .description.editor-content p,
.home-boutique .description.editor-content li,
.home-boutique .description.editor-content span,
.home-boutique .description.editor-content a,
.home-social-wall .description.editor-content h1,
.home-social-wall .description.editor-content h2,
.home-social-wall .description.editor-content h3,
.home-social-wall .description.editor-content h4,
.home-social-wall .description.editor-content h5,
.home-social-wall .description.editor-content h6,
.home-social-wall .description.editor-content p,
.home-social-wall .description.editor-content li,
.home-social-wall .description.editor-content span,
.home-social-wall .description.editor-content a {
  text-transform: none;
  color: #ffffff;
}

.home-slide-product .link-more-wrapper {
  text-align: center;
  padding-top: 15px;
}
.home-slide-product .link-more-wrapper .link-more {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 600;
  color: #ffffff;
  background-color: #00244c;
  padding: 15px;
  border: 2px solid #00244c;
  font-size: 12px;
  line-height: normal;
  font-size: 16px;
  text-transform: uppercase;
}
.home-slide-product .link-more-wrapper .link-more:disabled {
  background-color: #b2bdc9;
  border-color: #b2bdc9;
  cursor: not-allowed;
}
.home-slide-product .link-more-wrapper .link-more:hover {
  color: #ffffff;
  background-color: #b88210;
  border-color: #b88210;
}

.home-about-us .logo {
  width: 204px;
  margin: 0 auto calc(15px + 5px);
}
.home-about-us .logo img {
  display: block;
  width: 100%;
  height: auto;
}
.home-about-us .subtitle {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 300;
  font-size: 24px;
  color: #b88210;
  text-align: center;
}
.home-about-us .d-flex {
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: 0;
  padding: calc(15px * 3) 0;
}
@media (min-width: 768px) {
  .home-about-us .d-flex {
    margin-right: -15px;
    margin-left: -15px;
  }
}
.home-about-us .content-wrapper {
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background-color: rgba(0, 36, 76, 0.7);
}
@media (min-width: 768px) {
  .home-about-us .content-wrapper {
    width: 72%;
  }
}
.home-about-us .content-wrapper .image-cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.home-about-us .text-wrapper {
  color: #ffffff;
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
  padding: calc(15px * 2);
}
@media (min-width: 768px) {
  .home-about-us .text-wrapper {
    width: 38.86%;
    padding: calc(15px * 5) calc(15px * 3);
  }
}
@media (min-width: 1200px) {
  .home-about-us .text-wrapper {
    padding: calc(15px * 5) calc(15px * 3) calc(15px * 5) 12%;
  }
}
.home-about-us .text-wrapper .title {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 18px;
}
@media (min-width: 1024px) {
  .home-about-us .text-wrapper .title {
    font-size: 24px;
  }
}
.home-about-us .text-wrapper .description {
  font-family: roboto, arial, helvetica, sans-serif;
  font-weight: 400;
}
.home-about-us .text-wrapper .cta {
  font-size: 16px;
  color: #00244c;
  background-color: #ffffff;
  padding: calc(15px - 5px) calc(15px * 2);
}
.home-about-us .text-wrapper .cta:hover {
  color: #ffffff;
  background-color: #b88210;
  border-color: #b88210;
}
.home-about-us .video-wrapper {
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
  position: relative;
}
@media (min-width: 768px) {
  .home-about-us .video-wrapper {
    width: 61.14%;
  }
}
.home-about-us .video-wrapper:before {
  content: "";
  display: block;
  padding-top: 70%;
}
.home-about-us .video-wrapper:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  z-index: 0;
  display: block;
  background-color: #ffffff;
}
@media (min-width: 768px) {
  .home-about-us .video-wrapper:after {
    display: none;
  }
}
.home-about-us .video-wrapper .video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 15px;
  right: 15px;
  z-index: 2;
  border: 1px solid #ffffff;
  cursor: pointer;
}
@media (min-width: 768px) {
  .home-about-us .video-wrapper .video {
    top: -15px;
    bottom: -15px;
    left: 0;
    right: 0;
  }
}
.home-about-us .media-wrapper {
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  background-color: rgba(0, 36, 76, 0.7);
}
@media (min-width: 768px) {
  .home-about-us .media-wrapper {
    width: 28%;
  }
}
.home-about-us .media-wrapper .image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.home-about-us .media-wrapper .image img {
  width: 100%;
}

.home-focus .subtitle {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  color: #b88210;
  font-size: 16px;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .home-focus .subtitle {
    font-size: 22px;
  }
}
.home-focus .title {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  color: #ffffff;
  font-size: 22px;
  width: 60%;
}
@media (min-width: 768px) {
  .home-focus .title {
    font-size: 24px;
  }
}
.home-focus .description {
  font-family: roboto, arial, helvetica, sans-serif;
  font-weight: 400;
  color: #ffffff;
}
.home-focus .cta {
  font-size: 16px;
  color: #00244c;
  background-color: #ffffff;
  padding: calc(15px - 5px) calc(15px * 2);
}
.home-focus .cta:hover {
  color: #ffffff;
  background-color: #b88210;
  border-color: #b88210;
}
.home-focus .focus-content {
  position: relative;
}
@media (min-width: 1024px) {
  .home-focus .focus-content {
    padding-top: calc(15px * 3);
    margin-right: -15px;
    margin-left: -15px;
  }
}
.home-focus .focus-info {
  background-color: #00244c;
  padding: calc(15px * 2) calc(15px * 2) calc(15px * 7);
}
@media (min-width: 1024px) {
  .home-focus .focus-info {
    position: absolute;
    top: 0;
    right: 0;
    width: calc(66.67% - (15px * 2));
    margin-right: calc(8.33% + 15px);
    padding-left: 16.67%;
    padding-right: calc(15px * 4);
    padding-bottom: calc(15px * 2);
  }
}
@media (min-width: 1200px) {
  .home-focus .focus-info {
    width: calc(58.33% - (15px * 2));
    padding-left: calc(16.67% - (15px * 2));
  }
}
.home-focus .focus-visual {
  position: relative;
  width: 60%;
  max-width: 300px;
  margin: calc(15px * -5) auto 0;
}
@media (min-width: 1024px) {
  .home-focus .focus-visual {
    max-width: auto;
    width: calc(33.33% - (15px * 2));
    margin-top: 0;
    margin-left: calc(8.33% + 15px);
  }
}
.home-focus .focus-visual:before {
  content: "";
  display: block;
  padding-top: 145%;
}
.home-focus .focus-visual .image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.home-focus .focus-visual .image img {
  top: 0;
  transform: rotate(0.001deg) translate3d(-50%, 0, 0);
  width: 100%;
  min-height: auto;
}

.home-manufacturer-slide .brand-link {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 22px;
  text-transform: uppercase;
  text-decoration: none;
  color: #b2bdc9;
  transition: color 0.3s cubic-bezier(0.4, 0.5, 0.15, 1);
}
.home-manufacturer-slide .brand-link:hover {
  color: #b88210;
}
.home-manufacturer-slide .brand-link.has-logo {
  position: relative;
  display: block;
  height: 130px;
}
.home-manufacturer-slide .brand-link.has-logo:hover img {
  filter: grayscale(0);
}
.home-manufacturer-slide .image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.home-manufacturer-slide .image img {
  width: 160px;
  min-width: auto;
  min-height: auto;
  filter: grayscale(1);
  transition: filter 0.3s cubic-bezier(0.4, 0.5, 0.15, 1);
}
@media (min-width: 768px) {
  .home-manufacturer-slide .image img {
    width: 130px;
  }
}
.home-manufacturer-slide .slider-wrapper {
  padding: 0;
}
@media (min-width: 640px) {
  .home-manufacturer-slide .slider-wrapper {
    padding: 0 80px;
  }
}
.home-manufacturer-slide .slick-slide > div {
  display: flex;
  align-items: center;
  text-align: center;
}
@media (min-width: 640px) {
  .home-manufacturer-slide .slick-slide > div {
    margin-left: 15px;
    margin-right: 15px;
  }
}

.home-categories a {
  text-decoration: none;
  color: #ffffff;
}
.home-categories .cover {
  position: relative;
  height: 350px;
}
.home-categories .cover .title-section {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  text-align: center;
  margin-top: calc(15px * 4);
}
.home-categories .categories {
  margin-top: -200px;
}
.home-categories .categories .container {
  padding-right: 0;
  padding-left: 0;
}
@media (min-width: 768px) {
  .home-categories .categories .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}
.home-categories .categories .category {
  position: relative;
  background-color: #00244c;
  transform: scale(1);
  transition: all 0.5s ease;
}
.home-categories .categories .category:hover {
  transform: scale(1.17);
  background-color: #b88210;
}
.home-categories .categories .category:hover .text-content {
  background-color: rgba(184, 130, 16, 0.85);
}
.home-categories .categories .category:hover .text-content .title {
  margin-top: calc(15px * 3);
}
.home-categories .categories .category:hover .picto {
  opacity: 1;
  visibility: visible;
  transform: translate3d(0, 0, 0);
}
.home-categories .categories .category .image:before {
  content: "";
  display: block;
  padding-top: 100%;
}
.home-categories .categories .category .image img {
  width: 60%;
  min-height: auto;
  min-width: auto;
}
.home-categories .categories .category .picto {
  width: 90px;
  height: 90px;
  opacity: 0;
  visibility: hidden;
  margin: 0 auto;
  transform: translate3d(0, 50%, 0);
  transition-delay: 0.15s;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease;
}
.home-categories .categories .category .text-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: calc(15px * 3);
  background-color: rgba(0, 36, 76, 0.85);
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition-duration: 0.5s;
  transition-property: all;
  transition-timing-function: ease;
}
.home-categories .categories .category .text-content .title {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 16px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 calc(15px * 3);
  margin-top: calc(15px * 3);
  transition-delay: 0.05s;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease;
}
@media (min-width: 1024px) {
  .home-categories .categories .category .text-content .title {
    font-size: 24px;
    margin-top: calc(15px * 5);
  }
}
.home-categories .slider-wrapper {
  padding: 0;
}
@media (min-width: 640px) {
  .home-categories .slick-slide > div {
    margin-left: 15px;
    margin-right: 15px;
  }
}
@media (min-width: 640px) {
  .home-categories .slick-list {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.home-boutique .image-wrapper {
  position: relative;
}
.home-boutique .image-wrapper:before {
  content: "";
  display: block;
  padding-top: 100%;
}
.home-boutique .image-wrapper .image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.home-boutique .image-wrapper .image img {
  width: 100%;
  min-height: auto;
}
.home-boutique .layer {
  position: relative;
}
@media (min-width: 768px) {
  .home-boutique .layer-first .image-wrapper, .home-boutique .layer-second .image-wrapper {
    width: calc(66.67% - 15px);
  }
}
@media (min-width: 1024px) {
  .home-boutique .layer-first .image-wrapper, .home-boutique .layer-second .image-wrapper {
    width: calc(45% - 15px);
  }
}
@media (min-width: 1200px) {
  .home-boutique .layer-first .image-wrapper, .home-boutique .layer-second .image-wrapper {
    width: calc(50% - 15px);
  }
}
.home-boutique .layer-first {
  justify-content: flex-start;
}
.home-boutique .layer-second {
  justify-content: flex-end;
  margin-top: -30%;
}
.home-boutique .layer-third {
  margin-top: 0;
}
@media (min-width: 768px) {
  .home-boutique .layer-third {
    margin-top: -10%;
  }
}
@media (min-width: 1024px) {
  .home-boutique .layer-third {
    margin-top: -25%;
  }
}
.home-boutique .layer-third .image-wrapper {
  width: 100%;
}
@media (min-width: 768px) {
  .home-boutique .layer-third .image-wrapper {
    width: calc(66.67% - 15px);
  }
}
@media (min-width: 1024px) {
  .home-boutique .layer-third .image-wrapper {
    width: calc(38% - 15px);
  }
}
@media (min-width: 1200px) {
  .home-boutique .layer-third .image-wrapper {
    width: calc(41.5% - 15px);
  }
}
.home-boutique .content {
  background-color: #00244c;
  padding: calc(15px * 2);
  margin-bottom: 15px;
  width: 100%;
}
@media (min-width: 768px) {
  .home-boutique .content {
    width: calc(66.67% - 15px);
    padding: calc(15px * 5) calc(15px * 2);
    position: absolute;
    top: 58%;
    left: 0;
    z-index: 2;
    transform: translate3d(0, -50%, 0);
    margin-left: calc(32.9% + 15px);
  }
}
@media (min-width: 1024px) {
  .home-boutique .content {
    width: calc(41.5% - 15px);
    margin-left: calc(32.9% + 15px);
  }
}
@media (min-width: 1200px) {
  .home-boutique .content {
    padding: calc(15px * 7) calc(15px * 5);
  }
}
.home-boutique .content .picto {
  width: 40px;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .home-boutique .content .picto {
    width: 60px;
  }
}
.home-boutique .content .title {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  color: #ffffff;
  font-size: 22px;
}
@media (min-width: 768px) {
  .home-boutique .content .title {
    font-size: 24px;
  }
}
.home-boutique .content .description {
  font-family: roboto, arial, helvetica, sans-serif;
  font-weight: 400;
  color: #ffffff;
}
.home-boutique .content .cta {
  font-size: 16px;
  color: #00244c;
  background-color: #ffffff;
  padding: calc(15px - 5px) calc(15px * 2);
}
.home-boutique .content .cta:hover {
  color: #ffffff;
  background-color: #b88210;
  border-color: #b88210;
}

.home-social-wall .title-section {
  text-transform: none;
  text-align: center;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .home-social-wall .title-section {
    margin-bottom: calc(15px * 3);
  }
}
.home-social-wall .post-feed {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.home-social-wall .post-feed:hover .image-wrapper {
  transform: scale(1.3);
}
.home-social-wall .post-feed:hover .content {
  opacity: 1;
  visibility: visible;
  transform: translate3d(0, 0, 0);
}
.home-social-wall .post-feed:hover .social .like,
.home-social-wall .post-feed:hover .social .comment {
  opacity: 1;
  visibility: visible;
  transform: translate3d(0, 0, 0);
}
.home-social-wall .post-feed:hover .social .count {
  opacity: 1;
  visibility: visible;
  transform: translate3d(0, 0, 0);
}
.home-social-wall .post-feed:hover .text-content {
  opacity: 1;
  visibility: visible;
  transform: translate3d(0, 0, 0);
}
.home-social-wall .image-wrapper {
  position: relative;
  transform: scale(1);
  transition: transform 0.5s cubic-bezier(0.4, 0.5, 0.15, 1);
}
.home-social-wall .image-wrapper:before {
  content: "";
  display: block;
  padding-top: 100%;
}
.home-social-wall .image-wrapper .image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.home-social-wall .image-wrapper .image img {
  width: 100%;
  min-height: auto;
}
.home-social-wall .image-wrapper .image img {
  height: 100%;
  width: auto;
  min-width: auto;
}
.home-social-wall .content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transform: translate3d(0, 100%, 0);
  background-color: rgba(0, 36, 76, 0.7);
  transition: all 0.5s cubic-bezier(0.4, 0.5, 0.15, 1);
}
@media (min-width: 768px) {
  .home-social-wall .content {
    transition: all 0.5s ease-in-out;
    transform: none;
    top: 0;
  }
}
.home-social-wall .social {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: calc(15px * 2);
}
.home-social-wall .social .picto {
  margin-right: 10px;
}
.home-social-wall .social .picto img {
  width: auto;
  height: 40px;
}
.home-social-wall .social .count {
  opacity: 0;
  visibility: hidden;
  transform: translate3d(-15px, 0, 0);
  transition: all 0.3s ease-in-out;
}
.home-social-wall .social .like,
.home-social-wall .social .comment {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #ffffff;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s cubic-bezier(0.4, 0.5, 0.15, 1);
  transform: translate3d(0, 100%, 0);
}
.home-social-wall .social .like:nth-child(1),
.home-social-wall .social .comment:nth-child(1) {
  transition-delay: 110ms;
}
.home-social-wall .social .like:nth-child(2),
.home-social-wall .social .comment:nth-child(2) {
  transition-delay: 220ms;
}
.home-social-wall .social .like .picto img {
  width: auto;
  height: 38px;
}
.home-social-wall .social .like .count {
  transition-delay: 0.5s;
}
.home-social-wall .social .comment .count {
  transition-delay: 0.6s;
}
.home-social-wall .text-content {
  font-family: roboto, arial, helvetica, sans-serif;
  font-weight: 400;
  color: #ffffff;
  font-size: 12px;
  word-break: break-word;
  padding: calc(15px * 2);
  opacity: 0;
  visibility: hidden;
  transform: translate3d(0, 30px, 0);
  transition: all 0.5s cubic-bezier(0.4, 0.5, 0.15, 1);
  transition-delay: 400ms;
  display: none;
}
@media (min-width: 768px) {
  .home-social-wall .text-content {
    display: block;
  }
}
.home-social-wall .social-link {
  margin-top: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.home-social-wall .social-link .libelle {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 600;
  color: #00244c;
  text-decoration: none;
  position: relative;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
  text-transform: uppercase;
  line-height: 40px;
  cursor: initial;
}
.home-social-wall .social-link .libelle:after {
  content: "";
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #00244c;
  transform-origin: center;
  transition: transform 0.3s cubic-bezier(0.4, 0.5, 0.15, 1);
}
.home-social-wall .social-link .libelle:after {
  transform: scaleX(1);
}
.home-social-wall .social-link .libelle:hover:after {
  transform: scaleX(0);
}
.home-social-wall .social-link .icon {
  width: 40px;
  height: 40px;
  display: block;
  margin-right: 0;
  margin-left: 10px;
}
.home-social-wall .social-link .icon.facebook-white {
  line-height: 0;
  border-radius: 22px;
  background-color: #00244c;
  background-size: 22px;
  background-position: center;
  width: 44px;
  height: 44px;
}

/**
 * ----------------------------------
 * Product page stylesheet
 * ----------------------------------
 */
.product-detail {
  padding: calc(15px * 3) 0;
}
.product-detail .product-image {
  position: relative;
  margin-bottom: calc(15px * 3);
}
.product-detail .product-image .flags li {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 600;
  position: absolute;
  top: 0;
  z-index: 2;
  color: #ffffff;
}
.product-detail .product-image .flags .on-sale {
  left: 0;
}
.product-detail .product-image .flags .on-sale:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  border-top: 100px solid #02a5e6;
  border-right: 105px solid transparent;
}
.product-detail .product-image .flags .on-sale span {
  display: inline-block;
  text-transform: uppercase;
  transform: translate3d(20%, 150%, 0) rotate(-45deg);
}
.product-detail .product-image .flags .discount {
  font-size: 16px;
  right: 0;
  padding: 12px 5px;
  background-color: #02a5e6;
}
.product-detail .product-image .product-cover {
  position: relative;
}
.product-detail .product-image .product-cover:before {
  content: "";
  display: block;
  padding-top: 100%;
}
.product-detail .product-image .product-cover .image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.product-detail .product-image .product-cover .image img {
  width: 100%;
  min-height: auto;
}
.product-detail .product-image .product-cover .toggle-layer {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 40px;
  height: 40px;
  background: none;
  border: none;
  outline: none;
  z-index: 2;
  cursor: pointer;
}
.product-detail .product-image .product-cover .icon {
  width: 36px;
  height: 33px;
}
.product-detail .product-image .product-thumbnails {
  position: relative;
  margin-top: 15px;
}
.product-detail .product-image .product-thumbnails ul {
  position: relative;
  padding: 0 calc(15px + 5px);
}
@media (min-width: 640px) {
  .product-detail .product-image .product-thumbnails ul {
    padding: 0 calc(15px * 4);
  }
}
.product-detail .product-image .product-thumbnails .thumb-item .image {
  width: 70px;
  height: 70px;
  margin: auto;
}
@media (min-width: 640px) {
  .product-detail .product-image .product-thumbnails .thumb-item .image {
    width: 100px;
    height: 100px;
  }
}
.product-detail .product-image .product-thumbnails .thumb-item .thumb {
  width: 100%;
}
.product-detail .product-image .product-thumbnails .slick-arrow {
  background-image: svg-load("chevron.svg", fill=#00244c);
  position: absolute;
  top: 50%;
  width: 20px;
  height: 20px;
  border: none;
  font-size: 0;
  outline: none;
  background-color: transparent;
}
.product-detail .product-image .product-thumbnails .slick-arrow.slick-disabled {
  opacity: 0.3;
}
.product-detail .product-image .product-thumbnails .slick-arrow.slick-prev {
  left: 0;
  transform: translateY(-15px);
}
@media (min-width: 640px) {
  .product-detail .product-image .product-thumbnails .slick-arrow.slick-prev {
    left: calc(15px * 3);
  }
}
.product-detail .product-image .product-thumbnails .slick-arrow.slick-next {
  right: 0;
  transform: translateY(-15px) rotate(180deg);
}
@media (min-width: 640px) {
  .product-detail .product-image .product-thumbnails .slick-arrow.slick-next {
    right: calc(15px * 3);
  }
}
.product-detail .product-info {
  margin-bottom: calc(15px * 3);
}
.product-detail .product-info h1 {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 24px;
  color: #00244c;
}
@media (min-width: 1024px) {
  .product-detail .product-info h1 {
    font-size: 28px;
  }
}
.product-detail .product-info .product-flags {
  display: flex;
  align-items: center;
  margin-bottom: calc(15px * 2);
}
.product-detail .product-info .product-flags > li {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 12px;
  padding: 15px;
  margin-right: calc(15px - 5px);
  margin-bottom: 15px;
  background-color: #b88210;
  color: #ffffff;
  text-transform: uppercase;
  border-top-left-radius: 23px;
  border-bottom-right-radius: 23px;
}
.product-detail .product-info .product-flags > li.product-type {
  background-color: #39b54a;
  border-radius: 0;
}
.product-detail .product-info .product-label {
  margin-bottom: 15px;
}
.product-detail .product-info .product-label .label-list {
  display: flex;
  align-items: center;
}
.product-detail .product-info .product-label .label-list .label-item {
  height: 50px;
  width: auto;
  padding-right: 10px;
}
.product-detail .product-info .product-label .label-list .label-item img {
  width: auto;
  height: 100%;
  display: block;
}
.product-detail .product-info .product-label .label-list .label-item span {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 400;
  display: block;
  font-size: 10px;
}
.product-detail .product-info .product-short-description {
  font-family: roboto, arial, helvetica, sans-serif;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 15px;
}
.product-detail .product-info .product-short-description a {
  color: #000000;
  font-size: 16px;
  text-decoration: none;
  border-bottom: 1px solid #00244c;
}
.product-detail .product-info .product-short-description a:hover {
  color: #b88210;
  border: none;
  transition: 0.3s all cubic-bezier(0.4, 0.5, 0.15, 1);
}
.product-detail .product-info .product-short-description[data-readmore] {
  margin-bottom: 0;
}
.product-detail .product-info .product-variants-info .composition,
.product-detail .product-info .product-variants-info .variants {
  margin-bottom: 15px;
}
.product-detail .product-info .product-variants-info .composition {
  display: flex;
  align-items: center;
}
.product-detail .product-info .product-variants-info .composition span {
  font-family: roboto, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #00244c;
  padding: 0 8px;
  border-right: 1px solid #b88210;
}
.product-detail .product-info .product-variants-info .composition span:first-child {
  padding-left: 0;
}
.product-detail .product-info .product-variants-info .composition span:last-child {
  padding-right: 0;
  border: none;
}
.product-detail .product-info .product-variants-info .variants {
  font-family: roboto, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #00244c;
}
.product-detail .product-info .product-variants-info .variants p {
  display: inline;
}
.product-detail .product-info .product-variants-info .variants a {
  color: #00244c;
  margin: 0 5px;
}
.product-detail .product-info .product-prices {
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .product-detail .product-info .product-prices {
    margin-bottom: calc(15px * 2);
  }
}
.product-detail .product-info .product-prices .product-discount .regular-price {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  position: relative;
  font-size: 22px;
  color: #00244c;
}
.product-detail .product-info .product-prices .product-discount .regular-price:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  background: #00244c;
  transform: rotate(-12deg);
}
.product-detail .product-info .product-prices .product-pack-price {
  display: none;
}
.product-detail .product-info .product-prices .price-ecotax,
.product-detail .product-info .product-prices .product-without-taxes {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  position: relative;
  font-size: 22px;
  color: #b2bdc9;
}
.product-detail .product-info .product-prices .product-price.has-discount .discount-amount {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 14px;
  color: #b88210;
}
@media (min-width: 640px) {
  .product-detail .product-info .product-prices .product-price.has-discount .discount-amount {
    font-size: 16px;
  }
}
.product-detail .product-info .product-prices .product-price .current-price {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  color: #00244c;
  font-size: 36px;
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
}
.product-detail .product-info .product-prices .product-price .current-price span:not(:last-child) {
  margin-right: 10px;
}
.product-detail .product-info .product-prices .tax-shipping-delivery-label {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 15px;
}
.product-detail .product-info .product-prices .discount,
.product-detail .product-info .product-prices .price_unity {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  position: relative;
  font-size: 18px;
  color: #b88210;
  margin-left: calc(15px * 2);
}
.product-detail .product-info .product-prices .product-availability {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 15px;
}
.product-detail .product-info .product-prices .product-availability .available {
  color: #39b54a;
}
.product-detail .product-info .product-prices .product-availability .unavailable {
  color: #ff1100;
}
.product-detail .product-info .product-prices .product-availability .last-remaining {
  color: #ff9800;
}
.product-detail .product-info .product-discounts-title {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  color: #00244c;
  margin-bottom: 5px;
}
@media (min-width: 1024px) {
  .product-detail .product-info .product-discounts-title {
    font-size: 18px;
  }
}
.product-detail .product-info .product-discounts .table-product-discounts {
  background-color: #f9f2e8;
  margin-bottom: calc(15px * 2);
}
.product-detail .product-info .product-discounts .table-product-discounts th,
.product-detail .product-info .product-discounts .table-product-discounts td {
  padding: 8px;
}
.product-detail .product-info .product-discounts .table-product-discounts tr {
  border-bottom: #ffffff;
}
.product-detail .product-info .product-discounts .table-product-discounts th {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 600;
  color: #b88210;
}
.product-detail .product-info .product-discounts .table-product-discounts td {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 400;
  text-align: center;
}
.product-detail .product-info .product-actions .product-quantity {
  display: inline-block;
}
.product-detail .product-info .product-actions .label {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 400;
  font-size: 16px;
  display: inline-block;
  margin-right: 10px;
}
.product-detail .product-info .product-variants {
  padding-top: 10px;
}
.product-detail .product-info .product-variants-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.product-detail .product-info .product-variants .color-list {
  display: flex;
  align-items: center;
}
.product-detail .product-info .product-variants .color-list label {
  position: relative;
}
.product-detail .product-info .product-variants .color-list input {
  position: absolute;
  top: 15px;
  left: 15px;
  width: 15px;
  height: 15px;
  opacity: 0;
  transform: translate3d(-50%, -50%, 0);
}
.product-detail .product-info .product-variants .color-list .color {
  display: block;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 15px;
  border: 1px solid rgba(184, 130, 16, 0.3);
  cursor: pointer;
  transition: all 0.3s;
}
.product-detail .product-info .product-variants .color-list .color:hover {
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
}
.product-detail .product-info .product-variants .product-property li {
  display: block;
  padding: 7px 0 12px;
}
.product-detail .product-info .product-variants .product-property span:not(.label) {
  font-family: roboto, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #00244c;
}
.product-detail .product-info .product-add-to-cart {
  margin-top: calc(15px * 2);
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .product-detail .product-info .product-add-to-cart {
    margin-bottom: 0;
  }
}
.product-detail .product-info .product-add-to-cart .add {
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .product-detail .product-info .product-add-to-cart .add {
    flex-grow: 1;
    flex-shrink: 0;
    width: 50%;
    max-width: 350px;
  }
}
.product-detail .product-info .product-add-to-cart .add .add-to-cart {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 600;
  display: inline-block;
  font-size: 16px;
  color: #ffffff;
  background-color: #00244c;
  text-transform: uppercase;
  padding: calc(15px + 5px);
  transition: all 0.3s ease;
  text-decoration: none;
  outline: none;
  border: none;
  cursor: pointer;
  width: 100%;
}
.product-detail .product-info .product-add-to-cart .add .add-to-cart:hover {
  color: #ffffff;
  background-color: #b88210;
}
.product-detail .product-info .product-add-to-cart .add .add-to-cart:disabled {
  background-color: #b2bdc9;
  cursor: not-allowed;
}
.product-detail .product-info .product-add-to-cart .assurance-picto .assurance-item {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 600;
  font-size: 9px;
  text-transform: uppercase;
  text-align: center;
  padding: 0;
  display: inline-block;
  max-width: 70px;
}
@media (min-width: 1200px) {
  .product-detail .product-info .product-add-to-cart .assurance-picto .assurance-item {
    padding: 0 15px;
    max-width: 100px;
  }
}
.product-detail .product-info .product-add-to-cart .assurance-picto .assurance-item a {
  text-decoration: none;
  color: #00244c;
}
.product-detail .product-info .product-add-to-cart .assurance-picto .image {
  height: 30px;
  width: auto;
  margin: 0 auto 5px;
}
.product-detail .product-info .product-add-to-cart .assurance-picto .image img {
  display: inline-block;
  width: auto;
  height: 100%;
  min-width: auto;
}
.product-detail .product-info .product-add-to-cart .product-features {
  position: relative;
}
.product-detail .product-info .product-add-to-cart .product-features .social-sharing {
  position: absolute;
  bottom: 10px;
  right: 0;
}
@media (min-width: 768px) {
  .product-detail .product-info .product-add-to-cart .product-features .social-sharing {
    position: relative;
    bottom: 0;
    flex-grow: 1;
    flex-shrink: 0;
    width: 100%;
    margin-top: 15px;
  }
}
.product-detail .product-info .product-add-to-cart .block-reassurance {
  display: none;
}
.product-detail .product-additional-info .js-mailalert {
  padding: 15px 0;
}
.product-detail .product-additional-info .js-mailalert input[type=email] {
  margin-bottom: 15px;
  width: 100%;
}
@media (min-width: 640px) {
  .product-detail .product-additional-info .js-mailalert input[type=email] {
    width: 320px;
  }
}
.product-detail .product-additional-info .js-mailalert .btn {
  color: #00244c;
  background-color: transparent;
  padding: 15px;
  border: 2px solid #00244c;
  font-size: 12px;
  line-height: normal;
}
.product-detail .product-additional-info .js-mailalert .btn:hover {
  color: #b88210;
  background-color: transparent;
  border-color: #b88210;
}
.product-detail .product-more-info {
  padding-top: 15px;
  margin-top: 15px;
  border-top: 1px solid #b88210;
}
@media (min-width: 640px) {
  .product-detail .product-more-info {
    padding-top: calc(15px * 3);
    margin-top: calc(15px * 3);
  }
}
.product-detail .product-more-info .product-manufacturer .manufacturer {
  width: 160px;
  margin-bottom: 15px;
}
.product-detail .product-more-info .product-manufacturer .manufacturer img {
  display: block;
  width: 100%;
  height: auto;
}
.product-detail .product-more-info .product-manufacturer .title {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 15px;
}
@media (min-width: 1024px) {
  .product-detail .product-more-info .product-manufacturer .title {
    font-size: 24px;
  }
}
.product-detail .product-more-info .product-manufacturer .title a {
  text-transform: uppercase;
  text-decoration: none;
  color: #00244c;
}
.product-detail .product-more-info .detail {
  margin-bottom: calc(15px * 2);
  margin-top: 15px;
}
.product-detail .product-more-info .detail .nav-link {
  font-family: roboto, arial, helvetica, sans-serif;
  font-weight: 700;
  color: #00244c;
  text-decoration: none;
  position: relative;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
  display: inline-block;
}
.product-detail .product-more-info .detail .nav-link:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #b88210;
  transform-origin: center;
  transition: transform 0.3s cubic-bezier(0.4, 0.5, 0.15, 1);
}
.product-detail .product-more-info .detail .nav-link:after {
  transform: scaleX(1);
}
.product-detail .product-more-info .detail .nav-link:hover:after {
  transform: scaleX(0);
}
.product-detail .product-more-info .detail .product-manufacturer,
.product-detail .product-more-info .detail .product-reference,
.product-detail .product-more-info .detail .product-quantities,
.product-detail .product-more-info .detail .product-availability-date,
.product-detail .product-more-info .detail .product-features {
  font-family: roboto, arial, helvetica, sans-serif;
  font-weight: 400;
  margin-bottom: 15px;
}
.product-detail .product-more-info .detail .product-manufacturer label,
.product-detail .product-more-info .detail .product-reference label,
.product-detail .product-more-info .detail .product-quantities label,
.product-detail .product-more-info .detail .product-availability-date label,
.product-detail .product-more-info .detail .product-features label {
  font-weight: bold;
}
.product-detail .product-more-info .detail .product-manufacturer a,
.product-detail .product-more-info .detail .product-manufacturer span,
.product-detail .product-more-info .detail .product-reference a,
.product-detail .product-more-info .detail .product-reference span,
.product-detail .product-more-info .detail .product-quantities a,
.product-detail .product-more-info .detail .product-quantities span,
.product-detail .product-more-info .detail .product-availability-date a,
.product-detail .product-more-info .detail .product-availability-date span,
.product-detail .product-more-info .detail .product-features a,
.product-detail .product-more-info .detail .product-features span {
  text-decoration: none;
  color: #b88210;
}
.product-detail .product-more-info .detail .product-manufacturer .title,
.product-detail .product-more-info .detail .product-reference .title,
.product-detail .product-more-info .detail .product-quantities .title,
.product-detail .product-more-info .detail .product-availability-date .title,
.product-detail .product-more-info .detail .product-features .title {
  font-weight: bold;
}
.product-detail .product-more-info .detail .product-features .data-sheet {
  display: flex;
  align-items: center;
}
.product-detail .product-more-info .detail .product-features .data-sheet dt,
.product-detail .product-more-info .detail .product-features .data-sheet dd {
  flex-grow: 0;
  flex-shrink: 0;
  width: 50%;
  padding: 0 15px;
}
.product-detail .product-more-info .why {
  margin-bottom: calc(15px * 2);
}
@media (min-width: 768px) {
  .product-detail .product-more-info .why {
    margin-bottom: calc(15px * 4);
  }
}
.product-detail .product-more-info .why .title-info {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  color: #00244c;
  margin-bottom: 15px;
}
@media (min-width: 1024px) {
  .product-detail .product-more-info .why .title-info {
    font-size: 18px;
  }
}
.product-detail .product-more-info .product-info-accordion .collapse:not(.show) {
  display: none;
}
.product-detail .product-more-info .product-info-accordion .accordion-toggler {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 16px;
  position: relative;
  text-align: left;
  padding: 5px 14px 5px 0;
  text-transform: uppercase;
  color: #00244c;
  display: block;
  background: transparent;
  width: 100%;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #b88210;
  cursor: pointer;
}
@media (min-width: 1024px) {
  .product-detail .product-more-info .product-info-accordion .accordion-toggler {
    font-size: 18px;
  }
}
.product-detail .product-more-info .product-info-accordion .accordion-toggler:focus, .product-detail .product-more-info .product-info-accordion .accordion-toggler:hover {
  outline: none;
}
.product-detail .product-more-info .product-info-accordion .accordion-toggler:after {
  background-image: svg-load("chevron.svg", fill=#b88210);
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 14px;
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 14px;
  transform: rotate(-90deg);
  transition: transform 0.55s ease;
}
.product-detail .product-more-info .product-info-accordion .accordion-toggler[aria-expanded=true]:after, .product-detail .product-more-info .product-info-accordion .accordion-toggler.active-header:after {
  transform: rotate(90deg);
}
.product-detail .product-more-info .product-info-accordion .accordion-toggler h2 {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 16px;
}
@media (min-width: 1024px) {
  .product-detail .product-more-info .product-info-accordion .accordion-toggler h2 {
    font-size: 18px;
  }
}
.product-detail .product-more-info .product-info-accordion .accordion-content {
  padding: 15px 0;
  min-height: 1px;
}
.product-detail .product-more-info .tables-information-nutritionnelles td {
  font-family: roboto, arial, helvetica, sans-serif;
  font-weight: 400;
  padding: 5px 0;
  border-bottom: 1px solid #b2bdc9;
}
@media (min-width: 640px) {
  .product-detail .product-more-info .tables-information-nutritionnelles td:first-child {
    width: 65%;
  }
}
.product-detail .product-more-info .tables-information-nutritionnelles .taille_portion td {
  font-weight: 700;
  color: #b88210;
  border: none;
}
.product-detail .product-more-info .tables-information-nutritionnelles .energie td {
  font-weight: 700;
}
.product-detail .product-more-info .product-pack-list li {
  margin-bottom: 5px;
}
.product-detail .product-more-info .product-pack-list a {
  font-family: roboto, arial, helvetica, sans-serif;
  font-weight: 700;
  color: #000000;
  text-decoration: none;
  position: relative;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
}
.product-detail .product-more-info .product-pack-list a:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #b88210;
  transform-origin: center;
  transition: transform 0.3s cubic-bezier(0.4, 0.5, 0.15, 1);
}
.product-detail .product-more-info .product-pack-list a:after {
  transform: scaleX(1);
}
.product-detail .product-more-info .product-pack-list a:hover:after {
  transform: scaleX(0);
}
.product-detail .product-recette-wrapper {
  background-color: #f9f2e8;
  margin-bottom: calc(15px * 2);
}
.product-detail .product-recette .title {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  color: #00244c;
  font-size: 14px;
}
@media (min-width: 768px) {
  .product-detail .product-recette .title {
    font-size: 22px;
  }
}
.product-detail .product-recette .recette-toggler {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 18px;
  position: relative;
  line-height: 1.5;
  text-align: left;
  padding: 15px 40px 15px 0;
  color: #00244c;
  display: block;
  background: transparent;
  width: 100%;
  border: none;
}
@media (min-width: 1024px) {
  .product-detail .product-recette .recette-toggler {
    font-size: 24px;
  }
}
@media (min-width: 768px) {
  .product-detail .product-recette .recette-toggler {
    padding: 15px 40px;
  }
}
.product-detail .product-recette .recette-toggler:after {
  background-image: svg-load("chevron.svg", fill=#00244c);
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 20px;
  content: "";
  position: absolute;
  right: 10px;
  top: 50%;
  width: 20px;
  height: 20px;
  transform: translate3d(0, -10px, 0) rotate(-90deg);
  transition: transform 0.55s ease;
}
.product-detail .product-recette .recette-toggler[aria-expanded=true]:after {
  transform: translate3d(0, -10px, 0) rotate(90deg);
}
.product-detail .product-recette .recette-toggler span {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 300;
  color: #b88210;
  text-transform: uppercase;
}
.product-detail .product-recette .recette-accordion {
  padding: 15px 0 calc(15px * 2);
}
.product-detail .product-recette .how-to-cook {
  margin-bottom: 15px;
}
@media (min-width: 640px) {
  .product-detail .product-recette .how-to-cook {
    margin-bottom: calc(15px * 2);
  }
}
.product-detail .product-recette .how-to-cook ul {
  display: flex;
  align-items: center;
}
.product-detail .product-recette .how-to-cook ul li {
  flex-grow: 0;
  flex-shrink: 0;
  padding: 10px;
  border-right: 1px solid #b88210;
}
@media (min-width: 640px) {
  .product-detail .product-recette .how-to-cook ul li {
    padding: 15px;
    min-width: 100px;
  }
}
.product-detail .product-recette .how-to-cook ul li:first-child {
  padding-left: 0;
}
.product-detail .product-recette .how-to-cook ul li:last-child {
  padding-right: 0;
  border-right: none;
}
.product-detail .product-recette .how-to-cook span {
  font-weight: 700;
  display: block;
  padding-top: 10px;
  text-align: center;
}
.product-detail .product-recette .how-to-cook .picto {
  width: auto;
  height: 30px;
}
@media (min-width: 640px) {
  .product-detail .product-recette .how-to-cook .picto {
    height: 36px;
  }
}
.product-detail .product-recette .how-to-cook .picto img {
  display: block;
  width: auto;
  height: 100%;
  margin: auto;
}
.product-detail .product-recette .recette-image {
  position: relative;
  height: 400px;
}
.product-detail .product-recette .recette-image .image {
  position: absolute;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
}
.product-detail .product-recette .recette-ingredient,
.product-detail .product-recette .recette-prepration {
  margin-top: 15px;
}
.product-detail .product-tabs {
  margin-bottom: 15px;
}
.product-detail .product-tabs-mobile .collapse:not(.show) {
  display: none;
}
.product-detail .product-tabs-mobile .accordion-toggler {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 18px;
  position: relative;
  text-align: left;
  padding: 5px 14px 5px 0;
  text-transform: uppercase;
  color: #b88210;
  display: block;
  background: transparent;
  width: 100%;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #b88210;
  cursor: pointer;
}
@media (min-width: 1024px) {
  .product-detail .product-tabs-mobile .accordion-toggler {
    font-size: 24px;
  }
}
.product-detail .product-tabs-mobile .accordion-toggler:focus, .product-detail .product-tabs-mobile .accordion-toggler:hover {
  outline: none;
}
.product-detail .product-tabs-mobile .accordion-toggler:after {
  background-image: svg-load("chevron.svg", fill=#00244c);
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 20px;
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 20px;
  transform: rotate(-90deg);
  transition: transform 0.55s ease;
}
.product-detail .product-tabs-mobile .accordion-toggler[aria-expanded=true]:after, .product-detail .product-tabs-mobile .accordion-toggler.active-header:after {
  transform: rotate(90deg);
}
.product-detail .product-tabs-mobile .accordion-pane {
  padding: calc(15px * 2) 0;
  min-height: 1px;
}
.product-detail .product-tabs-desktop .tab-list ul {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #b88210;
}
.product-detail .product-tabs-desktop .tab-list ul > li {
  flex-grow: 1;
  flex-shrink: 0;
  max-width: 33.3333333333%;
}
.product-detail .product-tabs-desktop .tab-content {
  position: relative;
}
.product-detail .product-tabs-desktop .tab-content.tab-wrapper {
  min-height: 510px;
}
.product-detail .product-tabs-desktop .tab-toggler {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 16px;
  width: 100%;
  height: 74px;
  color: #00244c;
  text-transform: uppercase;
  background: transparent;
  border-color: #b88210;
  border-style: solid;
  border-width: 0;
  padding: 15px;
}
@media (min-width: 1024px) {
  .product-detail .product-tabs-desktop .tab-toggler {
    font-size: 18px;
  }
}
.product-detail .product-tabs-desktop .tab-toggler.active {
  border-width: 1px 1px 0;
  position: relative;
}
.product-detail .product-tabs-desktop .tab-toggler.active:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 100%;
  height: 1px;
  background: #ffffff;
}
.product-detail .product-tabs-desktop .tab-pane {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
}
.product-detail .product-notice {
  margin-top: calc(15px * 3);
}
@media (min-width: 768px) {
  .product-detail .product-notice {
    margin-top: 15px;
  }
}
.product-detail .product-notice .alcohol-notice {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 600;
  text-align: center;
  margin-bottom: 10px;
  font-size: 12px;
}
.product-detail .product-notice .random-notice {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  color: #00244c;
  padding-top: 10px;
  border-top: 1px solid #b88210;
}

/**
 * ----------------------------------
 * Page boutiques stylesheet
 * ----------------------------------
 */
.henaff-stores .store-info {
  padding: calc(15px * 2) 0;
}
@media (min-width: 768px) {
  .henaff-stores .store-info {
    padding: calc(15px * 4) 0;
  }
}
.henaff-stores .store-info .text-content {
  padding-right: 0;
}
@media (min-width: 1024px) {
  .henaff-stores .store-info .text-content {
    padding-right: 15%;
  }
}
.henaff-stores .store-info .title {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 24px;
  color: #00244c;
}
@media (min-width: 1024px) {
  .henaff-stores .store-info .title {
    font-size: 28px;
  }
}
.henaff-stores .store-picture {
  position: relative;
}
.henaff-stores .store-picture:before {
  content: "";
  display: block;
  padding-top: 75%;
}
.henaff-stores .store-picture .image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.henaff-stores .store-detail {
  background-color: #f9f2e8;
  padding-left: calc(15px * 2);
  padding-right: calc(15px * 2);
  padding-top: calc(15px * 5);
  padding-bottom: calc(15px * 5);
}
@media (min-width: 640px) {
  .henaff-stores .store-detail {
    padding-left: calc(6.6% + 15px);
    padding-right: calc((calc(100vw - var(--scrollbar-width)) - (540px - 30px)) / 2);
  }
}
@media (min-width: 768px) {
  .henaff-stores .store-detail {
    padding-left: calc(6.6% + 15px);
    padding-right: calc((calc(100vw - var(--scrollbar-width)) - (720px - 30px)) / 2);
  }
}
@media (min-width: 1024px) {
  .henaff-stores .store-detail {
    padding-left: calc(6.6% + 15px);
    padding-right: calc((calc(100vw - var(--scrollbar-width)) - (960px - 30px)) / 2);
  }
}
@media (min-width: 1200px) {
  .henaff-stores .store-detail {
    padding-left: calc(6.6% + 15px);
    padding-right: calc((calc(100vw - var(--scrollbar-width)) - (1170px - 30px)) / 2);
  }
}
@media (min-width: 640px) {
  .henaff-stores .store-detail.flex-md-row-reverse {
    padding-right: calc(6.6% + 15px);
    padding-left: calc((calc(100vw - var(--scrollbar-width)) - (540px - 30px)) / 2);
  }
}
@media (min-width: 768px) {
  .henaff-stores .store-detail.flex-md-row-reverse {
    padding-right: calc(6.6% + 15px);
    padding-left: calc((calc(100vw - var(--scrollbar-width)) - (720px - 30px)) / 2);
  }
}
@media (min-width: 1024px) {
  .henaff-stores .store-detail.flex-md-row-reverse {
    padding-right: calc(6.6% + 15px);
    padding-left: calc((calc(100vw - var(--scrollbar-width)) - (960px - 30px)) / 2);
  }
}
@media (min-width: 1200px) {
  .henaff-stores .store-detail.flex-md-row-reverse {
    padding-right: calc(6.6% + 15px);
    padding-left: calc((calc(100vw - var(--scrollbar-width)) - (1170px - 30px)) / 2);
  }
}
.henaff-stores .store-description {
  margin-bottom: calc(15px * 3);
}
.henaff-stores .store-description .title {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 300;
  color: #b88210;
  font-size: 22px;
  margin-bottom: 15px;
}
.henaff-stores .store-info-pratique {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  color: #00244c;
  border-bottom: 1px solid #b88210;
}
.henaff-stores .store-contact {
  font-family: roboto, arial, helvetica, sans-serif;
  font-weight: 400;
  flex-wrap: wrap;
  margin-bottom: calc(15px * 2);
}
.henaff-stores .store-contact address,
.henaff-stores .store-contact ul {
  flex-grow: 1;
  flex-shrink: 0;
  width: 100%;
  font-style: normal;
  margin-bottom: 15px;
}
@media (min-width: 640px) {
  .henaff-stores .store-contact address,
.henaff-stores .store-contact ul {
    width: 50%;
  }
}
.henaff-stores .store-contact address li,
.henaff-stores .store-contact ul li {
  padding: 4px 0;
}
.henaff-stores .store-contact a {
  color: #00244c;
  text-decoration: none;
  position: relative;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
}
.henaff-stores .store-contact a:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #b88210;
  transform-origin: center;
  transition: transform 0.3s cubic-bezier(0.4, 0.5, 0.15, 1);
}
.henaff-stores .store-contact a:after {
  transform: scaleX(1);
}
.henaff-stores .store-contact a:hover:after {
  transform: scaleX(0);
}
.henaff-stores .store-contact .btn-phone {
  display: inline-block;
  background-color: #00244c;
  color: #ffffff;
  font-size: 12px;
  margin: 3px;
  padding: 3px;
  cursor: pointer;
}
.henaff-stores .store-time .title {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  margin-bottom: 5px;
}
.henaff-stores .store-time-high, .henaff-stores .store-time-down {
  margin-bottom: calc(15px * 2);
}
.henaff-stores .store-loyalty {
  margin-top: calc(15px * 2);
}
@media (min-width: 768px) {
  .henaff-stores .store-loyalty {
    margin-top: calc(15px * 3);
  }
}
.henaff-stores .store-loyalty .loyalty-detail {
  width: 100%;
  max-width: 700px;
  margin: auto;
}

/**
 * ----------------------------------
 * Page contact stylesheet
 * ----------------------------------
 */
.henaff-contact #contact-form .form-fields .custom-select .holder {
  font-family: roboto, arial, helvetica, sans-serif;
  font-weight: 400;
  color: #b2bdc9;
  font-size: 14px;
}
.henaff-contact #contact-form .form-fields .d-flex {
  justify-content: left;
  margin-left: -15px;
  margin-right: -15px;
}
.henaff-contact #contact-form .form-fields .d-flex > [class*=form-control-] {
  flex-grow: 0;
  padding-left: 15px;
  padding-right: 15px;
}
.henaff-contact #contact-form .form-fields .form-control-label {
  width: 100%;
}
@media (min-width: 768px) {
  .henaff-contact #contact-form .form-fields .form-control-label {
    text-align: right;
    width: 25%;
  }
}
@media (min-width: 1200px) {
  .henaff-contact #contact-form .form-fields .form-control-label {
    width: 16.67%;
  }
}
.henaff-contact #contact-form .form-fields .form-control-input {
  width: 100%;
}
@media (min-width: 768px) {
  .henaff-contact #contact-form .form-fields .form-control-input {
    width: 58.33%;
  }
}
@media (min-width: 1200px) {
  .henaff-contact #contact-form .form-fields .form-control-input {
    width: 41.67%;
  }
}
.henaff-contact #contact-form .form-fields .form-control-note {
  font-family: roboto, arial, helvetica, sans-serif;
  font-weight: 400;
  color: #b2bdc9;
  font-size: 14px;
  font-style: italic;
  padding-top: 5px;
  display: inline-block;
}
.henaff-contact #contact-form .form-fields .form-control-comment {
  margin-left: 0;
}
.henaff-contact .form-footer {
  text-align: right;
}
.henaff-contact .form-footer,
.henaff-contact .gpdr-text,
.henaff-contact .contact-info {
  width: 100%;
}
@media (min-width: 768px) {
  .henaff-contact .form-footer,
.henaff-contact .gpdr-text,
.henaff-contact .contact-info {
    width: calc(85.53% - 30px);
  }
}
@media (min-width: 1200px) {
  .henaff-contact .form-footer,
.henaff-contact .gpdr-text,
.henaff-contact .contact-info {
    width: calc(59.86% - 30px);
  }
}
.henaff-contact .gpdr-text {
  font-family: roboto, arial, helvetica, sans-serif;
  font-weight: 400;
  color: #000000;
  font-size: 12px;
  margin-top: calc(15px * 2);
}
@media (min-width: 768px) {
  .henaff-contact .gpdr-text {
    margin-top: calc(15px * 3);
  }
}
.henaff-contact .gpdr-text a {
  color: #000000;
  text-decoration: none;
  position: relative;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
  font-size: 12px;
}
.henaff-contact .gpdr-text a:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #000000;
  transform-origin: center;
  transition: transform 0.3s cubic-bezier(0.4, 0.5, 0.15, 1);
}
.henaff-contact .gpdr-text a:after {
  transform: scaleX(1);
}
.henaff-contact .gpdr-text a:hover:after {
  transform: scaleX(0);
}
.henaff-contact .contact-rich .contact-info {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-top: 1px solid #b88210;
}
.henaff-contact .contact-rich .info,
.henaff-contact .contact-rich .adress {
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
  min-height: 42px;
}
@media (min-width: 640px) {
  .henaff-contact .contact-rich .info,
.henaff-contact .contact-rich .adress {
    width: 50%;
  }
}
.henaff-contact .contact-rich .mail,
.henaff-contact .contact-rich .fax,
.henaff-contact .contact-rich .phone,
.henaff-contact .contact-rich .adress,
.henaff-contact .contact-rich .facebook {
  font-family: roboto, arial, helvetica, sans-serif;
  font-weight: 400;
  padding-left: 40px;
  margin: 15px 0;
}
.henaff-contact .contact-rich .mail a,
.henaff-contact .contact-rich .fax a,
.henaff-contact .contact-rich .phone a,
.henaff-contact .contact-rich .adress a,
.henaff-contact .contact-rich .facebook a {
  color: #00244c;
  text-decoration: none;
}
.henaff-contact .contact-rich .mail,
.henaff-contact .contact-rich .fax {
  background-image: svg-load("envelop.svg", fill=#b88210);
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 30px;
}
.henaff-contact .contact-rich .phone {
  background-image: svg-load("telephone.svg", fill=#b88210);
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 30px;
}
.henaff-contact .contact-rich .adress {
  background-image: svg-load("pin.svg", fill=#b88210);
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 30px;
}
.henaff-contact .contact-rich .facebook {
  height: 30px;
  line-height: 30px;
  background-image: svg-load("facebook-out.svg", fill=#b88210);
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 30px;
}

/**
 * ----------------------------------
 * PAge cms stylesheet
 * ----------------------------------
 */
.henaff-cms .cms-content h1:not(.text-uppercase), .henaff-cms .cms-content h2:not(.text-uppercase), .henaff-cms .cms-content h3:not(.text-uppercase), .henaff-cms .cms-content h4:not(.text-uppercase), .henaff-cms .cms-content h5:not(.text-uppercase), .henaff-cms .cms-content h6:not(.text-uppercase) {
  text-transform: none;
}
.henaff-cms .cms-content a {
  font-family: roboto, arial, helvetica, sans-serif;
  font-weight: 600;
  color: #000000;
  text-decoration: none;
  position: relative;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
}
.henaff-cms .cms-content a:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #b88210;
  transform-origin: center;
  transition: transform 0.3s cubic-bezier(0.4, 0.5, 0.15, 1);
}
.henaff-cms .cms-content a:after {
  transform: scaleX(1);
}
.henaff-cms .cms-content a:hover:after {
  transform: scaleX(0);
}
.henaff-cms .cms-content h3 {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 300;
  color: #b88210;
}
.henaff-cms .cms-content h4 {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #00244c;
}
@media (min-width: 1024px) {
  .henaff-cms .cms-content h4 {
    font-size: 18px;
  }
}
.henaff-cms .cms-content .border-top {
  padding-top: 15px;
  border-top: 1px solid #b88210;
}
@media (min-width: 768px) {
  .henaff-cms .cms-content .border-top {
    padding-top: calc(15px * 2);
  }
}
.henaff-cms .cms-content .image-content,
.henaff-cms .cms-content .video {
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .henaff-cms .cms-content .image-content,
.henaff-cms .cms-content .video {
    margin-bottom: 0;
  }
}
.henaff-cms .cms-content .image-text,
.henaff-cms .cms-content .video-text {
  padding: calc(15px * 2) 0;
}
@media (min-width: 768px) {
  .henaff-cms .cms-content .image-text,
.henaff-cms .cms-content .video-text {
    padding: calc(15px * 3) 0;
  }
}
.henaff-cms .cms-content .image-content {
  position: relative;
  padding-top: 70%;
}
.henaff-cms .cms-content .image-content .image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.henaff-cms .cms-content .image-content .image img {
  width: 100%;
  min-height: auto;
}
.henaff-cms .cms-content .section-header {
  text-align: center;
}
.henaff-cms .cms-content .section-column div[class*=col-] {
  margin-top: 15px;
}
@media (min-width: 768px) {
  .henaff-cms .cms-content .section-column div[class*=col-] {
    margin-top: calc(15px * 2);
  }
}
.henaff-cms .cms-content .loyalty-detail {
  max-width: 600px;
}

/**
 * ----------------------------------
 * Page 404 stylesheet
 * ----------------------------------
 */
.page-not-found .btn {
  color: #ffffff;
  background-color: #00244c;
  padding: 15px;
  border: 2px solid #00244c;
  font-size: 12px;
  line-height: normal;
  padding: calc(15px - 5px) calc(15px * 2);
}
.page-not-found .btn:disabled {
  background-color: #b2bdc9;
  border-color: #b2bdc9;
  cursor: not-allowed;
}
.page-not-found .btn:hover {
  color: #ffffff;
  background-color: #b88210;
  border-color: #b88210;
}
.page-not-found.page-content .container {
  position: relative;
}
.page-not-found .content {
  max-width: 100%;
  text-align: center;
  margin-bottom: calc(15px * 2);
}
@media (min-width: 1024px) {
  .page-not-found .content {
    text-align: left;
    position: absolute;
    top: 40%;
    left: 15px;
    max-width: 50%;
    z-index: 1;
    transform: translate3d(0, -50%, 0);
  }
}
.page-not-found .content h2 {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 24px;
  color: #00244c;
}
@media (min-width: 1024px) {
  .page-not-found .content h2 {
    font-size: 28px;
  }
}
.page-not-found .content p {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  color: #00244c;
  font-size: 18px;
}
.page-not-found .content .searchbar {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
}
@media (min-width: 1024px) {
  .page-not-found .content .searchbar {
    margin-left: 0;
    margin-right: 0;
  }
}
.page-not-found .content .searchbar input[type=text] {
  padding: 11px 40px 10px 15px;
  width: 100%;
}
.page-not-found .content .searchbar button {
  background-color: #b88210;
  padding: 5px;
  width: 40px;
  height: 40px;
  border: none;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}
.page-not-found .content .searchbar .search {
  background-image: svg-load("search.svg", fill=#ffffff);
  width: 30px;
  height: 30px;
}
.page-not-found .background-wrapper {
  position: relative;
  padding-top: 75%;
}
@media (min-width: 1024px) {
  .page-not-found .background-wrapper {
    right: calc((calc(100vw - var(--scrollbar-width)) - 930px) / -2);
    width: 90%;
    margin-left: auto;
  }
}
@media (min-width: 1200px) {
  .page-not-found .background-wrapper {
    right: calc((calc(100vw - var(--scrollbar-width)) - 1140px) / -2);
  }
}
@media (min-width: 1600px) {
  .page-not-found .background-wrapper {
    right: -25%;
  }
}
.page-not-found .background-wrapper .image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.page-not-found .background-wrapper .image img {
  height: 100%;
  min-width: auto;
}
.page-not-found .block-reassurance {
  display: none;
}
.page-not-found #main-footer {
  margin: 0;
}

.page-search .product-list .title {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 24px;
  text-transform: uppercase;
  color: #00244c;
  margin-bottom: calc(15px * 2);
}
@media (min-width: 1024px) {
  .page-search .product-list .title {
    font-size: 28px;
  }
}
.page-search .product-list .search-result-content {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  font-size: 16px;
}

/**
 * ----------------------------------
 * Sitemap stylesheet
 * ----------------------------------
 */
.sitemap .title {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  color: #00244c;
  font-size: 12px;
  padding: 5px 0;
  border-bottom: 1px solid #b88210;
  text-transform: uppercase;
  margin-bottom: 15px;
}
.sitemap a {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 400;
  background-image: svg-load("turn-right.svg", fill=#b88210);
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: 12px;
  color: #000000;
  text-decoration: none;
  transition: 0.3s all cubic-bezier(0.4, 0.5, 0.15, 1);
  font-size: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 18px;
}
.sitemap a:hover {
  color: #b88210;
}
.sitemap ul {
  padding-left: 15px;
}

/**
 * ----------------------------------
 * Cart stylesheet
 * ----------------------------------
 */
.cart-slide-product {
  margin-top: calc(15px * 2);
}
@media (min-width: 768px) {
  .cart-slide-product {
    margin-top: calc(15px * 4);
  }
}
.cart-slide-product .title {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: #00244c;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 15px;
}
@media (min-width: 1024px) {
  .cart-slide-product .title {
    font-size: 24px;
  }
}
@media (min-width: 768px) {
  .cart-slide-product .title {
    margin-bottom: 0;
  }
}

.fix-cart-detailed-actions {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 5px 10px;
  background-color: #ffffff;
  display: block;
  z-index: 100;
  box-shadow: 0 -2px 16px 0 rgba(0, 0, 0, 0.2);
}
@media (min-width: 768px) {
  .fix-cart-detailed-actions {
    display: none;
  }
}
.fix-cart-detailed-actions .btn {
  font-size: 14px;
  line-height: 21px;
  width: 100%;
}
.fix-cart-detailed-actions .btn-primary {
  color: #ffffff;
  background-color: #00244c;
  padding: 15px;
  border: 2px solid #00244c;
  font-size: 12px;
  line-height: normal;
  padding: 10px 15px;
}
.fix-cart-detailed-actions .btn-primary:disabled {
  background-color: #b2bdc9;
  border-color: #b2bdc9;
  cursor: not-allowed;
}
.fix-cart-detailed-actions .btn-primary:hover {
  color: #ffffff;
  background-color: #b88210;
  border-color: #b88210;
}

.subscribe-fid {
  color: #00244c;
  background-color: transparent;
  padding: 15px;
  border: 2px solid #00244c;
  font-size: 12px;
  line-height: normal;
  font-size: 14px;
  line-height: 21px;
  padding: 8px 15px;
  width: 150px;
}
.subscribe-fid:hover {
  color: #b88210;
  background-color: transparent;
  border-color: #b88210;
}

.cart-content .title-section {
  margin-bottom: calc(15px * 2);
}
.cart-content .title-section .label {
  text-transform: none;
  font-size: 18px;
}
.cart-content .cart-grid-body {
  margin-bottom: 15px;
}
@media (min-width: 1024px) {
  .cart-content .cart-grid-body {
    margin-bottom: 0;
  }
}
.cart-content .cart-body {
  position: relative;
  margin-bottom: calc(15px * 2);
}
.cart-content .cart-detailed-totals--wrapper {
  padding: 15px;
  border: 2px solid #b88210;
  margin-bottom: 15px;
}
.cart-content .cart-detailed-totals--wrapper .cart-detailed-totals {
  padding: 0;
  border: none;
  margin-bottom: 0;
}
.cart-content .cart-message button {
  background-color: transparent;
  border: none;
  padding: 8px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate3d(0, -47%, 0);
  z-index: 1;
}
@media (min-width: 768px) {
  .cart-content .cart-message button {
    left: 15px;
  }
}
.cart-content .cart-message .close {
  background-image: svg-load("close.svg", fill=#ffffff);
  width: 12px;
  height: 12px;
  margin-right: 0;
}
@media (min-width: 768px) {
  .cart-content .cart-message .close {
    width: 14px;
    height: 14px;
  }
}
.cart-content .cart-message .message {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 300;
  position: relative;
  padding: 8px 8px 8px 25px;
  color: #ffffff;
  background-color: #00244c;
  font-size: 11px;
}
@media (min-width: 768px) {
  .cart-content .cart-message .message {
    font-size: 14px;
    padding: 10px 15px;
    text-align: center;
  }
}
.cart-content .cart-message .message p {
  margin-bottom: 0;
}
.cart-content .cart-message .message strong {
  font-size: 12px;
}
@media (min-width: 768px) {
  .cart-content .cart-message .message strong {
    font-size: 16px;
  }
}
.cart-content .cart-message .message:before {
  content: "";
  width: 70%;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 50%;
  background-color: rgba(255, 255, 255, 0.3);
  transform: translate3d(-50%, 0, 0);
}
.cart-content .cart-overview .no-items {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  padding: 15px;
  background-color: #f9f2e8;
  color: #00244c;
  display: block;
  margin-bottom: calc(15px * 2);
}
.cart-content .cart-overview .cart-items .cart-item {
  padding: 15px 0;
  border-top: 1px solid #f9f2e8;
}
.cart-content .cart-overview .cart-items .cart-item:first-child {
  border: none;
}
.cart-content .cart-overview .product-line-grid .product-image {
  position: relative;
  width: 100%;
}
.cart-content .cart-overview .product-line-grid .product-image:before {
  content: "";
  display: block;
  padding-top: 100%;
}
.cart-content .cart-overview .product-line-grid .product-image .image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.cart-content .cart-overview .product-line-grid .product-image .image img {
  width: 100%;
  min-height: auto;
}
@media (min-width: 768px) {
  .cart-content .cart-overview .product-line-grid .product-image {
    width: 125px;
  }
}
.cart-content .cart-overview .product-line-grid .product-line-info {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 400;
  margin-bottom: 5px;
}
.cart-content .cart-overview .product-line-grid .product-line-info .value {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 600;
  color: #00244c;
}
.cart-content .cart-overview .product-line-grid .product-line-info.product-name {
  margin-top: 15px;
  font-size: 16px;
}
.cart-content .cart-overview .product-line-grid .product-line-info.product-name a {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  color: #00244c;
  text-decoration: none;
}
.cart-content .cart-overview .product-line-grid .product-line-info.product-name .separator {
  display: inline;
}
@media (min-width: 640px) {
  .cart-content .cart-overview .product-line-grid .product-line-info.product-name .separator {
    display: none;
  }
}
.cart-content .cart-overview .product-line-grid .product-line-info.product-name .price {
  font-weight: bold;
  display: inline;
}
@media (min-width: 640px) {
  .cart-content .cart-overview .product-line-grid .product-line-info.product-name .price {
    display: none;
  }
}
.cart-content .cart-overview .product-line-grid .product-line-info.product-price .product-discount .regular-price {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  position: relative;
  color: #00244c;
}
.cart-content .cart-overview .product-line-grid .product-line-info.product-price .product-discount .regular-price:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  background: #00244c;
  transform: rotate(-12deg);
}
.cart-content .cart-overview .product-line-grid .product-line-info.product-price .product-discount .discount {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  color: #b88210;
}
.cart-content .cart-overview .product-line-grid .product-line-info.product-price .current-price {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 24px;
  color: #00244c;
  display: none;
}
@media (min-width: 640px) {
  .cart-content .cart-overview .product-line-grid .product-line-info.product-price .current-price {
    display: block;
  }
}
.cart-content .cart-overview .product-line-grid .product-line-actions {
  padding-top: 15px;
}
.cart-content .cart-overview .product-line-grid .product-line-actions .price {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 24px;
  color: #00244c;
}
.cart-content .cart-overview .product-line-grid .product-line-actions .cart-line-product-actions a {
  vertical-align: middle;
  line-height: 1;
}
.cart-content .cart-overview .product-line-grid .product-line-actions .cart-line-product-actions .icon {
  width: 24px;
  height: 24px;
}
@media (min-width: 640px) {
  .cart-content .cart-overview .product-line-grid .product-line-actions .cart-line-product-actions .icon {
    width: 18px;
    height: 18px;
  }
}
.cart-content .cart-summary-subtotal-discount .value {
  color: #b88210;
}
.cart-content .cart-empty-actions .btn {
  font-size: 14px;
  line-height: 21px;
  width: 100%;
  margin-top: 15px;
}
.cart-content .cart-empty-actions .btn-primary {
  color: #ffffff;
  background-color: #f8fbff;
  padding: 15px;
  border: 2px solid #f8fbff;
  font-size: 12px;
  line-height: normal;
}
.cart-content .cart-empty-actions .btn-primary:disabled {
  background-color: #b2bdc9;
  border-color: #b2bdc9;
  cursor: not-allowed;
}
.cart-content .cart-empty-actions .btn-primary:hover {
  color: #ffffff;
  background-color: #b88210;
  border-color: #b88210;
}
.cart-content .cart-detailed-actions .btn {
  font-size: 14px;
  line-height: 21px;
  width: 100%;
  margin-top: 15px;
}
.cart-content .cart-detailed-actions .btn-primary {
  color: #ffffff;
  background-color: #00244c;
  padding: 15px;
  border: 2px solid #00244c;
  font-size: 12px;
  line-height: normal;
}
.cart-content .cart-detailed-actions .btn-primary:disabled {
  background-color: #b2bdc9;
  border-color: #b2bdc9;
  cursor: not-allowed;
}
.cart-content .cart-detailed-actions .btn-primary:hover {
  color: #ffffff;
  background-color: #b88210;
  border-color: #b88210;
}
.cart-content .block-promo .cart-voucher-list {
  margin-bottom: 15px;
  padding: 15px calc(15px * 2);
  background-color: #00244c;
}
.cart-content .block-promo .cart-voucher-list .title {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  color: #b88210;
  font-size: 22px;
}
.cart-content .block-promo .cart-voucher-list .card-block li .label {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  color: #b88210;
  font-size: 14px;
  padding-bottom: 5px;
  margin-bottom: 10px;
  border-bottom: 1px solid #b88210;
}
.cart-content .block-promo .cart-voucher-list .card-block li .value {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 600;
  text-align: right;
  color: #ffffff;
  font-size: 16px;
}
.cart-content .block-promo .cart-voucher .promo-highlighted {
  margin-bottom: calc(15px * 2);
}
.cart-content .block-promo .cart-voucher .promo-highlighted .libelle {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  color: #b88210;
}
.cart-content .block-promo .cart-voucher .promo-highlighted .discount-list-item {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 600;
  padding: 5px 0;
  text-transform: uppercase;
  font-size: 14px;
  border-bottom: 1px solid rgba(184, 130, 16, 0.3);
  color: #00244c;
}
.cart-content .continue_shopping-link {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  color: #00244c;
  text-decoration: none;
  position: relative;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
  background-image: svg-load("slide-prev.svg", fill=#00244c);
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: 12px;
  padding-left: 14px;
  margin-bottom: 15px;
  display: inline-block;
}
.cart-content .continue_shopping-link:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #00244c;
  transform-origin: center;
  transition: transform 0.3s cubic-bezier(0.4, 0.5, 0.15, 1);
}
.cart-content .continue_shopping-link:after {
  transform: scaleX(1);
}
.cart-content .continue_shopping-link:hover:after {
  transform: scaleX(0);
}
.cart-content .refund-policy-link {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  color: #00244c;
  text-decoration: none;
  position: relative;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 15px;
  display: inline-block;
}
.cart-content .refund-policy-link:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #00244c;
  transform-origin: center;
  transition: transform 0.3s cubic-bezier(0.4, 0.5, 0.15, 1);
}
.cart-content .refund-policy-link:after {
  transform: scaleX(1);
}
.cart-content .refund-policy-link:hover:after {
  transform: scaleX(0);
}
.cart-content .block-promo--wrapper {
  padding: 15px calc(15px - 5px);
  border: 2px solid #b88210;
  margin-bottom: calc(15px * 2);
}
@media (min-width: 768px) {
  .cart-content .block-promo--wrapper {
    padding: 15px calc(15px + 5px);
  }
}
.cart-content .block-promo--wrapper .block-cart-loyalty {
  margin-top: 15px;
}
.cart-content .block-promo--wrapper .block-promo .alert,
.cart-content .block-promo--wrapper .block-cart-loyalty .alert {
  margin-top: 15px;
}
.cart-content .form-voucher,
.cart-content .form-loyalty {
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: calc(15px * 2);
}
@media (min-width: 1024px) {
  .cart-content .form-voucher,
.cart-content .form-loyalty {
    flex-wrap: nowrap;
  }
}
.cart-content .form-voucher label,
.cart-content .form-loyalty label {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  position: relative;
  color: #00244c;
  margin-bottom: 15px;
  padding-right: 40px;
}
@media (min-width: 1024px) {
  .cart-content .form-voucher label,
.cart-content .form-loyalty label {
    margin-bottom: 0;
  }
}
.cart-content .form-voucher .btn,
.cart-content .form-loyalty .btn {
  color: #00244c;
  background-color: transparent;
  padding: 15px;
  border: 2px solid #00244c;
  font-size: 12px;
  line-height: normal;
  font-size: 14px;
  line-height: 21px;
  padding: 8px 15px;
  width: 150px;
}
.cart-content .form-voucher .btn:hover,
.cart-content .form-loyalty .btn:hover {
  color: #b88210;
  background-color: transparent;
  border-color: #b88210;
}
.cart-content .form-voucher .tip,
.cart-content .form-loyalty .tip {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate3d(0, -9px, 0);
}
.cart-content .form-voucher .tip span,
.cart-content .form-loyalty .tip span {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 600;
  width: 20px;
  height: 20px;
  color: #ffffff;
  background-color: #00244c;
  border-radius: 10px;
  padding: 3px;
  display: block;
  font-size: 12px;
}
.cart-content .form-voucher .promo-input,
.cart-content .form-voucher .loyalty-input,
.cart-content .form-loyalty .promo-input,
.cart-content .form-loyalty .loyalty-input {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  color: #00244c;
  border: 1px solid #00244c;
  margin-right: 15px;
  width: calc(100% - 165px);
}
.cart-content .form-voucher .voucher-input--wrapper,
.cart-content .form-loyalty .voucher-input--wrapper {
  align-items: center;
  flex-grow: 1;
  flex-shrink: 0;
  width: 100%;
}
@media (min-width: 768px) {
  .cart-content .form-voucher .voucher-input--wrapper,
.cart-content .form-loyalty .voucher-input--wrapper {
    width: auto;
  }
}

/**
 * ----------------------------------
 * Checkout stylessheet
 * ----------------------------------
 */
.checkout-content .checkout-right-column {
  display: none;
}
.checkout-content .return_cart-link {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  color: #00244c;
  text-decoration: none;
  position: relative;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
  background-image: svg-load("slide-prev.svg", fill=#00244c);
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: 12px;
  padding-left: 14px;
}
.checkout-content .return_cart-link:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #00244c;
  transform-origin: center;
  transition: transform 0.3s cubic-bezier(0.4, 0.5, 0.15, 1);
}
.checkout-content .return_cart-link:after {
  transform: scaleX(1);
}
.checkout-content .return_cart-link:hover:after {
  transform: scaleX(0);
}
.checkout-content .cart-summary-line.cart-summary-article {
  margin-bottom: 15px;
}
.checkout-content .cart-summary-line.cart-summary-article a {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  color: #b88210;
  text-decoration: none;
  position: relative;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
}
.checkout-content .cart-summary-line.cart-summary-article a:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #b88210;
  transform-origin: center;
  transition: transform 0.3s cubic-bezier(0.4, 0.5, 0.15, 1);
}
.checkout-content .cart-summary-line.cart-summary-article a:after {
  transform: scaleX(1);
}
.checkout-content .cart-summary-line.cart-summary-article a:hover:after {
  transform: scaleX(0);
}
.checkout-content .cart-summary-product-list .media-list .media {
  display: flex;
}
.checkout-content .cart-summary-product-list .media-list .media-left {
  flex-grow: 0;
  flex-shrink: 0;
  width: 80px;
}
.checkout-content .cart-summary-product-list .media-list .media-left img {
  width: 100%;
  height: auto;
}
.checkout-content .cart-summary-product-list .media-list .media-body {
  flex-grow: 1;
  flex-shrink: 0;
  width: 70%;
  padding-left: 15px;
}
.checkout-content .cart-summary-product-list .media-list .media .product-name {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  color: #00244c;
}
.checkout-content .cart-summary-product-list .media-list .media .product-quantity {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 600;
  float: right;
  color: #b88210;
}
.checkout-content .cart-summary-product-list .media-list .media .product-price {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  display: block;
  color: #b88210;
  margin-top: 5px;
}
.checkout-content .cart-summary-product-list .media-list .media .product-line-info {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 400;
  font-size: 12px;
}
.checkout-content .checkout-voucher .promo-list {
  padding: 0 15px;
  background-color: #f9f2e8;
}
.checkout-content .checkout-voucher .d-flex {
  padding-top: 5px;
  border-top: 1px solid #b88210;
}
.checkout-content .checkout-voucher .label {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 14px;
  padding: 5px 0;
}
.checkout-content .checkout-voucher .value {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  font-size: 14px;
}
.checkout-content .checkout-voucher .delete-action {
  line-height: 18px;
}
.checkout-content .checkout-voucher .delete-action a {
  color: #00244c;
  font-size: 11px;
}
.checkout-content .checkout-voucher .delete-action .icon {
  top: 2px;
}
.checkout-content .checkout-voucher-list .voucher-text {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 400;
  color: #00244c;
  padding: 15px 0;
  border-top: 1px solid #b88210;
}
.checkout-content .checkout-voucher-list .voucher-list {
  padding: 15px calc(15px * 2);
  background-color: #00244c;
}
.checkout-content .checkout-voucher-list .voucher-list li:not(:last-child) {
  margin-bottom: 10px;
}
.checkout-content .checkout-voucher-list .voucher-list .label {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 600;
  color: #b88210;
  border-bottom: 1px solid #b88210;
  text-transform: uppercase;
}
.checkout-content .checkout-voucher-list .voucher-list .value {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 600;
  color: #ffffff;
  text-transform: uppercase;
  padding: 5px 0;
}
.checkout-content .checkout-voucher-list .return-shipment {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 400;
  color: #00244c;
  margin: 15px 0;
  padding: 15px 0;
  border-top: 1px solid #b88210;
}
.checkout-content .checkout-voucher-list .return-shipment .title {
  text-transform: uppercase;
}
.checkout-content .checkout-step {
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .checkout-content .checkout-step {
    margin-bottom: calc(15px * 2);
  }
}
.checkout-content .checkout-step.-current {
  border: 2px solid #b88210;
}
.checkout-content .checkout-step.-current .step-title {
  border-color: rgba(184, 130, 16, 0.3);
}
.checkout-content .checkout-step.-current .step-title .step-edit {
  display: none;
}
.checkout-content .checkout-step.-unreachable {
  opacity: 0.5;
}
.checkout-content .checkout-step .loyalty-registration-form {
  background-color: #f9f2e8;
  padding: 15px;
  margin-bottom: calc(15px * 2);
}
.checkout-content .checkout-step .loyalty-registration-form label {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  color: #00244c;
  font-size: 14px;
  line-height: 1;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .checkout-content .checkout-step .loyalty-registration-form label {
    font-size: 16px;
  }
}
.checkout-content .checkout-step .loyalty-registration-form .form-group {
  margin-bottom: 15px;
}
.checkout-content .checkout-step .loyalty-registration-form .title {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #00244c;
}
.checkout-content .checkout-step .have-loyalty-card .label {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  color: #00244c;
  font-size: 14px;
  line-height: 1;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .checkout-content .checkout-step .have-loyalty-card .label {
    font-size: 16px;
  }
}
.checkout-content .checkout-step .have-loyalty-card .code,
.checkout-content .checkout-step .have-loyalty-card .description {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  color: #00244c;
  display: block;
}
.checkout-content .checkout-step .have-loyalty-card .code {
  font-size: 14px;
  padding-bottom: 5px;
  background-color: transparent;
  border: none;
  width: 100%;
}
@media (min-width: 768px) {
  .checkout-content .checkout-step .have-loyalty-card .code {
    font-size: 16px;
  }
}
.checkout-content .checkout-step .have-loyalty-card .description {
  padding-top: 5px;
  font-size: 12px;
  border-top: 1px solid #b88210;
}
.checkout-content .checkout-step .have-loyalty-card .have-account {
  margin-top: 15px;
  display: block;
}
.checkout-content .checkout-step .have-loyalty-card .have-account a {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  color: #00244c;
  text-decoration: none;
  position: relative;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
}
.checkout-content .checkout-step .have-loyalty-card .have-account a:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #00244c;
  transform-origin: center;
  transition: transform 0.3s cubic-bezier(0.4, 0.5, 0.15, 1);
}
.checkout-content .checkout-step .have-loyalty-card .have-account a:after {
  transform: scaleX(1);
}
.checkout-content .checkout-step .have-loyalty-card .have-account a:hover:after {
  transform: scaleX(0);
}
.checkout-content .checkout-step .step-content {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 400;
  padding-top: 15px;
  padding-bottom: 15px;
}
@media (min-width: 768px) {
  .checkout-content .checkout-step .step-content {
    padding-top: calc(15px * 2);
    padding-bottom: calc(15px * 2);
  }
}
.checkout-content .checkout-step .step-content .identity a,
.checkout-content .checkout-step .step-content .logout a {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  color: #b88210;
  text-decoration: none;
  position: relative;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  line-height: 21px;
}
.checkout-content .checkout-step .step-content .identity a:after,
.checkout-content .checkout-step .step-content .logout a:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #b88210;
  transform-origin: center;
  transition: transform 0.3s cubic-bezier(0.4, 0.5, 0.15, 1);
}
.checkout-content .checkout-step .step-content .identity a:after,
.checkout-content .checkout-step .step-content .logout a:after {
  transform: scaleX(1);
}
.checkout-content .checkout-step .step-content .identity a:hover:after,
.checkout-content .checkout-step .step-content .logout a:hover:after {
  transform: scaleX(0);
}
.checkout-content .checkout-step .step-title {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 18px;
  position: relative;
  color: #00244c;
  text-transform: uppercase;
  padding: 15px calc(15px + 5px);
  border-bottom: 1px solid #b88210;
}
@media (min-width: 1024px) {
  .checkout-content .checkout-step .step-title {
    font-size: 24px;
  }
}
.checkout-content .checkout-step .step-subtitle {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  color: #b88210;
  margin-bottom: calc(15px * 2);
  margin-top: 15px;
  text-align: center;
}
@media (min-width: 1024px) {
  .checkout-content .checkout-step .step-subtitle {
    font-size: 18px;
  }
}
.checkout-content .checkout-step .step-number {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  color: #b88210;
}
.checkout-content .checkout-step .step-edit {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 600;
  color: #808080;
  font-size: 14px;
  text-transform: none;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate3d(0, -50%, 0);
  cursor: pointer;
}
.checkout-content .checkout-step .step-edit span {
  display: none;
}
@media (min-width: 768px) {
  .checkout-content .checkout-step .step-edit span {
    display: inline;
  }
}

#checkout-personal-information-step .step-content {
  padding: 15px 10px;
}
@media (min-width: 768px) {
  #checkout-personal-information-step .step-content {
    padding: calc(15px * 2);
    margin: auto;
  }
}
@media (min-width: 1024px) {
  #checkout-personal-information-step .step-content {
    width: 70%;
  }
}
#checkout-personal-information-step .nav-tabs {
  display: flex;
  flex-wrap: nowrap;
}
#checkout-personal-information-step .nav-item {
  flex-grow: 0;
  flex-shrink: 0;
  width: 50%;
}
#checkout-personal-information-step .nav-link {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 14px;
  text-decoration: none;
  padding: 15px;
  color: #00244c;
  display: block;
  text-align: center;
  border-bottom: 1px solid #b2bdc9;
  height: 100%;
}
#checkout-personal-information-step .nav-link.active {
  border-color: #b2bdc9;
  border-width: 1px;
  border-style: solid;
  border-bottom: none;
}
#checkout-personal-information-step .tab-content {
  border-color: #b2bdc9;
  border-width: 1px;
  border-style: solid;
  border-top: none;
}
#checkout-personal-information-step .tab-content .tab-pane {
  display: none;
  padding: calc(15px * 2) 10px;
}
@media (min-width: 768px) {
  #checkout-personal-information-step .tab-content .tab-pane {
    padding: calc(15px * 2) 15px;
  }
}
#checkout-personal-information-step .tab-content .tab-pane.active {
  display: block;
}
#checkout-personal-information-step .btn {
  color: #ffffff;
  background-color: #00244c;
  padding: 15px;
  border: 2px solid #00244c;
  font-size: 12px;
  line-height: normal;
  padding: 15px calc(15px * 2);
}
#checkout-personal-information-step .btn:disabled {
  background-color: #b2bdc9;
  border-color: #b2bdc9;
  cursor: not-allowed;
}
#checkout-personal-information-step .btn:hover {
  color: #ffffff;
  background-color: #b88210;
  border-color: #b88210;
}
#checkout-personal-information-step .help-block .error {
  margin-bottom: 15px;
}

#checkout-addresses-step .d-flex,
#checkout-delivery-step .d-flex {
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  #checkout-addresses-step .customer-address--wrapper,
#checkout-addresses-step .invoice-address--wrapper,
#checkout-addresses-step .relay-delivery-option--wrapper,
#checkout-addresses-step .home-delivery-option--wrapper,
#checkout-delivery-step .customer-address--wrapper,
#checkout-delivery-step .invoice-address--wrapper,
#checkout-delivery-step .relay-delivery-option--wrapper,
#checkout-delivery-step .home-delivery-option--wrapper {
    padding: 0 calc(15px * 2);
  }
}
#checkout-addresses-step .customer-address--wrapper,
#checkout-addresses-step .relay-delivery-option--wrapper,
#checkout-delivery-step .customer-address--wrapper,
#checkout-delivery-step .relay-delivery-option--wrapper {
  position: relative;
}
#checkout-addresses-step .customer-address--wrapper:after,
#checkout-addresses-step .relay-delivery-option--wrapper:after,
#checkout-delivery-step .customer-address--wrapper:after,
#checkout-delivery-step .relay-delivery-option--wrapper:after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 1px;
  height: 90%;
  background-color: #f9f2e8;
  visibility: hidden;
}
@media (min-width: 768px) {
  #checkout-addresses-step .customer-address--wrapper:after,
#checkout-addresses-step .relay-delivery-option--wrapper:after,
#checkout-delivery-step .customer-address--wrapper:after,
#checkout-delivery-step .relay-delivery-option--wrapper:after {
    visibility: visible;
  }
}
#checkout-addresses-step .customer-address--wrapper:before,
#checkout-addresses-step .relay-delivery-option--wrapper:before,
#checkout-delivery-step .customer-address--wrapper:before,
#checkout-delivery-step .relay-delivery-option--wrapper:before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  width: calc(100% - 20px);
  height: 1px;
  transform: translate3d(-50%, 0, 0);
  background-color: #f9f2e8;
  visibility: visible;
}
@media (min-width: 768px) {
  #checkout-addresses-step .customer-address--wrapper:before,
#checkout-addresses-step .relay-delivery-option--wrapper:before,
#checkout-delivery-step .customer-address--wrapper:before,
#checkout-delivery-step .relay-delivery-option--wrapper:before {
    visibility: hidden;
  }
}

#checkout-addresses-step .content {
  padding: 15px 0;
}
@media (min-width: 768px) {
  #checkout-addresses-step .content {
    padding: calc(15px * 2) 0;
  }
}
#checkout-addresses-step .customer-address--wrapper,
#checkout-addresses-step .invoice-address--wrapper {
  padding-left: 10px;
  padding-right: 10px;
}
@media (min-width: 768px) {
  #checkout-addresses-step .customer-address--wrapper,
#checkout-addresses-step .invoice-address--wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }
}
#checkout-addresses-step .customer-address--wrapper .add-customer-address {
  text-align: center;
  margin-bottom: calc(15px * 2);
}
#checkout-addresses-step .customer-address--wrapper .add-customer-address a {
  color: #00244c;
  background-color: transparent;
  padding: 15px;
  border: 2px solid #00244c;
  font-size: 12px;
  line-height: normal;
  padding: 10px 15px;
  font-size: 12px;
  width: 100%;
}
#checkout-addresses-step .customer-address--wrapper .add-customer-address a:hover {
  color: #b88210;
  background-color: transparent;
  border-color: #b88210;
}
@media (min-width: 640px) {
  #checkout-addresses-step .customer-address--wrapper .add-customer-address a {
    font-size: 14px;
    width: auto;
  }
}
@media (min-width: 768px) {
  #checkout-addresses-step .customer-address--wrapper .add-customer-address a {
    padding: 10px calc(15px * 2);
  }
}
#checkout-addresses-step .customer-address--wrapper .add-customer-address .add {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  font-style: normal;
  color: #00244c;
  padding-right: 10px;
}
#checkout-addresses-step .invoice-address--wrapper {
  margin-top: calc(15px * 2);
}
@media (min-width: 768px) {
  #checkout-addresses-step .invoice-address--wrapper {
    margin-top: 0;
  }
}
#checkout-addresses-step .invoice-address--wrapper .add-invoice-address,
#checkout-addresses-step .invoice-address--wrapper .different-invoice-address {
  margin-top: calc(15px * 2);
  text-align: center;
}
@media (min-width: 768px) {
  #checkout-addresses-step .invoice-address--wrapper .add-invoice-address,
#checkout-addresses-step .invoice-address--wrapper .different-invoice-address {
    text-align: left;
  }
}
#checkout-addresses-step .invoice-address--wrapper .add-invoice-address a,
#checkout-addresses-step .invoice-address--wrapper .different-invoice-address a {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 600;
  color: #00244c;
  text-decoration: underline;
  font-size: 12px;
}
#checkout-addresses-step .invoice-address--wrapper .add-invoice-address a:hover,
#checkout-addresses-step .invoice-address--wrapper .different-invoice-address a:hover {
  text-decoration: none;
}
@media (min-width: 768px) {
  #checkout-addresses-step .invoice-address--wrapper .add-invoice-address a,
#checkout-addresses-step .invoice-address--wrapper .different-invoice-address a {
    color: #00244c;
    text-decoration: none;
    position: relative;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 14px;
    line-height: 21px;
    font-size: 14px;
  }
  #checkout-addresses-step .invoice-address--wrapper .add-invoice-address a:after,
#checkout-addresses-step .invoice-address--wrapper .different-invoice-address a:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #00244c;
    transform-origin: center;
    transition: transform 0.3s cubic-bezier(0.4, 0.5, 0.15, 1);
  }
  #checkout-addresses-step .invoice-address--wrapper .add-invoice-address a:after,
#checkout-addresses-step .invoice-address--wrapper .different-invoice-address a:after {
    transform: scaleX(1);
  }
  #checkout-addresses-step .invoice-address--wrapper .add-invoice-address a:hover:after,
#checkout-addresses-step .invoice-address--wrapper .different-invoice-address a:hover:after {
    transform: scaleX(0);
  }
}
#checkout-addresses-step .invoice-address--wrapper .invoice-address-text {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 600;
  color: #00244c;
}
#checkout-addresses-step .form-footer .btn {
  min-width: 250px;
  margin-bottom: 15px;
}
#checkout-addresses-step .form-footer.customer-address-footer {
  padding: 15px 0;
}
#checkout-addresses-step .form-footer.checkout-address-footer {
  padding: 0 10px;
}
#checkout-addresses-step .address-selector {
  margin-bottom: calc(15px * 2);
}
#checkout-addresses-step .address-selector .address-item.selected label {
  border-color: #b88210;
  border-width: 2px;
}
#checkout-addresses-step .address-selector .address-item.disable-interaction {
  opacity: 0.3;
}
#checkout-addresses-step .address-selector .address-item.disable-interaction label {
  cursor: not-allowed;
}
#checkout-addresses-step .address-selector .address-item.disable-interaction label:hover {
  box-shadow: none;
}
#checkout-addresses-step .address-selector .address-item.disable-interaction input[type=radio],
#checkout-addresses-step .address-selector .address-item.disable-interaction .custom-radio {
  cursor: not-allowed;
}
#checkout-addresses-step .address-selector .address-item label {
  cursor: pointer;
  padding: calc(15px * 2) 15px;
  border: 1px solid #b2bdc9;
  margin-bottom: 15px;
  transition: all 0.3s cubic-bezier(0.4, 0.5, 0.15, 1);
  display: block;
}
@media (min-width: 768px) {
  #checkout-addresses-step .address-selector .address-item label {
    padding: calc(15px * 2);
  }
}
#checkout-addresses-step .address-selector .address-item label:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
#checkout-addresses-step .address-selector .address-item address {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  margin: 15px 0;
  padding-left: 25px;
  color: #00244c;
  font-style: normal;
  font-size: 16px;
}
#checkout-addresses-step .address-selector .address-item .address-alias {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 22px;
  color: #00244c;
}
#checkout-addresses-step .address-selector .address-item .address-footer {
  padding-top: 10px;
  margin-left: 25px;
  border-top: 1px solid #b88210;
}
#checkout-addresses-step .address-selector .address-item .address-footer a {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 600;
  color: #808080;
  text-decoration: none;
  font-size: 12px;
  margin-right: 10px;
}
#checkout-addresses-step .address-selector .address-item .address-footer a:after {
  display: none;
}

#checkout-delivery-step .delivery-option.active label {
  border-color: #b88210;
}
#checkout-delivery-step .delivery-option label {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  color: #00244c;
  padding: 15px 10px;
  border: 1px solid #b2bdc9;
  margin-bottom: 15px;
  display: block;
  cursor: pointer;
}
@media (min-width: 640px) {
  #checkout-delivery-step .delivery-option label {
    padding: calc(15px * 2) 15px;
  }
}
#checkout-delivery-step .delivery-option .custom-radio {
  width: 10%;
  height: 40px;
  flex-grow: 0;
  flex-shrink: 0;
  padding-left: 30px;
}
@media (min-width: 768px) {
  #checkout-delivery-step .delivery-option .custom-radio {
    width: 40px;
  }
}
@media (min-width: 768px) {
  #checkout-delivery-step .delivery-option .custom-radio span {
    width: 28px;
    height: 28px;
  }
}
@media (min-width: 768px) {
  #checkout-delivery-step .delivery-option .custom-radio span:before {
    width: 15px;
    height: 15px;
  }
}
#checkout-delivery-step .delivery-option .icon {
  width: 10%;
  height: 40px;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 10px;
  background-position: center;
  display: none;
}
@media (min-width: 768px) {
  #checkout-delivery-step .delivery-option .icon {
    margin-right: 15px;
    display: block;
  }
}
#checkout-delivery-step .delivery-option .carrier-delay {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  color: #00244c;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 10px;
  font-size: 14px;
  cursor: pointer;
  width: 64%;
}
@media (min-width: 768px) {
  #checkout-delivery-step .delivery-option .carrier-delay {
    width: 53%;
    padding-right: 15px;
  }
}
#checkout-delivery-step .delivery-option .carrier-price {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  color: #00244c;
  font-size: 14px;
  flex-grow: 0;
  flex-shrink: 0;
  width: 22%;
  text-align: right;
}
@media (min-width: 640px) {
  #checkout-delivery-step .delivery-option .carrier-price {
    font-size: 16px;
  }
}
#checkout-delivery-step .order-options label {
  display: inline-block;
  margin-bottom: 15px;
}
#checkout-delivery-step .order-options textarea {
  width: 100%;
}
#checkout-delivery-step .relay-delivery-option--wrapper {
  padding-bottom: 15px;
  padding-left: 10px;
  padding-right: 10px;
}
@media (min-width: 768px) {
  #checkout-delivery-step .relay-delivery-option--wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (min-width: 1024px) {
  #checkout-delivery-step .relay-delivery-option--wrapper {
    padding-bottom: 0;
  }
}
#checkout-delivery-step .home-delivery-option--wrapper {
  padding-top: 15px;
  padding-left: 10px;
  padding-right: 10px;
}
@media (min-width: 768px) {
  #checkout-delivery-step .home-delivery-option--wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (min-width: 1024px) {
  #checkout-delivery-step .home-delivery-option--wrapper {
    padding-top: 0;
  }
}
#checkout-delivery-step .delivery-option-setting {
  padding-left: 10px;
  padding-right: 10px;
}
@media (min-width: 768px) {
  #checkout-delivery-step .delivery-option-setting {
    padding-left: 15px;
    padding-right: 15px;
  }
}
#checkout-delivery-step .delivery-options-list .form-footer {
  padding: 0 10px;
}
@media (min-width: 768px) {
  #checkout-delivery-step .delivery-options-list .form-footer {
    padding: 0 15px;
  }
}

#checkout-payment-step .step-content {
  padding-left: 10px;
  padding-right: 10px;
}
@media (min-width: 768px) {
  #checkout-payment-step .step-content {
    padding-left: calc(15px * 2);
    padding-right: calc(15px * 2);
  }
}
#checkout-payment-step .cart-payment-intro {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  color: #00244c;
  margin-bottom: calc(15px * 2);
}
#checkout-payment-step .payment-options {
  padding-bottom: 15px;
}
#checkout-payment-step .payment-options .payment-option label {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  display: flex;
  align-items: center;
  color: #00244c;
  font-size: 14px;
  padding: 15px 10px;
  border: 1px solid #b2bdc9;
  margin-bottom: 15px;
  cursor: pointer;
  transition: all 0.3s;
}
@media (min-width: 640px) {
  #checkout-payment-step .payment-options .payment-option label {
    padding: 15px;
  }
}
#checkout-payment-step .payment-options .payment-option label.selected {
  border-color: #b88210;
}
#checkout-payment-step .payment-options .custom-radio,
#checkout-payment-step .payment-options img {
  margin-right: 10px;
}
@media (min-width: 768px) {
  #checkout-payment-step .payment-options .custom-radio,
#checkout-payment-step .payment-options img {
    margin-right: 20px;
  }
}
#checkout-payment-step .payment-options .definition-list {
  padding: 15px;
  background-color: #f9f2e8;
}
#checkout-payment-step .payment-options .definition-list section {
  color: #00244c;
}
#checkout-payment-step .payment-options .definition-list dl dt {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  margin: 8px 0;
}
#checkout-payment-step .payment-options .tab-content {
  margin-top: 5px;
}
@media (min-width: 768px) {
  #checkout-payment-step .payment-options .tab-content {
    margin: 0;
  }
}
#checkout-payment-step .condition-label {
  padding: 15px 10px;
  background-color: #eeeeef;
}
@media (min-width: 640px) {
  #checkout-payment-step .condition-label {
    padding: 15px;
  }
}
#checkout-payment-step .condition-label label {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 600;
  color: #00244c;
  font-size: 12px;
}
@media (min-width: 640px) {
  #checkout-payment-step .condition-label label {
    font-size: 14px;
  }
}
#checkout-payment-step .condition-label a {
  color: #b88210;
  text-decoration: underline;
  font-size: 12px;
  transition: all 0.3s;
}
@media (min-width: 640px) {
  #checkout-payment-step .condition-label a {
    font-size: 14px;
  }
}
#checkout-payment-step .condition-label a:hover {
  text-decoration: none;
}
#checkout-payment-step #modal-terms .page-heading {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #b88210;
}
@media (min-width: 1024px) {
  #checkout-payment-step #modal-terms .page-heading {
    font-size: 18px;
  }
}
#checkout-payment-step #modal-terms .page-subheading {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 14px;
}
#checkout-payment-step #payment-confirmation .js-alert-payment-conditions {
  margin-top: calc(15px * 2);
  display: block;
  text-align: left;
}
#checkout-payment-step #payment-confirmation .accordion-toggler {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 600;
  position: relative;
  text-align: left;
  padding: 5px 14px 5px 0;
  text-transform: uppercase;
  color: #00244c;
  display: block;
  background: transparent;
  width: 100%;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #b88210;
  cursor: pointer;
  padding: 5px 25px 5px 0;
  text-decoration: none;
  text-transform: none;
  border: none;
  font-size: 12px;
}
@media (min-width: 400px) {
  #checkout-payment-step #payment-confirmation .accordion-toggler {
    display: inline;
    font-size: 14px;
  }
}
#checkout-payment-step #payment-confirmation .accordion-toggler:focus, #checkout-payment-step #payment-confirmation .accordion-toggler:hover {
  outline: none;
}
#checkout-payment-step #payment-confirmation .accordion-toggler:after {
  background-image: svg-load("chevron.svg", fill=#b88210);
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 14px;
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 14px;
  transform: rotate(-90deg);
  transition: transform 0.55s ease;
}
#checkout-payment-step #payment-confirmation .accordion-toggler[aria-expanded=true]:after, #checkout-payment-step #payment-confirmation .accordion-toggler.active-header:after {
  transform: rotate(90deg);
}
#checkout-payment-step #order-summary-content {
  padding-top: 15px;
}
@media (min-width: 768px) {
  #checkout-payment-step #order-summary-content {
    padding-top: calc(15px * 2);
  }
}
#checkout-payment-step #order-summary-content .title {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #00244c;
  text-transform: uppercase;
  margin-bottom: 15px;
}
@media (min-width: 1024px) {
  #checkout-payment-step #order-summary-content .title {
    font-size: 18px;
  }
}
#checkout-payment-step #order-summary-content .subtitle {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  color: #b88210;
  font-size: 16px;
  margin-bottom: 15px;
  position: relative;
}
#checkout-payment-step #order-summary-content .address {
  cursor: pointer;
  padding: calc(15px * 2);
  border: 1px solid #b2bdc9;
  margin-bottom: 15px;
  transition: all 0.3s cubic-bezier(0.4, 0.5, 0.15, 1);
  display: block;
}
#checkout-payment-step #order-summary-content .address:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
#checkout-payment-step #order-summary-content .address address {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  margin: 15px 0;
  color: #00244c;
}
#checkout-payment-step #order-summary-content .address-alias {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #00244c;
}
#checkout-payment-step #order-summary-content .summary-selected-carrier {
  border: 1px solid #b2bdc9;
  padding: 15px;
}
#checkout-payment-step #order-summary-content .summary-selected-carrier .carrier-delay {
  font-size: 12px;
}
#checkout-payment-step #order-summary-content .summary-selected-carrier .carrier-logo {
  width: 40px;
  height: 40px;
  margin-right: 15px;
  flex-grow: 1;
  flex-shrink: 0;
}
#checkout-payment-step #order-summary-content .summary-selected-carrier .carrier-logo img {
  width: 100%;
  min-height: auto;
}
#checkout-payment-step #order-summary-content .summary-selected-carrier .carrier-name {
  flex-grow: 1;
  flex-wrap: 0;
}
#checkout-payment-step #order-summary-content .summary-selected-carrier .carrier-price {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  color: #00244c;
  font-size: 16px;
}

@media (min-width: 768px) {
  .delivery-option-setting {
    padding: 0 calc(15px * 2);
  }
}
.delivery-option-setting .note {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 400;
}
.delivery-option-setting .btn-link {
  color: #00244c;
  text-decoration: none;
  position: relative;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
  margin-top: 15px;
}
.delivery-option-setting .btn-link:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #00244c;
  transform-origin: center;
  transition: transform 0.3s cubic-bezier(0.4, 0.5, 0.15, 1);
}
.delivery-option-setting .btn-link:after {
  transform: scaleX(1);
}
.delivery-option-setting .btn-link:hover:after {
  transform: scaleX(0);
}
.delivery-option-setting .delivery-adress-text h4 {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #00244c;
  margin-bottom: 15px;
}
.delivery-option-setting .delivery-adress-text p {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #00244c;
  margin-bottom: 0;
}
.delivery-option-setting .mondialrelay-summary--wrapper,
.delivery-option-setting .collisimo-summary--wrapper {
  padding: 15px 15px 0;
  border: 1px solid #b2bdc9;
  border-bottom: none;
}
@media (min-width: 768px) {
  .delivery-option-setting .mondialrelay-summary--wrapper,
.delivery-option-setting .collisimo-summary--wrapper {
    padding: calc(15px * 2) calc(15px * 2) 0;
  }
}
.delivery-option-setting .order-information-delivery {
  padding: calc(15px * 2) 15px 15px;
  border: 1px solid #b2bdc9;
  border-top: none;
}
@media (min-width: 768px) {
  .delivery-option-setting .order-information-delivery {
    padding: calc(15px * 2);
  }
}
.delivery-option-setting .order-information-delivery .form-group {
  margin-bottom: calc(15px * 2);
}
@media (min-width: 768px) {
  .delivery-option-setting .order-information-delivery .form-group {
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) {
  .delivery-option-setting .order-information-delivery .form-group .form-control-label {
    width: auto;
    margin-right: 15px;
  }
}
@media (min-width: 768px) {
  .delivery-option-setting .order-information-delivery .form-group .form-control-input {
    flex-grow: 1;
  }
}
.delivery-option-setting #mondialrelay_summary {
  margin-top: calc(15px * 2);
}
.delivery-option-setting #delivery-information-delivery textarea {
  height: 40px;
  padding-right: 30px;
}
@media (min-width: 768px) {
  .delivery-option-setting #delivery-information-delivery textarea {
    padding-right: 140px;
  }
}
.delivery-option-setting #delivery-information-delivery .form-control-input {
  position: relative;
}
.delivery-option-setting #delivery-information-delivery .form-control-comment {
  position: absolute;
  right: 10px;
  top: 10px;
}

#mondialrelay_content .leaflet-popup-content-wrapper {
  border-radius: 0;
}
#mondialrelay_content #mondialrelay_save-container {
  padding: 15px 0;
  border-bottom-color: #f9f2e8;
}
#mondialrelay_content #mondialrelay_save-container button {
  color: #ffffff;
  background-color: #00244c;
  padding: 15px;
  border: 2px solid #00244c;
  font-size: 12px;
  line-height: normal;
  width: 100%;
}
#mondialrelay_content #mondialrelay_save-container button:disabled {
  background-color: #b2bdc9;
  border-color: #b2bdc9;
  cursor: not-allowed;
}
#mondialrelay_content #mondialrelay_save-container button:hover {
  color: #ffffff;
  background-color: #b88210;
  border-color: #b88210;
}
@media (min-width: 768px) {
  #mondialrelay_content #mondialrelay_save-container button {
    width: auto;
  }
}
#mondialrelay_content .MR-Widget {
  border: none !important;
  width: 100%;
  padding: 0;
  margin: 0;
}
#mondialrelay_content .MR-Widget .MRW-Title {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #00244c;
  background: none;
  border: none;
  text-align: left;
  padding: 15px 0;
}
@media (max-width: 767.98px) {
  #mondialrelay_content .MR-Widget .MRW-RList {
    display: none !important;
  }
}
@media (max-width: 767.98px) {
  #mondialrelay_content .MR-Widget .MRW-ShowList {
    display: none;
  }
}
@media (max-width: 767.98px) {
  #mondialrelay_content .MR-Widget .leaflet-zoom-animated {
    width: 240px;
  }
}
@media (max-width: 767.98px) {
  #mondialrelay_content .MR-Widget .leaflet-popup-content-wrapper {
    overflow: scroll;
  }
}

/**
 * ----------------------------------
 * Authentication stylesheet
 * ----------------------------------
 */
.account-link {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  color: #00244c;
  text-decoration: none;
  position: relative;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  line-height: 21px;
  margin-top: calc(15px * 2);
  display: inline-block;
}
.account-link:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #b88210;
  transform-origin: center;
  transition: transform 0.3s cubic-bezier(0.4, 0.5, 0.15, 1);
}
.account-link:after {
  transform: scaleX(1);
}
.account-link:hover:after {
  transform: scaleX(0);
}

.have-account {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  font-size: 16px;
  margin-top: calc(15px * 2);
  color: #00244c;
}
.have-account a {
  color: #b88210;
  text-decoration: none;
  position: relative;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  line-height: 21px;
  margin-left: 10px;
}
.have-account a:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #b88210;
  transform-origin: center;
  transition: transform 0.3s cubic-bezier(0.4, 0.5, 0.15, 1);
}
.have-account a:after {
  transform: scaleX(1);
}
.have-account a:hover:after {
  transform: scaleX(0);
}

.mention-rgpd {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  color: #00244c;
  font-size: 12px;
}
.mention-rgpd a {
  color: #00244c;
  text-decoration: none;
  position: relative;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
  font-size: 12px;
}
.mention-rgpd a:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #00244c;
  transform-origin: center;
  transition: transform 0.3s cubic-bezier(0.4, 0.5, 0.15, 1);
}
.mention-rgpd a:after {
  transform: scaleX(1);
}
.mention-rgpd a:hover:after {
  transform: scaleX(0);
}

.login-form,
.register-form,
.renew-password {
  padding: calc(15px * 2) 15px;
  margin-bottom: calc(15px * 2);
  border: 2px solid #b88210;
}
@media (min-width: 768px) {
  .login-form,
.register-form,
.renew-password {
    padding: calc(15px * 2);
    margin-bottom: 15px;
  }
}

@media (min-width: 768px) {
  .login-form #login-form {
    width: 100%;
  }
}
@media (min-width: 1024px) {
  .login-form #login-form {
    width: 100%;
  }
}

.forgotten-password {
  margin-bottom: calc(15px * 2);
}
.forgotten-password .send-renew-password-link {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  padding: calc(15px * 2) 15px;
  background-color: #f9f2e8;
  color: #00244c;
}
@media (min-width: 768px) {
  .forgotten-password .send-renew-password-link {
    padding: calc(15px * 2);
  }
}
.forgotten-password .form-group label {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  color: #00244c;
  font-size: 14px;
  line-height: 1;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .forgotten-password .form-group label {
    font-size: 16px;
  }
}
.forgotten-password .form-group .email {
  margin-bottom: 15px;
}
.forgotten-password .form-group .email input[type=email] {
  width: 100%;
}
.forgotten-password .form-group .button-wrapper {
  text-align: right;
}
.forgotten-password .form-group .button-wrapper button {
  color: #ffffff;
  background-color: #00244c;
  padding: 15px;
  border: 2px solid #00244c;
  font-size: 12px;
  line-height: normal;
}
.forgotten-password .form-group .button-wrapper button:disabled {
  background-color: #b2bdc9;
  border-color: #b2bdc9;
  cursor: not-allowed;
}
.forgotten-password .form-group .button-wrapper button:hover {
  color: #ffffff;
  background-color: #b88210;
  border-color: #b88210;
}
@media (min-width: 640px) {
  .forgotten-password .form-group .button-wrapper button.d-sm-inline-block {
    display: inline-block !important;
  }
}
.forgotten-password .form-group .button-wrapper a {
  color: #00244c;
  background-color: transparent;
  padding: 15px;
  border: 2px solid #00244c;
  font-size: 12px;
  line-height: normal;
}
.forgotten-password .form-group .button-wrapper a:hover {
  color: #b88210;
  background-color: transparent;
  border-color: #b88210;
}

@media (min-width: 768px) {
  .register-form .registration-form-checkout {
    width: 100%;
  }
}
.register-form .loyalty-registration-form label {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  color: #00244c;
  font-size: 14px;
  line-height: 1;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .register-form .loyalty-registration-form label {
    font-size: 16px;
  }
}
.register-form .loyalty-registration-form .form-group {
  margin-bottom: 15px;
}
.register-form .loyalty-registration-form .title {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #00244c;
}
.register-form .have-loyalty-card .label {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  color: #00244c;
  font-size: 14px;
  line-height: 1;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .register-form .have-loyalty-card .label {
    font-size: 16px;
  }
}
.register-form .have-loyalty-card .code,
.register-form .have-loyalty-card .description {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  color: #00244c;
  display: block;
}
.register-form .have-loyalty-card .code {
  font-size: 14px;
  padding-bottom: 5px;
  background-color: transparent;
  border: none;
  width: 100%;
}
@media (min-width: 768px) {
  .register-form .have-loyalty-card .code {
    font-size: 16px;
  }
}
.register-form .have-loyalty-card .description {
  padding-top: 5px;
  font-size: 12px;
  border-top: 1px solid #b88210;
}

.renew-password .email {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #00244c;
  margin-bottom: calc(15px * 2);
}

/**
 * ----------------------------------
 * Account stylesheet
 * ----------------------------------
 */
.henaff-my-account .account-sidemenu,
.henaff-identity .account-sidemenu,
.henaff-address .account-sidemenu,
.henaff-addresses .account-sidemenu,
.henaff-history .account-sidemenu,
.henaff-order-slip .account-sidemenu,
.henaff-loyalty .account-sidemenu {
  margin-bottom: calc(15px * 2);
}
.henaff-my-account .account-sidemenu .links-list li,
.henaff-identity .account-sidemenu .links-list li,
.henaff-address .account-sidemenu .links-list li,
.henaff-addresses .account-sidemenu .links-list li,
.henaff-history .account-sidemenu .links-list li,
.henaff-order-slip .account-sidemenu .links-list li,
.henaff-loyalty .account-sidemenu .links-list li {
  margin-bottom: 15px;
}
.henaff-my-account .account-sidemenu .links-list li.active a,
.henaff-identity .account-sidemenu .links-list li.active a,
.henaff-address .account-sidemenu .links-list li.active a,
.henaff-addresses .account-sidemenu .links-list li.active a,
.henaff-history .account-sidemenu .links-list li.active a,
.henaff-order-slip .account-sidemenu .links-list li.active a,
.henaff-loyalty .account-sidemenu .links-list li.active a {
  color: #b88210;
}
.henaff-my-account .account-sidemenu .links-list li.active a:after,
.henaff-identity .account-sidemenu .links-list li.active a:after,
.henaff-address .account-sidemenu .links-list li.active a:after,
.henaff-addresses .account-sidemenu .links-list li.active a:after,
.henaff-history .account-sidemenu .links-list li.active a:after,
.henaff-order-slip .account-sidemenu .links-list li.active a:after,
.henaff-loyalty .account-sidemenu .links-list li.active a:after {
  background-color: #b88210;
  transform: scaleX(1);
}
.henaff-my-account .account-sidemenu .links-list a,
.henaff-identity .account-sidemenu .links-list a,
.henaff-address .account-sidemenu .links-list a,
.henaff-addresses .account-sidemenu .links-list a,
.henaff-history .account-sidemenu .links-list a,
.henaff-order-slip .account-sidemenu .links-list a,
.henaff-loyalty .account-sidemenu .links-list a {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #00244c;
  text-decoration: none;
  position: relative;
}
.henaff-my-account .account-sidemenu .links-list a:after,
.henaff-identity .account-sidemenu .links-list a:after,
.henaff-address .account-sidemenu .links-list a:after,
.henaff-addresses .account-sidemenu .links-list a:after,
.henaff-history .account-sidemenu .links-list a:after,
.henaff-order-slip .account-sidemenu .links-list a:after,
.henaff-loyalty .account-sidemenu .links-list a:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  transform: scaleX(0);
  transform-origin: center;
  transition: all 0.3s cubic-bezier(0.4, 0.5, 0.15, 1);
}
.henaff-my-account .account-sidemenu .links-list a:hover,
.henaff-identity .account-sidemenu .links-list a:hover,
.henaff-address .account-sidemenu .links-list a:hover,
.henaff-addresses .account-sidemenu .links-list a:hover,
.henaff-history .account-sidemenu .links-list a:hover,
.henaff-order-slip .account-sidemenu .links-list a:hover,
.henaff-loyalty .account-sidemenu .links-list a:hover {
  color: #b88210;
}
.henaff-my-account .account-sidemenu .links-list a:hover:after,
.henaff-identity .account-sidemenu .links-list a:hover:after,
.henaff-address .account-sidemenu .links-list a:hover:after,
.henaff-addresses .account-sidemenu .links-list a:hover:after,
.henaff-history .account-sidemenu .links-list a:hover:after,
.henaff-order-slip .account-sidemenu .links-list a:hover:after,
.henaff-loyalty .account-sidemenu .links-list a:hover:after {
  background-color: #b88210;
  transform: scaleX(1);
}
.henaff-my-account .account-sidemenu .log-out,
.henaff-identity .account-sidemenu .log-out,
.henaff-address .account-sidemenu .log-out,
.henaff-addresses .account-sidemenu .log-out,
.henaff-history .account-sidemenu .log-out,
.henaff-order-slip .account-sidemenu .log-out,
.henaff-loyalty .account-sidemenu .log-out {
  padding-top: 15px;
}
.henaff-my-account .account-sidemenu .log-out a,
.henaff-identity .account-sidemenu .log-out a,
.henaff-address .account-sidemenu .log-out a,
.henaff-addresses .account-sidemenu .log-out a,
.henaff-history .account-sidemenu .log-out a,
.henaff-order-slip .account-sidemenu .log-out a,
.henaff-loyalty .account-sidemenu .log-out a {
  color: #00244c;
  background-color: transparent;
  padding: 15px;
  border: 2px solid #00244c;
  font-size: 12px;
  line-height: normal;
  padding: calc(15px - 5px) calc(15px * 2);
  width: 100%;
}
.henaff-my-account .account-sidemenu .log-out a:hover,
.henaff-identity .account-sidemenu .log-out a:hover,
.henaff-address .account-sidemenu .log-out a:hover,
.henaff-addresses .account-sidemenu .log-out a:hover,
.henaff-history .account-sidemenu .log-out a:hover,
.henaff-order-slip .account-sidemenu .log-out a:hover,
.henaff-loyalty .account-sidemenu .log-out a:hover {
  color: #b88210;
  background-color: transparent;
  border-color: #b88210;
}
.henaff-my-account .account-page-title,
.henaff-identity .account-page-title,
.henaff-address .account-page-title,
.henaff-addresses .account-page-title,
.henaff-history .account-page-title,
.henaff-order-slip .account-page-title,
.henaff-loyalty .account-page-title {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 18px;
  display: flex;
  align-items: center;
  color: #00244c;
  margin-bottom: calc(15px * 2);
}
@media (min-width: 1024px) {
  .henaff-my-account .account-page-title,
.henaff-identity .account-page-title,
.henaff-address .account-page-title,
.henaff-addresses .account-page-title,
.henaff-history .account-page-title,
.henaff-order-slip .account-page-title,
.henaff-loyalty .account-page-title {
    font-size: 24px;
  }
}
.henaff-my-account .account-page-title .icon,
.henaff-identity .account-page-title .icon,
.henaff-address .account-page-title .icon,
.henaff-addresses .account-page-title .icon,
.henaff-history .account-page-title .icon,
.henaff-order-slip .account-page-title .icon,
.henaff-loyalty .account-page-title .icon {
  width: 35px;
  height: 35px;
  margin-right: 10px;
}

.address-form,
.identity-form {
  padding: calc(15px * 2) 15px;
  border: 1px solid #b88210;
  /* @include media-breakpoint-up(lg) {
    padding: {
      top: calc(15px * 3);
      left: calc(15px * 2);
      right: calc(15px * 2);
      bottom: calc(15px * 3);
    };
  } */
}
@media (min-width: 768px) {
  .address-form,
.identity-form {
    padding-top: calc(15px * 3);
    padding-left: calc(15px * 2);
    padding-right: calc(15px * 2);
    padding-bottom: calc(15px * 3);
  }
}

.henaff-my-account .links li {
  width: 100%;
}
@media (min-width: 480px) {
  .henaff-my-account .links li {
    width: 50%;
  }
}
.henaff-my-account .card-account {
  position: relative;
  padding: calc(15px * 2) calc(15px * 2) calc(15px * 5);
  border: 1px solid #b88210;
  display: block;
  height: 350px;
  margin-bottom: 15px;
  text-decoration: none;
}
@media (min-width: 768px) {
  .henaff-my-account .card-account {
    margin-bottom: calc(15px * 2);
  }
}
.henaff-my-account .card-account:hover .link {
  color: #00244c;
  background-color: transparent;
}
.henaff-my-account .card-account .icon {
  width: 40px;
  height: 40px;
  margin-bottom: 15px;
  margin-right: 0;
}
.henaff-my-account .card-account .title {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: #00244c;
  margin-bottom: 10px;
}
@media (min-width: 1200px) {
  .henaff-my-account .card-account .title {
    font-size: 22px;
  }
}
.henaff-my-account .card-account .description {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  color: #00244c;
  font-size: 12px;
}
.henaff-my-account .card-account .btn {
  color: #ffffff;
  background-color: #00244c;
  padding: calc(15px - 5px) calc(15px * 2);
  border: 2px solid #00244c;
  margin-bottom: 0;
  position: absolute;
  bottom: calc(15px * 2);
  left: calc(15px * 2);
  transition: all 0.3s cubic-bezier(0.4, 0.5, 0.15, 1);
}
.henaff-my-account .card-account .btn:hover {
  color: #00244c;
  background-color: transparent;
  border-color: #00244c;
}

.henaff-address form,
.henaff-identity form {
  width: 100%;
}
@media (min-width: 768px) {
  .henaff-address form,
.henaff-identity form {
    width: 80%;
  }
}
.henaff-address .customer-address-footer,
.henaff-address .customer-identity-footer,
.henaff-identity .customer-address-footer,
.henaff-identity .customer-identity-footer {
  text-align: right;
}
.henaff-address .customer-address-footer .btn,
.henaff-address .customer-identity-footer .btn,
.henaff-identity .customer-address-footer .btn,
.henaff-identity .customer-identity-footer .btn {
  min-width: 250px;
}

.henaff-addresses .addresses-list address {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  margin: 15px 0;
  color: #00244c;
}
.henaff-addresses .addresses-list .address {
  cursor: pointer;
  padding: calc(15px * 2);
  border: 1px solid #b2bdc9;
  margin-bottom: 15px;
  transition: all 0.3s cubic-bezier(0.4, 0.5, 0.15, 1);
  display: block;
}
.henaff-addresses .addresses-list .address:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
.henaff-addresses .addresses-list .address-alias {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 22px;
  color: #00244c;
}
.henaff-addresses .addresses-list .address-footer {
  padding-top: 10px;
  border-top: 1px solid #b88210;
}
.henaff-addresses .addresses-list .address-footer a {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 600;
  color: #808080;
  text-decoration: none;
  font-size: 12px;
  margin-right: 10px;
}
.henaff-addresses .addresses-list .address-footer a:after {
  display: none;
}
.henaff-addresses .addresses-footer {
  margin-top: 15px;
}
.henaff-addresses .addresses-footer a {
  color: #00244c;
  background-color: transparent;
  padding: 15px;
  border: 2px solid #00244c;
  font-size: 12px;
  line-height: normal;
  padding: 10px 15px;
  font-size: 12px;
  width: 100%;
}
.henaff-addresses .addresses-footer a:hover {
  color: #b88210;
  background-color: transparent;
  border-color: #b88210;
}
@media (min-width: 640px) {
  .henaff-addresses .addresses-footer a {
    font-size: 14px;
    width: auto;
  }
}
@media (min-width: 768px) {
  .henaff-addresses .addresses-footer a {
    padding: 10px calc(15px * 2);
  }
}
.henaff-addresses .addresses-footer .add {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  font-style: normal;
  color: #00244c;
  padding-right: 10px;
}

.henaff-history .history-list,
.henaff-history .order-slip-list,
.henaff-order-slip .history-list,
.henaff-order-slip .order-slip-list {
  padding: calc(15px * 2);
  border: 1px solid #b88210;
}
.henaff-history .orders,
.henaff-history .credit-slips,
.henaff-order-slip .orders,
.henaff-order-slip .credit-slips {
  padding-left: 0;
}
@media (min-width: 768px) {
  .henaff-history .orders,
.henaff-history .credit-slips,
.henaff-order-slip .orders,
.henaff-order-slip .credit-slips {
    padding-left: calc(15px * 3);
  }
}
.henaff-history .orders .order,
.henaff-history .orders .slip,
.henaff-history .credit-slips .order,
.henaff-history .credit-slips .slip,
.henaff-order-slip .orders .order,
.henaff-order-slip .orders .slip,
.henaff-order-slip .credit-slips .order,
.henaff-order-slip .credit-slips .slip {
  border-bottom: 1px solid #b88210;
  margin-bottom: calc(15px * 2);
}
.henaff-history .orders .order-list,
.henaff-history .orders .slip-list,
.henaff-history .credit-slips .order-list,
.henaff-history .credit-slips .slip-list,
.henaff-order-slip .orders .order-list,
.henaff-order-slip .orders .slip-list,
.henaff-order-slip .credit-slips .order-list,
.henaff-order-slip .credit-slips .slip-list {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  color: #00244c;
  margin-bottom: 15px;
}
.henaff-history .orders .order-list .date,
.henaff-history .orders .slip-list .date,
.henaff-history .credit-slips .order-list .date,
.henaff-history .credit-slips .slip-list .date,
.henaff-order-slip .orders .order-list .date,
.henaff-order-slip .orders .slip-list .date,
.henaff-order-slip .credit-slips .order-list .date,
.henaff-order-slip .credit-slips .slip-list .date {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #00244c;
}
.henaff-history .orders .order-list .reference span,
.henaff-history .orders .slip-list .reference span,
.henaff-history .credit-slips .order-list .reference span,
.henaff-history .credit-slips .slip-list .reference span,
.henaff-order-slip .orders .order-list .reference span,
.henaff-order-slip .orders .slip-list .reference span,
.henaff-order-slip .credit-slips .order-list .reference span,
.henaff-order-slip .credit-slips .slip-list .reference span {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  color: #b88210;
}
.henaff-history .orders .order-list .price span,
.henaff-history .orders .order-list .payment span,
.henaff-history .orders .order-list .state span,
.henaff-history .orders .order-list .number span,
.henaff-history .orders .slip-list .price span,
.henaff-history .orders .slip-list .payment span,
.henaff-history .orders .slip-list .state span,
.henaff-history .orders .slip-list .number span,
.henaff-history .credit-slips .order-list .price span,
.henaff-history .credit-slips .order-list .payment span,
.henaff-history .credit-slips .order-list .state span,
.henaff-history .credit-slips .order-list .number span,
.henaff-history .credit-slips .slip-list .price span,
.henaff-history .credit-slips .slip-list .payment span,
.henaff-history .credit-slips .slip-list .state span,
.henaff-history .credit-slips .slip-list .number span,
.henaff-order-slip .orders .order-list .price span,
.henaff-order-slip .orders .order-list .payment span,
.henaff-order-slip .orders .order-list .state span,
.henaff-order-slip .orders .order-list .number span,
.henaff-order-slip .orders .slip-list .price span,
.henaff-order-slip .orders .slip-list .payment span,
.henaff-order-slip .orders .slip-list .state span,
.henaff-order-slip .orders .slip-list .number span,
.henaff-order-slip .credit-slips .order-list .price span,
.henaff-order-slip .credit-slips .order-list .payment span,
.henaff-order-slip .credit-slips .order-list .state span,
.henaff-order-slip .credit-slips .order-list .number span,
.henaff-order-slip .credit-slips .slip-list .price span,
.henaff-order-slip .credit-slips .slip-list .payment span,
.henaff-order-slip .credit-slips .slip-list .state span,
.henaff-order-slip .credit-slips .slip-list .number span {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
}
.henaff-history .orders .order-actions a,
.henaff-history .orders .slip-actions a,
.henaff-history .credit-slips .order-actions a,
.henaff-history .credit-slips .slip-actions a,
.henaff-order-slip .orders .order-actions a,
.henaff-order-slip .orders .slip-actions a,
.henaff-order-slip .credit-slips .order-actions a,
.henaff-order-slip .credit-slips .slip-actions a {
  color: #00244c;
  background-color: transparent;
  padding: 15px;
  border: 2px solid #00244c;
  font-size: 12px;
  line-height: normal;
  padding: 10px calc(15px * 2);
  display: block;
  margin-bottom: 15px;
}
.henaff-history .orders .order-actions a:hover,
.henaff-history .orders .slip-actions a:hover,
.henaff-history .credit-slips .order-actions a:hover,
.henaff-history .credit-slips .slip-actions a:hover,
.henaff-order-slip .orders .order-actions a:hover,
.henaff-order-slip .orders .slip-actions a:hover,
.henaff-order-slip .credit-slips .order-actions a:hover,
.henaff-order-slip .credit-slips .slip-actions a:hover {
  color: #b88210;
  background-color: transparent;
  border-color: #b88210;
}

.henaff-loyalty .loyalty-list {
  padding: calc(15px * 2);
  border: 1px solid #b88210;
  margin-bottom: 15px;
}
.henaff-loyalty .loyalty {
  padding-left: 0;
}
@media (min-width: 768px) {
  .henaff-loyalty .loyalty {
    padding-left: calc(15px * 3);
  }
}
.henaff-loyalty .loyalty-form {
  flex-wrap: wrap;
  justify-content: space-between;
}
.henaff-loyalty .loyalty-form .form-group {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .henaff-loyalty .loyalty-form .form-group {
    flex-grow: 0;
    flex-shrink: 0;
    width: 50%;
  }
  .henaff-loyalty .loyalty-form .form-group:first-child {
    padding-right: 7.5px;
  }
  .henaff-loyalty .loyalty-form .form-group:last-child {
    padding-left: 7.5px;
  }
}
.henaff-loyalty .loyalty-form label {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  color: #00244c;
  line-height: 1;
  padding-right: 15px;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .henaff-loyalty .loyalty-form label {
    margin-bottom: 0;
  }
}
.henaff-loyalty .loyalty-form .code {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 400;
  color: #00244c;
  line-height: 1;
  border-bottom: 1px solid #b2bdc9;
  padding-left: 4px;
  padding-bottom: 4px;
  flex-grow: 1;
  flex-shrink: 0;
  width: 100%;
}
@media (min-width: 768px) {
  .henaff-loyalty .loyalty-form .code {
    width: auto;
  }
}
.henaff-loyalty .loyalty-card {
  border-bottom: 1px solid #b88210;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
.henaff-loyalty .loyalty-card .form-group {
  flex-wrap: wrap;
}
.henaff-loyalty .loyalty-card .form-group label {
  flex-grow: 1;
  flex-shrink: 0;
  width: 100%;
  margin-bottom: 15px;
}
@media (min-width: 1024px) {
  .henaff-loyalty .loyalty-card .form-group label {
    width: 50%;
  }
}
.henaff-loyalty .loyalty-card .link-toggler {
  color: #00244c;
  text-decoration: none;
  position: relative;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
}
.henaff-loyalty .loyalty-card .link-toggler:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #00244c;
  transform-origin: center;
  transition: transform 0.3s cubic-bezier(0.4, 0.5, 0.15, 1);
}
.henaff-loyalty .loyalty-card .link-toggler:after {
  transform: scaleX(1);
}
.henaff-loyalty .loyalty-card .link-toggler:hover:after {
  transform: scaleX(0);
}
.henaff-loyalty .loyalty-card .link {
  color: #00244c;
  text-decoration: none;
  position: relative;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
}
.henaff-loyalty .loyalty-card .link:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #00244c;
  transform-origin: center;
  transition: transform 0.3s cubic-bezier(0.4, 0.5, 0.15, 1);
}
.henaff-loyalty .loyalty-card .link:after {
  transform: scaleX(1);
}
.henaff-loyalty .loyalty-card .link:hover:after {
  transform: scaleX(0);
}
.henaff-loyalty .loyalty-card .form-wrapper {
  padding-left: 0;
  text-align: right;
  flex-grow: 1;
  flex-shrink: 0;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s all cubic-bezier(0.4, 0.5, 0.15, 1);
}
@media (min-width: 1024px) {
  .henaff-loyalty .loyalty-card .form-wrapper {
    padding-left: 15px;
    width: 50%;
  }
}
.henaff-loyalty .loyalty-card .form-wrapper.show {
  opacity: 1;
  visibility: visible;
}
.henaff-loyalty .loyalty-card .code {
  display: block;
  margin-bottom: 15px;
  width: 100%;
}
.henaff-loyalty .loyalty-card .btn {
  color: #ffffff;
  background-color: #00244c;
  padding: 15px;
  border: 2px solid #00244c;
  font-size: 12px;
  line-height: normal;
  padding: 10px calc(15px * 2);
  display: inline-block;
}
.henaff-loyalty .loyalty-card .btn:disabled {
  background-color: #b2bdc9;
  border-color: #b2bdc9;
  cursor: not-allowed;
}
.henaff-loyalty .loyalty-card .btn:hover {
  color: #ffffff;
  background-color: #b88210;
  border-color: #b88210;
}
.henaff-loyalty .loyalty-code {
  margin-top: calc(15px * 2);
}
.henaff-loyalty .loyalty-code .title {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  color: #00244c;
  line-height: 1;
  margin-bottom: calc(15px * 2);
}
.henaff-loyalty .loyalty-code .code-list li {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.henaff-loyalty .loyalty-code .code-list .reference,
.henaff-loyalty .loyalty-code .code-list .date {
  flex-grow: 1;
  flex-shrink: 0;
}
.henaff-loyalty .loyalty-code .code-list .reference {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  color: #b88210;
}
.henaff-loyalty .loyalty-code .code-list .date {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 400;
}

.henaff-order-detail #order-infos,
.henaff-order-detail #order-history {
  padding: calc(15px * 2);
  border: 1px solid #b88210;
  margin-bottom: 15px;
}
.henaff-order-detail .box .order,
.henaff-order-detail .box .title {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #00244c;
}
.henaff-order-detail .box .reference {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 400;
}
.henaff-order-detail .box .reference span {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  color: #b88210;
}
.henaff-order-detail .box .detail-list li {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
}
.henaff-order-detail .box .detail-list span {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #00244c;
}
.henaff-order-detail .box .button {
  color: #00244c;
  background-color: transparent;
  padding: 15px;
  border: 2px solid #00244c;
  font-size: 12px;
  line-height: normal;
  padding: 10px calc(15px * 2);
}
.henaff-order-detail .box .button:hover {
  color: #b88210;
  background-color: transparent;
  border-color: #b88210;
}
.henaff-order-detail .box .invoice-url {
  margin-top: calc(15px * 2);
}
.henaff-order-detail .box .invoice-url a {
  color: #ffffff;
  background-color: #00244c;
  padding: 15px;
  border: 2px solid #00244c;
  font-size: 12px;
  line-height: normal;
}
.henaff-order-detail .box .invoice-url a:disabled {
  background-color: #b2bdc9;
  border-color: #b2bdc9;
  cursor: not-allowed;
}
.henaff-order-detail .box .invoice-url a:hover {
  color: #ffffff;
  background-color: #b88210;
  border-color: #b88210;
}
.henaff-order-detail .box .history-lines li {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  color: #00244c;
}
.henaff-order-detail .box .history-lines .state {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
}
.henaff-order-detail .addresses .box {
  padding: calc(15px * 2);
  border: 1px solid #b2bdc9;
  margin-bottom: 15px;
  transition: all 0.3s cubic-bezier(0.4, 0.5, 0.15, 1);
  display: block;
}
.henaff-order-detail .addresses .box h4 {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #00244c;
}
.henaff-order-detail .addresses .box address {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  margin: 15px 0;
  color: #00244c;
}
.henaff-order-detail .order-detail,
.henaff-order-detail .order-carriers {
  padding: calc(15px * 2);
  border: 1px solid #b2bdc9;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .henaff-order-detail .order-detail,
.henaff-order-detail .order-carriers {
    border: none;
    padding: 0;
  }
}
.henaff-order-detail .order-detail .table,
.henaff-order-detail .order-carriers .table {
  border: 1px solid #b2bdc9;
}
.henaff-order-detail .order-detail .table thead th,
.henaff-order-detail .order-carriers .table thead th {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  color: #00244c;
  padding: 15px;
  text-align: left;
  background-color: rgba(178, 189, 201, 0.3);
}
.henaff-order-detail .order-detail .table thead th:last-child,
.henaff-order-detail .order-carriers .table thead th:last-child {
  width: 1%;
  white-space: nowrap;
}
.henaff-order-detail .order-detail .table tbody td,
.henaff-order-detail .order-carriers .table tbody td {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 400;
  padding: 15px;
  border-bottom: 1px solid #b2bdc9;
}
.henaff-order-detail .order-detail .table tbody p,
.henaff-order-detail .order-carriers .table tbody p {
  margin-bottom: 0;
}
.henaff-order-detail .order-detail .table tfoot td,
.henaff-order-detail .order-carriers .table tfoot td {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 400;
  padding: 15px;
}
.henaff-order-detail .order-detail .table tfoot td.text-align-right,
.henaff-order-detail .order-carriers .table tfoot td.text-align-right {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  color: #00244c;
}
.henaff-order-detail .order-detail .order-items .order-item {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #b88210;
}
.henaff-order-detail .order-detail .order-items .order-item .qty span {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
}
.henaff-order-detail .order-detail .order-totals .label {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  color: #00244c;
}
.henaff-order-detail .order-detail .order-totals .value {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
}
.henaff-order-detail .order-carriers .shipping-lines li {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.henaff-order-detail .order-carriers .shipping-lines .label {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  color: #00244c;
}
.henaff-order-detail .order-carriers .shipping-lines .value {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
}
.henaff-order-detail .return-order-history {
  padding: calc(15px * 2) 0;
}
.henaff-order-detail .order-message-form {
  margin-top: calc(15px * 2);
}
@media (min-width: 768px) {
  .henaff-order-detail .order-message-form {
    margin-top: calc(15px * 4);
  }
}
.henaff-order-detail .order-message-form .title {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 16px;
}
@media (min-width: 1024px) {
  .henaff-order-detail .order-message-form .title {
    font-size: 18px;
  }
}
.henaff-order-detail .order-message-form .description {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
}
.henaff-order-detail .order-message-form .box {
  margin: auto;
  width: 100%;
}
@media (min-width: 1024px) {
  .henaff-order-detail .order-message-form .box {
    width: 60%;
  }
}
.henaff-order-detail .order-message-form .form-group {
  flex-wrap: wrap;
  margin-bottom: 15px;
}
.henaff-order-detail .order-message-form .form-group label {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  color: #00244c;
  font-size: 14px;
  line-height: 1;
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
  margin-bottom: 15px;
}
@media (min-width: 640px) {
  .henaff-order-detail .order-message-form .form-group label {
    width: 25%;
    font-size: 16px;
    margin-bottom: 0;
  }
}
.henaff-order-detail .order-message-form .form-group textarea,
.henaff-order-detail .order-message-form .form-group .custom-select {
  width: 100%;
}
.henaff-order-detail .order-message-form .form-group .form-control-input {
  flex-grow: 1;
  flex-shrink: 0;
  width: 100%;
}
@media (min-width: 640px) {
  .henaff-order-detail .order-message-form .form-group .form-control-input {
    width: auto;
  }
}

/**
 * ----------------------------------
 * Order confirmation stylesheet
 * ----------------------------------
 */
#order-confirmation .order-payement,
#order-confirmation .order-resume,
#order-confirmation .order-details,
#order-confirmation-resume .order-payement,
#order-confirmation-resume .order-resume,
#order-confirmation-resume .order-details {
  padding: 15px;
  border: 2px solid #b88210;
  margin-bottom: 15px;
}
#order-confirmation .confirmation-message,
#order-confirmation-resume .confirmation-message {
  padding: 15px;
  color: #39b54a;
  border: 2px solid rgba(57, 181, 74, 0.3);
  background-color: transparent;
  border-radius: 0;
  margin-bottom: calc(15px * 2);
}
#order-confirmation .confirmation-message p,
#order-confirmation-resume .confirmation-message p {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 400;
  margin-bottom: 0;
}
#order-confirmation .confirmation-message a,
#order-confirmation-resume .confirmation-message a {
  color: #39b54a;
  text-decoration: none;
  position: relative;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
}
#order-confirmation .confirmation-message a:after,
#order-confirmation-resume .confirmation-message a:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #39b54a;
  transform-origin: center;
  transition: transform 0.3s cubic-bezier(0.4, 0.5, 0.15, 1);
}
#order-confirmation .confirmation-message a:after,
#order-confirmation-resume .confirmation-message a:after {
  transform: scaleX(1);
}
#order-confirmation .confirmation-message a:hover:after,
#order-confirmation-resume .confirmation-message a:hover:after {
  transform: scaleX(0);
}
#order-confirmation .page-order-confirmation .order-resume h3,
#order-confirmation .resume-order--wrapper .order-resume h3,
#order-confirmation-resume .page-order-confirmation .order-resume h3,
#order-confirmation-resume .resume-order--wrapper .order-resume h3 {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  color: #00244c;
  font-size: 14px;
}
#order-confirmation .page-order-confirmation .order-resume .order-confirmation-table,
#order-confirmation .resume-order--wrapper .order-resume .order-confirmation-table,
#order-confirmation-resume .page-order-confirmation .order-resume .order-confirmation-table,
#order-confirmation-resume .resume-order--wrapper .order-resume .order-confirmation-table {
  margin-top: 15px;
  padding: 0 15px;
}
#order-confirmation .page-order-confirmation .order-resume .order-confirmation-table .order-line,
#order-confirmation .resume-order--wrapper .order-resume .order-confirmation-table .order-line,
#order-confirmation-resume .page-order-confirmation .order-resume .order-confirmation-table .order-line,
#order-confirmation-resume .resume-order--wrapper .order-resume .order-confirmation-table .order-line {
  padding: 15px 0;
  border-top: 1px solid #f9f2e8;
}
#order-confirmation .page-order-confirmation .order-resume .order-confirmation-table .product-image,
#order-confirmation .resume-order--wrapper .order-resume .order-confirmation-table .product-image,
#order-confirmation-resume .page-order-confirmation .order-resume .order-confirmation-table .product-image,
#order-confirmation-resume .resume-order--wrapper .order-resume .order-confirmation-table .product-image {
  position: relative;
}
#order-confirmation .page-order-confirmation .order-resume .order-confirmation-table .product-image:before,
#order-confirmation .resume-order--wrapper .order-resume .order-confirmation-table .product-image:before,
#order-confirmation-resume .page-order-confirmation .order-resume .order-confirmation-table .product-image:before,
#order-confirmation-resume .resume-order--wrapper .order-resume .order-confirmation-table .product-image:before {
  content: "";
  display: block;
  padding-top: 100%;
}
#order-confirmation .page-order-confirmation .order-resume .order-confirmation-table .product-image .image,
#order-confirmation .resume-order--wrapper .order-resume .order-confirmation-table .product-image .image,
#order-confirmation-resume .page-order-confirmation .order-resume .order-confirmation-table .product-image .image,
#order-confirmation-resume .resume-order--wrapper .order-resume .order-confirmation-table .product-image .image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
#order-confirmation .page-order-confirmation .order-resume .order-confirmation-table .product-image .image img,
#order-confirmation .resume-order--wrapper .order-resume .order-confirmation-table .product-image .image img,
#order-confirmation-resume .page-order-confirmation .order-resume .order-confirmation-table .product-image .image img,
#order-confirmation-resume .resume-order--wrapper .order-resume .order-confirmation-table .product-image .image img {
  width: 100%;
  min-height: auto;
}
#order-confirmation .page-order-confirmation .order-resume .order-confirmation-table .details,
#order-confirmation .resume-order--wrapper .order-resume .order-confirmation-table .details,
#order-confirmation-resume .page-order-confirmation .order-resume .order-confirmation-table .details,
#order-confirmation-resume .resume-order--wrapper .order-resume .order-confirmation-table .details {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  color: #b88210;
  font-size: 12px;
}
@media (min-width: 768px) {
  #order-confirmation .page-order-confirmation .order-resume .order-confirmation-table .details,
#order-confirmation .resume-order--wrapper .order-resume .order-confirmation-table .details,
#order-confirmation-resume .page-order-confirmation .order-resume .order-confirmation-table .details,
#order-confirmation-resume .resume-order--wrapper .order-resume .order-confirmation-table .details {
    font-size: 14px;
  }
}
#order-confirmation .page-order-confirmation .order-resume .order-confirmation-table .qty,
#order-confirmation .resume-order--wrapper .order-resume .order-confirmation-table .qty,
#order-confirmation-resume .page-order-confirmation .order-resume .order-confirmation-table .qty,
#order-confirmation-resume .resume-order--wrapper .order-resume .order-confirmation-table .qty {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #00244c;
  padding-top: 8px;
}
@media (min-width: 768px) {
  #order-confirmation .page-order-confirmation .order-resume .order-confirmation-table .qty,
#order-confirmation .resume-order--wrapper .order-resume .order-confirmation-table .qty,
#order-confirmation-resume .page-order-confirmation .order-resume .order-confirmation-table .qty,
#order-confirmation-resume .resume-order--wrapper .order-resume .order-confirmation-table .qty {
    padding-top: 0;
  }
}
#order-confirmation .page-order-confirmation .order-resume .order-confirmation-table .qty .bold,
#order-confirmation .resume-order--wrapper .order-resume .order-confirmation-table .qty .bold,
#order-confirmation-resume .page-order-confirmation .order-resume .order-confirmation-table .qty .bold,
#order-confirmation-resume .resume-order--wrapper .order-resume .order-confirmation-table .qty .bold {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
}
#order-confirmation .page-order-confirmation .order-resume .order-confirmation-table .table-prices,
#order-confirmation .resume-order--wrapper .order-resume .order-confirmation-table .table-prices,
#order-confirmation-resume .page-order-confirmation .order-resume .order-confirmation-table .table-prices,
#order-confirmation-resume .resume-order--wrapper .order-resume .order-confirmation-table .table-prices {
  border-top: 1px solid #f9f2e8;
}
#order-confirmation .page-order-confirmation .order-resume .order-confirmation-table .table-prices td,
#order-confirmation .resume-order--wrapper .order-resume .order-confirmation-table .table-prices td,
#order-confirmation-resume .page-order-confirmation .order-resume .order-confirmation-table .table-prices td,
#order-confirmation-resume .resume-order--wrapper .order-resume .order-confirmation-table .table-prices td {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  padding: 5px 0;
  font-size: 14px;
  color: #00244c;
}
@media (min-width: 768px) {
  #order-confirmation .page-order-confirmation .order-resume .order-confirmation-table .table-prices td,
#order-confirmation .resume-order--wrapper .order-resume .order-confirmation-table .table-prices td,
#order-confirmation-resume .page-order-confirmation .order-resume .order-confirmation-table .table-prices td,
#order-confirmation-resume .resume-order--wrapper .order-resume .order-confirmation-table .table-prices td {
    font-size: 16px;
  }
}
#order-confirmation .page-order-confirmation .order-resume .order-confirmation-table .table-prices td:first-child,
#order-confirmation .resume-order--wrapper .order-resume .order-confirmation-table .table-prices td:first-child,
#order-confirmation-resume .page-order-confirmation .order-resume .order-confirmation-table .table-prices td:first-child,
#order-confirmation-resume .resume-order--wrapper .order-resume .order-confirmation-table .table-prices td:first-child {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
}
#order-confirmation .page-order-confirmation .order-resume .order-confirmation-table .table-prices td:last-child,
#order-confirmation .resume-order--wrapper .order-resume .order-confirmation-table .table-prices td:last-child,
#order-confirmation-resume .page-order-confirmation .order-resume .order-confirmation-table .table-prices td:last-child,
#order-confirmation-resume .resume-order--wrapper .order-resume .order-confirmation-table .table-prices td:last-child {
  text-align: right;
}
#order-confirmation .page-order-confirmation .order-resume .order-confirmation-table .table-prices td.has-discount,
#order-confirmation .resume-order--wrapper .order-resume .order-confirmation-table .table-prices td.has-discount,
#order-confirmation-resume .page-order-confirmation .order-resume .order-confirmation-table .table-prices td.has-discount,
#order-confirmation-resume .resume-order--wrapper .order-resume .order-confirmation-table .table-prices td.has-discount {
  color: #b88210;
}
#order-confirmation .page-order-confirmation .order-resume .order-confirmation-table .table-prices .total-value td:last-child,
#order-confirmation .resume-order--wrapper .order-resume .order-confirmation-table .table-prices .total-value td:last-child,
#order-confirmation-resume .page-order-confirmation .order-resume .order-confirmation-table .table-prices .total-value td:last-child,
#order-confirmation-resume .resume-order--wrapper .order-resume .order-confirmation-table .table-prices .total-value td:last-child {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 22px;
}
#order-confirmation .page-order-confirmation .order-details .title,
#order-confirmation .resume-order--wrapper .order-details .title,
#order-confirmation-resume .page-order-confirmation .order-details .title,
#order-confirmation-resume .resume-order--wrapper .order-details .title {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 15px;
  color: #00244c;
}
@media (min-width: 1024px) {
  #order-confirmation .page-order-confirmation .order-details .title,
#order-confirmation .resume-order--wrapper .order-details .title,
#order-confirmation-resume .page-order-confirmation .order-details .title,
#order-confirmation-resume .resume-order--wrapper .order-details .title {
    font-size: 18px;
  }
}
#order-confirmation .page-order-confirmation .order-details em,
#order-confirmation .resume-order--wrapper .order-details em,
#order-confirmation-resume .page-order-confirmation .order-details em,
#order-confirmation-resume .resume-order--wrapper .order-details em {
  display: block;
  color: #b2bdc9;
  padding: 5px 0;
}
#order-confirmation .page-order-confirmation .order-details ul li,
#order-confirmation .resume-order--wrapper .order-details ul li,
#order-confirmation-resume .page-order-confirmation .order-details ul li,
#order-confirmation-resume .resume-order--wrapper .order-details ul li {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  padding: 5px 0;
}
#order-confirmation .definition-list,
#order-confirmation-resume .definition-list {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 400;
}
#order-confirmation .definition-list strong,
#order-confirmation-resume .definition-list strong {
  color: #00244c;
}
#order-confirmation .definition-list a,
#order-confirmation-resume .definition-list a {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  color: #b88210;
  text-decoration: none;
  position: relative;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
}
#order-confirmation .definition-list a:after,
#order-confirmation-resume .definition-list a:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #b88210;
  transform-origin: center;
  transition: transform 0.3s cubic-bezier(0.4, 0.5, 0.15, 1);
}
#order-confirmation .definition-list a:after,
#order-confirmation-resume .definition-list a:after {
  transform: scaleX(1);
}
#order-confirmation .definition-list a:hover:after,
#order-confirmation-resume .definition-list a:hover:after {
  transform: scaleX(0);
}
#order-confirmation .definition-list .price,
#order-confirmation-resume .definition-list .price {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  color: #00244c;
  font-size: 22px;
}

#order-confirmation-resume .order-resume {
  margin-top: 15px;
}
#order-confirmation-resume .order-resume a {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  color: #b88210;
  transition: all 0.3s;
}
#order-confirmation-resume .order-resume a:hover {
  text-decoration: none;
}

/**
 * ----------------------------------
 * Product list
 * ----------------------------------
 */
.product-list-header {
  margin-bottom: 15px;
}
.product-list-header .title {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 24px;
  color: #00244c;
  margin-bottom: 15px;
}
@media (min-width: 1024px) {
  .product-list-header .title {
    font-size: 28px;
  }
}
.product-list-header .current-page {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 16px;
}
@media (min-width: 1024px) {
  .product-list-header .current-page {
    font-size: 18px;
  }
}
.product-list-header .description {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  color: #00244c;
}

#search_filters_wrapper #search_filter_controls {
  margin-bottom: 15px;
  margin-right: -15px;
  margin-left: -15px;
  border-bottom: 1px solid #b88210;
}
#search_filters_wrapper .mobile-facet-toggler {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #00244c;
  text-align: right;
}
#search_filters_wrapper .mobile-facet-toggler span {
  vertical-align: sub;
}
#search_filters_wrapper .mobile-facet-toggler .btn {
  height: 70px;
  border: none;
  color: #00244c;
  font-size: 16px;
  padding: 15px 25px;
  margin-right: 15px;
  transition: all 0.3s cubic-bezier(0.4, 0.5, 0.15, 1);
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 600;
  background-image: svg-load("chevron-right.svg", fill=#00244c);
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: right 50%;
  background-size: 18px;
}
#search_filters_wrapper .mobile-facet-toggler .btn.show-facet {
  background-image: svg-load("close.svg", fill=#00244c);
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: right 50%;
  background-size: 18px;
}

#js-product-list .products {
  display: flex;
  flex-wrap: wrap;
  margin-right: -7px;
  margin-left: -7px;
}
@media (min-width: 1024px) {
  #js-product-list .products {
    margin-right: calc(15px * -2);
    margin-left: calc(15px * -2);
  }
}
#js-product-list .products > [class*=col-] {
  padding-right: 7px;
  padding-left: 7px;
  margin-bottom: 15px;
}
@media (min-width: 640px) {
  #js-product-list .products > [class*=col-] {
    padding-right: calc(15px * 2);
    padding-left: calc(15px * 2);
    margin-bottom: calc(15px * 4);
  }
}

#search_filters .facet {
  margin-bottom: 15px;
}
#search_filters .facet .title {
  width: calc(100% - 30px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  text-align: left;
  padding: 5px 14px 5px 0;
  text-transform: uppercase;
  color: #00244c;
  display: block;
  background: transparent;
  width: 100%;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #b88210;
  cursor: pointer;
  margin-bottom: 15px;
}
#search_filters .facet .title:focus, #search_filters .facet .title:hover {
  outline: none;
}
#search_filters .facet .title:after {
  background-image: svg-load("chevron.svg", fill=#b88210);
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 18px;
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 18px;
  transform: rotate(-90deg);
  transition: transform 0.55s ease;
}
#search_filters .facet .title[aria-expanded=true]:after, #search_filters .facet .title.active-header:after {
  transform: rotate(90deg);
}
#search_filters .facet-title {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  margin: 0;
}
#search_filters .facet-label {
  display: flex;
  padding: 5px 0;
}
#search_filters .facet-label .custom-checkbox {
  top: 0;
}
#search_filters .facet-label .custom-checkbox span {
  border-color: #b88210;
}
#search_filters .facet-label .search-link {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 400;
  width: calc(100% - 30px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #00244c;
  text-decoration: none;
  font-size: 16px;
}
#search_filters .facet-label .magnitude {
  display: none;
}

#_desktop_search_filters_clear_all {
  padding: calc(15px * 2) 0;
}
#_desktop_search_filters_clear_all .btn {
  color: #b88210;
  background-color: transparent;
  padding: 15px;
  border: 2px solid #b88210;
  font-size: 12px;
  line-height: normal;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}
#_desktop_search_filters_clear_all .btn:hover {
  color: #00244c;
  background-color: transparent;
  border-color: #00244c;
}
#_desktop_search_filters_clear_all .btn:hover .close {
  background-image: svg-load("close.svg", fill=#00244c);
}
#_desktop_search_filters_clear_all .close {
  width: 12px;
  height: 12px;
  margin-right: 10px;
  transition: all 0.3s cubic-bezier(0.4, 0.5, 0.15, 1);
}

#js-active-search-filters {
  padding: 15px calc(15px * 2);
  margin: calc(15px * 2) 0;
  background-color: #00244c;
}
#js-active-search-filters .active-filter-title {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 600;
  margin: 0 0 5px;
  color: #ffffff;
  text-transform: uppercase;
  padding-top: 5px;
}
#js-active-search-filters .filter-block {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 400;
  position: relative;
  display: inline-block;
  padding: 10px 25px 10px 10px;
  margin-right: 5px;
  margin-bottom: 10px;
  color: #00244c;
  background-color: #ffffff;
  font-size: 12px;
}
#js-active-search-filters .js-search-link {
  position: absolute;
  top: 50%;
  right: 10px;
  z-index: 2;
  transform: translate3d(0, -50%, 0);
}
#js-active-search-filters .js-search-link .close {
  width: 10px;
  height: 10px;
  margin-right: 0;
}

#js-product-list-top .form-inline .form-group {
  text-align: right;
}
#js-product-list-top .form-inline .custom-select .holder {
  width: 240px;
  text-align: left;
}

/**
 * ----------------------------------
 * Manufacturers stylesheet
 * ----------------------------------
 */
.manufacturer-list-header {
  padding: calc(15px * 2) 0;
  /* .subtitle {
    @include montserrat-light;

    font-size: $font-size-subtitle;
    color: col(gold);
    line-height: 1.4;
    margin-bottom: $gutter;

    @include media-breakpoint-up(md) {
      font-size: $font-size-title;
    }
  } */
}
@media (min-width: 768px) {
  .manufacturer-list-header {
    padding: calc(15px * 4) 0;
  }
}
.manufacturer-list-header .title {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 24px;
  color: #00244c;
  text-transform: uppercase;
  margin-bottom: 15px;
}
@media (min-width: 1024px) {
  .manufacturer-list-header .title {
    font-size: 28px;
  }
}
.manufacturer-list-header .map {
  position: relative;
  height: 100%;
  width: 100%;
  padding-top: 75%;
}
.manufacturer-list-header .map .image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.manufacturer-list-header .map .image img {
  width: 90%;
  min-width: auto;
  min-height: auto;
}

.manufacturer-list div[class*=col] {
  margin-bottom: calc(15px * 2);
}
@media (min-width: 480px) and (max-width: 640px) {
  .manufacturer-list div[class*=col] {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
.manufacturer-list .brand {
  padding: 10px;
  height: 100%;
  max-width: 320px;
  min-height: 450px;
  border: 1px solid #b88210;
  margin: auto;
}
.manufacturer-list .brand-img {
  position: relative;
  min-height: 200px;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .manufacturer-list .brand-img {
    min-height: 160px;
  }
}
.manufacturer-list .brand-img .image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.manufacturer-list .brand-img .image img {
  width: 100%;
  min-width: auto;
  min-height: auto;
}
.manufacturer-list .brand-infos .thumbnail {
  position: relative;
  min-height: 200px;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .manufacturer-list .brand-infos .thumbnail {
    min-height: 150px;
  }
}
.manufacturer-list .brand-infos .thumbnail .image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.manufacturer-list .brand-infos .link {
  color: #00244c;
  text-decoration: none;
  position: relative;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 16px;
  padding-top: 10px;
}
.manufacturer-list .brand-infos .link:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #b88210;
  transform-origin: center;
  transition: transform 0.3s cubic-bezier(0.4, 0.5, 0.15, 1);
}
.manufacturer-list .brand-infos .link:after {
  transform: scaleX(1);
}
.manufacturer-list .brand-infos .link:hover:after {
  transform: scaleX(0);
}

.henaff-manufacturer .manufacturer-logo {
  position: relative;
  width: calc(59.87% + calc(15px * 2));
  height: 150px;
  margin-top: -80px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 640px) {
  .henaff-manufacturer .manufacturer-logo {
    margin-top: -145px;
  }
}
@media (min-width: 1024px) {
  .henaff-manufacturer .manufacturer-logo {
    height: 200px;
    width: calc(42.77% + calc(15px * 2));
  }
}
.henaff-manufacturer .manufacturer-logo:before {
  content: "";
  position: absolute;
  background-color: #ffffff;
  left: 0;
  right: 0;
  top: 0;
  padding-top: 100%;
  border-radius: 50%;
}
.henaff-manufacturer .manufacturer-logo .image {
  width: 45%;
  max-width: 160px;
  min-height: 160px;
  margin: auto;
}
.henaff-manufacturer .manufacturer-logo .image img {
  width: 100%;
  min-height: auto;
}
.henaff-manufacturer .manufacturer-about {
  padding: calc(15px * 3) 0;
}
@media (min-width: 768px) {
  .henaff-manufacturer .manufacturer-about {
    padding: calc(15px * 4) 0;
  }
}
.henaff-manufacturer .manufacturer-about .visual {
  position: relative;
  max-width: 360px;
  margin: auto;
}
.henaff-manufacturer .manufacturer-about .visual:before {
  content: "";
  display: block;
  padding-top: 100%;
}
.henaff-manufacturer .manufacturer-about .visual .image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.henaff-manufacturer .manufacturer-about .visual .image img {
  width: 100%;
  min-height: auto;
}
.henaff-manufacturer .manufacturer-about .text-content {
  text-align: center;
  padding: 0 8.54%;
  margin-bottom: calc(15px * 2);
}
.henaff-manufacturer .manufacturer-about .text-content .title {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 24px;
  color: #00244c;
  margin-bottom: 15px;
}
@media (min-width: 1024px) {
  .henaff-manufacturer .manufacturer-about .text-content .title {
    font-size: 28px;
  }
}
.henaff-manufacturer .manufacturer-about .text-content .subtitle {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 300;
  font-size: 22px;
  color: #b88210;
  line-height: 1.4;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .henaff-manufacturer .manufacturer-about .text-content .subtitle {
    font-size: 24px;
  }
}
.henaff-manufacturer .manufacturer-sections {
  padding: 0 0 100px;
  background-color: #f9f2e8;
}
@media (min-width: 768px) {
  .henaff-manufacturer .manufacturer-sections {
    padding: calc(15px * 2) 0 176px;
  }
}
.henaff-manufacturer .manufacturer-sections .section {
  padding: 15px 0;
  text-align: center;
}
@media (min-width: 768px) {
  .henaff-manufacturer .manufacturer-sections .section {
    padding: calc(15px * 2) 0;
  }
}
.henaff-manufacturer .manufacturer-sections .section-column .text-content {
  padding: 0;
}
@media (min-width: 768px) {
  .henaff-manufacturer .manufacturer-sections .section-column .text-content {
    padding: 0 8.55%;
  }
}
.henaff-manufacturer .manufacturer-sections .image-section,
.henaff-manufacturer .manufacturer-sections .video {
  margin-bottom: 15px;
}
.henaff-manufacturer .manufacturer-sections .image-section {
  position: relative;
  padding-top: 70%;
}
.henaff-manufacturer .manufacturer-sections .image-section .image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.henaff-manufacturer .manufacturer-sections .title {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 24px;
  color: #00244c;
  margin-bottom: 15px;
}
@media (min-width: 1024px) {
  .henaff-manufacturer .manufacturer-sections .title {
    font-size: 28px;
  }
}

.manufacturer-adress {
  margin-top: -85px;
}
@media (min-width: 768px) {
  .manufacturer-adress {
    margin-top: -146px;
  }
}
.manufacturer-adress.no-marge {
  margin-top: 0;
}
.manufacturer-adress .adress {
  border: 1px solid #b88210;
  background-color: #ffffff;
  padding: 15px;
  width: 100%;
  margin: auto;
}
@media (min-width: 768px) {
  .manufacturer-adress .adress {
    width: 75%;
  }
}
@media (min-width: 1024px) {
  .manufacturer-adress .adress {
    width: 62%;
  }
}
.manufacturer-adress .adress .map {
  position: relative;
}
.manufacturer-adress .adress .map:before {
  content: "";
  display: block;
  padding-top: 85%;
}
.manufacturer-adress .adress .map .image {
  position: absolute;
  top: 0;
  left: 0;
}
.manufacturer-adress .adress .map .image img {
  top: 0;
  width: 100%;
  min-height: auto;
  transform: rotate(0.001deg) translate3d(-50%, 0, 0);
}
.manufacturer-adress .adress .logo {
  position: relative;
  padding-top: 160px;
  margin-bottom: calc(15px * 2);
}
.manufacturer-adress .adress .logo .image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.manufacturer-adress .adress .logo .image img {
  width: 160px;
  min-width: auto;
  min-height: auto;
  left: 0;
  top: 0;
  transform: rotate(0.001deg) translate3d(0, 0, 0);
}
.manufacturer-adress .adress .editor-content {
  color: #00244c;
  margin-bottom: 15px;
  font-size: 16px;
}
.manufacturer-adress .adress .editor-content p {
  margin: 0;
}

.manufacturer-products {
  padding: calc(15px * 2) 0;
}
@media (min-width: 768px) {
  .manufacturer-products {
    padding: calc(15px * 3) 0;
  }
}
.manufacturer-products .title {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: #00244c;
  text-transform: uppercase;
  margin-bottom: 15px;
  text-align: center;
}
@media (min-width: 1024px) {
  .manufacturer-products .title {
    font-size: 24px;
  }
}
.manufacturer-products .link-more {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 600;
  color: #ffffff;
  background-color: #00244c;
  padding: 15px;
  border: 2px solid #00244c;
  font-size: 12px;
  line-height: normal;
  font-size: 16px;
  text-transform: uppercase;
}
.manufacturer-products .link-more:disabled {
  background-color: #b2bdc9;
  border-color: #b2bdc9;
  cursor: not-allowed;
}
.manufacturer-products .link-more:hover {
  color: #ffffff;
  background-color: #b88210;
  border-color: #b88210;
}
.manufacturer-products .link-more-wrapper {
  text-align: center;
}

/**
 * ----------------------------------
 * FAQ stylesheet
 * ----------------------------------
 */
.henaff-module-advancefaqs-faqs .title {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 24px;
  color: #00244c;
  margin-bottom: calc(15px * 2);
}
@media (min-width: 1024px) {
  .henaff-module-advancefaqs-faqs .title {
    font-size: 28px;
  }
}
.henaff-module-advancefaqs-faqs .description {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 400;
}
.henaff-module-advancefaqs-faqs .slide {
  margin: 15px 0;
}
.henaff-module-advancefaqs-faqs .slide a {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  color: #00244c;
  text-decoration: none;
  position: relative;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
}
.henaff-module-advancefaqs-faqs .slide a:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #b88210;
  transform-origin: center;
  transition: transform 0.3s cubic-bezier(0.4, 0.5, 0.15, 1);
}
.henaff-module-advancefaqs-faqs .slide a:after {
  transform: scaleX(1);
}
.henaff-module-advancefaqs-faqs .slide a:hover:after {
  transform: scaleX(0);
}
.henaff-module-advancefaqs-faqs .list-subject-FAQ {
  margin-top: calc(15px * 2);
}
.henaff-module-advancefaqs-faqs .list-subject-FAQ .categories {
  flex-wrap: wrap;
  align-items: center;
  padding: 10px 0;
  border-top: 1px solid #b88210;
  border-bottom: 1px solid #b88210;
}
.henaff-module-advancefaqs-faqs .list-subject-FAQ .categories > li {
  position: relative;
  padding-left: 10px;
  margin: 10px 0;
  flex-grow: 0;
  flex-shrink: 0;
}
.henaff-module-advancefaqs-faqs .list-subject-FAQ .categories > li:not(:last-child) {
  padding-right: 10px;
}
.henaff-module-advancefaqs-faqs .list-subject-FAQ .categories > li:not(:last-child):after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  height: 24px;
  width: 1px;
  background-color: #b88210;
  transform: translate3d(0, -12px, 0);
}
.henaff-module-advancefaqs-faqs .list-subject-FAQ .category-name {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 600;
  color: #00244c;
  text-decoration: none;
  font-size: 16px;
  transition: 0.3s all cubic-bezier(0.4, 0.5, 0.15, 1);
}
.henaff-module-advancefaqs-faqs .list-subject-FAQ .category-name:hover {
  color: #b88210;
}
.henaff-module-advancefaqs-faqs .list-topics-FAQ {
  padding-bottom: calc(15px * 4);
}
.henaff-module-advancefaqs-faqs .list-topics-FAQ .topic-title {
  margin-top: calc(15px * 2);
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .henaff-module-advancefaqs-faqs .list-topics-FAQ .topic-title {
    margin-top: calc(15px * 3);
  }
}
.henaff-module-advancefaqs-faqs .list-topics-FAQ .topic-title .title {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  color: #00244c;
  text-decoration: none;
  font-size: 24px;
  transition: 0.3s all cubic-bezier(0.4, 0.5, 0.15, 1);
  margin-bottom: 0;
}
.henaff-module-advancefaqs-faqs .list-topics-FAQ .topic-title .title:hover {
  color: #b88210;
}
.henaff-module-advancefaqs-faqs .list-topics-FAQ .accordion-header {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  position: relative;
  text-align: left;
  padding: 5px 14px 5px 0;
  text-transform: uppercase;
  color: #00244c;
  display: block;
  background: transparent;
  width: 100%;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #b88210;
  cursor: pointer;
  font-size: 14px;
  border: none;
  text-transform: none;
  padding: 10px calc(15px * 2);
  cursor: pointer;
}
.henaff-module-advancefaqs-faqs .list-topics-FAQ .accordion-header:focus, .henaff-module-advancefaqs-faqs .list-topics-FAQ .accordion-header:hover {
  outline: none;
}
.henaff-module-advancefaqs-faqs .list-topics-FAQ .accordion-header:after {
  background-image: svg-load("chevron.svg", fill=#00244c);
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 18px;
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 18px;
  transform: rotate(-90deg);
  transition: transform 0.55s ease;
}
.henaff-module-advancefaqs-faqs .list-topics-FAQ .accordion-header[aria-expanded=true]:after, .henaff-module-advancefaqs-faqs .list-topics-FAQ .accordion-header.active-header:after {
  transform: rotate(90deg);
}
@media (min-width: 768px) {
  .henaff-module-advancefaqs-faqs .list-topics-FAQ .accordion-header {
    font-size: 16px;
    padding: 15px calc(15px * 2);
  }
}
.henaff-module-advancefaqs-faqs .list-topics-FAQ .accordion-header:before {
  content: "";
  position: absolute;
  left: 10px;
  top: 50%;
  height: 8px;
  width: 8px;
  transform: translateY(-4px);
  border-radius: 50%;
  background-color: #b88210;
}
.henaff-module-advancefaqs-faqs .list-topics-FAQ .accordion-content {
  display: none;
  padding: calc(15px * 2);
  background-color: #f9f2e8;
  margin-bottom: calc(15px * 2);
}
.henaff-module-advancefaqs-faqs .list-topics-FAQ .accordion-content.open-content {
  display: block;
}
.henaff-module-advancefaqs-faqs .list-topics-FAQ .accordion-content > *:not(.editor-content) {
  display: none;
}

/**
 * ----------------------------------
 * Gdpr stylesheet
 * ----------------------------------
 */
.gdpr-account-content .gdpr-section {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 500;
  padding: calc(15px * 2);
  background-color: #f9f2e8;
  margin-bottom: 15px;
  font-size: 14px;
  color: #00244c;
}
.gdpr-account-content .gdpr-section .title {
  font-family: montserrat, arial, helvetica, sans-serif;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 15px;
}
@media (min-width: 1024px) {
  .gdpr-account-content .gdpr-section .title {
    font-size: 24px;
  }
}
.gdpr-account-content .gdpr-section .text a {
  color: #00244c;
  text-decoration: none;
  position: relative;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
}
.gdpr-account-content .gdpr-section .text a:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #b88210;
  transform-origin: center;
  transition: transform 0.3s cubic-bezier(0.4, 0.5, 0.15, 1);
}
.gdpr-account-content .gdpr-section .text a:after {
  transform: scaleX(1);
}
.gdpr-account-content .gdpr-section .text a:hover:after {
  transform: scaleX(0);
}
.gdpr-account-content .gdpr-section .btn {
  color: #ffffff;
  background-color: #00244c;
  padding: 15px;
  border: 2px solid #00244c;
  font-size: 12px;
  line-height: normal;
  display: block;
  margin-bottom: 10px;
}
.gdpr-account-content .gdpr-section .btn:disabled {
  background-color: #b2bdc9;
  border-color: #b2bdc9;
  cursor: not-allowed;
}
.gdpr-account-content .gdpr-section .btn:hover {
  color: #ffffff;
  background-color: #b88210;
  border-color: #b88210;
}
.gdpr-account-content .gdpr-section .btn:first-child {
  margin-right: 0;
}
@media (min-width: 768px) {
  .gdpr-account-content .gdpr-section .btn:first-child {
    margin-right: 15px;
  }
}
@media (min-width: 768px) {
  .gdpr-account-content .gdpr-section .btn {
    display: inline-block;
    margin-bottom: 0;
  }
}
.gdpr-account-content .gdpr-section .btn--wrapper {
  text-align: right;
}

.henaff-module-psgdpr-gdpr .my-account-links--wrapper {
  margin-top: calc(15px * 2);
}
.henaff-module-psgdpr-gdpr .my-account-links--wrapper a {
  margin-top: 0;
}