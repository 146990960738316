/**
 * ----------------------------------
 * Block image text
 * ----------------------------------
 */
.home-about-us,
.home-focus,
.home-categories,
.home-boutique,
.home-social-wall {
  padding: $gutter 0;

  @include media-breakpoint-up(md) {
    padding: calc(#{$gutter} * 3) 0;
  }

  .description {
    margin-bottom: $gutter;

    &.editor-content {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      li,
      span,
      a {
        text-transform: none;
        color: col(white);
      }
    }
  }
}

.home-slide-product {
  .link-more-wrapper {
    text-align: center;
    padding-top: $gutter;

    .link-more {
      @include montserrat-semibold;
      @include button-color(white, blue, gold);

      font-size: $font-size-medium;
      text-transform: uppercase;
    }
  }
}

// About us
.home-about-us {
  .logo {
    width: 204px;
    margin: 0 auto calc(#{$gutter} + 5px);

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  .subtitle {
    @include montserrat-light;

    font-size: $font-size-title;
    color: col(gold);
    text-align: center;
  }

  .d-flex {
    flex-wrap: wrap;
    margin-right: 0;
    margin-left: 0;
    padding: calc(#{$gutter} * 3) 0;

    @include media-breakpoint-up(md) {
      margin-right: -#{$gutter};
      margin-left: -#{$gutter};
    }
  }

  .content-wrapper {
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    background-color: $modal-backdrop-bg;

    @include media-breakpoint-up(md) {
      width: 72%;
    }

    .image-cover {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
    }
  }

  .text-wrapper {
    color: col(white);
    flex-grow: 0;
    flex-shrink: 0;
    width: 100%;
    padding: calc(#{$gutter} * 2);

    @include media-breakpoint-up(md) {
      width: 38.86%;
      padding: calc(#{$gutter} * 5) calc(#{$gutter} * 3);
    }

    @include media-breakpoint-up(xl) {
      padding: calc(#{$gutter} * 5) calc(#{$gutter} * 3) calc(#{$gutter} * 5) 12%;
    }

    .title {
      @include typo-h2;
    }

    .description {
      @include roboto;
    }

    .cta {
      @include default-button;
    }
  }

  .video-wrapper {
    flex-grow: 0;
    flex-shrink: 0;
    width: 100%;
    position: relative;

    @include media-breakpoint-up(md) {
      width: 61.14%;
    }

    &:before {
      content: "";
      display: block;
      padding-top: 70%;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 50px;
      z-index: 0;
      display: block;
      background-color: col(white);

      @include media-breakpoint-up(md) {
        display: none;
      }
    }

    .video {
      position: absolute;
      top: 0;
      bottom: 0;
      left: $gutter;
      right: $gutter;
      z-index: 2;
      border: 1px solid col(white);
      cursor: pointer;

      @include media-breakpoint-up(md) {
        top: -#{$gutter};
        bottom: -#{$gutter};
        left: 0;
        right: 0;
      }
    }
  }

  .media-wrapper {
    flex-grow: 0;
    flex-shrink: 0;
    position: relative;
    background-color: $modal-backdrop-bg;

    @include media-breakpoint-up(md) {
      width: 28%;
    }

    .image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;

      img {
        width: 100%;
      }
    }
  }
}

// home focus
.home-focus {
  .subtitle {
    @include montserrat-medium;

    color: col(gold);
    font-size: $font-size-medium;
    text-transform: uppercase;

    @include media-breakpoint-up(md) {
      font-size: $font-size-subtitle;
    }
  }

  .title {
    @include montserrat-bold;

    color: col(white);
    font-size: $font-size-subtitle;
    width: 60%;

    @include media-breakpoint-up(md) {
      font-size: $font-size-title;
    }
  }

  .description {
    @include roboto;

    color: col(white);
  }

  .cta {
    @include default-button;
  }

  .focus-content {
    position: relative;

    @include media-breakpoint-up(lg) {
      padding-top: calc(#{$gutter} * 3);
      margin-right: -#{$gutter};
      margin-left: -#{$gutter};
    }
  }

  .focus-info {
    background-color: col(blue);
    padding: calc(#{$gutter} * 2) calc(#{$gutter} * 2) calc(#{$gutter} * 7);

    @include media-breakpoint-up(lg) {
      position: absolute;
      top: 0;
      right: 0;
      width: calc(66.67% - (#{$gutter} * 2));
      margin-right: calc(8.33% + #{$gutter});
      padding-left: 16.67%;
      padding-right: calc(#{$gutter} * 4);
      padding-bottom: calc(#{$gutter} * 2);
    }

    @include media-breakpoint-up(xl) {
      width: calc(58.33% - (#{$gutter} * 2));
      padding-left: calc(16.67% - (#{$gutter} * 2));
    }
  }

  .focus-visual {
    position: relative;
    width: 60%;
    max-width: 300px;
    margin: calc(#{$gutter} * -5) auto 0;

    @include media-breakpoint-up(lg) {
      max-width: auto;
      width: calc(33.33% - (#{$gutter} * 2));
      margin-top: 0;
      margin-left: calc(8.33% + #{$gutter});
    }

    &:before {
      content: "";
      display: block;
      padding-top: 145%;
    }

    .image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      img {
        top: 0;
        transform: rotate(.001deg) translate3d(-50%, 0, 0);
        width: 100%;
        min-height: auto;
      }
    }
  }
}

// Manufacturer home
.home-manufacturer-slide {
  .brand-link {
    @include montserrat-bold;

    font-size: $font-size-subtitle;
    text-transform: uppercase;
    text-decoration: none;
    color: col(grey);
    transition: color .3s $ease-default;

    &:hover {
      color: col(gold);
    }

    &.has-logo {
      position: relative;
      display: block;
      height: 130px;

      &:hover {
        img {
          filter: grayscale(0);
        }
      }
    }
  }

  .image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    img {
      width: 160px;
      min-width: auto;
      min-height: auto;
      filter: grayscale(1);
      transition: filter .3s $ease-default;

      @include media-breakpoint-up(md) {
        width: 130px;
      }
    }
  }

  .slider-wrapper {
    padding: 0;

    @include media-breakpoint-up(sm) {
      padding: 0 80px;
    }
  }

  .slick-slide {
    > div {
      display: flex;
      align-items: center;
      text-align: center;

      @include media-breakpoint-up(sm) {
        margin-left: $gutter;
        margin-right: $gutter;
      }
    }
  }
}

// Categories slide
.home-categories {
  a {
    text-decoration: none;
    color: col(white);
  }

  .cover {
    position: relative;
    height: 350px;

    .title-section {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1;
      text-align: center;
      margin-top: calc(#{$gutter} * 4);
    }

  }

  .categories {
    margin-top: -200px;

    .container {
      padding-right: 0;
      padding-left: 0;

      @include media-breakpoint-up(md) {
        padding-right: $gutter;
        padding-left: $gutter;
      }
    }

    .category {
      position: relative;
      background-color: col(blue);
      transform: scale(1);
      transition: all .5s ease;

      &:hover {
        transform: scale(1.17);
        background-color: col(gold);

        .text-content {
          background-color: rgba(col(gold), .85);

          .title {
            margin-top: calc(#{$gutter} * 3);
          }
        }

        .picto {
          opacity: 1;
          visibility: visible;
          transform: translate3d(0, 0, 0);
        }
      }

      .image {
        &:before {
          content: "";
          display: block;
          padding-top: 100%;
        }

        img {
          width: 60%;
          min-height: auto;
          min-width: auto;
        }
      }

      .picto {
        width: 90px;
        height: 90px;
        opacity: 0;
        visibility: hidden;
        margin: 0 auto;
        transform: translate3d(0, 50%, 0);
        transition: {
          delay: .15s;
          duration: .3s;
          property: all;
          timing-function: ease;
        };
      }

      .text-content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: calc(#{$gutter} * 3);
        background-color: rgba(col(blue), .85);
        display: flex;
        flex-direction: column;
        justify-content: center;
        transition: {
          duration: .5s;
          property: all;
          timing-function: ease;
        };

        .title {
          @include montserrat-semibold;

          text-align: center;
          color: col(white);
          text-transform: uppercase;
          font-size: $font-size-medium;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          padding: 0 calc(#{$gutter} * 3);
          margin-top: calc(#{$gutter} * 3);
          transition: {
            delay: .05s;
            duration: .3s;
            property: all;
            timing-function: ease;
          };

          @include media-breakpoint-up(lg) {
            font-size: $font-size-title;
            margin-top: calc(#{$gutter} * 5);
          }
        }
      }
    }
  }

  .slider-wrapper {
    padding: 0;
  }

  .slick-slide {
    > div {
      @include media-breakpoint-up(sm) {
        margin-left: $gutter;
        margin-right: $gutter;
      }
    }
  }

  .slick-list {
    @include media-breakpoint-up(sm) {
      margin-left: -#{$gutter};
      margin-right: -#{$gutter};
    }
  }
}

// Boutique
.home-boutique {
  .image-wrapper {
    @include figure-square;
  }

  .layer {
    position: relative;

    &-first,
    &-second {
      .image-wrapper {
        @include media-breakpoint-up(md) {
          width: calc(66.67% - #{$gutter});
        }

        @include media-breakpoint-up(lg) {
          width: calc(45% - #{$gutter});
        }

        @include media-breakpoint-up(xl) {
          width: calc(50% - #{$gutter});
        }
      }
    }

    &-first {
      justify-content: flex-start;
    }

    &-second {
      justify-content: flex-end;
      margin-top: -30%;
    }

    &-third {
      margin-top: 0;

      @include media-breakpoint-up(md) {
        margin-top: -10%;
      }

      @include media-breakpoint-up(lg) {
        margin-top: -25%;
      }

      .image-wrapper {
        width: 100%;

        @include media-breakpoint-up(md) {
          width: calc(66.67% - #{$gutter});
        }

        @include media-breakpoint-up(lg) {
          width: calc(38% - #{$gutter});
        }

        @include media-breakpoint-up(xl) {
          width: calc(41.5% - #{$gutter});
        }
      }
    }
  }

  .content {
    background-color: col(blue);
    padding: calc(#{$gutter} * 2);
    margin-bottom: $gutter;
    width: 100%;

    @include media-breakpoint-up(md) {
      width: calc(66.67% - #{$gutter});
      padding: calc(#{$gutter} * 5) calc(#{$gutter} * 2);
      position: absolute;
      top: 58%;
      left: 0;
      z-index: 2;
      transform: translate3d(0, -50%, 0);
      margin-left: calc(32.9% + #{$gutter});
    }

    @include media-breakpoint-up(lg) {
      width: calc(41.5% - #{$gutter});
      margin-left: calc(32.9% + #{$gutter});
    }

    @include media-breakpoint-up(xl) {
      padding: calc(#{$gutter} * 7) calc(#{$gutter} * 5);
    }

    .picto {
      width: 40px;
      margin-bottom: $gutter;

      @include media-breakpoint-up(md) {
        width: 60px;
      }
    }

    .title {
      @include montserrat-bold;

      color: col(white);
      font-size: $font-size-subtitle;

      @include media-breakpoint-up(md) {
        font-size: $font-size-title;
      }
    }

    .description {
      @include roboto;

      color: col(white);
    }

    .cta {
      @include default-button;
    }
  }
}

.home-social-wall {
  .title-section {
    text-transform: none;
    text-align: center;
    margin-bottom: $gutter;

    @include media-breakpoint-up(md) {
      margin-bottom: calc(#{$gutter} * 3);
    }
  }

  .post-feed {
    position: relative;
    overflow: hidden;
    cursor: pointer;

    &:hover {
      .image-wrapper {
        transform: scale(1.3);
      }

      .content {
        opacity: 1;
        visibility: visible;
        transform: translate3d(0, 0, 0);
      }

      .social {
        .like,
        .comment {
          opacity: 1;
          visibility: visible;
          transform: translate3d(0, 0, 0);
        }

        .count {
          opacity: 1;
          visibility: visible;
          transform: translate3d(0, 0, 0);
        }
      }

      .text-content {
        opacity: 1;
        visibility: visible;
        transform: translate3d(0, 0, 0);
      }
    }
  }

  .image-wrapper {
    @include figure-square;

    .image img {
      height: 100%;
      width: auto;
      min-width: auto;
    }

    transform: scale(1);
    transition: transform .5s $ease-default;
  }

  .content {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    transform: translate3d(0, 100%, 0);
    background-color: $modal-backdrop-bg;
    transition: all .5s $ease-default;

    @include media-breakpoint-up(md) {
      transition: all .5s ease-in-out;
      transform: none;
      top: 0;
    }
  }

  .social {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: calc(#{$gutter} * 2);

    .picto {
      margin-right: 10px;

      img {
        width: auto;
        height: 40px;
      }
    }

    .count {
      opacity: 0;
      visibility: hidden;
      transform: translate3d(-15px, 0, 0);
      transition: all .3s ease-in-out;
    }

    .like,
    .comment {
      @include montserrat;
      @include staggerAnimation (110ms, 2);

      font-size: $font-size-medium;
      display: flex;
      align-items: center;
      color: col(white);
      opacity: 0;
      visibility: hidden;
      transition: all .5s $ease-default;
      transform: translate3d(0, 100%, 0);
    }

    .like {
      .picto {
        img {
          width: auto;
          height: 38px;
        }
      }

      .count {
        transition-delay: .5s;
      }
    }

    .comment {
      .count {
        transition-delay: .6s;
      }
    }
  }

  .text-content {
    @include roboto;

    color: col(white);
    font-size: $font-size-small;
    word-break: break-word;
    padding: calc(#{$gutter} * 2);
    opacity: 0;
    visibility: hidden;
    transform: translate3d(0, 30px, 0);
    transition: all .5s $ease-default;
    transition-delay: 400ms;
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  .social-link {
    margin-top: $gutter;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .libelle {
      @include montserrat-semibold;
      @include link(blue, blue, 14px, 10px);

      text-transform: uppercase;
      line-height: 40px;
      cursor: initial;
    }

    .icon {
      width: 40px;
      height: 40px;
      display: block;
      margin-right: 0;
      margin-left: 10px;

      &.facebook-white {
        line-height: 0;
        border-radius: 22px;
        background-color: col(blue);
        background-size: 22px;
        background-position: center;
        width: 44px;
        height: 44px;
      }
    }
  }
}
