/**
 * ----------------------------------
 * Color
 * ----------------------------------
 */
$theme-colors: (
  "white": #ffffff,
  "grey": #b2bdc9,
  "light-grey": #f8fbff,
  "mid-grey": #eeeeef,
  "dark-grey": #45586d,
  "grey-contact": #808080,
  "black": #000000,
  "blue": #00244c,
  "blue-dark": #00162e,
  "gold": #b88210,
  "light-gold": #f9f2e8,
  "green": #39b54a,
  "red": #ff1100,
  "orange": #ff9800,
  "promo": #02a5e6,
  "facebook": #385c8e,
  "twitter": #76a9ea,
  "google-plus": #f34a38,
  "pinterest": #d7143a
);
