/**
 * ----------------------------------
 * Social share stylesheet
 * ----------------------------------
 */
.social-sharing {
  text-align: right;
  position: relative;

  .share-toggler {
    border: none;
    background: transparent;
    width: 40px;
    height: 40px;
    padding: 5px;

    img {
      display: block;
      width: auto;
      height: 100%;
    }
  }

  .share-icons {
    display: flex;
    align-items: center;
    position: absolute;
    top: 40px;
    right: 0;
    flex-direction: column;
    z-index: 1;

    @include media-breakpoint-up(sm) {
      top: 0;
      right: 40px;
      flex-direction: row-reverse;
    }

    &.toggle {
      li {
        opacity: 1;
        visibility: visible;
        transform: translate3d(0, 0, 0);
      }
    }

    li {
      opacity: 0;
      visibility: hidden;
      margin: 10px 0 0;
      transform: translate3d(0, -10px, 0);
      transition: opacity 100ms ease-in-out, visibility 100ms ease-in-out, transform 100ms ease-in-out;

      @include staggerAnimation (100ms, 5);

      @include media-breakpoint-up(sm) {
        margin: 0 10px 0 0;
        transform: translate3d(10px, 0, 0);
      }
    }

    .social-icon {
      display: block;
      width: 40px;
      height: 40px;
      transition: all .3s;
      border-radius: 20px;
      border: 1px solid col(grey);
      background-color: col(grey);
      background-size: 20px;
      background-position: center;
      background-repeat: no-repeat;
    }

    .icon-facebook {
      .social-icon {
        @include svg('facebook.svg', white);

        &:hover {
          border-color: col(facebook);
          background-color: col(facebook);
        }
      }
    }

    .icon-twitter {
      .social-icon {
        @include svg('twitter.svg', white);

        &:hover {
          border-color: col(twitter);
          background-color: col(twitter);
        }
      }
    }

    .icon-googleplus {
      .social-icon {
        @include svg('google-plus.svg', white);

        background-size: 26px;

        &:hover {
          border-color: col(google-plus);
          background-color: col(google-plus);
        }
      }
    }

    .icon-pinterest {
      .social-icon {
        @include svg('pinterest.svg', white);

        &:hover {
          border-color: col(pinterest);
          background-color: col(pinterest);
        }
      }
    }

    .icon-mail {
      .social-icon {
        @include svg('mail.svg', white);

        &:hover {
          border-color: col(gold);
          background-color: col(gold);
        }
      }
    }
  }
}
