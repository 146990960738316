/**
 * ----------------------------------
 * PAge cms stylesheet
 * ----------------------------------
 */
.henaff-cms {
  .cms-content {
    #{$header-selector} {
      &:not(.text-uppercase) {
        text-transform: none;
      }
    }

    a {
      @include roboto-semibold;
      @include link(black, gold);
    }

    h3 {
      @include montserrat-light;

      color: col(gold);
    }

    h4 {
      @include typo-h3;

      color: col(blue);
    }

    .border-top {
      padding-top: $gutter;
      border-top: 1px solid col(gold);

      @include media-breakpoint-up(md) {
        padding-top: calc(#{$gutter} * 2);
      }
    }

    .image-content,
    .video {
      margin-bottom: $gutter;

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
    }

    .image-text,
    .video-text {
      padding: calc(#{$gutter} * 2) 0;

      @include media-breakpoint-up(md) {
        padding: calc(#{$gutter} * 3) 0;
      }
    }

    .image-content {
      position: relative;
      padding-top: 70%;

      .image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;

        img {
          width: 100%;
          min-height: auto;
        }
      }
    }

    .section-header {
      text-align: center;
    }

    .section-column {
      div[class*="col-"] {
        margin-top: $gutter;

        @include media-breakpoint-up(md) {
          margin-top: calc(#{$gutter} * 2);
        }
      }
    }

    .loyalty-detail {
      max-width: 600px;
    }
  }
}
