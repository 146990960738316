/**
 * ----------------------------------
 * Page 404 stylesheet
 * ----------------------------------
 */
.page-not-found {
  .btn {
    @include button-color(white, blue, gold);

    padding: calc(#{$gutter} - 5px) calc(#{$gutter} * 2);
  }

  &.page-content {
    .container {
      position: relative;
    }
  }

  .content {
    max-width: 100%;
    text-align: center;
    margin-bottom: calc(#{$gutter} * 2);

    @include media-breakpoint-up(lg) {
      text-align: left;
      position: absolute;
      top: 40%;
      left: $gutter;
      max-width: 50%;
      z-index: 1;
      transform: translate3d(0, -50%, 0);
    }

    h2 {
      @include typo-h1;

      color: col(blue);
    }

    p {
      @include montserrat-bold;

      color: col(blue);
      font-size: 18px;
    }

    .searchbar {
      width: 300px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: $gutter;

      @include media-breakpoint-up(lg) {
        margin-left: 0;
        margin-right: 0;
      }

      input[type="text"] {
        padding: 11px 40px 10px $gutter;
        width: 100%;
      }

      button {
        background-color: col(gold);
        padding: 5px;
        width: 40px;
        height: 40px;
        border: none;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
      }

      .search {
        @include svg('search.svg', white);

        width: 30px;
        height: 30px;
      }
    }
  }

  .background {
    &-wrapper {
      position: relative;
      padding-top: 75%;

      @include media-breakpoint-up(lg) {
        right: calc((#{$width-no-scroll} - 930px) / -2);
        width: 90%;
        margin-left: auto;
      }

      @include media-breakpoint-up(xl) {
        right: calc((#{$width-no-scroll} - 1140px) / -2);
      }

      @media (min-width: 1600px) {
        right: -25%;
      }

      .image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        img {
          height: 100%;
          min-width: auto;
        }
      }
    }
  }

  .block-reassurance {
    display: none;
  }

  #main-footer {
    margin: 0;
  }
}

.page-search {
  .product-list {
    .title {
      @include typo-h1;

      text-transform: uppercase;
      color: col(blue);
      margin-bottom: calc(#{$gutter} * 2);
    }

    .search-result-content {
      @include montserrat-medium;

      font-size: $font-size-medium;
    }
  }
}
