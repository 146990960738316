/**
 * ----------------------------------
 * Functions
 * ----------------------------------
 */

// Get colors
@function col($color) {
  @return map-get($theme-colors, '#{$color}');
}
