/**
 * ----------------------------------
 * Loyaty stylesheet
 * ----------------------------------
 */
.loyalty-detail {
  padding: $gutter;
  box-shadow: $shadow-card;

  .title {
    @include typo-h3;

    color: col(blue);
  }

  .logo {
    @include figure-square;

    width: 100%;
    max-width: 280px;
    margin: auto;

    @include media-breakpoint-up(md) {
      width: 25%;
    }
  }

  .text {
    @include montserrat;
    padding-left: $gutter;
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 75%;
    }

    strong {
      color: col(gold);
    }
  }

  .d-flex {
    flex-wrap: wrap;
  }
}
