/**
 * ----------------------------------
 * Block image text
 * ----------------------------------
 */
.block-image-text {
  padding: calc(#{$gutter} * 3) 0;

  .text-content {
    padding: calc(#{$gutter} * 3);
    background-color: col(blue);
  }

  .subtitle {
    @include montserrat-medium;

    font-size: $font-size-subtitle;
    color: col(gold);
    text-transform: uppercase;
  }

  .title {
    @include montserrat-medium;

    font-size: $font-size-title;
    color: col(white);
  }

  .description {
    color: col(white);
    font-size: $font-size-default;
  }

  .cta {
    @include default-button;
  }

  .media--wrapper {
    &:after {
      content: "";
      display: block;
      padding-bottom: 70%;

      @include media-breakpoint-up(md) {
        padding-bottom: 40%;
      }
    }

    .image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}
