/**
 * ----------------------------------
 * Image stylesheet
 * ----------------------------------
 */

.image {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  transform: translateZ(0);

  &.ready {
    img {
      opacity: 1;
    }
  }

  .media {
    width: 100%;
    height: 100%;
  }

  img {
    display: block;
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: rotate(.001deg) translate3d(-50%, -50%, 0);
    z-index: 0;
    opacity: 0;
    transition: opacity .5s cubic-bezier(.4, .5, .15, 1);
    perspective: 1000px;
  }
}

.picto {
  img {
    display: block;
    width: 100%;
    height: auto;
  }
}
