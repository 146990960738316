/**
 * ----------------------------------
 * FAQ stylesheet
 * ----------------------------------
 */
.henaff-module-advancefaqs-faqs {
  .title {
    @include typo-h1;

    color: col(blue);
    margin-bottom: calc(#{$gutter} * 2);
  }

  .description {
    @include montserrat;
  }

  .slide {
    margin: $gutter 0;

    a {
      @include montserrat-bold;
      @include link(blue, gold);
    }
  }

  // list Subjects
  .list-subject-FAQ {
    margin-top: calc(#{$gutter} * 2);

    .categories {
      flex-wrap: wrap;
      align-items: center;
      padding: 10px 0;
      border: {
        top: 1px solid col(gold);
        bottom: 1px solid col(gold);
      };

      > li {
        position: relative;
        padding-left: 10px;
        margin: 10px 0;
        flex-grow: 0;
        flex-shrink: 0;

        &:not(:last-child) {
          padding-right: 10px;

          &:after {
            content: "";
            position: absolute;
            right: 0;
            top: 50%;
            height: 24px;
            width: 1px;
            background-color: col(gold);
            transform: translate3d(0, -12px, 0);
          }
        }
      }
    }

    .category-name {
      @include montserrat-semibold;

      color: col(blue);
      text-decoration: none;
      font-size: $font-size-medium;
      transition: .3s all $ease-default;

      &:hover {
        color: col(gold);
      }
    }
  }

  .list-topics-FAQ {
    padding-bottom: calc(#{$gutter} * 4);

    .topic-title {
      margin-top: calc(#{$gutter} * 2);
      margin-bottom: $gutter;

      @include media-breakpoint-up(md) {
        margin-top: calc(#{$gutter} * 3);
      }

      .title {
        @include montserrat-bold;

        color: col(blue);
        text-decoration: none;
        font-size: $font-size-title;
        transition: .3s all $ease-default;
        margin-bottom: 0;

        &:hover {
          color: col(gold);
        }
      }
    }

    // accordion
    .accordion {
      &-header {
        @include montserrat-bold;
        @include accordion-header(blue, "chevron.svg", blue, 18px);

        font-size: $font-size-default;
        border: none;
        text-transform: none;
        padding: 10px calc(#{$gutter} * 2);
        cursor: pointer;

        @include media-breakpoint-up(md) {
          font-size: $font-size-medium;
          padding: $gutter calc(#{$gutter} * 2);
        }

        &:before {
          content: "";
          position: absolute;
          left: 10px;
          top: 50%;
          height: 8px;
          width: 8px;
          transform: translateY(-4px);
          border-radius: 50%;
          background-color: col(gold);
        }
      }

      &-content {
        display: none;
        padding: calc(#{$gutter} * 2);
        background-color: col(light-gold);
        margin-bottom: calc(#{$gutter} * 2);

        &.open-content {
          display: block;
        }

        > * {
          &:not(.editor-content) {
            display: none;
          }
        }
      }
    }
  }
}
