/**
 * ----------------------------------
 * Global layout
 * ----------------------------------
 */
#captcha-box {
  margin-bottom: $gutter;
}

.henaff-index,
.henaff-authentication,
.henaff-password,
.henaff-cart,
.henaff-order-confirmation,
.henaff-my-account,
.henaff-identity,
.henaff-address,
.henaff-addresses,
.henaff-history,
.henaff-order-slip,
.henaff-loyalty,
.henaff-order-detail,
.henaff-module-henaff_asten-recovery_carte {
  .breadcrumb {
    display: none;
  }
}

.henaff-authentication,
.henaff-password,
.henaff-cart,
.henaff-checkout,
.henaff-order-confirmation,
.henaff-my-account,
.henaff-identity,
.henaff-address,
.henaff-addresses,
.henaff-history,
.henaff-order-slip,
.henaff-loyalty,
.henaff-order-detail,
.henaff-module-henaff_asten-recovery_carte {
  #main-wrapper {
    margin-top: calc(#{$gutter} * 4);
  }
}

.henaff-module-ps_emailsubscription-verification {
  .message-txt {
    @include montserrat-bold;

    color: col(blue);
    font-size: 18px;
    margin-bottom: 0;
    padding: calc(#{$gutter} * 3) 0;
  }
}

.page-header {
  margin-bottom: calc(#{$gutter} * 2);

  .title {
    @include typo-h1;

    color: col(blue);
    text-transform: uppercase;
  }
}

// hidden
.hidden {
  &-max-sm {
    &.ready {
      display: none;

      @include media-breakpoint-down(sm) {
        display: block;
      }
    }
  }

  &-sm {
    &.ready {
      display: none;

      @include media-breakpoint-up(md) {
        display: block;
      }
    }
  }
}
