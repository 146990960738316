/**
 * -------------------------------------------------------------
 * Google fonts
 * -------------------------------------------------------------
 */

// Roboto
@import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,,700i&display=swap');

// Montserrat
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,500,500i,600,600i,700,700i&display=swap');
