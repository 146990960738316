/**
 * ----------------------------------
 * SVG
 * ----------------------------------
 */
.danger {
  @include svg('warning.svg', red);
}

.feedback {
  @include svg('comment.svg', orange);
}

.play {
  @include svg('play.svg', white);
}

.edit {
  @include svg('edit.svg', grey-contact);
}

.close {
  @include svg('close.svg', gold);

  &-white {
    @include svg('close.svg', white);
  }

  &-blue {
    @include svg('close.svg', blue);
  }
}

.panier {
  @include svg('panier.svg', gold);
}

.compte {
  @include svg('compte.svg', gold);
}

.envelop {
  @include svg('envelop.svg', gold);
}

.location {
  @include svg('pin.svg', gold);
}

.gift {
  @include svg('gift.svg', gold);
}

.return-merchandise {
  @include svg('return.svg', gold);
}

.voucher {
  @include svg('voucher.svg', gold);
}

.euro {
  @include svg('euro.svg', gold);
}

.search {
  @include svg('search.svg', gold);
}

.delete {
  @include svg('delete.svg', blue);

  &-white {
    @include svg('delete.svg', white);
  }

  &-grey {
    @include svg('delete.svg', grey-contact);
  }
}

.help {
  @include svg('help.svg', grey);

  &:hover {
    @include svg('help.svg', blue);
  }
}

.slide-next {
  @include svg('slide-next.svg', blue);
}

.slide-prev {
  @include svg('slide-prev.svg', blue);
}

.turn {
  @include svg('turn-right.svg', gold);
}

.eye {
  @include svg('eye.svg', blue);
}

.livraison {
  @include svg('livraison.svg');
}

.pin-blue {
  @include svg('pin-blue.svg');
}

.instagram {
  @include svg('instagram.svg', blue);
}

.facebook {
  @include svg('facebook.svg', blue);

  &-white {
    @include svg('facebook.svg', white);
  }

  &-outline {
    @include svg('facebook-outline.svg', blue);
  }
}
