/**
 * ----------------------------------
 * Page contact stylesheet
 * ----------------------------------
 */
.henaff-contact {
  #contact-form {
    .form-fields {
      .custom-select {
        .holder {
          @include roboto;

          color: col(grey);
          font-size: $font-size-default;
        }
      }

      .d-flex {
        justify-content: left;
        margin-left: -$gutter;
        margin-right: -$gutter;

        > [class*="form-control-"] {
          flex-grow: 0;
          padding-left: $gutter;
          padding-right: $gutter;
        }
      }

      .form-control-label {
        width: 100%;

        @include media-breakpoint-up(md) {
          text-align: right;
          width: 25%;
        }

        @include media-breakpoint-up(xl) {
          width: 16.67%;
        }
      }

      .form-control-input {
        width: 100%;

        @include media-breakpoint-up(md) {
          width: 58.33%;
        }

        @include media-breakpoint-up(xl) {
          width: 41.67%;
        }
      }

      .form-control-note {
        @include roboto;

        color: col(grey);
        font-size: $font-size-default;
        font-style: italic;
        padding-top: 5px;
        display: inline-block;
      }

      .form-control-comment {
        margin-left: 0;
      }
    }
  }

  .form-footer {
    text-align: right;
  }

  .form-footer,
  .gpdr-text,
  .contact-info {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: calc(85.53% - 30px);
    }

    @include media-breakpoint-up(xl) {
      width: calc(59.86% - 30px);
    }
  }

  .gpdr-text {
    @include roboto;

    color: col(black);
    font-size: $font-size-small;
    margin-top: calc(#{$gutter} * 2);

    @include media-breakpoint-up(md) {
      margin-top: calc(#{$gutter} * 3);
    }

    a {
      @include link(black, black);

      font-size: $font-size-small;
    }
  }

  .contact-rich {
    .contact-info {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      border-top: 1px solid col(gold);
    }

    .info,
    .adress {
      flex-grow: 0;
      flex-shrink: 0;
      width: 100%;
      min-height: 42px;

      @include media-breakpoint-up(sm) {
        width: 50%;
      }
    }

    .mail,
    .fax,
    .phone,
    .adress,
    .facebook {
      @include roboto;

      padding-left: 40px;
      margin: $gutter 0;

      a {
        color: col(blue);
        text-decoration: none;
      }
    }

    .mail,
    .fax {
      @include background(true, "envelop.svg", gold, 30px, left, top);
    }

    .phone {
      @include background(true, "telephone.svg", gold, 30px, left, top);
    }

    .adress {
      @include background(true, "pin.svg", gold, 30px, left, top);
    }

    .facebook {
      height: 30px;
      line-height: 30px;
      @include background(true, "facebook-out.svg", gold, 30px, left, top);
    }
  }
}
