/**
 * ----------------------------------
 * Account stylesheet
 * ----------------------------------
 */
.henaff-my-account,
.henaff-identity,
.henaff-address,
.henaff-addresses,
.henaff-history,
.henaff-order-slip,
.henaff-loyalty {
  .account-sidemenu {
    margin-bottom: calc(#{$gutter} * 2);

    .links-list {
      li {
        margin-bottom: $gutter;

        &.active {
          a {
            color: col(gold);

            &:after {
              background-color: col(gold);
              transform: scaleX(1);
            }
          }
        }
      }

      a {
        @include montserrat-medium;

        font-size: $font-size-medium;
        color: col(blue);
        text-decoration: none;
        position: relative;

        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          transform: scaleX(0);
          transform-origin: center;
          transition: all .3s $ease-default;
        }

        &:hover {
          color: col(gold);

          &:after {
            background-color: col(gold);
            transform: scaleX(1);
          }
        }
      }
    }

    .log-out {
      padding-top: $gutter;

      a {
        @include button-border(blue, gold);

        padding: calc(#{$gutter} - 5px) calc(#{$gutter} * 2);
        width: 100%;
      }
    }
  }

  .account-page-title {
    @include typo-h2;

    display: flex;
    align-items: center;
    color: col(blue);
    margin-bottom: calc(#{$gutter} * 2);

    .icon {
      width: 35px;
      height: 35px;
      margin-right: 10px;
    }
  }
}

.address-form,
.identity-form {
  padding: calc(#{$gutter} * 2) $gutter;
  border: 1px solid col(gold);

  @include media-breakpoint-up(md) {
    padding: {
      top: calc(#{$gutter} * 3);
      left: calc(#{$gutter} * 2);
      right: calc(#{$gutter} * 2);
      bottom: calc(#{$gutter} * 3);
    };
  }

  /* @include media-breakpoint-up(lg) {
    padding: {
      top: calc(#{$gutter} * 3);
      left: calc(#{$gutter} * 2);
      right: calc(#{$gutter} * 2);
      bottom: calc(#{$gutter} * 3);
    };
  } */
}

// My account
.henaff-my-account {
  .links {
    li {
      width: 100%;

      @media (min-width: 480px) {
        width: 50%;
      }
    }
  }

  .card-account {
    position: relative;
    padding: calc(#{$gutter} * 2) calc(#{$gutter} * 2) calc(#{$gutter} * 5);
    border: 1px solid col(gold);
    display: block;
    height: 350px;
    margin-bottom: $gutter;
    text-decoration: none;

    @include media-breakpoint-up(md) {
      margin-bottom: calc(#{$gutter} * 2);
    }

    &:hover {
      .link {
        color: col(blue);
        background-color: transparent;
      }
    }

    .icon {
      width: 40px;
      height: 40px;
      margin-bottom: $gutter;
      margin-right: 0;
    }

    .title {
      @include montserrat-bold;

      font-size: 18px;
      color: col(blue);
      margin-bottom: 10px;

      @include media-breakpoint-up(xl) {
        font-size: $font-size-subtitle;
      }
    }

    .description {
      @include montserrat-medium;

      color: col(blue);
      font-size: $font-size-small;
    }

    .btn {
      @extend %button;

      color: col(white);
      background-color: col(blue);
      padding: calc(#{$gutter} - 5px) calc(#{$gutter} * 2);
      border: 2px solid col(blue);
      margin-bottom: 0;
      position: absolute;
      bottom: calc(#{$gutter} * 2);
      left: calc(#{$gutter} * 2);
      transition: all .3s $ease-default;

      &:hover {
        color: col(blue);
        background-color: transparent;
        border-color: col(blue);
      }
    }
  }
}

// Adress form
.henaff-address,
.henaff-identity {
  form {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 80%;
    }
  }

  .customer-address-footer,
  .customer-identity-footer {
    text-align: right;

    .btn {
      min-width: 250px;
    }
  }
}

// Adresses
.henaff-addresses {
  .addresses-list {
    address {
      @include montserrat-medium;

      margin: $gutter 0;
      color: col(blue);
    }

    .address {
      cursor: pointer;
      padding: calc(#{$gutter} * 2);
      border: 1px solid col(grey);
      margin-bottom: $gutter;
      transition: all .3s $ease-default;
      display: block;

      &:hover {
        box-shadow: $box-shadow;
      }

      &-alias {
        @include montserrat-bold;

        font-size: $font-size-subtitle;
        color: col(blue);
      }

      &-footer {
        padding-top: 10px;
        border-top: 1px solid col(gold);

        a {
          @include montserrat-semibold;

          color: col(grey-contact);
          text-decoration: none;
          font-size: $font-size-small;
          margin-right: 10px;

          &:after {
            display: none;
          }
        }
      }
    }
  }

  .addresses-footer {
    margin-top: $gutter;

    a {
      @include button-border(blue, gold);

      padding: 10px $gutter;
      font-size: $font-size-small;
      width: 100%;

      @include media-breakpoint-up(sm) {
        font-size: $font-size-default;
        width: auto;
      }

      @include media-breakpoint-up(md) {
        padding: 10px calc(#{$gutter} * 2);
      }
    }

    .add {
      @include montserrat-medium;

      font-style: normal;
      color: col(blue);
      padding-right: 10px;
    }
  }
}

// History && Order-slip
.henaff-history,
.henaff-order-slip {
  .history-list,
  .order-slip-list {
    padding: calc(#{$gutter} * 2);
    border: 1px solid col(gold);
  }

  .orders,
  .credit-slips {
    padding-left: 0;

    @include media-breakpoint-up(md) {
      padding-left: calc(#{$gutter} * 3);
    }

    .order,
    .slip {
      border-bottom: 1px solid col(gold);
      margin-bottom: calc(#{$gutter} * 2);

      &-list {
        @include montserrat-medium;

        color: col(blue);
        margin-bottom: $gutter;

        .date {
          @include montserrat-bold;

          font-size: $font-size-medium;
          color: col(blue);
        }

        .reference {
          span {
            @include montserrat-bold;

            color: col(gold);
          }
        }

        .price,
        .payment,
        .state,
        .number {
          span {
            @include montserrat-bold;
          }
        }
      }

      &-actions {
        a {
          @include button-border(blue, gold);

          padding: 10px calc(#{$gutter} * 2);
          display: block;
          margin-bottom: $gutter;
        }
      }
    }
  }
}

// Loyalty
.henaff-loyalty {
  .loyalty-list {
    padding: calc(#{$gutter} * 2);
    border: 1px solid col(gold);
    margin-bottom: $gutter;
  }

  .loyalty {
    padding-left: 0;

    @include media-breakpoint-up(md) {
      padding-left: calc(#{$gutter} * 3);
    }

    &-form {
      flex-wrap: wrap;
      justify-content: space-between;

      .form-group {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: $gutter;

        @include media-breakpoint-up(md) {
          flex-grow: 0;
          flex-shrink: 0;
          width: 50%;

          &:first-child {
            padding-right: 7.5px;
          }

          &:last-child {
            padding-left: 7.5px;
          }
        }
      }

      label {
        @include montserrat-bold;

        color: col(blue);
        line-height: 1;
        padding-right: $gutter;
        margin-bottom: $gutter;

        @include media-breakpoint-up(md) {
          margin-bottom: 0;
        }
      }

      .code {
        @include montserrat;

        color: col(blue);
        line-height: 1;
        border-bottom: 1px solid col(grey);
        padding-left: 4px;
        padding-bottom: 4px;
        flex-grow: 1;
        flex-shrink: 0;
        width: 100%;

        @include media-breakpoint-up(md) {
          width: auto;
        }
      }
    }

    &-card {
      border-bottom: 1px solid col(gold);
      margin-bottom: $gutter;
      padding-bottom: $gutter;

      .form-group {
        flex-wrap: wrap;

        label {
          flex-grow: 1;
          flex-shrink: 0;
          width: 100%;
          margin-bottom: $gutter;

          @include media-breakpoint-up(lg) {
            width: 50%;
          }
        }
      }

      .link-toggler {
        @include link(blue, blue);
      }

      .link {
        @include link(blue, blue);
      }

      .form-wrapper {
        padding-left: 0;
        text-align: right;
        flex-grow: 1;
        flex-shrink: 0;
        width: 100%;
        opacity: 0;
        visibility: hidden;
        transition: .3s all $ease-default;

        @include media-breakpoint-up(lg) {
          padding-left: $gutter;
          width: 50%;
        }

        &.show {
          opacity: 1;
          visibility: visible;
        }
      }

      .code {
        display: block;
        margin-bottom: $gutter;
        width: 100%;
      }

      .btn {
        @include button-color(white, blue, gold);
        padding: 10px calc(#{$gutter} * 2);
        display: inline-block;
      }
    }

    &-code {
      margin-top: calc(#{$gutter} * 2);

      .title {
        @include montserrat-bold;

        color: col(blue);
        line-height: 1;
        margin-bottom: calc(#{$gutter} * 2);
      }

      .code-list {
        li {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 10px;
        }

        .reference,
        .date {
          flex-grow: 1;
          flex-shrink: 0;
        }

        .reference {
          @include montserrat-bold;

          color: col(gold);
        }

        .date {
          @include montserrat;
        }
      }
    }
  }
}

.henaff-order-detail {
  #order-infos,
  #order-history {
    padding: calc(#{$gutter} * 2);
    border: 1px solid col(gold);
    margin-bottom: $gutter;
  }

  .box {
    .order,
    .title {
      @include montserrat-bold;

      font-size: $font-size-medium;
      color: col(blue);
    }

    .reference {
      @include montserrat;

      span {
        @include montserrat-bold;
        color: col(gold);
      }
    }

    .detail-list {
      li {
        @include montserrat-medium;
      }

      span {
        @include montserrat-bold;

        font-size: $font-size-medium;
        color: col(blue);
      }
    }

    .button {
      @include button-border(blue, gold);

      padding: 10px calc(#{$gutter} * 2);
    }

    .invoice-url {
      margin-top: calc(#{$gutter} * 2);

      a {
        @include button-color(white, blue, gold);
      }
    }

    .history-lines {
      li {
        @include montserrat-medium;
        color: col(blue);
      }

      .state {
        @include montserrat-bold;
      }
    }
  }

  .addresses {
    .box {
      padding: calc(#{$gutter} * 2);
      border: 1px solid col(grey);
      margin-bottom: $gutter;
      transition: all .3s $ease-default;
      display: block;

      h4 {
        @include montserrat-bold;

        font-size: $font-size-medium;
        color: col(blue);
      }

      address {
        @include montserrat-medium;

        margin: $gutter 0;
        color: col(blue);
      }
    }
  }

  .order-detail,
  .order-carriers {
    padding: calc(#{$gutter} * 2);
    border: 1px solid col(grey);
    margin-bottom: $gutter;

    @include media-breakpoint-up(md) {
      border: none;
      padding: 0;
    }

    .table {
      border: 1px solid col(grey);

      thead {
        th {
          @include montserrat-bold;

          color: col(blue);
          padding: $gutter;
          text-align: left;
          background-color: rgba(col(grey), .3);

          &:last-child {
            width: 1%;
            white-space: nowrap;
          }
        }
      }

      tbody {
        td {
          @include montserrat;

          padding: $gutter;
          border-bottom: 1px solid col(grey);
        }

        p {
          margin-bottom: 0;
        }
      }

      tfoot {
        td {
          @include montserrat;

          padding: $gutter;

          &.text-align-right {
            @include montserrat-bold;

            color: col(blue);
          }
        }
      }
    }
  }

  .order-detail {
    .order-items {
      .order-item {
        margin-bottom: $gutter;
        padding-bottom: $gutter;
        border-bottom: 1px solid col(gold);

        .qty {
          span {
            @include montserrat-medium;
          }
        }
      }
    }

    .order-totals {
      .label {
        @include montserrat-bold;

        color: col(blue);
      }

      .value {
        @include montserrat-medium;
      }
    }
  }

  .order-carriers {
    .shipping-lines {
      li {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      .label {
        @include montserrat-bold;

        color: col(blue);
      }

      .value {
        @include montserrat-medium;
      }
    }
  }

  .return-order-history {
    padding: calc(#{$gutter} * 2) 0;
  }

  .order-message-form {
    margin-top: calc(#{$gutter} * 2);

    @include media-breakpoint-up(md) {
      margin-top: calc(#{$gutter} * 4);
    }

    .title {
      @include typo-h3;
    }

    .description {
      @include montserrat-medium;
    }

    .box {
      margin: auto;
      width: 100%;

      @include media-breakpoint-up(lg) {
        width: 60%;
      }
    }

    .form-group {
      flex-wrap: wrap;
      margin-bottom: $gutter;

      label {
        @include montserrat-medium;

        color: col(blue);
        font-size: $font-size-default;
        line-height: 1;
        flex-grow: 0;
        flex-shrink: 0;
        width: 100%;
        margin-bottom: $gutter;

        @include media-breakpoint-up(sm) {
          width: 25%;
          font-size: $font-size-medium;
          margin-bottom: 0;
        }
      }

      textarea,
      .custom-select {
        width: 100%;
      }

      .form-control-input {
        flex-grow: 1;
        flex-shrink: 0;
        width: 100%;

        @include media-breakpoint-up(sm) {
          width: auto;
        }
      }
    }
  }
}
