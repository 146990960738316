/**
 * ----------------------------------
 * Page boutiques stylesheet
 * ----------------------------------
 */
.henaff-stores {
  .store {
    &-info {
      padding: calc(#{$gutter} * 2) 0;

      @include media-breakpoint-up(md) {
        padding: calc(#{$gutter} * 4) 0;
      }

      .text-content {
        padding-right: 0;

        @include media-breakpoint-up(lg) {
          padding-right: 15%;
        }
      }

      .title {
        @include typo-h1;

        color: col(blue);
      }
    }

    &-picture {
      position: relative;

      &:before {
        content: "";
        display: block;
        padding-top: 75%;
      }

      .image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }

    &-detail {
      background-color: col(light-gold);
      padding: {
        left: calc(#{$gutter} * 2);
        right: calc(#{$gutter} * 2);
        top: calc(#{$gutter} * 5);
        bottom: calc(#{$gutter} * 5);
      };

      @include breakpointMixin() using ($breakpoint, $container-max-width) {
        $padding: calc((#{$width-no-scroll} - (#{$container-max-width} - 30px)) / 2);

        padding: {
          left: calc(6.6% + #{$gutter});
          right: $padding;
        };
      }

      &.flex-md-row-reverse {
        @include breakpointMixin() using ($breakpoint, $container-max-width) {
          $padding: calc((#{$width-no-scroll} - (#{$container-max-width} - 30px)) / 2);

          padding: {
            right: calc(6.6% + #{$gutter});
            left: $padding;
          };
        }
      }
    }

    &-description {
      margin-bottom: calc(#{$gutter} * 3);

      .title {
        @include montserrat-light;

        color: col(gold);
        font-size: $font-size-subtitle;
        margin-bottom: $gutter;
      }
    }

    &-info-pratique {
      @include montserrat-bold;

      color: col(blue);
      border-bottom: 1px solid col(gold);
    }

    &-contact {
      @include roboto;

      flex-wrap: wrap;
      margin-bottom: calc(#{$gutter} * 2);

      address,
      ul {
        flex-grow: 1;
        flex-shrink: 0;
        width: 100%;
        font-style: normal;
        margin-bottom: $gutter;

        @include media-breakpoint-up(sm) {
          width: 50%;
        }

        li {
          padding: 4px 0;
        }
      }

      a {
        @include link(blue, gold);
      }

      .btn-phone {
        display: inline-block;
        background-color: col(blue);
        color: #ffffff;
        font-size: 12px;
        margin: 3px;
        padding: 3px;
        cursor: pointer;
      }
    }

    &-time {
      .title {
        @include montserrat-bold;

        margin-bottom: 5px;
      }

      &-high,
      &-down {
        margin-bottom: calc(#{$gutter} * 2);
      }
    }

    &-loyalty {
      margin-top: calc(#{$gutter} * 2);

      @include media-breakpoint-up(md) {
        margin-top: calc(#{$gutter} * 3);
      }

      .loyalty-detail {
        width: 100%;
        max-width: 700px;
        margin: auto;
      }
    }
  }
}
